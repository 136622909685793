import { useTheme } from '@emotion/react';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { ConditionalDisplay } from '../../ConditionalDisplay';

export const getNumOfDigits = (number) => Math.abs(number)?.toString()?.length;

export const RenderCustomizedLabel = ({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  value = { callsAnswered: 0, callsAbandoned: 0 },
  colors = [],
}) => {
  // const { x, y, width, height, value, colors } = props;
  const { callsAnswered, callsAbandoned } = value;
  const answeredLength = getNumOfDigits(callsAnswered) ?? 1;
  const abandonedLength = getNumOfDigits(callsAbandoned) ?? 1;

  const offset = (abandonedLength + answeredLength) * 4.5;
  return (
    <ConditionalDisplay bool={callsAnswered || callsAbandoned}>
      <g>
        <text
          x={x + width}
          y={y + height / 2}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          <tspan
            x={x + width + (offset < 1 || isNaN(offset) ? 5 : offset)}
            fill={colors[0]}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: '12px' }}
          >
            {callsAnswered}
          </tspan>
          <tspan
            dx={5}
            fill={colors[1]}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: '12px' }}
          >
            {callsAbandoned}
          </tspan>
        </text>
      </g>
    </ConditionalDisplay>
  );
};

export const MyStackedBarChart = ({ data }) => {
  const theme = useTheme();
  return (
    <ResponsiveContainer width="99%" height="99%">
      <BarChart
        data={data}
        layout="vertical"
        margin={{
          top: 20,
          right: 80,
          bottom: 5,
        }}
        barCategoryGap={'20%'}
      >
        <XAxis type="number" allowDecimals={false} />
        <YAxis type="category" dataKey="timeSlot" width={140} interval={0} />
        <Tooltip
          itemStyle={{ color: 'black' }}
          labelStyle={{ color: 'black' }}
        />
        <Legend wrapperStyle={{ paddingLeft: '45px' }} />
        <Bar
          dataKey="callsAnswered"
          name="Answered"
          stackId="a"
          unit={' call(s)'}
          fill={theme.palette.charts.success}
        />
        <Bar
          dataKey="callsAbandoned"
          name="Abandoned"
          stackId="a"
          unit={' call(s)'}
          fill={theme.palette.charts.failure}
        >
          <LabelList
            valueAccessor={valueAccessor('stack a')}
            content={
              <RenderCustomizedLabel
                colors={[
                  theme.palette.charts.success,
                  theme.palette.charts.failure,
                ]}
              />
            }
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export const valueAccessor = (name) => (item) => {
  return item ? item : 0;
};
