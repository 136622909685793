import { Tabs, Tab, Paper, styled } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { selectorToUserReducer } from '../../reducers/UserReducer';
import { reactRouterHistoryToCurrentPath } from '../../transformations/utils';
import { ConditionalDisplay } from '../ConditionalDisplay';
import { ReadOnlyWrap } from '../ReadOnlyWrap';

export const userAdgroupsToHasAccess = (user, adGroup) =>
  !adGroup?.length || adGroup?.some((g) => user[g]);

export const ProtectedRoutes = (props) => {
  const { links, mainPath } = props;
  const user = useSelector(selectorToUserReducer);
  const userAccessibleRoutes = links?.filter(({ adGroup }) =>
    userAdgroupsToHasAccess(user, adGroup),
  );
  const defaultRedirect = userAccessibleRoutes?.[0]?.routeName ?? '/';
  const viewRoutes = userAccessibleRoutes?.map(({ Component, routeName }) => (
    <Route
      exact={mainPath ? true : false}
      key={routeName}
      path={mainPath ? `${mainPath}${routeName}` : routeName}
      // Uncomment the line below and comment ReadOnlyWrap to ignore read-only pathing
      // component={Component}
    >
      <ReadOnlyWrap RenderProp={Component} {...props} />
    </Route>
  ));
  return (
    <>
      <ConditionalDisplay bool={mainPath}>
        <TabRouteComponent links={links} mainPath={mainPath} />
      </ConditionalDisplay>
      <Paper
        sx={{ boxSizing: 'border-box', padding: '20px', height: '100%' }}
        elevation={0}
      >
        <Switch>
          {viewRoutes}
          <ConditionalDisplay bool={mainPath}>
            <Redirect from={mainPath} to={`${mainPath}${defaultRedirect}`} />
          </ConditionalDisplay>
        </Switch>
      </Paper>
    </>
  );
};

const linkToTabComponent = ({ friendlyName }) => (
  <Tab key={friendlyName} label={friendlyName} />
);

const linksToRoutesAndTabs = (accessibleRoutes) =>
  accessibleRoutes?.map?.(linkToTabComponent) ?? [];

const StyledTabs = styled(Tabs)(() => ({
  height: '100%',
  '.MuiTabs-flexContainer': {
    height: '100%',
  },
}));
const TabRouteComponent = (props) => {
  const { links, mainPath } = props;
  const history = useHistory();
  const currentUrlLocation = reactRouterHistoryToCurrentPath(history);
  const user = useSelector(selectorToUserReducer);
  const userAccessibleRoutes = links?.filter(({ adGroup }) =>
    userAdgroupsToHasAccess(user, adGroup),
  );
  const handleChange = (_, newValue) => {
    history?.push(
      mainPath
        ? `${mainPath}${userAccessibleRoutes[newValue]?.routeName}`
        : userAccessibleRoutes[newValue]?.routeName,
    );
  };
  const tabValueIndex = useMemo(
    () =>
      userAccessibleRoutes?.findIndex(({ routeName }) =>
        currentUrlLocation?.includes(routeName),
      ) ?? 0,
    [currentUrlLocation, userAccessibleRoutes],
  );
  const viewTabs = linksToRoutesAndTabs(userAccessibleRoutes);
  return (
    <StyledTabs
      sx={{ height: '100%' }}
      onChange={handleChange}
      value={tabValueIndex === -1 ? 0 : tabValueIndex}
    >
      {viewTabs}
    </StyledTabs>
  );
};
export default TabRouteComponent;
