import React from 'react';
import { Box, Divider } from '@mui/material';
import { MapHorizontalBox, MapVerticalBox } from '..';
import { ConditionalDisplay } from '../../ConditionalDisplay';
import { GLOBAL_STATS_MAIN_METRIC } from '../../GlobalStats/GlobalStatsTableComponent/constants';
import { KeyedComponent } from '../../KeyedComponent';

export const CollectionOfBoxedInformation = ({
  titlesAndValues = [],
  showDivider = true,
  vertical = true,
}) => {
  const boxes = titlesAndValues?.map(
    KeyedComponent(vertical ? MapVerticalBox : MapHorizontalBox),
  );
  return (
    <>
      <ConditionalDisplay bool={boxes?.length}>
        <Box className={GLOBAL_STATS_MAIN_METRIC}>{boxes}</Box>
        <ConditionalDisplay bool={showDivider}>
          <Divider sx={{ width: '100%', marginTop: '20px' }} />
        </ConditionalDisplay>
      </ConditionalDisplay>
    </>
  );
};
