import React from 'react';
import CircleMetricWithTextComponent from '../CircleMetricWithTextComponent';
import MetricComponent from '../MetricComponent';
import WorkForceExpandedComponent from '../WorkForceExpandedComponent';

import './index.css';

export const WORKFORCE_DETAIL = 'work-force-detail',
  WORKFORCE_INTERVAL_STYLE = 'workforce-interval-metric',
  WORKFORCE_MONTHLY_STYLE = 'workforce-monthly-metric',
  WORKFORCE_MAIN_STYLE = 'workforce-main-metric',
  WORKFORCE_TITLE_STYLE = 'work-force-title',
  WORKFORCE_PANEL_CLASS = 'work-force-panel',
  CARD_CLASS = 'card',
  HEADING_CONTAINER = 'headingContainer',
  BACK_BTN_CLASS = 'backBtn',
  CurrentCircleMetricType = 'current';

const HeaderContainer = ({ displayName }) => (
  <div className={WORKFORCE_TITLE_STYLE}>{displayName}</div>
);
const WorkForceOverviewComponent = ({
  sla,
  monthlySLA,
  callsInQ,
  longestWait,
  abandonRate,
  asa,
}) => (
  <div className={WORKFORCE_DETAIL}>
    <div className={WORKFORCE_INTERVAL_STYLE}>
      <CircleMetricWithTextComponent
        serviceLevel={sla}
        type={CurrentCircleMetricType}
      />
    </div>
    <div className={WORKFORCE_MAIN_STYLE}>
      <MetricComponent label={callsInQ} value="Calls in Queue" />
      <MetricComponent label={longestWait} value="Longest Wait" />
      <MetricComponent label={abandonRate} value="Abandoned Rate" />
      <MetricComponent label={asa} value="ASA" />
    </div>
    <div className={WORKFORCE_MONTHLY_STYLE}>
      <CircleMetricWithTextComponent
        serviceLevel={monthlySLA}
        type={'monthly'}
      />
    </div>
  </div>
);
export const PartnerRowComponent = (props) => (
  <div className={WORKFORCE_PANEL_CLASS}>
    <HeaderContainer {...props} />
    <WorkForceOverviewComponent {...props} />
    <WorkForceExpandedComponent {...props} />
  </div>
);
export default PartnerRowComponent;
