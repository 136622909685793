import {
  onShoreWahSortKey,
  onShoreBamSortKey,
  outSourceOnShoreSortKey,
  offshoreSortKey,
  outsourcedOffshoreSortKey,
} from '../../reducers/TableDataReducer/constants';

import {
  gqlOffshoreKey,
  gqlOnshoreNonPPXKey,
  gqlOnShoreKey,
  gqlOutsourcedOffshoreKey,
  gqlOutsourcedOnshoreKey,
} from '../../data/constants';

/**
 * Check for whether passed subtype is included in array of allocated subtypes
 * @param {Array} includedSubTypes
 * @param {String} passedSubType
 * @returns true/false depending on if subtype exists
 */
export const checkIfSubtypeIncluded = (includedSubTypes, passedSubType) => {
  return includedSubTypes.some((e) => e.SubTargetTypeName === passedSubType);
};

/**
 * Remove a key that is being use in an array of objects from every object
 * @param {Array} pArr
 * @param {String} keyToRemove
 * @returns array of objects with key provided removed
 */
export const removeKeysFromArrOfObj = (pArr, keyToRemove) => {
  return pArr.map(({ [keyToRemove]: removed, ...keysToKeep }) => keysToKeep);
};

/**
 * Update user allocations while only including relevant Allocations
 * @param {Array} SubTargetAllocations
 * @param {Number} onshoreBamValue
 * @param {Number} onshoreWahValue
 * @param {Number} outsourcedOnshoreValue
 * @param {Number} offshoreValue
 * @param {Number} outsourcedOffshoreValue
 * @returns Curried reducer function that returns array of
 * updated user allocations based on passed Array of SubTargetAllocations
 */
export const updateUserAllocations =
  (
    SubTargetAllocations,
    onshoreBamValue,
    onshoreWahValue,
    outsourcedOnshoreValue,
    offshoreValue,
    outsourcedOffshoreValue,
  ) =>
  (accumulator, { SubTargetTypeName, ...other }) => {
    const allocationValueToReturnFormat = (Allocation) => ({
      ...other,
      SubTargetTypeName,
      Allocation,
    });
    if (checkIfSubtypeIncluded(SubTargetAllocations, SubTargetTypeName)) {
      switch (SubTargetTypeName) {
        case onShoreBamSortKey:
          accumulator.push(allocationValueToReturnFormat(onshoreBamValue));
          break;
        case onShoreWahSortKey:
          accumulator.push(allocationValueToReturnFormat(onshoreWahValue));
          break;
        case outSourceOnShoreSortKey:
          accumulator.push(
            allocationValueToReturnFormat(outsourcedOnshoreValue),
          );
          break;
        case offshoreSortKey:
          accumulator.push(allocationValueToReturnFormat(offshoreValue));
          break;
        case outsourcedOffshoreSortKey:
          accumulator.push(
            allocationValueToReturnFormat(outsourcedOffshoreValue),
          );
          break;
        default:
          accumulator.push({
            ...other,
            SubTargetTypeName,
          });
      }
    }
    return accumulator;
  };

/**
 * updates the returned value from api call for scheduler
 * @param {Array} SubTargetAllocations
 * @returns Curried function that removes Allocations that are not relevant
 * to passed Array of SubTargetAllocations
 */
export const removeMissingAllocations = (SubTargetAllocations) => (res) => {
  const isArray = Array.isArray(res);
  const onshorePPXIncluded = checkIfSubtypeIncluded(
    SubTargetAllocations,
    onShoreBamSortKey,
  );
  const onShoreNonPPX = checkIfSubtypeIncluded(
    SubTargetAllocations,
    onShoreWahSortKey,
  );
  const offShoreIncluded = checkIfSubtypeIncluded(
    SubTargetAllocations,
    offshoreSortKey,
  );
  const outSourcedOnShoreIncluded = checkIfSubtypeIncluded(
    SubTargetAllocations,
    outSourceOnShoreSortKey,
  );
  const outSourcedOffShoreIncluded = checkIfSubtypeIncluded(
    SubTargetAllocations,
    outsourcedOffshoreSortKey,
  );
  let returnRes = res;
  // Remove the allocations that are not relevant to a client
  if (!onshorePPXIncluded) {
    returnRes = ternaryComparisonWithCallbacks(
      isArray,
      removeKeysFromArrOfObj,
      removeKeyFromObject,
      returnRes,
      gqlOnShoreKey,
    );
  }
  if (!onShoreNonPPX) {
    returnRes = ternaryComparisonWithCallbacks(
      isArray,
      removeKeysFromArrOfObj,
      removeKeyFromObject,
      returnRes,
      gqlOnshoreNonPPXKey,
    );
  }
  if (!offShoreIncluded) {
    returnRes = ternaryComparisonWithCallbacks(
      isArray,
      removeKeysFromArrOfObj,
      removeKeyFromObject,
      returnRes,
      gqlOffshoreKey,
    );
  }
  if (!outSourcedOnShoreIncluded) {
    returnRes = ternaryComparisonWithCallbacks(
      isArray,
      removeKeysFromArrOfObj,
      removeKeyFromObject,
      returnRes,
      gqlOutsourcedOnshoreKey,
    );
  }
  if (!outSourcedOffShoreIncluded) {
    returnRes = ternaryComparisonWithCallbacks(
      isArray,
      removeKeysFromArrOfObj,
      removeKeyFromObject,
      returnRes,
      gqlOutsourcedOffshoreKey,
    );
  }
  return returnRes;
};

/**
 *
 * @param {Bool} ifThis
 * @param {Callback} thenDoThis
 * @param {Callback} otherwise
 * @param {Array|Object} res
 * @param {String} key
 * @returns Callback which returns an updated array of objects
 * or an updated object dpending on conditional
 */
export const ternaryComparisonWithCallbacks = (
  ifThis,
  thenDoThis,
  otherwise,
  res,
  key,
) => {
  return ifThis ? thenDoThis(res, key) : otherwise(res, key);
};

/**
 *
 * @param {Object} object
 * @param {String} key
 * @returns Object with removed field
 */
export const removeKeyFromObject = (object, key) => {
  delete object[key];
  return object;
};
