import {
  getMinutesElapsed,
  addLessThanSymbol,
  dataFormatToPartnerData,
  arrayToConditionallyRemoveOrAddElement,
  getSecondsElapsedFromSeconds,
  getCurrentTimeInSeconds,
} from '../../transformations/utils';
import { abcSort } from '../../components/Table/transformations';
import { selectionAll } from './index';

const inTimeTransform = (inTime) =>
  inTime && typeof inTime === 'string' ? Number(inTime) : inTime;

export const stringToAppendedMinuteAnnotation = (str) => `${str} (m)`;
export const inTimeToLapsedTime = (inTime) => {
  const timeStamp = inTimeTransform(inTime);
  const currentTime = Date.now();
  const modifiedTimeInStatus = getMinutesElapsed(currentTime, timeStamp);
  return addLessThanSymbol(modifiedTimeInStatus) + '';
};

/**
 * Takes a string or int Date and calculates total seconds elapsed between
 * two times then and now
 * now - then === what we are getting
 * @param {String | Int} inTime
 * @returns total seconds since last epoch
 */
export const inTimeToLapsedTimeSeconds = (inTime) => {
  const timeStamp = inTimeTransform(inTime);
  const currentTime = getCurrentTimeInSeconds();
  const modifiedTimeInStatus = getSecondsElapsedFromSeconds(
    currentTime,
    timeStamp,
  );
  return modifiedTimeInStatus;
};

/**
 * Takes a number of seconds and converts to m:s format
 * @param {Number} totalSeconds
 * @returns {String} in format '00:00'
 */
export const returnFormattedTime = (totalSeconds) => {
  if (totalSeconds < 0) return '< 0';
  const minutes = Math.floor(totalSeconds / 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });
  const seconds = (totalSeconds % 60).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  });
  return `${minutes}:${seconds}`;
};

export const workerlistToABCSortByStatus = (list) =>
  list.sort(({ status: s1 }, { status: s2 }) =>
    s1 < s2 ? -1 : s1 > s2 ? 1 : 0,
  );
export const syncToWorkForceGlobalState = (payload) => (lob) => {
  const keyedGroups = Object.keys(payload);
  return keyedGroups.map((group) =>
    dataFormatToPartnerData({
      [group]: { ...payload[group] },
    })(lob),
  );
};
export const globalStatToSiteSelection =
  (value) =>
  ({ wfSite: { selection = [] } }) => {
    const wfSiteFilter = Object.keys(value).reduce((acc, group) => {
      const { summary_stats: { site = null } = {} } = value[group];
      if (site && !acc.includes(site)) acc.push(site);
      return acc;
    }, []);
    return [...new Set([...selection, ...wfSiteFilter])];
  };
export const updateSelectedCoach =
  (activeLob) =>
  (activeGroup) =>
  ({ coach: { active: activeCoach, all }, supervisorList }) => {
    const availableCoaches = activeLob.reduce((acc, lob) => {
      const lobs = supervisorList[lob];
      if (lobs) {
        Object.keys(lobs).forEach((g) => {
          if (activeGroup.includes(g)) {
            acc.push(...lobs[g]);
          }
        });
      }
      return acc;
    }, []);
    let coach = all
      ? availableCoaches
      : activeCoach.filter((coach) => availableCoaches.includes(coach));
    if (!coach.length && availableCoaches.length) {
      coach = [availableCoaches[0]];
    }
    return coach;
  };

export const expertsToAvailableLocations = (xprt) =>
  xprt.reduce((acc, { site = null }) => {
    if (site && !acc.includes(site)) {
      acc.push(site);
    }
    return acc;
  }, []);
export const selectionToArrayCompareLength =
  ({ selection = [] } = {}) =>
  (arr) =>
    arr.length === selection.length;

export const selectionChangeSwitchCaseSetup =
  (type) =>
  (payload) =>
  (bulkVariable) =>
  ({ all = false, selection = [], active = [] }) =>
  (transform) => {
    if (payload === selectionAll) {
      const state = {
        all: !all,
        active: !all ? selection : [selection[0]],
      };
      return transform(state);
    }
    if (type === bulkVariable) {
      const update = arrayToConditionallyRemoveOrAddElement(active)(payload);
      return transform({
        active: update.length ? update : [payload],
        all: false,
      });
    } else {
      return transform({ active: [payload], all: false });
    }
  };

export const updateSelectedGroup =
  (activeLob) =>
  (activeGroup) =>
  ({ group: { available } = {} }) => {
    const allGroups = Object.keys(available).reduce((acc, lob) => {
      if (activeLob.includes(lob)) {
        acc.push(...available[lob]);
      }
      return acc;
    }, []);
    const groupSelection = [...new Set([...allGroups])];
    const newGroups = activeGroup.filter((g) => groupSelection.includes(g));
    if (!newGroups.length) newGroups.push(groupSelection[0]);
    return {
      active: newGroups,
      selection: groupSelection,
    };
  };

export const coachSelection = (coachList) => (activeLob) => (activeGroup) => {
  return Object.keys(coachList)
    .reduce((acc, lob) => {
      if (!activeLob.includes(lob)) return acc;
      Object.keys(coachList[lob]).forEach((group) => {
        if (
          activeGroup.includes(group) &&
          Object.keys(coachList[lob][group]).length !== 0
        ) {
          acc = [...new Set([...coachList[lob][group], ...acc])];
        }
      });
      return acc;
    }, [])
    .sort(abcSort);
};
