import { useTheme } from '@emotion/react';
import React from 'react';

import { Cell, Legend, Pie, PieChart } from 'recharts';

export const MyPieChart = ({ data = [], outerR = 50, innerR = 35 }) => {
  const theme = useTheme();
  return (
    // <Box sx={{ width: 'fit-content' }}>
    <PieChart width={230} height={180}>
      <Pie
        data={data}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={outerR}
        innerRadius={innerR}
        label
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            label={entry.name}
            fill={theme.palette.charts[entry.color]}
          />
        ))}
      </Pie>
      <Legend iconSize={12} />
    </PieChart>
    // </Box>
  );
};
