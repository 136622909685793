export const mappedComponent = (Component) => (arr) => {
  console.log('Heloo');
  return (
    <>
      {arr.map((data) => (
        <Component {...data} />
      ))}
    </>
  );
};

const getPastDateString = (offset) => {
  const prevDate = new Date();
  prevDate.setDate(prevDate.getDate() - offset);
  return prevDate.toLocaleDateString();
};

const toDaysHoursMins = (milliseconds) => {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;

  return {
    hours: hours % 24 | 0,
    minutes: minutes % 60 | 0,
  };
};

const isToday = (current, timeStampDate) => {
  return new Date(current).toLocaleDateString() === timeStampDate;
};

const formatTodayString = (current, timeStamp) => {
  const { hours, minutes } = toDaysHoursMins(current - timeStamp);
  if (hours) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else {
    return !minutes
      ? 'now'
      : `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  }
};

const isYesterday = (timeStampDate) => {
  const yesterday = getPastDateString(1);
  return yesterday === timeStampDate;
};

const formatYesterdayString = (timeStamp) => {
  const timeString = new Date(timeStamp).toLocaleTimeString();
  return `yesterday at ${timeString}`;
};

const isThisWeek = (timeStamp) => {
  const thisWeek = getPastDateString(6);
  const thisWeekInMS = new Date(thisWeek).getTime();
  return timeStamp < thisWeekInMS;
};

const formatThisWeekString = (timeStamp) => {
  const daysOfTheWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const date = new Date(timeStamp);
  return `${daysOfTheWeek[date.getDay()]} at ${date.toLocaleTimeString()}`;
};

const formatDateTime = (timeStamp) => {
  const date = new Date(timeStamp);
  return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
};

export const processTime = (timeStamp) => {
  const current = Date.now();
  const timeStampDate = new Date(timeStamp).toLocaleDateString();
  switch (true) {
    case isToday(current, timeStampDate):
      return formatTodayString(current, timeStamp);
    case isYesterday(timeStampDate):
      return formatYesterdayString(timeStamp);
    case isThisWeek(timeStamp):
      return formatThisWeekString(timeStamp);
    default:
      return formatDateTime(timeStamp);
  }
};

export const lobToNotificationSyncDocTitle = (lob) =>
  `${lob}_orbit_notifications`;
