import React, { useState } from 'react';
import { Addchart, SwapHoriz } from '@mui/icons-material';
import { useWFProps } from './transformations';
import PartnerRowComponent from '../PartnerRowComponent';
import { SECTION_CLASS } from '../../data/constants';
import { serviceLevelToClass } from '../CircleMetricComponent';
import { NotificationCard } from '../Notifications';
import { GraphQlTable, GraphqlRowHelperWrap } from '../ExpertStatus';
import { WORKFORCE_TABLE_EXPANDED } from '../../services/KibanaEvents';
import './index.css';
import {
  Stack,
  Tooltip,
  Badge,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slide,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';
import { allDataRowViews } from '../WorkForceExpandedComponent/transformations';
import { arrayToConditionallyRemoveOrAddElement } from '../../transformations/utils';
import { useDispatch, useSelector } from 'react-redux';
import { taskQHeaderArrayToUpdateAppSelect } from '../../reducers/UserReducer';
import {
  wfQueueGraphQlHeader,
  wfSlGraphQlHeader,
  wfCallsInQueueGraphQlHeader,
  wfLongestWaitGraphQlHeader,
  wfAbandonedRateGraphQlHeader,
  wfTotalCallsGraphQlHeader,
  wfEnglishAvailableGraphQlHeader,
  wfASAGraphQlHeader,
  wfBilingualAvailableGraphQlHeader,
  wfPercentExpertAvailGraphQlHeader,
} from '../../reducers/TableDataReducer/datatypes';

const WorkForceTableRow = (props = {}) => {
  const {
    displayName = 'display name',
    sla,
    callsInQ,
    longestWait,
    abandonRate,
    totalCalls,
    expertsAvailEn,
    expertsAvailBil,
    asa,
    expertsAvailablePercent,
    notifications,
  } = props;
  const SL_TEXT_COLOR = serviceLevelToClass(sla);
  const notificationLength = notifications?.length;
  return (
    <>
      <Typography>
        <Tooltip
          sx={{ padding: 0, margin: 0 }}
          placement="right"
          title={
            !notificationLength ? (
              ''
            ) : (
              <Stack
                spacing={2}
                className="handle-custom-scroll"
                maxHeight="95vh"
              >
                {notifications.map((notification) => (
                  <NotificationCard
                    key={JSON.stringify(notification)}
                    {...notification}
                  />
                ))}
              </Stack>
            )
          }
        >
          <Badge badgeContent={notificationLength} color="primary">
            {displayName}
          </Badge>
        </Tooltip>
      </Typography>

      <Typography className={SL_TEXT_COLOR}>
        <b>{sla}</b>
      </Typography>
      <Typography>{callsInQ}</Typography>
      <Typography>{longestWait}</Typography>
      <Typography>{abandonRate}</Typography>
      <Typography>{totalCalls}</Typography>
      <Typography>{expertsAvailEn}</Typography>
      <Typography>{expertsAvailBil}</Typography>
      <Typography>{asa}</Typography>
      <Typography>{expertsAvailablePercent}</Typography>
    </>
  );
};

const DataHeaderSelectionTable = ({ title, headers, change } = {}) => {
  const headerView = headers?.map((h) => (
    <TableRow key={h} onClick={() => change(h)}>
      <TableCell component="th" scope="row">
        {h}
      </TableCell>
    </TableRow>
  ));
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: 250 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography color="primary">{title}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{headerView}</TableBody>
      </Table>
    </TableContainer>
  );
};
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TaskQueueDataViewSelection = ({ open = false, close } = {}) => {
  const dispatch = useDispatch();
  const stateEnabledQs = useSelector(
    (state = {}) => state?.user?.userSettings?.taskQueueViews ?? [],
  );
  const [enabledQs, setEnabledQs] = useState(stateEnabledQs);
  const disabledHeaders = allDataRowViews?.filter(
    (h) => !enabledQs?.includes(h),
  );
  const taskQRowClick = (title) => {
    setEnabledQs((queues) =>
      arrayToConditionallyRemoveOrAddElement(queues)(title),
    );
  };
  const saveSelection = () => {
    dispatch(taskQHeaderArrayToUpdateAppSelect(enabledQs));
    close();
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      keepMounted
    >
      <DialogTitle>{'Task Queue Data Row'}</DialogTitle>
      <DialogContent className="flex-row-parent">
        <DataHeaderSelectionTable
          title="Enabled"
          headers={enabledQs}
          change={taskQRowClick}
        />
        <SwapHoriz fontSize="large" />
        <DataHeaderSelectionTable
          title="Disabled"
          headers={disabledHeaders}
          change={taskQRowClick}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={saveSelection}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
const WorkforceExpandedPanel = (props) => {
  const [open, setOpen] = useState(false);
  const onClickUpdated = () => setOpen((x) => !x);
  return (
    <div className={SECTION_CLASS}>
      <IconButton onClick={onClickUpdated} color="primary">
        <Addchart />
      </IconButton>
      <TaskQueueDataViewSelection open={open} close={onClickUpdated} />
      <PartnerRowComponent {...props} />
    </div>
  );
};

const PartnerListContainerComponent = () => (
  <GraphQlTable
    analyticsTag={WORKFORCE_TABLE_EXPANDED}
    tableHandler={useWFProps}
    Row={WorkForceTableRow}
    ExpandedRow={WorkforceExpandedPanel}
    demensions="250px repeat(9, minmax(30px, 8.5%))"
    headers={[
      wfQueueGraphQlHeader,
      wfSlGraphQlHeader,
      wfCallsInQueueGraphQlHeader,
      wfLongestWaitGraphQlHeader,
      wfAbandonedRateGraphQlHeader,
      wfTotalCallsGraphQlHeader,
      wfEnglishAvailableGraphQlHeader,
      wfBilingualAvailableGraphQlHeader,
      wfASAGraphQlHeader,
      wfPercentExpertAvailGraphQlHeader,
    ]}
  />
);

export default PartnerListContainerComponent;
