import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { HelixUser } from '../../data/datatypes';
import { useLocalStorage } from '../../hooks/UseLocalStorage';
import GetUserSettings from '../../services/GetUserSettings';
import config from '../../config.json';
import { getEnvironment } from '../../utils/applicationEnvironment';
import { noAutoClosePopWarningMessage } from '../../services/sweetAlerts';
import {
  userAuthToUserSignIn,
  selectorToUserAuthenticated,
  tokenToAdGroupPerms,
  valueToSaveUserSettingsAction,
  orbitEditorCheckVal,
  ubifAdminCheckVal,
  orbitTACheckVal,
  workforceAdminAdGroup,
  selectorToHelixUser,
  orbitUploadCheckVal,
} from '../../reducers/UserReducer';
import kibanaEventTrigger, {
  KIBANA_FAILED_STATE,
  KIBANA_USER_LOG_IN_EVENT,
} from '../../services/KibanaEvents';
const noAuthPages = ['help'];
const env = getEnvironment();
Amplify.configure(config[env].Amplify);

// awsUserToPayload :: AwsUser -> Payload
export const awsUserToPayload = (awsUser) =>
  awsUser.signInUserSession.idToken.payload;

// identitiesToUserId :: [Identity] -> UserId
export const identitiesToUserId = (identities) => identities[0].userId;

// payloadAndUserIdToHelixUser :: Payload -> UserId -> HelixUser
export const payloadAndUserIdToHelixUser =
  ({ given_name, family_name, email, 'custom:employeeID': employeeId }) =>
  (userId) =>
    new HelixUser(given_name, family_name, email, userId, employeeId);
const payloadToHelixUser = (payload) =>
  payloadAndUserIdToHelixUser(payload)(identitiesToUserId(payload.identities));

// awsUserToHelixUser :: AwsUser -> HelixUser
const awsUserToHelixUser = (awsUser) =>
  payloadToHelixUser(awsUserToPayload(awsUser));

const getHelixUser = async () => {
  const awsUser = await Auth.currentAuthenticatedUser();
  const helixUser = awsUserToHelixUser(awsUser);
  return helixUser;
};

const setSignInAuthState = (dispatch) =>
  dispatch(
    userAuthToUserSignIn({
      authenticated: true,
    }),
  );

const signIn = (dispatch) => {
  setSignInAuthState(dispatch);
};
const initialUserDataFetch = async (dispatch) => {
  const helixUser = await getHelixUser();
  dispatch(userAuthToUserSignIn({ helixUser }));
  cognitoUserAuth(dispatch);
};
const cognitoUserAuth = (dispatch) => {
  const poolData = {
    UserPoolId: config[env].Amplify.Auth.userPoolId,
    ClientId: config[env].Amplify.Auth.userPoolWebClientId,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser !== null) {
    cognitoUser.getSession((err, session) => {
      if (err) {
        console.error(err);
      } else if (!session.isValid()) {
        /*eslint no-console: "off"*/
        console.log('Invalid session.');
      } else {
        // need to set on store instead of reading from storage throughout the application
        const idToken = session.getIdToken().payload['custom:groups'] || '';
        let adGroupAction = tokenToAdGroupPerms(idToken);
        if (['DEV', 'QA', 'LOCAL'].includes(env)) {
          // let users have access to all pages
          // only in dev / local environment
          adGroupAction = tokenToAdGroupPerms(
            orbitEditorCheckVal +
              ubifAdminCheckVal +
              workforceAdminAdGroup +
              orbitUploadCheckVal +
              orbitTACheckVal,
          );
        }
        /*eslint no-console: "off"*/
        console.log('User found. Setting ad group now...');
        dispatch(adGroupAction);
      }
    });
  } else {
    /*eslint no-console: "off"*/
    console.log('User not found.');
  }
};

export const saveUserSettings = (dispatch) => (response) => {
  const userSettingsAction = valueToSaveUserSettingsAction(response);
  dispatch(userSettingsAction);
};

const checkUserAuth = async (dispatch) => {
  const isLoggedIn = await Auth.currentAuthenticatedUser()
    .then(() => true)
    .catch(() => false);
  if (!isLoggedIn) {
    Auth.federatedSignIn({
      provider: config[env].Amplify.Auth.oauth.identityProvider,
    });
  } else {
    setSignInAuthState(dispatch);
  }
};
const oneMinuteInMilliseconds = 1000 * 60;
const twelveHoursInMilliseconds = oneMinuteInMilliseconds * 60 * 12;
const fifteenMinutesInMilliseconds = oneMinuteInMilliseconds * 15;
const clearSession = async (setSessionToken) => {
  console.log('clearing users session, and forcing a relogin');
  try {
    localStorage.clear();
    sessionStorage.clear();
    setSessionToken('session-tracking', twelveHoursInMilliseconds);
    await Auth.signOut({ global: true });
    Auth.federatedSignIn({
      provider: config[env].Amplify.Auth.oauth.identityProvider,
    });
  } catch (err) {
    console.error('Error clearing users session ', err);
  }
};
const orbitSessionToken = 'orbit-app-session';

/**
 * This is used to manage the users session after they are roaming the app
 * @param LocalStorageValue LocalStorage value, with expiry
 * @param setSessionToken Function
 * @param alertedUser Boolean
 * @param {*} alertUserForSessionEnd Function for alerting the user
 */
const orbitSessionTokenToHandleUserSession =
  ({ expiry } = {}, setSessionToken, alertedUser, alertUserForSessionEnd) =>
  () => {
    const now = Date.now();
    const sessionEndsWithin15Min = expiry - now < fifteenMinutesInMilliseconds;
    if (sessionEndsWithin15Min && !alertedUser) {
      alertUserForSessionEnd();
      return;
    }
    if (!expiry || now > Number(expiry)) {
      clearSession(setSessionToken);
    }
  };
const AuthHub = () => {
  const dispatch = useDispatch();

  const [alertedUser, setAlertedUser] = useState(false);
  const [sessionToken, setSessionToken] = useLocalStorage(orbitSessionToken);
  const authenticated = useSelector(selectorToUserAuthenticated);
  const helixUser = useSelector(selectorToHelixUser);

  const alertUserForSessionEnd = () => {
    setAlertedUser(true);
    noAutoClosePopWarningMessage(
      'Session Reload',
      'Your orbit UI 12 hour session is set to expire.The app will reload in 15m',
    );
  };

  // everytime on page load
  useEffect(() => {
    let intervalSessionCheck;
    const asynced = async () => {
      const isAuthPage = !noAuthPages.includes(
        window.location.pathname.slice(1),
      );
      if (isAuthPage) {
        if (!sessionToken) {
          // page load clearing session
          await clearSession(setSessionToken);
        } else {
          await checkUserAuth(dispatch);

          // checking every 15 minutes on a users session status
          intervalSessionCheck = setInterval(
            orbitSessionTokenToHandleUserSession(
              sessionToken,
              setSessionToken,
              alertedUser,
              alertUserForSessionEnd,
            ),
            fifteenMinutesInMilliseconds,
          );
        }
      }
    };
    asynced();

    return () => clearInterval(intervalSessionCheck);
  }, [sessionToken, alertedUser]);
  // runs once after the user successfully signs in
  useEffect(() => {
    if (authenticated) {
      initialUserDataFetch(dispatch);
    }
  }, [authenticated]);

  useEffect(() => {
    if (helixUser.employeeId.length) {
      GetUserSettings(dispatch)(helixUser);
      kibanaEventTrigger(formatUserDataForKibana(helixUser));
    }
  }, [helixUser.employeeId]);

  return <React.Fragment />;
};

const formatUserDataForKibana = (helixUser) => {
  const body = {
    event: KIBANA_USER_LOG_IN_EVENT,
    employeeId: helixUser?.employeeId,
    userId: helixUser?.userId,
  };
  return body;
};

export default AuthHub;
