import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../images/orbitLogo.svg';
import { DialogHeaderWithClose } from '../AddNewExpertButton';
import darkModeLogo from '../../images/darkModeOrbitLogo.svg';
import { withLoggingAnalytics } from '../../services/KibanaEvents';
import {
  valueToSaveDarkMode,
  lobToGroupToUpdateAppSelect,
  selectorToUserDarkMode,
} from '../../reducers/UserReducer';
import { lobFilterToGroupFilterToAvailableToSelection } from '../../reducers/SelectionReducer/transformations';
import { arrayToConditionallyRemoveOrAddElement } from '../../transformations/utils';
import {
  Brightness2TwoTone,
  Brightness2Outlined,
  Settings,
} from '@mui/icons-material';
import {
  Toolbar,
  useMediaQuery,
  Button,
  IconButton,
  Typography,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  Chip,
  Box,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MobileNavBar from '../RoutingComponent/MobileNavRouting/index';
import ToggleSwitch, { ToggleSwitchModel } from '../ToggleSwitchComponent';

import './index.scss';
import TabRouteComponent from '../RoutingComponent';

const appProperties = require('appProperties');
const versionNumber = appProperties.orbit_version;

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: theme?.palette?.mode === 'dark' ? '#111' : '#FFFFFF',
  borderBottom: `1px solid ${
    theme?.palette?.mode === 'dark' ? 'rgb(128, 128, 128)' : 'rgb(51, 51, 51)'
  }`,
  zIndex: 46,
  position: 'sticky',
  top: 0,
}));
const DarkModeMoonIcon = ({ darkMode, dispatch }) => {
  const onClick = () => dispatch(valueToSaveDarkMode(darkMode ? false : true));
  return (
    <IconButton onClick={withLoggingAnalytics('dark-mode-button', onClick)}>
      {darkMode ? (
        <Brightness2TwoTone color="primary" fontSize="large" />
      ) : (
        <Brightness2Outlined color="primary" fontSize="large" />
      )}
    </IconButton>
  );
};
const MobileFriendlyNavbar = (props) => {
  const isMobile = useMediaQuery('(max-width:1330px)');
  return isMobile ? (
    <MobileNavBar {...props} />
  ) : (
    <TabRouteComponent links={props?.links} />
  );
};
const SelectionDisplay = ({ title, selection, selected, size, change }) => {
  const isLarge = size === 'large';
  const singleClick = (x) => {
    change((a) => arrayToConditionallyRemoveOrAddElement(a)(x));
  };
  const isAll = !selection?.length
    ? false
    : selection?.length <= selected?.length;
  const allClick = () => (isAll ? change([]) : change(selection));
  return (
    <Box>
      <Stack flexDirection="row" gap={2} alignItems="center">
        <Typography color="primary" variant="subtitle1">
          {title}
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={isAll}
              onChange={withLoggingAnalytics(
                'user-settings-all-toggle-clicked',
                allClick,
              )}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="ALL"
        />
      </Stack>
      <Stack
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="start"
        alignItems="center"
        gap={2}
      >
        {selection?.map((x, i) => {
          const isSelected = selected?.includes(x);
          return (
            <Chip
              onClick={() => singleClick(x)}
              sx={{ width: isLarge ? '200px' : '130px' }}
              label={x}
              color={isSelected ? 'primary' : 'default'}
              key={x + i}
              variant={'outlined'}
            />
          );
        })}
      </Stack>
    </Box>
  );
};
const UserAppSelect = ({ dispatch }) => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);
  const lobSelected = useSelector(
    (state) => state?.user?.userSettings?.selectedLob ?? [],
  );
  const groupSelected = useSelector(
    (state) => state?.user?.userSettings?.selectedQueue ?? [],
  );
  const availableLob = useSelector(
    (state) => state?.selection?.lobSelection ?? [],
  );
  const available = useSelector((state) => state?.selection?.available ?? []);
  const [localGroupSelected, setGroup] = useState(groupSelected);
  const [localLobSelected, setLob] = useState(lobSelected);
  const [groupSelection, setGroupSelection] = useState([]);
  const saveSettings = () => {
    dispatch(lobToGroupToUpdateAppSelect(localLobSelected)(localGroupSelected));
    onClose();
  };
  const noLobSelected = !localLobSelected?.length;
  const disabledSubmit = noLobSelected || !localGroupSelected?.length;
  useEffect(() => {
    if (noLobSelected) {
      setGroupSelection([]);
    } else {
      const { groupSelection } = lobFilterToGroupFilterToAvailableToSelection(
        localLobSelected,
      )([])(available);
      setGroupSelection(groupSelection);
      setGroup(localGroupSelected?.filter((e) => groupSelection?.includes(e)));
    }
  }, [localLobSelected]);
  const reset = () => {
    setLob(lobSelected);
    setGroup(groupSelected);
  };
  useEffect(() => {
    reset();
  }, [open]);
  return (
    <>
      <IconButton
        onClick={withLoggingAnalytics('user-settings-icon-button', onOpen)}
      >
        <Settings fontSize="large" color="primary" />
      </IconButton>
      <Dialog open={open} onClose={onClose}>
        <DialogHeaderWithClose close={onClose} title={'Edit Settings'} />
        <DialogContent>
          <Stack gap={4}>
            <SelectionDisplay
              change={setLob}
              title="Lob Filters"
              selection={availableLob}
              selected={localLobSelected}
            />
            <SelectionDisplay
              change={setGroup}
              size="large"
              title="Group Filters"
              selection={groupSelection}
              selected={localGroupSelected}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack gap={2} flexDirection="row">
            <Button
              variant="text"
              onClick={withLoggingAnalytics(
                'user-settings-reset-button',
                reset,
              )}
            >
              Reset Changes
            </Button>
            <Button
              disabled={disabledSubmit}
              onClick={withLoggingAnalytics(
                'user-settings-submit-button',
                saveSettings,
              )}
              variant="contained"
            >
              Submit
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
const Navbar = ({ children, ...props }) => {
  const darkMode = useSelector(selectorToUserDarkMode);
  const selectedInterval = useSelector(
    (state) => state?.expert?.selectedInterval ?? '',
  );
  const toggleProps = new ToggleSwitchModel(props?.dispatch, {
    selectedInterval,
  });
  return (
    <>
      <StyledToolBar>
        <Link to="/work-force">
          <div className="logo-wrapper">
            <img src={darkMode ? darkModeLogo : logo} />
            <Typography
              color="primary"
              className="app-version-number"
              fontSize={13}
            >
              v{versionNumber}
            </Typography>
          </div>
        </Link>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width={'100%'}
          height={'64px'}
        >
          <MobileFriendlyNavbar {...props} />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignSelf="flex-end"
            alignItems="center"
          >
            <ToggleSwitch {...toggleProps} />
            <DarkModeMoonIcon darkMode={darkMode} {...props} />
            <UserAppSelect {...props} />
          </Stack>
        </Stack>
      </StyledToolBar>
      {children}
    </>
  );
};

export default React.memo(Navbar);
