import React, { useEffect } from 'react';
import { GraphQlTable } from '../ExpertStatus';
import {
  csvDownloadLinkGraphQLHeader,
  dateOfUploadGraphQLHeader,
  groupGraphQLHeader,
  lobGraphQLHeader,
  numberOfProfilesUpdatedGraphQLHeader,
  successStatusGraphQLHeader,
} from '../../reducers/TableDataReducer/datatypes';

import './index.scss';
import { Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUpsellUploadTableSort,
  upsellUploadTableResponseToState,
} from '../../reducers/TableDataReducer';
import {
  dateToFormattedAwsDate,
  sleepWellCb3x,
} from '../../transformations/utils';
import { API } from 'aws-amplify';
import { fetchUpsellQueryString } from '../../data/queries';
import { functionToHandleIncomingSort } from '../../reducers/TableDataReducer/transformations';

export const DownloadURL = ({ link, displayText }) => (
  <a href={link} className="download-link" download>
    {displayText}
  </a>
);

export const UpsellRow = ({
  uploadDate = '',
  lob = '',
  client = '',
  url = '',
  uploadCount = '',
  status = true,
}) => {
  const date = dateToFormattedAwsDate(uploadDate);
  if (lob && client)
    return (
      <>
        <Typography>{date}</Typography>
        <Typography>{lob}</Typography>
        <Typography>{client}</Typography>
        <DownloadURL link={url} displayText="Link to .csv" />
        <Typography>{uploadCount}</Typography>
        <Typography color={status ? 'green' : 'red'}>
          {status ? 'Success' : 'Failure'}
        </Typography>
      </>
    );
  else return <>Please wait, loading new data</>;
};

export const useUpsellUploadGraphQLFetch = () => {
  const dispatch = useDispatch();
  const { items, loading, sort, refetch } = useSelector(
    (state) => state?.tableData?.upsellUpload,
  );
  useEffect(() => {
    (async function () {
      const variables = {
        ...sort,
        limit: 30,
      };
      console.log('[ GraphQL Query Fetching... ] ', { ...variables });
      const selections = await sleepWellCb3x(async () => {
        const response = await API.graphql({
          query: fetchUpsellQueryString,
          variables,
        });
        dispatch(
          upsellUploadTableResponseToState(
            response?.data?.searchUpsellUploadNotifications?.items ?? [],
          ),
        );
      });
    })();
  }, [refetch, sort, loading]);
  const sortKeyToSortDirection = functionToHandleIncomingSort((payload) =>
    dispatch(setUpsellUploadTableSort(payload)),
  );
  return {
    loading,
    items,
    sort,
    setSort: sortKeyToSortDirection,
  };
};

export const UpsellUpdateActivityTable = () => {
  return (
    <Paper className="upsell-upload-table">
      <GraphQlTable
        hasEditPermissions={false}
        demensions="repeat(6, 16%)"
        tableHandler={useUpsellUploadGraphQLFetch}
        Row={UpsellRow}
        includeFooter={false}
        headers={[
          dateOfUploadGraphQLHeader,
          lobGraphQLHeader,
          groupGraphQLHeader,
          csvDownloadLinkGraphQLHeader,
          numberOfProfilesUpdatedGraphQLHeader,
          successStatusGraphQLHeader,
        ]}
      />
    </Paper>
  );
};
