import swal from 'sweetalert2';
import { API } from 'aws-amplify';
import {
  postApiData,
  deleteApiData,
  singleGqlQueryGetAllItemsUntilNullToken,
  apiKeyToFormattedHeader,
} from './shared';
import { getEnvironment } from '../utils/applicationEnvironment';
import {
  noAutoClosePopFailMessage,
  noAutoClosePopSuccessMessage,
  popHideSuccessMsg,
  popCustomHtmlMessage,
  popHideFailMessage,
} from './sweetAlerts';
import config from '../config.json';
import kibanaEventTrigger, {
  KIBANA_UPDATE_EXPERT_ATTRIBUTE,
  KIBANA_FAILED_STATE,
  KIBANA_SUCCESS_STATE,
  KIBANA_NEW_EXPERT_ADDED,
  KIBANA_NEW_BULK_EXPERT_ADDED,
  KIBANA_EDIT_EXPERT,
  KIBANA_UPDATE_EXPERT_EXTENSION_MARKET,
} from './KibanaEvents';
import {
  transformJsonString,
  HANDLE_BAD_TASKQUEUE_DATA_TO_BE_REMOVED_ONCE_DATA_FIXED,
  baseStringToArrayField,
} from '../reducers/TableDataReducer/transformations';
const env = getEnvironment();
const expertAttributeApiKey = config[env].API_AUTH_KEYS.EXPERT_ATTRIBUTE_KEY;
const DEFAULT_ERROR = 'Invalid Employee ID';
const transformExpertForFetchExpertSkills = ({
  data: { getWorker: { routing = {}, ...otherProps } } = {},
}) => {
  const transformRouting =
    HANDLE_BAD_TASKQUEUE_DATA_TO_BE_REMOVED_ONCE_DATA_FIXED(
      transformJsonString(routing),
    );
  return {
    routing: transformRouting,
    ...otherProps,
  };
};
export const fetchExpertSkills = async (worker_Id) => {
  const query = `
    query MyQuery($worker_Id: String!) {
      getWorker(worker_Id: $worker_Id){
       routing
       lob
       group
      }
    }`;
  const expert = await API.graphql({
    query,
    variables: {
      worker_Id,
    },
  });
  return transformExpertForFetchExpertSkills(expert);
};
export const addExpert = (isBulk) => (req) => async (helixUser) => {
  const apiUrl = isBulk
    ? config[env].CREATE_EXPERT_BULK_UPLOAD
    : config[env].ADD_NEW_EXPERT;
  const kibana = {
    ...req,
    event: isBulk ? KIBANA_NEW_BULK_EXPERT_ADDED : KIBANA_NEW_EXPERT_ADDED,
  };

  const headers = {
    'x-api-key': expertAttributeApiKey,
  };
  if (isBulk) headers['Content-Type'] = 'multipart/form-data';
  postApiData(apiUrl, req, headers)
    .then(({ data: { message: response = [] } = {} } = {}) => {
      if (isBulk) {
        const messageToHtmlString = response
          .map(
            ({ empId = '', message = [] }) => `<p>${empId}</p>
          <div>
          ${message && message.join('<br>')}
          </div>`,
          )
          .join('<br>');
        popCustomHtmlMessage(messageToHtmlString);
      } else {
        popHideSuccessMsg('Expert created successfully');
      }

      return { response, state: KIBANA_SUCCESS_STATE };
    })
    .catch(({ response: { errorMessage } = {} }) => {
      const text = errorMessage || DEFAULT_ERROR;
      noAutoClosePopFailMessage('Uploading Issues')(text);
      return { response: text, state: KIBANA_FAILED_STATE };
    })
    .then(({ response, state }) => {
      /*eslint no-console: "off"*/
      console.log(
        '[ API - Response ] CSV bulk expert create upload ',
        response,
      );
      kibanaEventTrigger({
        ...kibana,
        ...helixUser,
        state,
      });
    });
};
const updateExpertAttributes = async (req) => {
  const apiUrl = config[env].UPDATE_WORKER_ATTRIBUTES;
  const kibana = {
    ...req,
    event: KIBANA_EDIT_EXPERT,
  };
  try {
    await postApiData(apiUrl, req, {
      'x-api-key': expertAttributeApiKey,
    });
    popHideSuccessMsg('Changes Saved!');
    kibanaEventTrigger({
      status: 'success',
      ...kibana,
    });
  } catch (err) {
    kibanaEventTrigger({
      status: 'failed',
      ...kibana,
    });
    popHideFailMessage(err?.response?.errorMessage ?? 'Error updating Worker');
    console.log(err);
  }
};

export default updateExpertAttributes;

export const deleteExpertFetch = async (worker_Id = '') => {
  const apiUrl = config[env]?.DELETE_EXPERT_TASK;
  const authKey = config[env]?.API_AUTH_KEYS.DELETE_EXPERT_TASK_KEY;
  const headers = {
    'x-api-key': authKey,
  };
  const params = {
    action: 'fetch',
    payload: {
      worker_Id,
    },
  };
  let data;
  try {
    await deleteApiData(apiUrl, params, headers).then((response) => {
      data = response?.data;
    });
  } catch (err) {
    popHideFailMessage('Failed to Load Worker Data', err?.response?.data ?? '');
  }
  return data;
};
export const deleteExpertStuckTask =
  (taskSids = [], setTaskDeleted) =>
  async () => {
    const apiUrl = config[env]?.DELETE_EXPERT_TASK;
    const authKey = config[env]?.API_AUTH_KEYS.DELETE_EXPERT_TASK_KEY;
    const headers = {
      'x-api-key': authKey,
    };
    const params = {
      action: 'delete',
      payload: {
        taskSids,
      },
    };
    try {
      await deleteApiData(apiUrl, params, headers);
      setTaskDeleted(true);
      popHideSuccessMsg('Expert Task Cancelled');
    } catch (err) {
      popHideFailMessage(
        'Failed to Cancel Expert Stuck Task',
        err?.response?.data ?? '',
      );
    }
  };

export const getExpertByExtension = async (extension) => {
  try {
    const query = `
    query MyQuery($extension: String) {
      getWorkerByExtension(extension: $extension){
        items {
          worker_Id
        }
      }
    }`;
    const res = await API.graphql({
      query,
      variables: {
        extension,
      },
    });
    return res?.data?.getWorkerByExtension?.items?.[0] ?? {};
  } catch (err) {
    console.log('[ Extension Look up Expert ERROR ] - ', err);
    return {};
  }
};
export const getMarketDetailsFetch = async (val) => {
  const keySearches = baseStringToArrayField(val);
  try {
    const filter = {
      or: keySearches?.map((contains) => ({ market: { contains } })) ?? [],
    };
    const query = `
    query MyQuery($filter: ModelZipCodeFilterInput, $nextToken: String) {
      listZipCodes(filter: $filter, nextToken: $nextToken, limit: 5000){
        items {
          market
          zipCode
        }
        nextToken
      }
    }`;
    const markets = await singleGqlQueryGetAllItemsUntilNullToken(query, {
      filter,
    });
    console.log('[ Market Search Results ] ', markets);
    return markets;
  } catch (err) {
    console.log('[ Market Search ERROR ] - ', err);
    return [];
  }
};

export const updateExpert = async (
  {
    helixUser: { employeeId, userId },
    worker_Id,
    market: previousMarketValue,
    extension: previousExtensionValue,
  },
  market,
  extension,
) => {
  try {
    const query = `
      mutation MyMutation($input: UpdateWorkerInput!) {
      updateWorker(input: $input) {
        worker_Id
      }
    }`;
    let input = {
      worker_Id,
    };
    if (market?.length) input.market = market;
    if (extension?.length) input.extension = extension;
    const variables = {
      input,
    };
    await API.graphql({
      query,
      variables,
    });
    popHideSuccessMsg('Expert updated successfully');

    kibanaEventTrigger({
      event: KIBANA_UPDATE_EXPERT_EXTENSION_MARKET,
      previousMarketValue,
      previousExtensionValue,
      updateSent: input,
      expertAdvocateUpdatedBy: {
        employeeId,
        userId,
      },
    });
    return true;
  } catch (err) {
    popHideFailMessage('Error updating Expert');
    kibanaEventTrigger({
      event: KIBANA_UPDATE_EXPERT_EXTENSION_MARKET,
      message: 'EVENT FAILED',
      expertAdvocateUpdatedBy: {
        employeeId,
        userId,
      },
    });
    console.log('[ ERROR Updating Expert Market ] - ', err);
    return false;
  }
};

export const postQSPAutomation = async (file, loggingAnalytics) => {
  const apiUrl = config[env].UPLOAD_QSP_AUTOMATION;
  const apiKey = config[env].UPLOAD_QSP_AUTOMATION_APIKEY;

  try {
    const response = await postApiData(
      apiUrl,
      file,
      apiKeyToFormattedHeader(apiKey),
    );
    if (response?.data?.message?.success) {
      popHideSuccessMsg('CSV Uploaded Successfully');
    } else {
      noAutoClosePopSuccessMessage(
        `CSV Uploaded Successfully.`,
        `The following ID's were not found: ${
          response?.data?.message?.invalidIds ?? ''
        }`,
      );
    }
    loggingAnalytics({
      status: 'success',
    });
  } catch (err) {
    loggingAnalytics({
      status: 'failed',
    });
    noAutoClosePopFailMessage('Error Uploading CSV')(
      JSON.stringify(err?.response?.data ?? ''),
    );
    console.log(`Error uploading QSP Automation CSV: `, err);
  }
};
