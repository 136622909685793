import React, { useEffect, useState, useCallback } from 'react';
import CtaText from '../CtaTextComponent';
import ButtonComponent from '../ButtonComponent';
import debounce from 'lodash.debounce';
import { LinearProgress, TextField, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import CloseButton from '../XCloseButton';
import './index.scss';
import { DEFAULT_FUNCTION } from '../../data/datatypes';

export const HEADING_TEXT_CLASS = 'headingText',
  MODAL_SCREEN_WRAP = 'modal-screen-wrap',
  MODAL_CONTAINER_CLASS = 'modalContainer',
  MODAL_CLOSE_CONTAINER_CLASS = 'modalCloseContainer',
  MODAL_INTERIOR_CLASS = 'modalInterior',
  MODAL_ACTION_HEADER = 'modal-action-header',
  MODAL_CONTENT_CLASS = 'orbit-modal-content',
  BTN_EFFECTS_CLASS = 'btn ripple-effect',
  CANCEL_BTN_CLASS = 'order-btn-cancel',
  SAVE_BTN_CLASS = 'save-btn-apply',
  SAVE_BTN_TEXT = 'Save',
  BUTTON_GROUP_CONTAINER_CLASS = 'button-group-container',
  DEFAULT_HEADING = 'Default Heading',
  MODAL_INPUT_CLASS = 'modal-input',
  MODAL_INPUT_ERROR = 'modal-input-error',
  INPUT_CONTAINER_CLASS = 'inputContainer',
  NO_SCROLL_CLASSNAME = 'noscroll',
  API_INPUT_SEARCH_ID = 'api-input-search',
  RESET_ALL = 'Reset all',
  RESET_ALL_CTA_STYLE = 'reset-cta-text',
  SMALL_RELATIVE_MODAL_WRAP = 'small-relative-modal-wrapper';
const StyledTextField = styled((props) => <TextField {...props} />)(
  ({ theme, error = false }) => {
    const errorDefaultPlain = error ? theme?.status?.danger : '';
    const errorDefaultPrimaryColor = error
      ? theme?.status?.danger
      : theme?.palette?.primary?.main;
    return {
      background: theme.palette.background.dark,
      width: '100%',
      '& label.Mui-focused': {
        color: errorDefaultPrimaryColor,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: errorDefaultPlain,
        },
        '&:hover fieldset': {
          borderColor: errorDefaultPlain,
        },
        '&.Mui-focused fieldset': {
          borderColor: errorDefaultPrimaryColor,
        },
      },
    };
  },
);

export const ModalTextInput = ({
  label = '',
  onChange = DEFAULT_FUNCTION,
  disabled = false,
  value = '',
  error = false,
  ...other
} = {}) => (
  <StyledTextField
    {...other}
    label={label}
    value={value}
    error={error}
    disabled={disabled}
    type="search"
    variant="outlined"
    onChange={onChange}
  />
);
export const DebounceApiInput = ({
  onChange,
  value,
  showLoading = true,
  ...other
} = {}) => {
  const [inputVal, setInputVal] = useState(value);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const debounceInput = useCallback(
    debounce((value) => {
      onChange(value).then((e) => setError(e), setLoading(false));
    }, 1000),
    [onChange, setLoading],
  );
  const valueChangeToApiFire = ({ target: { value } }) => {
    setInputVal(value);
    debounceInput(value);
    setLoading(true);
  };
  return (
    <Box width="100%">
      <ModalTextInput
        error={error}
        value={inputVal}
        id={API_INPUT_SEARCH_ID}
        onChange={valueChangeToApiFire}
        {...other}
      />
      {loading && showLoading ? <LinearProgress /> : null}
    </Box>
  );
};

const CancelButton = ({ closeDialog }) => {
  return (
    <button
      id={CANCEL_BTN_CLASS}
      className={BTN_EFFECTS_CLASS}
      onClick={closeDialog}
    >
      Cancel
    </button>
  );
};

const ResetAllCtaText = ({ resetAllCta }) => {
  return (
    <CtaText
      className={RESET_ALL_CTA_STYLE}
      text={RESET_ALL}
      callToAction={resetAllCta}
    />
  );
};

const ButtonGroupContainer = ({
  submitDialog,
  disabled = false,
  useResetCta,
  ...props
} = {}) => {
  return (
    <div className={BUTTON_GROUP_CONTAINER_CLASS}>
      <ButtonComponent
        text={SAVE_BTN_TEXT}
        disabled={disabled}
        id={SAVE_BTN_CLASS}
        onClick={submitDialog}
      />
      {useResetCta ? (
        <ResetAllCtaText {...props} />
      ) : (
        <CancelButton {...props} />
      )}
    </div>
  );
};

const DialogCloseButton = ({ closeDialog = null } = {}) => (
  <CloseButton close={closeDialog} />
);

const HeadingText = ({ headingText = DEFAULT_HEADING } = {}) => (
  <span className={HEADING_TEXT_CLASS}>{headingText}</span>
);

const Dialog = ({
  isOpen = false,
  children,
  isFullscreen = true,
  ...props
} = {}) => {
  useEffect(() => {
    if (isFullscreen) {
      if (isOpen) {
        /*eslint no-undef: "off"*/
        window.scrollTo(0, 0);
        /*eslint no-undef: "off"*/
        document.body.classList.add(NO_SCROLL_CLASSNAME);
      } else {
        /*eslint no-undef: "off"*/
        document.body.classList.remove(NO_SCROLL_CLASSNAME);
      }
    }
  }, [isOpen]);
  const wrapClass = isFullscreen
    ? `${MODAL_SCREEN_WRAP} shadow-background`
    : MODAL_SCREEN_WRAP;
  return isOpen ? (
    <div className={wrapClass}>
      <span
        className={MODAL_CLOSE_CONTAINER_CLASS}
        onClick={(e) => {
          e.preventDefault();
          const { closeDialog } = props;
          closeDialog();
        }}
      ></span>
      <div
        className={
          isFullscreen ? MODAL_CONTAINER_CLASS : SMALL_RELATIVE_MODAL_WRAP
        }
      >
        <div className={MODAL_ACTION_HEADER}>
          <HeadingText {...props} />
          <DialogCloseButton {...props} />
        </div>
        <div className={MODAL_INTERIOR_CLASS}>
          <div className={MODAL_CONTENT_CLASS}>{children}</div>
        </div>
        {isFullscreen ? <ButtonGroupContainer {...props} /> : null}
      </div>
    </div>
  ) : null;
};
export default Dialog;
