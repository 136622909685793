import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Stack } from '@mui/material';

const SingleToggleOption = ({ value, onChange, selected }) => {
  return (
    <ToggleButton
      color="primary"
      sx={{ width: '30%', whiteSpace: 'nowrap' }}
      value={value}
      selected={selected === value}
      onChange={onChange}
    >
      {value}
    </ToggleButton>
  );
};

export default function MultiOptionToggle({ options, selected, setSelected }) {
  const handleChange = (e) => {
    const incomingValue = e?.target?.value;
    if (selected !== incomingValue) setSelected(incomingValue);
  };
  const buttonViews = options.map((opt, i) => (
    <SingleToggleOption
      key={opt ?? i}
      selected={selected}
      onChange={handleChange}
      value={opt}
    />
  ));

  return (
    <ToggleButtonGroup
      fullWidth
      color="primary"
      sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      onChange={handleChange}
      value={selected}
    >
      {buttonViews}
    </ToggleButtonGroup>
  );
}
