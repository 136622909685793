import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorToUserReducer } from '../../reducers/UserReducer';

const PrivateRoute = ({
  orbitEditor = false,
  ubifAdmin = false,
  workforceAdmin = false,
  ...props
}) => {
  const {
    orbitEditor: isOrbitEditor,
    ubifAdmin: isUbifAdmin,
    authenticated,
  } = useSelector(selectorToUserReducer);
  if (orbitEditor) {
    return isOrbitEditor ? <Route {...props} /> : <Redirect to="/" />;
  } else if (ubifAdmin) {
    return isUbifAdmin ? <Route {...props} /> : <Redirect to="/" />;
  } else {
    return !authenticated ? <></> : <Route {...props} />;
  }
};
export default PrivateRoute;
