import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import {
  GraphQlTable,
  ExpertDescription,
  TitleKeyMapPair,
  KeyMapPairDesignedComponent,
} from '../ExpertStatus';
import { StyledTooltip } from '../ExpertStatus/wand/index.js';
import {
  deleteExpertFetch,
  deleteExpertStuckTask,
} from '../../services/Experts';
import {
  clientTitleGraphQlHeader,
  activeToggleGraphQlHeader,
  onshoreBamHeader,
  onshoreWahHeader,
  outsourcedOnshoreGraphQlHeader,
  offshoreGraphQlHeader,
  outsourcedOffshoreGraphQlHeader,
  GraphqlTableSort,
} from '../../reducers/TableDataReducer/datatypes';
import {
  inTimeToLapsedTime,
  stringToAppendedMinuteAnnotation,
} from '../../reducers/ExpertReducer/transformations';
import {
  eventTargetHandler,
  arrayToRemoveDups,
  keyValuePairToMappedWithObject,
} from '../../transformations/utils';
import {
  withLoggingAnalytics,
  SAVE_LOCATION_BASED_ROUTING,
  useLoggingAnalytics,
} from '../../services/KibanaEvents';
import {
  clientSortKey,
  activeSortKey,
  onShoreWahSortKey,
  onShoreBamSortKey,
  outSourceOnShoreSortKey,
  offshoreSortKey,
  outsourcedOffshoreSortKey,
  tableDataKeyToTitlePair,
} from '../../reducers/TableDataReducer/constants';
import {
  functionToHandleIncomingSort,
  generateTablePageString,
  numberMinusOne,
  firstIndexInViewAndLimitToLastIndexInView,
  zeroPageIndexAndLimitToFirstIndexInView,
  workernameToFirstLastNameRead,
} from '../../reducers/TableDataReducer/transformations';
import { abcSort, booleanSort, numericalSort } from '../Table/transformations';
import {
  getSubTargetAllocation,
  saveSubTargetAllocations,
  getSubTargetTypes,
  createLocationBasedRoutingActivity,
  getAllRecentLocationRoutingActivities,
  updateTimeslotScheduler,
} from './services';
import {
  checkIfSubtypeIncluded,
  updateUserAllocations,
} from './transformations';
import { DebounceApiInput } from '../Dialog';
import {
  popHideSuccessMsg,
  popHideFailMessage,
} from '../../services/sweetAlerts';
import {
  Edit as EditIcon,
  Backup as BackupIcon,
  EditOff as EditOffIcon,
  PriorityHigh as PriorityHighIcon,
} from '@mui/icons-material';
import {
  Typography,
  IconButton,
  Switch,
  CircularProgress,
  Box,
  styled,
  Tooltip,
  Button,
  Stack,
  TextField,
  Paper,
  Input,
} from '@mui/material';
import LocationBasedRoutingScheduler, {
  gqlOutsourcedOnshoreKey,
} from './LocationBasedRoutingScheduler';
import {
  fetchTaskqueueWithSid,
  createNewTaskqueue,
  updateTaskqueue,
} from '../../services/taskqueue';
import { SelectDropDown, SelectionDropDownProps } from '../DropviewSelection';
import { addPercentAnnotation } from '../WorkForceExpandedComponent/transformations';
import AuthConditionalRender from '../AuthConditionalRender';
import { useTheme } from '@mui/material/styles';
import { DEFAULT_FUNCTION } from '../../data/datatypes';
import { CancelTaskTable } from '../CancelTaskTable';

import './index.scss';

const slKeyAnswered = 'answered',
  slKeyOffered = 'offered',
  slKeyOptions = [slKeyAnswered, slKeyOffered];

const CustomIcon = styled(PriorityHighIcon)(({ theme }) => ({
  color: theme.status.danger,
  '&.Mui-checked': {
    color: theme.status.danger,
  },
}));

const initialValuesTaskqueueForm = {
  taskqueueSid: '',
  active: false,
  group: '',
  language: '',
  lastReservation: '',
  lob: '',
  obQueue: {
    caller_id: '',
    conversation_attribute_2: '',
    sid: '',
  },
  serviceLevel: 30,
  SLKey: slKeyAnswered,
  taskqueueName: '',
};
export const ErrorWithTooltip = () => {
  return (
    <Tooltip title="All values must equal 100" arrow>
      <CustomIcon />
    </Tooltip>
  );
};
const EditPencilSubmitChange = ({
  isEditMode,
  click,
  loading,
  resetEditMode,
  valid,
}) => {
  const isNotValid = !valid;
  if (loading) {
    return (
      <Box>
        <CircularProgress size={17} />
      </Box>
    );
  } else if (isEditMode) {
    return (
      <>
        <StyledTooltip title="Cancel Edit" arrow>
          <IconButton padding="15px 0px 0px 0px" onClick={resetEditMode}>
            <EditOffIcon color="danger" fontSize="small" />
          </IconButton>
        </StyledTooltip>
        <IconButton
          padding={'10px'}
          style={{ boxSizing: 'border-box' }}
          onClick={click}
        >
          {isNotValid ? (
            <ErrorWithTooltip />
          ) : (
            <StyledTooltip title="Save Changes" arrow>
              <BackupIcon color="secondary" fontSize="small" />
            </StyledTooltip>
          )}
        </IconButton>
      </>
    );
  } else
    return (
      <StyledTooltip title="Manual Edit" arrow>
        <IconButton
          padding={'10px'}
          style={{ boxSizing: 'border-box' }}
          onClick={click}
        >
          <EditIcon color="primary" fontSize="small" />
        </IconButton>
      </StyledTooltip>
    );
};
const ConditionallyDisplayAndEditValue = ({
  isEditMode,
  value,
  setValue,
  isValid,
  disabled,
}) => {
  return (
    <Box padding="10px" sx={{ boxSizing: 'border-box' }}>
      {isEditMode ? (
        <TextField
          fullWidth
          error={!isValid}
          InputProps={{
            inputProps: { min: 0, max: 100 },
          }}
          variant="standard"
          value={value}
          type="number"
          disabled={disabled}
          onChange={eventTargetHandler((eventVal) =>
            setValue(Number(eventVal)),
          )}
        />
      ) : (
        <Typography textAlign="center">{value} %</Typography>
      )}
    </Box>
  );
};

/**
 * Turns boolean value into "ON" or "OFF" string
 * @param {boolean} value
 * @returns string
 */

export const booleanToOnOffReadString = (value) => (value ? 'ON' : 'OFF');
export const ifValueBooleanHandleReadString = (value) =>
  typeof value === 'boolean' ? booleanToOnOffReadString(value) : value;

/**
 * Add percent if Location Based Routing TargetType is a Routing Location
 * @param {string} readValue
 * @param {string} value
 * @returns string
 */

export const locationBasedRoutingSortKeyToValueIdentifier = (
  readValue,
  value,
) => {
  return locationBasedRoutingTargetTypes?.includes(value)
    ? `${addPercentAnnotation(readValue)}`
    : ifValueBooleanHandleReadString(readValue);
};

/**
 * Turns TargetType Key into header and adds "from" and "to" in order to compare
 * previous and new values
 * @param {string} targetType
 * @param {string} previous
 * @param {string} updated
 * @returns string
 */

const generateTargetTypeFromToString = (targetType, previous, updated) => {
  return (
    tableDataKeyToTitlePair[targetType] +
    ' from ' +
    locationBasedRoutingSortKeyToValueIdentifier(previous, targetType) +
    ' to ' +
    locationBasedRoutingSortKeyToValueIdentifier(updated, targetType) +
    ', '
  );
};
/**
 * Compares the updated and previous values and puts them in a readable string
 * @param {{isActive, allocations:[{ SubTargetTypeName, SubTargetTypeId, Allocation}]}} updated
 * @param {{isActive, allocations:[{ SubTargetTypeName, SubTargetTypeId, Allocation}]}} previous
 * @returns string
 */
export const locationBasedRoutingObjectValuesToString = (updated, previous) => {
  let startString = '';
  if (updated?.isActive !== previous?.isActive) {
    startString = generateTargetTypeFromToString(
      activeSortKey,
      previous?.isActive,
      updated?.isActive,
    );
  }
  return updated?.allocations
    ?.reduce((acc, { SubTargetTypeName, SubTargetTypeId, Allocation }) => {
      const previousAllocation =
        previous?.allocations?.find(
          (prev) => prev?.SubTargetTypeId === SubTargetTypeId,
        )?.Allocation ?? 0;
      if (previousAllocation !== Allocation) {
        // write to string displaying update
        acc =
          acc +
          generateTargetTypeFromToString(
            SubTargetTypeName,
            previousAllocation,
            Allocation,
          );
      }
      return acc;
    }, startString)
    .slice(0, -2)
    .concat('.');
};

const locationBasedRoutingUpdateToString = async (
  user,
  ClientName,
  userUpdatedAllocations,
  SubTargetAllocations,
  isActive,
  IsActive,
) => {
  try {
    const rightNow = moment()
      .tz('America/Chicago')
      .format('MMMM Do YYYY, h:mm:ss a');
    const message = `${rightNow} - ${user?.userId ?? ''}(${
      user?.employeeId ?? ''
    }) updated ${ClientName ?? ''} ${locationBasedRoutingObjectValuesToString(
      { allocations: userUpdatedAllocations, isActive },
      { allocations: SubTargetAllocations, isActive: IsActive },
    )}`;
    await createLocationBasedRoutingActivity(message, user?.employeeId);
  } catch (err) {
    console.log('bad data reading updated string ', err);
    return '';
  }
};
const targetTypesToFindAllocation = (targetTypes) => (targetName) =>
  Number(
    targetTypes?.find(
      ({ SubTargetTypeName }) => SubTargetTypeName === targetName,
    )?.Allocation ?? 0,
  );
const locationBasedRoutingTargetTypes = [
  onShoreBamSortKey,
  onShoreWahSortKey,
  outSourceOnShoreSortKey,
  offshoreSortKey,
  outsourcedOffshoreSortKey,
];

export const allParamsEqual100 = (...params) =>
  params?.reduce((acc, num) => acc + num, 0) === 100;

const AdminRow = ({
  ClientName,
  IsActive,
  SubTargetAllocations,
  TargetType,
  saveChanges,
  subTargetTypes,
  hasEditPermissions,
} = {}) => {
  const user = useSelector((state) => state?.user?.helixUser);

  const findAllocation = targetTypesToFindAllocation(SubTargetAllocations);
  const onshoreWahInitAllocation = findAllocation(onShoreWahSortKey);
  const onshoreBamInitAllocation = findAllocation(onShoreBamSortKey);
  const outsourcedOnshortInitAllocation = findAllocation(
    outSourceOnShoreSortKey,
  );
  const offshoreInitAllocation = findAllocation(offshoreSortKey);
  const outsourcedOffshoreInitAllocation = findAllocation(
    outsourcedOffshoreSortKey,
  );

  const [onshoreWahValue, setOnshoreWah] = useState(onshoreWahInitAllocation);
  const [onshoreBamValue, setOnShoreBam] = useState(onshoreBamInitAllocation);
  const [outsourcedOnshoreValue, setOutsourcedOnshore] = useState(
    outsourcedOnshortInitAllocation,
  );
  const [offshoreValue, setOffshore] = useState(offshoreInitAllocation);
  const [outsourcedOffshoreValue, setOutsourcedOffshore] = useState(
    outsourcedOffshoreInitAllocation,
  );
  useEffect(() => {
    resetEditMode();
  }, [ClientName, IsActive, SubTargetAllocations]);

  const equals100 = allParamsEqual100(
    onshoreWahValue,
    onshoreBamValue,
    outsourcedOnshoreValue,
    offshoreValue,
    outsourcedOffshoreValue,
  );
  const [isActive, setIsActive] = useState(IsActive);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const click = async () => {
    if (!isEditMode) {
      setEditMode(true);
    } else if (equals100) {
      setLoading(true);
      try {
        const userUpdatedAllocations = subTargetTypes?.reduce(
          updateUserAllocations(
            SubTargetAllocations,
            onshoreBamValue,
            onshoreWahValue,
            outsourcedOnshoreValue,
            offshoreValue,
            outsourcedOffshoreValue,
          ),
          [],
        );
        const bodyUpdateParams = {
          ClientName,
          IsActive: isActive,
          TargetType,
          SubTargetAllocations: userUpdatedAllocations,
        };

        await saveChanges(bodyUpdateParams);
        await locationBasedRoutingUpdateToString(
          user,
          ClientName,
          userUpdatedAllocations,
          SubTargetAllocations,
          isActive,
          IsActive,
        );
        await updateTimeslotScheduler(
          ClientName,
          onshoreBamValue,
          onshoreWahValue,
          outsourcedOnshoreValue,
          offshoreValue,
          outsourcedOffshoreValue,
          SubTargetAllocations,
        );
        setEditMode(false);
        popHideSuccessMsg('Routing Changes Saved');
      } catch (err) {
        console.log('[ ERROR Saving Sub Target Allocations ] - ', err);
        popHideFailMessage('Failed to save changes');
      }
      setLoading(false);
    }
  };
  const resetEditMode = () => {
    setOnshoreWah(onshoreWahInitAllocation);
    setOnShoreBam(onshoreBamInitAllocation);
    setOutsourcedOnshore(outsourcedOnshortInitAllocation);
    setOffshore(offshoreInitAllocation);
    setOutsourcedOffshore(outsourcedOffshoreInitAllocation);
    setIsActive(IsActive);
    setEditMode(false);
  };

  return (
    <>
      <Typography>{ClientName}</Typography>
      <Switch
        disabled={!isEditMode}
        checked={isActive}
        onChange={(e) => setIsActive(e.target.checked)}
      />
      <ConditionallyDisplayAndEditValue
        isValid={equals100}
        setValue={setOnShoreBam}
        isEditMode={isEditMode}
        value={onshoreBamValue}
        disabled={
          !checkIfSubtypeIncluded(SubTargetAllocations, onShoreBamSortKey)
        }
      />
      <ConditionallyDisplayAndEditValue
        isValid={equals100}
        setValue={setOnshoreWah}
        isEditMode={isEditMode}
        value={onshoreWahValue}
        disabled={
          !checkIfSubtypeIncluded(SubTargetAllocations, onShoreWahSortKey)
        }
      />
      <ConditionallyDisplayAndEditValue
        isValid={equals100}
        setValue={setOutsourcedOnshore}
        isEditMode={isEditMode}
        value={outsourcedOnshoreValue}
        disabled={
          !checkIfSubtypeIncluded(SubTargetAllocations, outSourceOnShoreSortKey)
        }
      />
      <ConditionallyDisplayAndEditValue
        isValid={equals100}
        setValue={setOffshore}
        isEditMode={isEditMode}
        value={offshoreValue}
        disabled={
          !checkIfSubtypeIncluded(SubTargetAllocations, offshoreSortKey)
        }
      />
      <ConditionallyDisplayAndEditValue
        isValid={equals100}
        setValue={setOutsourcedOffshore}
        isEditMode={isEditMode}
        value={outsourcedOffshoreValue}
        disabled={
          !checkIfSubtypeIncluded(
            SubTargetAllocations,
            outsourcedOffshoreSortKey,
          )
        }
      />
      <AuthConditionalRender authBool={hasEditPermissions}>
        <EditPencilSubmitChange
          valid={equals100}
          resetEditMode={resetEditMode}
          isEditMode={isEditMode}
          click={
            isEditMode
              ? withLoggingAnalytics(SAVE_LOCATION_BASED_ROUTING, click, {
                  locationRoutingUpdatedBy: user?.userId,
                  previousValues: {
                    ClientName,
                    IsActive,
                    SubTargetAllocations,
                    TargetType,
                  },
                  updatedValues: {
                    ClientName,
                    IsActive: isActive,
                    onshoreWah: onshoreWahValue,
                    onshoreNonBam: onshoreBamValue,
                    outsourcedOnshore: outsourcedOnshoreValue,
                    offshore: offshoreValue,
                    outsourcedOffshore: outsourcedOffshoreValue,
                  },
                })
              : click
          }
          loading={loading}
        />
      </AuthConditionalRender>
      <LocationBasedRoutingScheduler
        client={ClientName}
        hasEditPermissions={hasEditPermissions}
        SubTargetAllocations={SubTargetAllocations}
      />
    </>
  );
};
const allocationKeyToAllocationFieldFindValue = (keyToMatch) => (allocations) =>
  allocations?.SubTargetAllocations?.find(
    ({ SubTargetTypeName = '' } = {}) => SubTargetTypeName === keyToMatch,
  )?.Allocation ?? 0;
const directionABToSortNumsOnKeyMatch = (direction, a, b) => (keyToMatch) => {
  const findAllocationValue =
    allocationKeyToAllocationFieldFindValue(keyToMatch);
  const firstAllocation = findAllocationValue(a);
  const secondAllocation = findAllocationValue(b);
  return direction === 'asc'
    ? numericalSort(firstAllocation)(secondAllocation)
    : numericalSort(secondAllocation)(firstAllocation);
};
const useLocationBasedRouting = (refetch, setRefetch) => () => {
  const groups = useSelector((state) => state?.selection?.groupSelection);
  const [items, setItems] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [limit, setTableLimit] = React.useState(10);
  const [sort, setSort] = React.useState(
    new GraphqlTableSort('asc', clientSortKey),
  );
  const setSortKey = functionToHandleIncomingSort(setSort);

  const nextPage = useCallback(() => setPage(page + 1), [page, setPage]);
  const previousPage = useCallback(() => setPage(page - 1), [page, setPage]);
  const goToFirstPage = useCallback(() => setPage(1), [page, setPage]);
  const goToLastPage = useCallback(
    () => setPage(Math.ceil(items?.length / limit)),
    [items],
  );
  const zeroIndexPage = numberMinusOne(page);
  const prevPageAvailable = !!zeroIndexPage;
  const firstIndexOfCurrentView = zeroPageIndexAndLimitToFirstIndexInView(
    zeroIndexPage,
    limit,
  );
  const lastIndexOfCurrentVew = firstIndexInViewAndLimitToLastIndexInView(
    firstIndexOfCurrentView,
    limit,
  );
  const totalSortedCount = items?.length ?? 0;
  const nextPageAvailable = lastIndexOfCurrentVew < totalSortedCount;
  const currentTablePageString = generateTablePageString(
    firstIndexOfCurrentView,
    lastIndexOfCurrentVew,
    totalSortedCount,
  );
  const saveChanges = async (body) => {
    await saveSubTargetAllocations(body);
    setRefetch(true);
  };
  const targetTypeToIsLocationBasedRoutingType = ({
    SubTargetTypeName = '',
  } = {}) => locationBasedRoutingTargetTypes?.includes(SubTargetTypeName);
  const subTargetTypesToLocationBasedRoutingTargetTypes = (subTargetTypes) =>
    subTargetTypes?.filter(targetTypeToIsLocationBasedRoutingType) ?? {};

  useEffect(() => {
    const asynced = async () => {
      setLoading(true);
      setRefetch(false);
      await new Promise(async (resolve) => {
        const allos = await getSubTargetAllocation();
        const types = await getSubTargetTypes();
        const subTargetTypes =
          subTargetTypesToLocationBasedRoutingTargetTypes(types);
        setItems(
          allos?.map(
            keyValuePairToMappedWithObject('subTargetTypes', subTargetTypes),
          ) ?? [],
        );
        resolve(true);
      });
      setLoading(false);
    };
    if (refetch) asynced();
  }, [...groups, refetch]);

  const viewItems = items
    ?.sort((a, b) => {
      const { field, direction } = sort;
      if (field === clientSortKey) {
        return direction === 'asc'
          ? abcSort(a?.ClientName)(b?.ClientName)
          : abcSort(b?.ClientName)(a?.ClientName);
      } else if (field === activeSortKey) {
        return direction === 'asc'
          ? booleanSort(a?.IsActive)(b?.IsActive)
          : booleanSort(b?.IsActive)(a?.IsActive);
      } else {
        const sortNumbersOnKeyMatch = directionABToSortNumsOnKeyMatch(
          direction,
          a,
          b,
        );
        switch (field) {
          case onShoreBamSortKey:
            return sortNumbersOnKeyMatch(onShoreBamSortKey);
          case onShoreWahSortKey:
            return sortNumbersOnKeyMatch(onShoreWahSortKey);
          case outSourceOnShoreSortKey:
            return sortNumbersOnKeyMatch(outSourceOnShoreSortKey);
          case offshoreSortKey:
            return sortNumbersOnKeyMatch(offshoreSortKey);
          case outsourcedOffshoreSortKey:
            return sortNumbersOnKeyMatch(outsourcedOffshoreSortKey);
        }
      }
    })
    .slice(numberMinusOne(firstIndexOfCurrentView), lastIndexOfCurrentVew);
  return {
    nextPage,
    previousPage,
    goToLastPage,
    goToFirstPage,
    nextPageAvailable,
    prevPageAvailable,
    limit,
    setTableLimit,
    currentTablePageString,
    setSort: setSortKey,
    sort,
    loading,
    items: viewItems,
    saveChanges,
  };
};

const LocationBasedRoutingAttrTable = ({
  refetch,
  setRefetch,
  hasEditPermissions,
}) => {
  return (
    <GraphQlTable
      hasEditPermissions={hasEditPermissions}
      tableHandler={useLocationBasedRouting(refetch, setRefetch)}
      Row={AdminRow}
      demensions="minmax(100px, 20%) minmax(100px, 5%) repeat(5, minmax(80px, 16%)) repeat(3, 40px)"
      headers={[
        clientTitleGraphQlHeader,
        activeToggleGraphQlHeader,
        onshoreBamHeader,
        onshoreWahHeader,
        outsourcedOnshoreGraphQlHeader,
        offshoreGraphQlHeader,
        outsourcedOffshoreGraphQlHeader,
      ]}
    />
  );
};

const OffsetColorPaperWrapContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  padding: '10px',
  border: `1px solid ${theme.palette.divider}`,
}));
const LocationBasedRoutingActivityDisplay = ({ refetch }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const asynced = async () => {
      const activities = await getAllRecentLocationRoutingActivities();
      setData(activities);
    };
    if (refetch) asynced();
  }, [refetch]);
  return (
    <OffsetColorPaperWrapContainer>
      <TitleKeyMapPair
        Row={KeyMapPairDesignedComponent}
        loop={data}
        title="Activity History"
      />
    </OffsetColorPaperWrapContainer>
  );
};
export const LocationBasedRoutingTab = (props) => {
  const [refetch, setRefetch] = useState(true);
  return (
    <Stack flexDirection="column" gap={2}>
      <LocationBasedRoutingAttrTable
        refetch={refetch}
        setRefetch={setRefetch}
        hasEditPermissions={props.hasEditPermissions}
      />
      <LocationBasedRoutingActivityDisplay refetch={refetch} />
    </Stack>
  );
};

export const CancelTask = () => {
  const [employeeId, setEmployeeId] = useState('');
  const [searchExpert, setSearchExpert] = useState();
  const [taskDeleted, setTaskDeleted] = useState(false);

  const apiEmployeeFetch = useCallback((emp_id) => {
    return new Promise(async (resolve) => {
      if (emp_id.length === 6 && taskDeleted === false) {
        const expert = await deleteExpertFetch(emp_id);
        console.log(expert);
        if (expert) {
          setEmployeeId(emp_id);
          setSearchExpert(expert);
          setTaskDeleted(false);
          resolve(false);
        } else {
          setEmployeeId('');
          setSearchExpert();
          resolve(true);
        }
      } else {
        setEmployeeId('');
        setSearchExpert();
        resolve(true);
      }
    });
  }, []);
  const {
    workerName,
    worker_Id,
    status,
    statusIntime,
    supervisorName,
    supervisorId,
    reservations,
  } = searchExpert || {};

  const columns = ['Task SID', 'Task Age (s)'];

  return (
    <Box>
      <Stack display="flex" flexDirection="row">
        <Box width="400px">
          <DebounceApiInput
            onChange={apiEmployeeFetch}
            value={employeeId}
            label="Expert ID #"
            showLoading
          />
        </Box>
      </Stack>
      {searchExpert && taskDeleted === false ? (
        <>
          <Box>
            <Stack flexDirection={'row'}>
              <ExpertDescription
                supervisorName={supervisorName}
                supervisorId={supervisorId}
                statusTimeLapse={stringToAppendedMinuteAnnotation(
                  inTimeToLapsedTime(statusIntime),
                )}
                status={status}
                name={`${workernameToFirstLastNameRead(
                  workerName,
                )} (${worker_Id})`}
              />
              {reservations.length !== 0 && (
                <CancelTaskTable
                  data={reservations}
                  columns={columns}
                  workerName={workerName}
                  worker_Id={worker_Id}
                  taskDeleted={taskDeleted}
                  setTaskDeleted={setTaskDeleted}
                />
              )}
            </Stack>
          </Box>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

function TextEntries(props) {
  const theme = useTheme();
  return (
    <div className="text-entries">
      <TextField
        {...props}
        autoComplete="off"
        variant="outlined"
        fullWidth
        style={{ background: theme.palette.background.dark }}
      />
    </div>
  );
}

const NumberInputWithNoIncDecArrows = styled(TextField)(() => ({
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
}));
const NumberInputComponent = ({
  error = false,
  value = 0,
  name = '',
  fx = DEFAULT_FUNCTION,
  disabled = false,
  min = 0,
  max = 100,
  label = '',
  required = false,
}) => {
  return (
    <NumberInputWithNoIncDecArrows
      label={label}
      name={name}
      disabled={disabled}
      required={required}
      error={error}
      inputProps={{ min, max }}
      type="number"
      value={value}
      onChange={(e) =>
        fx({
          target: {
            name: e?.target?.getAttribute('name') ?? '',
            value: +e?.target?.value ?? 0,
          },
        })
      }
    />
  );
};
const conversation_attribute_2 = 'conversation_attribute_2';
const caller_id = 'caller_id';

const serviceLevelToIsNotValid = (serviceLevel) =>
  Number.isNaN(serviceLevel) || serviceLevel > 999 || serviceLevel < 1;

export const removeWhiteSpaceFromString = (str) => str?.trim?.() ?? '';
export const CreateTaskqueForm = () => {
  const logAnalytics = useLoggingAnalytics('Modify Taskqueue Form');
  const [taskqueueData, setTaskqueueData] = useState(
    initialValuesTaskqueueForm,
  );
  const [previousValues, setPreviousValues] = useState({});

  const lobOptions = useSelector(
    (state) => state?.selection?.lobSelection ?? [],
  );
  const groupOptions = useSelector((state) =>
    arrayToRemoveDups(
      state?.selection?.available?.reduce(
        (acc, { lob = '', group = '' } = {}) => {
          if (lob === taskqueueData?.lob) {
            acc.push(group);
          }
          return acc;
        },
        [],
      ) ?? [],
    ),
  );

  useEffect(() => {
    if (
      taskqueueData?.group?.length &&
      !groupOptions?.includes(taskqueueData?.group)
    ) {
      setTaskqueueData((data) => ({ ...data, group: '' }));
    }
  }, [taskqueueData?.lob, setTaskqueueData, groupOptions]);
  const handleChange = (event) => {
    let { name, value } = event.target;
    const handleObqueueValues = () =>
      setTaskqueueData({
        ...taskqueueData,
        obQueue: {
          ...taskqueueData.obQueue,
          [name]: value,
        },
      });
    switch (name) {
      case caller_id:
      case conversation_attribute_2:
        handleObqueueValues();
        break;
      default:
        setTaskqueueData({ ...taskqueueData, [name]: value });
    }
  };
  const submit = async () => {
    const {
      taskqueueName,
      taskqueueSid,
      obQueue: { caller_id, conversation_attribute_2 } = {},
    } = taskqueueData;
    const transformBeforeUpdate = {
      ...taskqueueData,
      taskqueueName: removeWhiteSpaceFromString(taskqueueName),
      obQueue: JSON.stringify({
        conversation_attribute_2: conversation_attribute_2 + '',
        caller_id: caller_id + '',
        sid: taskqueueSid,
      }),
    };
    const isNewTaskqueue = !(Object.keys(previousValues)?.length ?? false);
    if (isNewTaskqueue) {
      await createNewTaskqueue(transformBeforeUpdate, logAnalytics);
    } else {
      await updateTaskqueue(
        transformBeforeUpdate,
        logAnalytics,
        previousValues,
      );
    }
    // need to wait for Dynamo to update, then refetch
    setTimeout(
      async () => await fetchTaskqueue(transformBeforeUpdate?.taskqueueSid),
      1000,
    );
  };

  const fetchTaskqueue = useCallback((incomingInput) => {
    const taskqueueSid = removeWhiteSpaceFromString(incomingInput);
    return new Promise(async (resolve) => {
      const [taskQueueFetchResponse] = await fetchTaskqueueWithSid(
        taskqueueSid,
      );
      if (
        taskQueueFetchResponse &&
        Object.keys(taskQueueFetchResponse)?.length
      ) {
        setPreviousValues(taskQueueFetchResponse);
        setTaskqueueData({
          ...initialValuesTaskqueueForm,
          ...taskQueueFetchResponse,
        });
      } else {
        setPreviousValues({});
        setTaskqueueData({ ...initialValuesTaskqueueForm, taskqueueSid });
      }
      resolve(false);
    });
  }, []);

  const invalidServiceLevel = serviceLevelToIsNotValid(
    taskqueueData?.serviceLevel,
  );
  const disableSubmit =
    !taskqueueData?.lob?.length ||
    !taskqueueData?.group?.length ||
    !taskqueueData?.taskqueueSid?.length ||
    !taskqueueData?.language?.length ||
    invalidServiceLevel ||
    !taskqueueData?.SLKey?.length ||
    +taskqueueData?.obQueue?.[conversation_attribute_2] > 9999 ||
    +taskqueueData?.obQueue?.[caller_id] > 9999999999 ||
    !taskqueueData?.taskqueueName?.length;
  const theme = useTheme();

  const disabledButtonStyle = {
    color: 'rgba(0, 0, 0, 0.26)',
  };

  const buttonStyle = {
    color: 'black',
    backgroundColor: 'white',
    ':hover': {
      backgroundColor: theme?.palette?.button?.main,
      color: theme?.palette?.neutral?.contrastText,
    },
  };

  const disabledButtonStyling = disableSubmit
    ? disabledButtonStyle
    : buttonStyle;
  return (
    <div className="create-taskqueue-form-container">
      <form className="form-class-container">
        <Stack gap={2}>
          <DebounceApiInput
            id="taskqueue-sid"
            label="Taskqueue Sid"
            required
            name="taskqueueSid"
            value={taskqueueData?.taskqueueSid ?? ''}
            onChange={fetchTaskqueue}
            showLoading
          />
          <TextEntries
            id="taskqueue-name"
            label="Taskqueue Name"
            required
            name="taskqueueName"
            value={taskqueueData?.taskqueueName ?? ''}
            onChange={handleChange}
          />
          <SelectDropDown
            {...new SelectionDropDownProps(
              'Line of Business',
              false,
              lobOptions,
              taskqueueData?.lob?.length ? [taskqueueData?.lob] : [''],
              (data) => handleChange({ target: { name: 'lob', value: data } }),
              false,
            )}
          />
          <SelectDropDown
            {...new SelectionDropDownProps(
              'Group',
              false,
              groupOptions,
              taskqueueData?.group?.length ? [taskqueueData?.group] : [''],
              (data) =>
                handleChange({ target: { name: 'group', value: data } }),
              false,
            )}
          />
          <SelectDropDown
            {...new SelectionDropDownProps(
              'Language',
              false,
              ['en-us', 'es-mx', 'fr-fr'],
              taskqueueData?.language?.length
                ? [taskqueueData?.language]
                : [''],
              (data) =>
                handleChange({ target: { name: 'language', value: data } }),
              false,
            )}
          />
          <SelectDropDown
            {...new SelectionDropDownProps(
              'Service Level Key',
              false,
              slKeyOptions,
              [taskqueueData?.SLKey],
              (data) =>
                handleChange({ target: { name: 'SLKey', value: data } }),
              false,
            )}
          />
          <NumberInputComponent
            label="Service Level"
            disabled={false}
            required={true}
            min={1}
            max={999}
            name="serviceLevel"
            error={invalidServiceLevel}
            value={(taskqueueData?.serviceLevel ?? 0) + ''}
            fx={handleChange}
          />
          {/* max 4 */}
          <NumberInputComponent
            label="Taskqueue Number"
            disabled={false}
            max={9999}
            name="conversation_attribute_2"
            error={+taskqueueData?.obQueue?.[conversation_attribute_2] > 9999}
            value={
              (taskqueueData?.obQueue?.[conversation_attribute_2] || 0) + ''
            }
            fx={handleChange}
          />
          {/* always a 10 digit # */}
          <StyledTooltip title="Exclude CallerID Dashes" arrow>
            <NumberInputComponent
              label="Caller ID"
              disabled={false}
              max={9999999999}
              name="caller_id"
              error={+taskqueueData?.obQueue?.[caller_id] > 9999999999}
              value={(taskqueueData?.obQueue?.[caller_id] || 0) + ''}
              fx={handleChange}
            />
          </StyledTooltip>
          <Stack alignItems="center">
            <Button
              className="create-taskqueue-button"
              variant="outlined"
              sx={disabledButtonStyling}
              disabled={disableSubmit}
              onClick={submit}
            >
              SUBMIT
            </Button>
          </Stack>
        </Stack>
      </form>
    </div>
  );
};
