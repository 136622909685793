import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { RoundedButton } from '../ButtonComponent';
import './index.css';

const dragDropUploadId = 'drag-drop-upload',
  dragDropUploadMainContent = 'drag-drop-upload-content',
  uploadContainer = 'user-upload-container',
  uploadFileDetailDisplay = 'user-file-details',
  circleXBtnClass = 'circle-button-x purple lighten-4',
  xClass = 'x-removal-class',
  documentMimeTypeAcceptions =
    'text/csv, application/vnd.ms-excel, text/xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const CircleXButton = ({ onClick }) => (
  <IconButton className={circleXBtnClass} onClick={onClick}>
    <Close sx={{ color: 'black' }} />
  </IconButton>
);
const RemoveDocBtn = ({ onClick = () => null, files }) =>
  files.map((f) => {
    const { name, size, path } = f;
    return (
      <div className={uploadFileDetailDisplay} key={path}>
        <CircleXButton onClick={() => onClick(f)} />
        <span>
          {name} - {size} bytes
        </span>
      </div>
    );
  });
function UserUpload({ files, setFiles }) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        () => acceptedFiles,
        // UNCOMMENT FOR MULTI FILE UPLOAD
        // {
        //   const newFiles = acceptedFiles.filter(
        //     ({ path: acceptPath }) =>
        //       !files.some(({ path: savedPath }) => savedPath === acceptPath),
        //   );
        //   return newFiles.length ? [...files, ...newFiles] : files;
        // }
      );
    },
    [files],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: documentMimeTypeAcceptions,
  });
  const removeFile = useCallback(
    (file) => {
      const copy = [...files];
      const remove = copy.indexOf(file);
      if (remove != -1) copy.splice(remove, 1);
      setFiles(() => copy);
    },
    [files],
  );
  return (
    <div className={uploadContainer}>
      <div {...getRootProps()} id={dragDropUploadId}>
        <input {...getInputProps()} />
        <section className={dragDropUploadMainContent}>
          <span>Drag and drop file(s) here</span>
          <span>or</span>
          <RoundedButton text="Select file(s)" />
        </section>
      </div>
      <RemoveDocBtn files={files} onClick={removeFile} />
    </div>
  );
}
export default UserUpload;
