import { fetchApiData, postApiData } from './shared';
import { popHideSuccessMsg, popHideFailMessage } from './sweetAlerts';
import { getEnvironment } from '../utils/applicationEnvironment';
import kibanaEventTrigger, {
  KIBANA_FAILED_STATE,
  KIBANA_SUCCESS_STATE,
  KIBANA_CREATE_UPDATE_UBIF,
} from './KibanaEvents';
import config from '../config.json';

const env = getEnvironment();

export const fetchUbifFormWithDN = async (params) => {
  const apiUrl = config[env].UBIF_FORM_SEARCH;
  const { data = {} } = await fetchApiData(apiUrl, {}, params);
  return data;
};

export const postUbifStore = (inputData) => (helixUser) => {
  const apiUrl = config[env].UPDATE_UBIF_STORE;
  const authKey = config[env].API_AUTH_KEYS.ORBIT_UBIF_KEY;
  const headers = {
    'x-api-key': authKey,
  };
  postApiData(apiUrl, inputData, headers)
    .then(({ response: { data = 'Ubif Form Success' } = {} } = {}) => ({
      data,
      state: KIBANA_SUCCESS_STATE,
    }))
    .catch(({ response: { data } = {} } = {}) => ({
      err: data,
      state: KIBANA_FAILED_STATE,
    }))
    .then(async ({ data, state, err }) => {
      if (data) popHideSuccessMsg(data);
      else popHideFailMessage(err);
      kibanaEventTrigger({
        event: KIBANA_CREATE_UPDATE_UBIF,
        ...helixUser,
        response: data ? data : err,
        value: inputData,
        state,
      });
      return data;
    });
};
