import swal from 'sweetalert2';
import { fetchApiData } from './shared';
import { getEnvironment } from '../utils/applicationEnvironment';
import config from '../config.json';
import kibanaEventTrigger, {
  KIBANA_SEARCH_NEW_EXPERT,
  KIBANA_FAILED_STATE,
  KIBANA_SUCCESS_STATE,
} from './KibanaEvents';

const env = getEnvironment();

const apiUrl = config[env].NEW_EXPERT_DETAILS;
const orbitAPIKey = config[env].API_AUTH_KEYS.EXPERT_ATTRIBUTE_KEY;

const NewExpertDetails = (req) => async (helixUser) => {
  const kibanaResults = {
    ...req,
    event: KIBANA_SEARCH_NEW_EXPERT,
  };
  return fetchApiData(
    apiUrl,
    {
      'x-api-key': orbitAPIKey,
    },
    req,
  )
    .then(({ data }) => {
      return { data, state: KIBANA_SUCCESS_STATE };
    })
    .catch((err) => {
      return { err, state: KIBANA_FAILED_STATE };
    })
    .then(async ({ data, state, err }) => {
      const alert = {
        showCancelButton: false,
        showConfirmButton: false,
        icon: 'error',
        timer: 1000,
      };
      if (data) {
        if (!data.Supervisor_Name && !data.Supervisor_Id) {
          const text = 'User already exists';
          swal.fire({
            text,
            ...alert,
          });
        }
      } else {
        const text = 'Invalid employee ID';
        swal.fire({
          text,
          ...alert,
        });
      }
      kibanaEventTrigger({
        ...kibanaResults,
        ...helixUser,
        value: data ? data : err,
        state,
      });
      return data;
    });
};

export default NewExpertDetails;
