import React from 'react';
import { styled } from '@mui/material/styles';
import {
  InputLabel,
  Autocomplete,
  ClickAwayListener,
  InputBase,
  Popper,
  Box,
} from '@mui/material';
import './index.css';
import {
  arrayToRemoveDups,
  reduceArrOfObjToNoDupes,
} from '../../transformations/utils';
import { ALL_CAPITAL } from '../../data/constants';
import { DEFAULT_FUNCTION } from '../../data/datatypes';

export const ArrowForDropdown = () => {
  return (
    <svg
      className="tinyArrow"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      data-testid="ArrowDropDownIcon"
    >
      <path d="M7 10l5 5 5-5z"></path>
    </svg>
  );
};
export const SpecialLabel = () => {
  return (
    <fieldset aria-hidden="true" className="specialLabel">
      <legend>
        <span>&nbsp;</span>
      </legend>
    </fieldset>
  );
};
export const StyledAutocomplete = styled((props) => (
  <Autocomplete {...props} />
))(({ theme }) => ({
  background: theme.palette.background.dark,
}));
const PopperComponent = ({ anchorEl, open, disablePortal, ...other }) => (
  <div {...other} />
);
const PopperRenderOptionComponent = (props, option) => (
  <li {...props} key={option}>
    <span>{option}</span>
  </li>
);
const TitleHocToPopperUserInputComponent = (title) => (params) =>
  (
    <InputBase
      ref={params?.InputProps?.ref}
      inputProps={params?.inputProps}
      autoFocus
      placeholder={`Filter ${title}`}
    />
  );
export const DropdownSelectionLabel = styled(InputLabel)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme?.palette?.background?.paper,
  borderRadius: '8px',
  padding: '0 4px',
  color:
    theme?.palette?.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.85)'
      : `rgba(0, 0, 0, 0.8)`,
}));
const CustomSelectionSearchDropDown = ({
  selectionList,
  selectionValue,
  onClick,
  selections = [],
  anchorEl,
  setAnchorEl,
  title,
}) => {
  const handleClose = () => {
    if (anchorEl) {
      anchorEl?.focus();
    }
    setAnchorEl(null);
  };
  const listPlusAllOption = (list) => [ALL_CAPITAL, ...list];
  const onChange = (event, _, reason) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Backspace' || event.key === 'Enter') &&
      reason === 'removeOption'
    ) {
      return;
    }
    const selectedItem = event.currentTarget.children[0].innerText;
    onClick(selectedItem);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'taskq-label' : undefined;
  const options = listPlusAllOption(arrayToRemoveDups(selections));
  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      className="styledPopper"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <StyledAutocomplete
            open
            multiple
            options={options}
            value={selectionValue}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            noOptionsText={`No ${title} found`}
            renderInput={TitleHocToPopperUserInputComponent(title)}
            onChange={onChange}
            PopperComponent={PopperComponent}
            renderOption={PopperRenderOptionComponent}
          />
        </div>
      </ClickAwayListener>
    </Popper>
  );
};
export const SearchableDropdown = ({ selectionList, title, ...otherProps }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const toggleTaskqModal = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Box>
        <div onClick={toggleTaskqModal} className="selectionListContainer">
          {selectionList}
        </div>
        <ArrowForDropdown />
        <SpecialLabel />
        <DropdownSelectionLabel shrink={true}>{title}</DropdownSelectionLabel>
      </Box>
      <CustomSelectionSearchDropDown
        {...otherProps}
        title={title}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};
export default SearchableDropdown;
