import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  HEALTH_CHECK_PAGE_SLUG,
  MAIN_SUCCESS_COLOR_LIGHT,
  MAIN_WARNING_COLOR,
  MAIN_ERROR_COLOR_LIGHT,
  MAIN_SUCCESS_COLOR_DARK,
  MAIN_ERROR_COLOR_DARK,
} from './data/constants';
import AuthHub from './components/AuthHub';
import DashboardComponent from './components/DashboardComponent';
import HealthTableComponent from './components/HealthTableComponent';
import Help from './components/Help';
import store from './reducers';
import PrivateRoute from './Routes/PrivateRoute';
import './App.scss';
import { red } from '@mui/material/colors';
import FullscreenImageWithText from './components/FullscreenImageWithText';
import BrokenComputerLight from './images/brokenComputerLight.svg';
import BrokenComputerDark from './images/brokenComputerDark.svg';

export const isDarkToTheme = (isDark) =>
  createTheme({
    typography: {
      fontFamily: `"Apercu","Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif`,
      button: {
        textTransform: 'none',
      },
    },
    palette: {
      mode: isDark ? 'dark' : 'light',
      divider: isDark ? '#808080' : '#333',
      text: {
        main: 'blue',
      },
      background: {
        paper: isDark ? '#323232' : '#f0f0f5',
        paperAltLight: isDark ? '#323232' : '#fff',
        light: '#CB5AFF',
        main: '#8223d2',
        dark: isDark ? '#111111' : '#FFFFFF',
        contrastText: '#6EFAC3',
      },
      primary: {
        light: '#CB5AFF',
        main: isDark ? '#6EFAC3' : '#8223d2',
        dark: '#6700B8',
        contrastText: isDark ? '#CB5AFF' : '#00E7A1',
      },
      secondary: {
        light: '#00E7A1',
        main: '#00E7A1',
        dark: '#00B574',
        contrastText: '#fff',
      },
      neutral: {
        main: isDark ? '#FFFFFF' : '#323232',
        background: isDark ? '#191919' : '#FFFFFF',
        border: isDark ? '#808080' : '#000000',
        contrastText: isDark ? '#6efac3' : '#a839f3',
      },
      info: {
        main: '#808080',
        secondary: isDark ? '#fff' : '#606062',
      },
      button: {
        main: isDark ? '#363636' : '#f0f0f5',
      },
      chartsContainer: {
        main: isDark ? '#FFFFFF' : '#323232',
        background: isDark ? '#191919' : '#FFFFFF',
        border: 1,
        margin: 1,
        // paddingTop: 1,
        display: 'flex',
      },
      success: {
        main: isDark ? MAIN_SUCCESS_COLOR_DARK : MAIN_SUCCESS_COLOR_LIGHT,
      },
      error: {
        main: isDark ? MAIN_ERROR_COLOR_DARK : MAIN_ERROR_COLOR_LIGHT,
      },
      warning: {
        main: MAIN_WARNING_COLOR,
      },
      charts: {
        success: isDark ? '#6EFAC3' : 'rgb(130, 35, 210)',
        failure: isDark ? '#FFEE32' : red[800],
        main: isDark ? '#FFFFFF' : '#323232',
        labelColor: isDark ? 'white' : 'black',
        background: isDark ? '#191919' : '#FFFFFF',
        padding: 1,
        // margin: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      titleBar: {
        main: isDark ? '#8223d2' : '#fff',
        background: isDark ? '#fff' : '#8223d2',
      },
    },
    status: {
      danger: red[800],
    },
  });
export const AppWrapper = (props) => {
  const darkmode = useSelector(
    (state) => state?.user?.userSettings?.darkMode ?? false,
  );
  const orbitRestricted = useSelector((state) => state?.user?.orbitRestricted);
  const classNameWrap = darkmode ? 'darkmode' : '';
  const brokenText = 'An assigned AD group is restricting access to Orbit';
  return (
    <>
      <Helmet>
        <body className={classNameWrap} />
      </Helmet>
      <ThemeProvider theme={isDarkToTheme(darkmode)}>
        {orbitRestricted ? (
          <FullscreenImageWithText
            image={darkmode ? BrokenComputerDark : BrokenComputerLight}
            text={brokenText}
          />
        ) : (
          props.children
        )}
      </ThemeProvider>
    </>
  );
};
const App = () => (
  <Provider store={store}>
    <AuthHub />
    <AppWrapper>
      <BrowserRouter>
        <Switch>
          <Route exact path="/help" component={Help} />
          <PrivateRoute
            path={HEALTH_CHECK_PAGE_SLUG}
            component={HealthTableComponent}
          />
          <DashboardComponent />
        </Switch>
      </BrowserRouter>
    </AppWrapper>
  </Provider>
);

export default App;
