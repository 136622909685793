import React, { useEffect, useState } from 'react';
import './index.scss';
import { Box } from '@mui/material';
import {
  globalContactCenterStatsAPIFetch,
  globalDataRowMap,
} from './GlobalContactCenterStats';
import { globalTableSort } from './GlobalContactCenterStats/transformations';
import {
  globalCallStatsAPIFetch,
  GlobalCallStatsChartsComponent,
} from './GlobalCallStats';
import { numberOfExpertsHeader, statusTableHeader } from './constants';
import { GlobalStatsTableComponent } from './GlobalStatsTableComponent';
import { ConditionalDisplay } from '../ConditionalDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { BoxedInfoDataModel } from '../../data/datatypes';
import { globalExpertStatsResponseToState } from '../../reducers/TableDataReducer';
import { People, SupportAgent } from '@mui/icons-material';
import { CollectionOfBoxedInformation } from '../BoxedInformation/CollectionOfBoxedInformation';
import { ChartHeader } from '../GlobalCharts';

const GLOBAL_STAT_HEADING_CLASS = 'globalStatsHeading';

export const GlobalStatsSection = ({
  children = <></>,
  className = 'contact-center-layout-table',
}) => {
  return (
    <div className={className}>
      <ConditionalDisplay bool={children}>{children}</ConditionalDisplay>
    </div>
  );
};

export const ContactCenterBoxes = () => {
  const { totalCount = 0, offlineCount = 0 } = useSelector(
    (state) => state?.tableData?.globalExpertStats,
  );

  const [boxedInformation, setBoxedInformation] = useState([]);

  useEffect(() => {
    setBoxedInformation([
      new BoxedInfoDataModel(
        'Total Experts',
        totalCount,
        <People sx={{ fontSize: 'larger', color: 'primary.main' }} />,
      ),
      new BoxedInfoDataModel(
        'Experts Logged In',
        totalCount - offlineCount,
        <SupportAgent sx={{ fontSize: 'larger', color: 'primary.main' }} />,
      ),
    ]);
  }, [totalCount, offlineCount]);

  return (
    <CollectionOfBoxedInformation
      titlesAndValues={boxedInformation}
      showDivider={true}
    />
  );
};

export const CallCenterBoxes = () => {
  const [boxedInformationTop, setBoxedInformationTop] = useState([]);
  const [boxedInformationBottom, setBoxedInformationBottom] = useState([]);
  const { summaryStats } = useSelector(
    (state) => state?.tableData?.globalCallStats,
  );

  useEffect(() => {
    setBoxedInformationTop([
      new BoxedInfoDataModel(
        'Calls to IVR',
        summaryStats?.callsToIVR ?? 0,
        <></>,
        'Calls offered to IVR',
        {
          borderLeft: 'solid 1px',
          borderRight: 'solid 1px',
          borderTop: 'solid 1px',
        },
      ),
      new BoxedInfoDataModel('Calls Offered', summaryStats?.callsOffered ?? 0),
      new BoxedInfoDataModel(
        'Calls Answered',
        summaryStats?.callsAnswered ?? 0,
        <></>,
        '',
        { borderLeft: 'solid 1px', borderRight: 'solid 1px' },
      ),
      new BoxedInfoDataModel(
        'Calls Abandoned',
        summaryStats?.callsAbandoned ?? 0,
      ),
    ]),
      setBoxedInformationBottom([
        new BoxedInfoDataModel(
          'System Disconnects',
          summaryStats?.SystemDisconnects ?? 0,
          <></>,
          'Unresponsive Customers disconnected by IVR',
          {
            borderLeft: 'solid 1px',
            borderRight: 'solid 1px',
            borderTop: 'solid 1px',
          },
        ),
        new BoxedInfoDataModel(
          'Customer Disconnects',
          summaryStats?.CustomerDisconnects ?? 0,
          <></>,
          'Calls abandoned from IVR',
          {
            borderLeft: 'solid 1px',
            borderRight: 'solid 1px',
            borderTop: 'solid 1px',
          },
        ),
        new BoxedInfoDataModel(
          'Deflections',
          summaryStats?.deflections ?? 0,
          <></>,
          'Customers redirected to messaging by IVR',
          {
            borderLeft: 'solid 1px',
            borderRight: 'solid 1px',
            borderTop: 'solid 1px',
          },
        ),
        new BoxedInfoDataModel(
          'Avaya Transfers',
          summaryStats?.avayaTransfers ?? 0,
          <></>,
          'IVR transfers to Avaya',
          {
            borderLeft: 'solid 1px',
            borderRight: 'solid 1px',
            borderTop: 'solid 1px',
          },
        ),
      ]);
  }, [summaryStats]);
  useEffect(() => {});

  return (
    <ConditionalDisplay
      bool={boxedInformationTop?.length && boxedInformationBottom?.length}
    >
      <Box sx={{ width: '99%' }}>
        <CollectionOfBoxedInformation
          titlesAndValues={boxedInformationTop}
          showDivider={false}
          vertical={false}
        />
      </Box>
      <Box
        sx={{
          paddingTop: 3,
          width: '100%',
        }}
      />
      <Box sx={{ border: 1 }}>
        <ChartHeader header={'IVR Call Stats'} size="h5" />
        <CollectionOfBoxedInformation
          titlesAndValues={boxedInformationBottom}
          showDivider={false}
          vertical={false}
        />
      </Box>
    </ConditionalDisplay>
  );
};

const GlobalStats = () => {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        flex: '1 1',
        justifyContent: 'space-evenly',
      }}
    >
      <GlobalStatsSection
        apiFetchFunction={globalContactCenterStatsAPIFetch(dispatch)}
        dispatchFunction={globalExpertStatsResponseToState(dispatch)}
      >
        <GlobalStatsHeader header={'Contact Center Activity Stats'} />
        <GlobalStatsTableComponent
          dimensions={'150px 150px'}
          dataUpdateInterval={30000}
          width={'300px'}
          headers={[statusTableHeader, numberOfExpertsHeader]}
          sortMethod={globalTableSort}
          rowMapMethod={globalDataRowMap}
          Boxes={<ContactCenterBoxes />}
          useScrollBar={false}
          selector="globalExpertStats"
        />
      </GlobalStatsSection>
      <GlobalStatsSection
        apiFetchFunction={globalCallStatsAPIFetch}
        useInterval={false}
        className="contact-center-layout-chart"
      >
        <GlobalCallStatsChartsComponent
          useInterval={true}
          Boxes={<CallCenterBoxes />}
        />
      </GlobalStatsSection>
    </Box>
  );
};

export const GlobalStatsHeader = ({ header }) => (
  <Box width="400px">
    <div className={GLOBAL_STAT_HEADING_CLASS}>{header}</div>
  </Box>
);

export default GlobalStats;
