import React from 'react';
import { Typography, Stack, Divider } from '@mui/material';
import { processTime } from './transformations';

import './index.scss';

export const NotificationCard = ({ name, title, timestamp }) => {
  const time = processTime(timestamp);
  return (
    <>
      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="subtitle1">
          <strong>{name}</strong>
        </Typography>
        <Typography variant="caption">{title}</Typography>
        <Typography fontSize={13} color="primary.contrastText">
          <strong>{time}</strong>
        </Typography>
      </Stack>
      <Divider />
    </>
  );
};
