import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DebounceApiInput } from '../Dialog';
import {
  groupToUpateGroupFilterState,
  lobToUpateLobFilterState,
  statusToUpateStatusFilterState,
  statusToUpateCoachFilterState,
  statusToUpateSiteFilterState,
  userInputSearch,
  taskQueueToUpdateTaskQueueFilterState,
} from '../../reducers/SelectionReducer';
import { styled } from '@mui/material/styles';
import SearchableDropdown, {
  DropdownSelectionLabel,
} from '../SearchableDropdown';
import {
  withLoggingAnalytics,
  GROUP_FILTER_EVENT,
  LOB_FILTER_EVENT,
  STATUS_FILTER_EVENT,
  COACH_FILTER_EVENT,
  SITE_FILTER_EVENT,
  TASKQ_FILTER_EVENT,
} from '../../services/KibanaEvents';
import { MenuItem, FormControl, Select } from '@mui/material';
import './index.css';
import { ALL_CAPITAL } from '../../data/constants';
import { mapSelections } from './transformations';

const StyledFormControl = styled((props) => <FormControl {...props} />)(
  ({ theme }) => ({
    width: '300px',
    background: theme.palette.background.dark,
  }),
);
export const lobTitle = 'Line of business';
const coachTitle = 'Coach',
  groupTitle = 'Group',
  statusTitle = 'Status',
  siteTitle = 'Site',
  taskQTitle = 'Task Queue';
const titleToFilterAndSelections = (
  {
    lobFilter,
    lobSelection,

    groupFilter,
    groupSelection,

    statusFilter,
    statusSelection,

    coachSelection,
    coachFilter,

    siteSelection,
    siteFilter,

    taskQueueFilter,
    taskQueueSelection,
  } = {},
  title,
) => {
  const returnFilterAndSelection = (filter, selection, analytics, action) => ({
    filter,
    selection,
    analytics,
    action,
  });
  switch (title) {
    case lobTitle:
      return returnFilterAndSelection(
        lobFilter,
        lobSelection,
        LOB_FILTER_EVENT,
        lobToUpateLobFilterState,
      );
    case groupTitle:
      return returnFilterAndSelection(
        groupFilter,
        groupSelection,
        GROUP_FILTER_EVENT,
        groupToUpateGroupFilterState,
      );
    case statusTitle:
      return returnFilterAndSelection(
        statusFilter,
        statusSelection,
        STATUS_FILTER_EVENT,
        statusToUpateStatusFilterState,
      );
    case coachTitle:
      return returnFilterAndSelection(
        coachFilter,
        coachSelection,
        COACH_FILTER_EVENT,
        statusToUpateCoachFilterState,
      );
    case siteTitle:
      return returnFilterAndSelection(
        siteFilter,
        siteSelection,
        SITE_FILTER_EVENT,
        statusToUpateSiteFilterState,
      );
    case taskQTitle:
      return returnFilterAndSelection(
        taskQueueFilter,
        taskQueueSelection,
        TASKQ_FILTER_EVENT,
        taskQueueToUpdateTaskQueueFilterState,
      );
    default:
      return returnFilterAndSelection(lobFilter, lobSelection);
  }
};
export class SelectionDropDownProps {
  constructor(
    title,
    allowSearch,
    selections,
    filters,
    click,
    selectAllOption = true,
  ) {
    this.title = title;
    this.allowSearch = allowSearch;
    this.selections = selections;
    this.filters = filters;
    this.onClick = click;
    this.selectAllOption = selectAllOption;
  }
}
const useTitleSelectionProps = (title, allowSearch, objectKey) => {
  const dispatch = useDispatch();
  const selections = useSelector((state) => state?.selection);
  let { filter, selection, analytics, action } = titleToFilterAndSelections(
    selections,
    title,
  );

  selection = selection.map((item) =>
    objectKey ? item?.[objectKey] ?? '' : item,
  );

  const actionDispatchInit = (actionToPayload) => (item) =>
    dispatch(actionToPayload(item));

  const onClick = withLoggingAnalytics(analytics, actionDispatchInit(action));
  return new SelectionDropDownProps(
    title,
    allowSearch,
    selection,
    filter,
    onClick,
    true,
  );
};

export const SelectDropDown = ({
  title,
  allowSearch = false,
  selections = [],
  filters = [],
  onClick,
  selectAllOption = true,
}) => {
  let selectionValue =
    !filters?.length && selectAllOption ? [ALL_CAPITAL] : filters ?? [];

  const selectView = selections?.map(mapSelections(onClick));
  const inputLabelContent = !allowSearch ? title : null;
  const selectionList = selectionValue.join(', ');
  return (
    <StyledFormControl>
      <DropdownSelectionLabel variant="outlined" id={title} color="primary">
        {inputLabelContent}
      </DropdownSelectionLabel>
      {allowSearch ? (
        <SearchableDropdown
          title={title}
          selectionList={selectionList}
          selectionValue={selectionValue}
          onClick={onClick}
          selections={[...selections]}
        />
      ) : (
        <Select
          label={title}
          labelId={title}
          multiple
          value={selectionValue}
          variant="outlined"
        >
          {selectAllOption ? (
            <MenuItem value={ALL_CAPITAL} onClick={() => onClick(ALL_CAPITAL)}>
              {ALL_CAPITAL}
            </MenuItem>
          ) : null}
          {selectView}
        </Select>
      )}
    </StyledFormControl>
  );
};
export const CallExpertSelectionDropDown = ({
  title,
  allowSearch = false,
  objectKey = '',
}) => {
  const mainSelectionProps = useTitleSelectionProps(
    title,
    allowSearch,
    objectKey,
  );
  return <SelectDropDown {...mainSelectionProps} />;
};

export const DebounceOpenFieldSearch = () => {
  const dispatch = useDispatch();
  const fieldSearch = useSelector((state) => state?.selection?.fieldSearch);
  const handleInputSearch = (searchInput) => {
    return new Promise((res) => {
      dispatch(userInputSearch(searchInput));
      res(false);
    });
  };
  return (
    <div className="expert-global-search">
      <DebounceApiInput
        showLoading={false}
        onChange={handleInputSearch}
        value={fieldSearch}
        label="Search Expert"
        disabled={false}
      />
    </div>
  );
};

export const SelectionContainerWrap = ({ children } = {}) => {
  return <div className="filter-selection-wrap">{children}</div>;
};
const DropviewFilters = () => {
  return (
    <SelectionContainerWrap>
      <CallExpertSelectionDropDown title={lobTitle} />
      <CallExpertSelectionDropDown title={groupTitle} allowSearch={true} />
      <CallExpertSelectionDropDown
        title={coachTitle}
        allowSearch={true}
        objectKey="name"
      />
      <CallExpertSelectionDropDown title={taskQTitle} allowSearch={true} />
      <CallExpertSelectionDropDown title={statusTitle} />
      <CallExpertSelectionDropDown title={siteTitle} allowSearch={true} />
      <DebounceOpenFieldSearch />
    </SelectionContainerWrap>
  );
};
export default DropviewFilters;
