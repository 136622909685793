import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Typography, Stack } from '@mui/material';
import ButtonOpenDialog from '../ButtonOpenDialog';
import UserUpload from '../UserUpload';
import { postQSPAutomation } from '../../services/Experts';
import { useLoggingAnalytics } from '../../services/KibanaEvents';
import { useTheme } from '@mui/material/styles';

import './index.scss';
import { UpsellUpdateActivityTable } from '../UpsellUpdateActivityTable';

export const CSVUploadButton = () => {
  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <Typography fontSize="15px">Load .CSV </Typography>
      <AddCircleOutlineIcon fontSize="small" />
    </Box>
  );
};

export const DisplayBoxWith3Texts1Button = ({
  header = '',
  subtitle = '',
  Button = () => <></>,
  footer = '',
  ...otherProps
}) => {
  return (
    <Stack gap={3} className="restore-component">
      <Typography className="restore-component-header">{header}</Typography>
      <Typography className="restore-component-title-divider" />
      <Typography className="restore-component-text">{subtitle}</Typography>
      <Typography />
      <Stack className="restore-component-button">
        <Button {...otherProps} />
      </Stack>
      <Typography className="restore-component-footer" sx={{ fontSize: 11 }}>
        {footer}
      </Typography>
    </Stack>
  );
};

export const UploadCSVDialogButton = (props) => {
  const [files, setFiles] = useState([]);
  const loggingAnalytics = useLoggingAnalytics('QSP Automation Upload');
  const handleChange = async (e) => {
    await postQSPAutomation(files[0], loggingAnalytics).then((res) =>
      console.log(res),
    );
  };
  const disabledSubmit = !files?.length;
  const theme = useTheme();

  return (
    <ButtonOpenDialog
      cb={handleChange}
      title={`Upload Upsell/QSP Automation CSV`}
      disabledSubmit={disabledSubmit}
      disabledDialogOpenButton={!props?.hasEditPermissions ?? true}
      toolTipTitle="Upload"
      CtaIcon={CSVUploadButton}
      Render={<UserUpload files={files} setFiles={setFiles} />}
      buttonStyle={{
        color: 'black',
        backgroundColor: 'white',
        ':hover': {
          backgroundColor: theme?.palette?.button?.main,
          color: theme?.palette?.neutral?.contrastText,
        },
      }}
    />
  );
};
const UploadCSVPage = (props) => {
  const header = 'Update selected experts Upsell and QSP100 attribute values';
  const subtitle =
    'Load.CSV button will update the Upsell and QSP100 attribute values for selected experts. Changes will reflect in Orbit after database updates are complete.';
  const footer = 'Database is updated nightly at 2am.';

  return (
    <>
      <DisplayBoxWith3Texts1Button
        header={header}
        subtitle={subtitle}
        footer={footer}
        Button={UploadCSVDialogButton}
        {...props}
      />
      <UpsellUpdateActivityTable />
    </>
  );
};

export default UploadCSVPage;
