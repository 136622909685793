import React from 'react';
import { styled } from '@mui/material/styles';
import { withLoggingAnalytics } from '../../services/KibanaEvents';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { valueToIsButtonSelectedAction } from '../../reducers/ExpertReducer';
import './index.scss';

export const CURRENT_INTERVAL_BTN_TEXT = 'Current Interval',
  TODAY_BTN_TEXT = 'Today';
export class ToggleSwitchModel {
  constructor(dispatch, { selectedInterval } = {}) {
    this.ctaClick = (interval) => {
      dispatch(valueToIsButtonSelectedAction(interval));
    };
    this.interval = selectedInterval;
  }
}

const StyledLeftToggleButton = styled(ToggleButton)`
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  height: 30px;
  white-space: nowrap;
`;
const StyledRightToggleButton = styled(ToggleButton)`
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  height: 30px;
`;
export default function TogglesComponent({ ctaClick, interval }) {
  const handleChange = (event, interv) => {
    if (interv?.length) {
      ctaClick(interv);
    }
    withLoggingAnalytics('interval-toggle', null);
  };
  return (
    <ToggleButtonGroup
      color="primary"
      value={interval}
      exclusive
      onChange={handleChange}>
      <StyledLeftToggleButton value={CURRENT_INTERVAL_BTN_TEXT}>
        {CURRENT_INTERVAL_BTN_TEXT}
      </StyledLeftToggleButton>
      <StyledRightToggleButton
        value={TODAY_BTN_TEXT}
        style={{ width: '100px' }}>
        {TODAY_BTN_TEXT}
      </StyledRightToggleButton>
    </ToggleButtonGroup>
  );
}
