import { fetchApiData } from './shared';
import { getEnvironment } from '../utils/applicationEnvironment';
import config from '../config.json';
import kibanaEventTrigger, {
  KIBANA_GET_USER_SETTINGS,
  KIBANA_FAILED_STATE,
  KIBANA_SUCCESS_STATE,
} from './KibanaEvents';
import { saveUserSettings } from '../components/AuthHub';

const env = getEnvironment();
const apiUrl = config[env].GET_USER_SETTINGS;
const orbitAPIKey = config[env].API_AUTH_KEYS.ORBIT_API_KEY;

const GetUserSettings = (dispatch) => async (helixUser) => {
  const { employeeId } = helixUser;
  const kibanaResults = {
    event: KIBANA_GET_USER_SETTINGS,
  };
  fetchApiData(
    apiUrl,
    {
      'x-api-key': orbitAPIKey,
    },
    { empId: employeeId },
  )
    .then((success) => {
      console.log('success: ', success);
      return { response: success, state: KIBANA_SUCCESS_STATE };
    })
    .catch((err) => {
      return { response: err, state: KIBANA_FAILED_STATE };
    })
    .then(({ response, state }) => {
      /*eslint no-console: "off"*/
      console.log('[ API - Response ] User settings: ', response);
      const { data: { settings = {} } = {} } = response;
      saveUserSettings(dispatch)(settings);
      kibanaEventTrigger({
        ...kibanaResults,
        ...helixUser,
        settings,
        state,
      });
    });
};

export default GetUserSettings;
