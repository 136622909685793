import expertReducer from './ExpertReducer';
import userReducer from './UserReducer';
import selectionReducer from './SelectionReducer';
import tableData from './TableDataReducer';
import { createStore, combineReducers } from 'redux';

const rootReducer = combineReducers({
  user: userReducer,
  expert: expertReducer,
  selection: selectionReducer,
  tableData,
});

const store = createStore(
  rootReducer,
  /*eslint no-undef: "off"*/
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export default store;
