import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { sleepWellCb3x } from '../../transformations/utils';
import {
  workforceTableResponseToState,
  setWorkforceTableLimit,
  setWorkforceTablePage,
  setWorkforceTableSort,
} from '../../reducers/TableDataReducer';
import {
  selectorToUserSavedQueues,
  selectorToUserSavedLobs,
} from '../../reducers/UserReducer';
import {
  graphQlWorkforceToViewData,
  numberMinusOne,
  zeroPageIndexAndLimitToFirstIndexInView,
  firstIndexInViewAndLimitToLastIndexInView,
  generateTablePageString,
  sortToIntervalToHandleCi,
  functionToHandleIncomingSort,
} from '../../reducers/TableDataReducer/transformations';
import {
  ElasticMustySearch,
  ElasticSearchTerm,
} from '../../reducers/TableDataReducer/datatypes';
import {
  workforceSummaryStatsFetch,
  workforceNotificationFetchQuery,
} from '../../data/queries';

const mapNotificationsToLobAndGroup =
  (notifications = []) =>
  ({ lob: summaryStatLob, group: summaryStatGroup } = {}) =>
    notifications
      ?.reduce((acc, { taskqueues: { items } = {} }) => {
        const matchedNotifications = items?.reduce(
          (
            nestedAcc,
            {
              lob: notificationLob,
              group: notificationGroup,
              taskqueueName: name,
              notifications: taskQNotifications,
            } = {},
          ) => {
            if (
              summaryStatLob === notificationLob &&
              summaryStatGroup === notificationGroup
            ) {
              const moddedNotification = (
                taskQNotifications?.calls_in_queue ?? []
              ).map((notificationDetails) => {
                const { title, time_stamp } = JSON.parse(notificationDetails);
                return {
                  name,
                  timestamp: time_stamp ? Number(time_stamp) : null,
                  title,
                };
              });
              nestedAcc.push(...moddedNotification);
            }
            return nestedAcc;
          },
          [],
        );
        if (matchedNotifications?.length) {
          acc.push(...matchedNotifications);
        }
        return acc;
      }, [])
      ?.sort(({ timestamp: a }, { timestamp: b }) => a - b);
const mapWfNotificationsToSummaryStats =
  (appsyncQuerySummaryStats = {}) =>
  (appsyncQueryNotifications = {}) => {
    const { items: summaryStats, total } = appsyncQuerySummaryStats;
    const { items: notifications } = appsyncQueryNotifications;
    const summaryStatsLinkedWithNotification = summaryStats?.map(
      (lobGroupSummaryStats) => {
        return {
          ...lobGroupSummaryStats,
          notifications:
            mapNotificationsToLobAndGroup(notifications)(lobGroupSummaryStats),
        };
      },
    );
    return { items: summaryStatsLinkedWithNotification, total };
  };
export const useWFProps = () => {
  const dispatch = useDispatch();
  const { items, limit, loading, refetch, page, sort, totalSortedCount } =
    useSelector((state) => state?.tableData?.workforce);
  const selectedInterval = useSelector(
    (state) => state?.expert?.selectedInterval,
  );
  const selectedLob = useSelector(selectorToUserSavedLobs);
  const selectedGroup = useSelector(selectorToUserSavedQueues);
  const zeroIndexPage = numberMinusOne(page);
  useEffect(() => {
    if (!loading && (items?.length || !totalSortedCount)) {
      return;
    }
    const asyncFetch = async () => {
      const filter = new ElasticMustySearch([
        new ElasticSearchTerm('lob', selectedLob),
        new ElasticSearchTerm('group', selectedGroup),
      ]);
      const from = !zeroIndexPage ? 0 : zeroIndexPage * limit;

      const variables = {
        sort: sortToIntervalToHandleCi(sort)(selectedInterval),
        filter: JSON.stringify(filter),
        limit,
        from,
      };
      const response = await sleepWellCb3x(async () => {
        const { data: { searchSummaryStatsWithESFilter = {} } = {} } =
          await API.graphql({
            query: workforceSummaryStatsFetch,
            variables,
          });
        const { data: { searchLOBGroupMapsWithESFilter = {} } = {} } =
          await API.graphql({
            query: workforceNotificationFetchQuery,
            variables: { ...variables, sort: null },
          });
        console.log(
          '[ Workforce GraphQL Response ] - ',
          searchSummaryStatsWithESFilter,
        );
        return mapWfNotificationsToSummaryStats(searchSummaryStatsWithESFilter)(
          searchLOBGroupMapsWithESFilter,
        );
      });
      dispatch(
        workforceTableResponseToState(
          graphQlWorkforceToViewData(selectedInterval, response),
        ),
      );
    };
    if (selectedLob && selectedGroup) asyncFetch();
  }, [
    selectedLob,
    selectedGroup,
    limit,
    loading,
    refetch,
    page,
    sort,
    selectedInterval,
  ]);
  const nextPage = useCallback(() => {
    dispatch(setWorkforceTablePage(page + 1));
  }, [page]);
  const previousPage = useCallback(() => {
    dispatch(setWorkforceTablePage(page - 1));
  }, [page]);
  const goToFirstPage = useCallback(() => {
    dispatch(setWorkforceTablePage(1));
  }, [page]);
  const goToLastPage = useCallback(() => {
    const lastPage = Math.ceil(totalSortedCount / limit);
    dispatch(setWorkforceTablePage(lastPage));
  }, [page, totalSortedCount, limit]);
  const sortKeyToSortDirection = functionToHandleIncomingSort((payload) =>
    dispatch(setWorkforceTableSort(payload)),
  );
  const setTableLimit = (value) =>
    dispatch(setWorkforceTableLimit(value ?? 50));
  const firstIndexOfCurrentView = zeroPageIndexAndLimitToFirstIndexInView(
    zeroIndexPage,
    limit,
  );
  const lastIndexOfCurrentVew = firstIndexInViewAndLimitToLastIndexInView(
    firstIndexOfCurrentView,
    limit,
  );
  const currentTablePageString = generateTablePageString(
    firstIndexOfCurrentView,
    lastIndexOfCurrentVew,
    totalSortedCount,
  );
  const prevPageAvailable = !!zeroIndexPage;
  const nextPageAvailable = lastIndexOfCurrentVew < totalSortedCount;
  return {
    nextPage,
    previousPage,
    goToLastPage,
    goToFirstPage,
    currentTablePageString,
    nextPageAvailable,
    prevPageAvailable,
    setSort: sortKeyToSortDirection,
    sort,
    setTableLimit,
    limit,
    loading,
    items,
  };
};
