import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import './index.css';

export const SERVICE_LEVEL_HIGH_CLASS = 'serviceLevelHigh',
  SERVICE_LEVEL_MEDIUM_CLASS = 'serviceLevelMedium',
  SERVICE_LEVEL_LOW_CLASS = 'serviceLevelLow',
  SERVICE_LEVEL_UPPER_THRESHOLD = 75,
  SERVICE_LEVEL_LOWER_THRESHOLD = 30;

export const serviceLevelToClass = (serviceLevel) =>
  serviceLevel > SERVICE_LEVEL_UPPER_THRESHOLD
    ? SERVICE_LEVEL_HIGH_CLASS
    : serviceLevel > SERVICE_LEVEL_LOWER_THRESHOLD
    ? SERVICE_LEVEL_MEDIUM_CLASS
    : SERVICE_LEVEL_LOW_CLASS;
const serviceLevelToColor = (serviceLevel) =>
  serviceLevel > SERVICE_LEVEL_UPPER_THRESHOLD
    ? '#00bf63'
    : serviceLevel > SERVICE_LEVEL_LOWER_THRESHOLD
    ? '#edb800'
    : '#d91b1b';
const StyledCircularProgress = (color) => styled(CircularProgress)`
  color: ${color};
`;
const CircularProgressWithPercentProgress = ({ serviceLevel }) => {
  const Progress = StyledCircularProgress(serviceLevelToColor(serviceLevel));
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <Progress
        variant="determinate"
        value={serviceLevel}
        size={100}
        thickness={3.5}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="circleMetricNumber">{`${serviceLevel}%`}</div>
      </Box>
    </Box>
  );
};

export const CircleMetricComponent = (props) => (
  <CircularProgressWithPercentProgress {...props} />
);

export default CircleMetricComponent;
