import React from 'react';
import { Typography } from '@mui/material';
import { DEFAULT_FUNCTION } from '../../data/datatypes';

import './index.scss';

export function disableBrowserCta(ctaFunc) {
  ctaFunc?.();
  return false;
}

const CtaText = ({
  text,
  callToAction = DEFAULT_FUNCTION,
  className = '',
  color = 'primary',
}) => (
  <Typography
    color={color}
    className={`cta-text${className && ' ' + className}`}
    onClick={() => disableBrowserCta(callToAction)}
  >
    {text}
  </Typography>
);

export default React.memo(CtaText);
