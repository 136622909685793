import React from 'react';
import './index.css';

export const BUTTON_TYPE = 'submit',
  BUTTON_CLASSNAME = 'btn waves-effect waves-light',
  ROUNDED_BTN_ID = 'rounded-btn';

export const RoundedButton = (props) => (
  <ButtonComponent {...props} id={ROUNDED_BTN_ID} />
);

export const ButtonComponent = ({ text = '', className = '', ...other }) => {
  return (
    <button
      type={BUTTON_TYPE}
      className={`${BUTTON_CLASSNAME}${className && ' ' + className}`}
      {...other}>
      {text}
    </button>
  );
};

export default ButtonComponent;
