import swal from 'sweetalert2';
const SUCCESS = 'success',
  FAIL = 'error';

export const popHideSuccessMsg = (title) =>
  swal.fire({
    icon: SUCCESS,
    title,
    showConfirmButton: false,
    timer: 1500,
  });
export const popHideFailMessage = (title, text = '') =>
  swal.fire({
    icon: FAIL,
    title,
    text,
    showConfirmButton: false,
    timer: 1500,
  });

export const noAutoClosePopFailMessage = (title) => (text) =>
  swal.fire({
    icon: FAIL,
    title,
    text,
    showConfirmButton: true,
    confirmButtonColor: '#8223d2',
  });
export const noAutoClosePopSuccessMessage = (title, text = '') =>
  swal.fire({
    icon: SUCCESS,
    title,
    text,
    showConfirmButton: true,
    confirmButtonColor: '#8223d2',
  });
export const popCustomHtmlMessage = (html) =>
  swal.fire({
    icon: SUCCESS,
    html,
    showConfirmButton: true,
    confirmButtonColor: '#8223d2',
  });

export const popUpWarningMessage = async (title, html, cb) => {
  const initialConfirmCall = await swal.fire({
    title,
    html,
    icon: 'warning',
    iconColor: '#8223d2',
    showCancelButton: true,
    confirmButtonColor: '#8223d2',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    focusCancel: true,
    reverseButtons: false,
  });
  if (initialConfirmCall?.isConfirmed) {
    await cb?.();
  }
};

export const noAutoClosePopWarningMessage = async (title, text) =>
  swal.fire({
    title,
    text,
    icon: 'warning',
    iconColor: '#8223d2',
    // showCancelButton: true,
    confirmButtonColor: '#8223d2',
    // cancelButtonColor: '#d33',
    confirmButtonText: 'Okay',
  });
