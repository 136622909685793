import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { postApiData } from './shared';
import { getEnvironment } from '../utils/applicationEnvironment';
import config from '../config.json';
import Amplify from 'aws-amplify';
import kibanaEventTrigger, {
  KIBANA_SAVE_USER_SETTINGS,
  KIBANA_FAILED_STATE,
  KIBANA_SUCCESS_STATE,
} from './KibanaEvents';
import { DEFAULT_FUNCTION } from '../data/datatypes';

const env = getEnvironment();

Amplify.configure(config[env].UPDATE_USER_SETTINGS);

const apiUrl = config[env].UPDATE_USER_SETTINGS;
const orbitAPIKey = config[env].API_AUTH_KEYS.ORBIT_API_KEY;

const stateToUserSettingsApiData = ({
  user: {
    helixUser = {},
    updateUserSettingsReady,
    userSettings: {
      darkMode = false,
      selectedQueue,
      selectedLob,
      taskQueueViews,
      taskQueueHeaderSort,
    },
  },
  expert: { selectedInterval } = {},
  selection: { coachFilter, statusFilter, siteFilter } = {},
}) => {
  const { employeeId: empId } = helixUser;
  return {
    /////////main settings
    activeLob: selectedLob,
    darkMode,
    activeGroup: selectedQueue,
    empId,
    selectedInterval,
    activeCoach: coachFilter,
    activeStatus: statusFilter,
    activeSite: siteFilter,
    taskQueueViews,
    taskQueueHeaderSort,
    ////////////
    helixUser,
    updateListen: [
      selectedInterval,
      darkMode,
      selectedQueue,
      selectedLob,
      coachFilter,
      statusFilter,
      siteFilter,
      taskQueueViews,
      taskQueueHeaderSort,
    ],
    updateUserSettingsReady,
  };
};
// THIS SHOULD NOT BE REUSED. AS IT IS BEING UTILIZED TO ONLY BE USED ONCE, THEN RE-USED AFTER INVOCATION
export const useUpdateUserSettings = () => {
  const { updateListen, helixUser, updateUserSettingsReady, ...settings } =
    useSelector(stateToUserSettingsApiData);
  const updateUser = async (cb = DEFAULT_FUNCTION) =>
    UpdateUserSettings(settings)(helixUser)(cb);
  useEffect(() => {
    if (updateUserSettingsReady) {
      updateUser();
    }
  }, updateListen);
};

const UpdateUserSettings =
  ({
    activeLob,
    activeGroup,
    darkMode,
    empId,
    selectedInterval,
    activeCoach,
    activeStatus,
    activeSite,
    taskQueueViews = [],
    taskQueueHeaderSort = '',
  }) =>
  (helixUser) =>
  async (cb) => {
    const kibanaResults = {
      event: KIBANA_SAVE_USER_SETTINGS,
    };

    const settingsData = {
      empId,
      selectedLob: activeLob,
      selectedQueue: activeGroup,
      darkMode,
      selectedInterval,
      selectedCoach: activeCoach,
      statusFilter: activeStatus,
      expertSite: activeSite,
      taskQueueViews,
      taskQueueHeaderSort,
    };

    postApiData(apiUrl, settingsData, {
      'x-api-key': orbitAPIKey,
    })
      .then((success) => {
        cb();
        return { response: success, state: KIBANA_SUCCESS_STATE };
      })
      .catch((err) => ({ response: err, state: KIBANA_FAILED_STATE }))
      .then(({ response, state }) =>
        kibanaEventTrigger({
          ...kibanaResults,
          ...helixUser,
          settings: settingsData,
          state,
        }),
      );
  };

export default useUpdateUserSettings;
