import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from '../Navbar';
import { KeyedComponent } from '../KeyedComponent';
import { getFormattedTime } from '../../transformations/utils';
import {
  healthCheckRouteList,
  MAIN_SUCCESS_COLOR_LIGHT,
  MAIN_WARNING_COLOR,
  MAIN_ERROR_COLOR_LIGHT,
} from '../../data/constants';
import getHealthAlarmsData from '../../services/Health';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';

import './index.css';
import {
  Paper,
  Toolbar,
  Typography,
  Stack,
  Tabs,
  Tab,
  Box,
  Badge,
  Grid,
  Tooltip as MaterialUiToolTip,
} from '@mui/material';
import {
  CloudDoneRounded,
  InfoOutlined,
  ReportProblemRounded,
} from '@mui/icons-material';
import { ConditionalDisplay } from '../ConditionalDisplay';
import { useTheme } from '@emotion/react';

const HEALTH_TABLE_TITLE = 'Current Status';
class AlarmIdentity {
  constructor(
    appName = '',
    serviceName = '',
    alarmArn = '',
    alarmName = '',
    timestamp = '',
    value = '',
    healthName = '',
  ) {
    this.key = alarmArn;
    this.appName = appName;
    this.serviceName = serviceName;
    this.alarmArn = alarmArn;
    this.alarmName = alarmName;
    this.timestamp = timestamp;
    this.value = value;
    this.healthName = healthName;
  }
}
const alarmToAppName = ({ appName }) => appName;
const alarmToAlarmName = ({ alarmName }) => alarmName;
const alarmToAlarmValue = ({ value }) => value;
const alarmToAlarmTimeStamp = ({ timestamp }) => timestamp;
const alarmToServiceName = ({ serviceName }) => serviceName;
const alarmToArn = ({ alarmArn }) => alarmArn;
const alarmToHealthName = ({ healthName }) => healthName;
const alarmApiToAlarmIdentity = ({
  AppName,
  ServiceName,
  AlarmArn,
  AlarmName,
  StateUpdatedTimestamp,
  StateValue,
  HealthName,
}) =>
  new AlarmIdentity(
    AppName,
    ServiceName,
    AlarmArn,
    AlarmName,
    StateUpdatedTimestamp,
    StateValue,
    HealthName,
  );
const handleAlarmApiResponse = (alarmApiArray = []) =>
  alarmApiArray.map(alarmApiToAlarmIdentity);

// const TabTitle = ({ title }) => <Tab label={title} />;
// const HealthTab = ({ loopedIndex, tabActiveIndex }) => <Box></Box>;
const ALARM_INSUFFICIENT = 'INSUFFICIENT',
  ALARM_OKAY = 'OK',
  ALARM_FAIL = 'ALARM';
const alarmsToAlertOn = [ALARM_INSUFFICIENT, ALARM_FAIL];
const alarmValueToStatusColor = (alarmValue) => {
  if (alarmValue === ALARM_FAIL) return 'error';
  if (alarmValue === ALARM_INSUFFICIENT) return 'warning';
  if (alarmValue === ALARM_OKAY) return 'success';
  else return 'error';
};
const serviceAvailableToMuiColor = (percentAvailable) => {
  if (percentAvailable === 50 || percentAvailable < 50) {
    return 'error.main';
  }
  if (percentAvailable === 75 || percentAvailable < 75) {
    return 'warning.main';
  } else return 'success.main';
};
const appPercentageAvailableToStatusColor = (percentAvailable) => {
  if (percentAvailable === 50 || percentAvailable < 50) {
    return MAIN_ERROR_COLOR_LIGHT;
  }
  if (percentAvailable === 75 || percentAvailable < 75) {
    return MAIN_WARNING_COLOR;
  } else return MAIN_SUCCESS_COLOR_LIGHT;
};
const alarmValueToPercentage = (alarmValue) => {
  if (alarmValue === ALARM_FAIL) return 0;
  if (alarmValue === ALARM_INSUFFICIENT) return 50;
  if (alarmValue === ALARM_OKAY) return 100;
  else return 0;
};
const alarmValueToShouldAlert = (alarmValue) =>
  alarmsToAlertOn.includes(alarmValue);
const alarmsToMainTabs = (alarms) =>
  alarms.reduce((acc, alarm) => {
    const newAcc = [...acc];
    const appName = alarmToAppName(alarm);
    const alarmValue = alarmToAlarmValue(alarm);
    const shouldAlert = alarmValueToShouldAlert(alarmValue);
    const indexOfApp = newAcc.findIndex(
      (alarmTab) => getTitleProp(alarmTab) === appName,
    );
    if (indexOfApp === -1) {
      const alertCount = shouldAlert ? 1 : 0;
      newAcc.push(new AlarmTabProps(appName, alertCount));
    } else {
      const existingAlertCount = getAlertCountProp(newAcc[indexOfApp]);
      const alertCount = shouldAlert
        ? existingAlertCount + 1
        : existingAlertCount;
      const updatedAppAlert = new AlarmTabProps(appName, alertCount);
      newAcc.splice(indexOfApp, 1, updatedAppAlert);
    }
    return newAcc;
  }, []);
const tabAppNameToSecondaryTabs = (alarms, tabAppName) =>
  alarms.reduce((acc, alarm) => {
    const newAcc = [...acc];
    const appName = alarmToAppName(alarm);
    const alarmValue = alarmToAlarmValue(alarm);
    const shouldAlert = alarmValueToShouldAlert(alarmValue);
    if (appName === tabAppName) {
      const serviceName = alarmToServiceName(alarm);
      const indexOfService = newAcc.findIndex(
        (alarmTab) => getTitleProp(alarmTab) === serviceName,
      );
      if (indexOfService === -1) {
        const alertCount = shouldAlert ? 1 : 0;
        newAcc.push(new AlarmTabProps(serviceName, alertCount));
      } else {
        const existingAlertCount = getAlertCountProp(newAcc[indexOfService]);
        const alertCount = shouldAlert
          ? existingAlertCount + 1
          : existingAlertCount;
        const updatedAppAlert = new AlarmTabProps(serviceName, alertCount);
        newAcc.splice(indexOfService, 1, updatedAppAlert);
      }
    }

    return newAcc;
  }, []);
const DisplayHealthTabs = ({
  alarms = [],
  setSelectedTab,
  selectedTab,
  title = '',
}) => {
  const theme = useTheme();
  const mainTabIndex = alarms.findIndex(
    (tab) => getTitleProp(tab) === selectedTab,
  );
  const mainTabIndexValue = mainTabIndex === -1 ? 0 : mainTabIndex;
  const handleTabChange = (_, tabIndex) =>
    setSelectedTab(getTitleProp(alarms[tabIndex]));
  return (
    <Box paddingBottom="10px">
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '700',
          textAlign: 'center',
          backgroundColor: 'titleBar.background',
        }}
        color={'titleBar.main'}
      >
        {title}
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <Tabs
          value={mainTabIndexValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          padding
        >
          {alarms.map(TabWithBadgeNumber)}
        </Tabs>
      </Box>
    </Box>
  );
};
const selectedAppAndServiceNameFilter =
  (selectedAppName, selectedServiceName) => (alarm) =>
    alarmToAppName(alarm) === selectedAppName &&
    alarmToServiceName(alarm) === selectedServiceName;
export const HealthTableComponent = () => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(Date.now());
  const [healthData, setHealthData] = useState([]);
  const [selectedAppName, setSelectedAppName] = useState('');
  const [selectedServiceName, setSelectedServiceName] = useState('');

  const [mainTabs, setMainTabs] = useState([]);
  const [secondaryTabs, setSecondaryTabs] = useState([]);
  const [displayAlarms, setDisplayAlarms] = useState([]);

  const retrieveData = async () => {
    const alarms = await getHealthAlarmsData();
    if (!alarms) return;
    const alarmApiToAlarmTypes = handleAlarmApiResponse(alarms);
    setHealthData(alarmApiToAlarmTypes);
    setTime(Date.now());
    setTimeout(retrieveData, 120000);
    return alarmApiToAlarmTypes;
  };
  //
  // Init Fetch, settting main tabs once & setting default service selected
  //
  useEffect(() => {
    const asynced = async () => {
      const data = await retrieveData();
      setSelectedAppName(alarmToAppName(data[0]));
      setMainTabs(alarmsToMainTabs(data));
    };
    asynced();
  }, []);

  //
  // Update Secondary Tab Selections, when the main tab is changed
  //
  useEffect(() => {
    if (healthData?.length && selectedAppName?.length) {
      const nextStateSecondaryTabs = tabAppNameToSecondaryTabs(
        healthData,
        selectedAppName,
      );
      if (selectedAppName === 'Orbit') {
        nextStateSecondaryTabs?.sort((x) => (x?.title === 'Health' ? -1 : 0));
      }
      const selectedServiceIsValid = nextStateSecondaryTabs.some(
        (alarm) => getTitleProp(alarm) === selectedServiceName,
      );
      if (!selectedServiceIsValid) {
        setSelectedServiceName(getTitleProp(nextStateSecondaryTabs?.[0] ?? {}));
      }
      setSecondaryTabs(nextStateSecondaryTabs);
    }
  }, [selectedAppName, healthData]);

  //
  // update display filter on tab change
  //
  useEffect(() => {
    if (
      healthData.length &&
      selectedAppName.length &&
      selectedServiceName.length
    ) {
      setDisplayAlarms(
        healthData.filter(
          selectedAppAndServiceNameFilter(selectedAppName, selectedServiceName),
        ),
      );
    }
  }, [healthData, selectedAppName, selectedServiceName, setDisplayAlarms]);
  return (
    <Navbar links={healthCheckRouteList} dispatch={dispatch}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
          flexDirection: 'row',
          minHeight: '88vh',
        }}
      >
        <ConditionalDisplay bool={false}>
          <HealthDataBarChart alarms={[...healthData]} />
        </ConditionalDisplay>
        <Box sx={{ marginTop: '30px' }}>
          <Typography sx={{ fontSize: '30px', paddingBottom: '15px' }}>
            {HEALTH_TABLE_TITLE}
          </Typography>
          <StackedStatus alarms={[...healthData]} />
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            Health check last performed at:{' '}
            <Typography color="primary" variant="span">
              {getFormattedTime(time)()}
            </Typography>
          </Typography>
        </Box>
        <Paper
          sx={{
            marginBottom: '20px',
            marginTop: '88px',
            backgroundColor: 'background.paperAltLight',
          }}
        >
          <Stack width={'63vw'}>
            <DisplayHealthTabs
              title="Applications"
              alarms={mainTabs}
              setSelectedTab={setSelectedAppName}
              selectedTab={selectedAppName}
            />
            <Box>
              <DisplayHealthTabs
                title="Services"
                alarms={secondaryTabs}
                setSelectedTab={setSelectedServiceName}
                selectedTab={selectedServiceName}
                isChild
              />
            </Box>
            <Box border="solid 1px" borderColor="info.secondary">
              <HeaderRow />
              {displayAlarms.map(KeyedComponent(AlarmRow))}
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Navbar>
  );
};

const HeaderRow = () => (
  <Grid container columns={16} justifyContent="center">
    <Grid
      item
      xs={8}
      display="flex"
      paddingLeft={2.5}
      borderBottom="solid 1px"
      borderColor="info.secondary"
    >
      <Typography sx={{ color: 'info.secondary' }}>Service</Typography>
    </Grid>
    <Grid
      item
      xs={3}
      display="flex"
      justifyContent="center"
      paddingRight={1}
      borderBottom="solid 1px"
      borderColor="info.secondary"
    >
      <Typography sx={{ color: 'info.secondary' }}>Status</Typography>
    </Grid>
    <Grid
      item
      xs={5}
      display="flex"
      justifyContent="end"
      paddingRight={'20px'}
      borderBottom="solid 1px"
      borderColor="info.secondary"
    >
      <Typography sx={{ color: 'info.secondary' }}>Last Changed</Typography>
    </Grid>
  </Grid>
);
const roundToTheSecondDecimal = (num) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

const HealthBarsTooltip = (props) => {
  const {
    appName = '',
    'App Availability': percentageAvailable = '',
    serviceAvailability = [],
  } = props?.payload?.[0]?.payload ?? {};
  return (
    <Paper sx={{ padding: '15px', boxSizing: 'border-box' }}>
      <Stack display="flex" flexDirection="column">
        <Typography color="primary" sx={{ fontSize: '20px', padding: '10px' }}>
          {appName} ( {percentageAvailable}% )
        </Typography>
        <Box sx={{ padding: '15px', boxSizing: 'border-box' }}>
          <Typography sx={{ padding: '10px 10px 10px 0px' }} color="primary">
            Services:
          </Typography>
          <Box sx={{ paddingLeft: '10px', boxSizing: 'border-box' }}>
            {serviceAvailability?.map(
              ({ serviceName, alarmAvailabilitiesForService }) => (
                <Typography
                  color={serviceAvailableToMuiColor(
                    alarmAvailabilitiesForService,
                  )}
                  key={serviceName}
                >
                  {serviceName} ( {alarmAvailabilitiesForService}% )
                </Typography>
              ),
            )}
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
};

const reduceToUniqueApps = (acc, alarm) => {
  const appName = alarmToAppName(alarm);
  if (!acc.includes(appName)) acc.push(appName);
  return acc;
};

const reduceToViewData = (alarms) => (acc, appName) => {
  const matchedServices = alarms.reduce((acc, alarm) => {
    const serviceName = alarmToServiceName(alarm);
    const alarmAppName = alarmToAppName(alarm);
    const alarmPercentageAvailable = alarmValueToPercentage(
      alarmToAlarmValue(alarm),
    );
    if (alarmAppName === appName) {
      const existingServiceIndex = acc.findIndex(
        (batchedService) => alarmToServiceName(batchedService) === serviceName,
      );
      if (existingServiceIndex == -1) {
        acc.push({
          serviceName,
          alarmAvailabilities: [alarmPercentageAvailable],
        });
      } else {
        acc[existingServiceIndex].alarmAvailabilities.push(
          alarmPercentageAvailable,
        );
      }
    }
    return acc;
  }, []);
  acc.push({ appName, matchedServices });
  return acc;
};

const reducePerenctageViewData = (
  percentageViewAcc,
  { appName, matchedServices },
) => {
  const serviceAvailability = matchedServices.reduce(
    (percentageAvailableAcc, { serviceName, alarmAvailabilities }) => {
      const alarmAvailabilitiesForService = roundToTheSecondDecimal(
        alarmAvailabilities.reduce((acc, num) => acc + num, 0) /
          alarmAvailabilities.length,
      );
      percentageAvailableAcc.push({
        serviceName,
        alarmAvailabilitiesForService,
      });
      return percentageAvailableAcc;
    },
    [],
  );
  const percentageAvailable =
    serviceAvailability.reduce(
      (acc, { alarmAvailabilitiesForService }) =>
        acc + alarmAvailabilitiesForService,
      0,
    ) / serviceAvailability.length;
  percentageViewAcc.push({
    appName,
    'App Availability': roundToTheSecondDecimal(percentageAvailable),
    serviceAvailability,
  });
  return percentageViewAcc;
};

const getPercentageViewData = (alarms) => {
  const uniqueApps = [...alarms]?.reduce(reduceToUniqueApps, []);
  const viewData = uniqueApps?.reduce(reduceToViewData(alarms), []);
  const percentageViewData = viewData?.reduce(reducePerenctageViewData, []);
  return percentageViewData;
};

const StackedStatus = ({ alarms = [] }) => {
  const percentageViewData = getPercentageViewData(alarms);
  return (
    <Paper
      width={'30vw'}
      sx={{
        marginBottom: '10px',
        border: 1,
        borderColor: 'grey',
        backgroundColor: 'background.paperAltLight',
      }}
    >
      <Stack spacing={0}>
        <StatusRow app={percentageViewData[0]} />
        <StatusRow app={percentageViewData[1]} />
        <StatusRow app={percentageViewData[2]} />
        <StatusRow app={percentageViewData[3]} />
        <StatusRow app={percentageViewData[4]} showBorder={false} />
      </Stack>
    </Paper>
  );
};

const StatusRow = ({ app, showBorder = true }) => {
  let value = 0;
  let toolTipContent = '';
  if (app) {
    value = app['App Availability'];
    toolTipContent = app?.serviceAvailability.reduce((acc, curr) => {
      acc +=
        curr?.serviceName + ': ' + curr?.alarmAvailabilitiesForService + ' | ';
      return acc;
    }, '');
  }
  return (
    <ConditionalDisplay bool={app != null}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 3.25,
          borderBottom: showBorder ? 1 : 0,
          borderColor: 'grey',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography paddingRight={2.5} sx={{ fontSize: '20px' }}>
            {app?.appName}
          </Typography>
          <MaterialUiToolTip title={toolTipContent}>
            <InfoOutlined variant="outlined" fontSize="20px" />
          </MaterialUiToolTip>
        </Box>
        <SetStatusIcon value={value} />
      </Box>
    </ConditionalDisplay>
  );
};

const SetStatusIcon = ({ value }) => {
  return (
    <>
      {value < 100 ? (
        <ReportProblemRounded fontSize="large" color="error" />
      ) : (
        <CloudDoneRounded fontSize="large" color="success" />
      )}
    </>
  );
};

const HealthDataBarChart = ({ alarms = [] }) => {
  const percentageViewData = getPercentageViewData(alarms);

  return (
    <Box height={'40vh'} width={'60vw'}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={percentageViewData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="appName" />
          <YAxis domain={[0, 100]}></YAxis>
          {/*
NOTE: HEY JAKE!!!
HORIZONTAL BAR CHANGES HERE..
          <XAxis domain={[0, 100]} />
          <YAxis
            yAxisId={0}
            dataKey={'appName'}
            type="category"
            axisLine={false}
            tickLine={false}
            tick={YAxisLeftTick}
          /> */}
          <Tooltip content={<HealthBarsTooltip />} />
          <Bar dataKey="App Availability" barSize={20} minPointSize={3}>
            {percentageViewData.map(({ 'App Availability': availability }) => (
              <Cell fill={appPercentageAvailableToStatusColor(availability)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};
const StatusValueIcon = ({ alarmValue = '', color }) => {
  if (alarmsToAlertOn.includes(alarmValue)) {
    return <ReportProblemRounded color={color} />;
  }
  return <CloudDoneRounded color={color} />;
};

const AlarmRow = (alarm) => {
  const timeSinceChecked = getFormattedTime(alarmToAlarmTimeStamp(alarm))();
  const alarmValue = alarmToAlarmValue(alarm);
  const color = alarmValueToStatusColor(alarmValue);
  const textColor = `${color}.main`;
  return (
    <Box
      sx={{
        padding: '10px',
        boxSizing: 'border-box',
      }}
    >
      <Grid
        container
        columns={16}
        sx={{
          padding: '10px',
          boxSizing: 'border-box',
          borderRadius: '5px',
        }}
      >
        <Grid item xs={8} alignContent="center" display="flex">
          <Stack
            width="100%"
            display="flex"
            alignItems="center"
            flexDirection="row"
            gap={1}
          >
            <Typography color={textColor}>
              {alarmToHealthName(alarm)}
            </Typography>
            <MaterialUiToolTip title={alarmToAlarmName(alarm)}>
              <InfoOutlined variant="outlined" fontSize="20px" />
            </MaterialUiToolTip>
          </Stack>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <StatusValueIcon alarmValue={alarmValue} color={color} />
        </Grid>
        <Grid
          item
          xs={5}
          display="flex"
          justifyContent="end"
          alignItems="center"
          textAlign="end"
        >
          <Typography color={textColor}>{timeSinceChecked}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
class AlarmTabProps {
  constructor(title = '', alertCount = 0) {
    this.title = title;
    this.alertCount = alertCount;
  }
}
const getTitleProp = ({ title }) => title;
const getAlertCountProp = ({ alertCount }) => alertCount;
const TabWithBadgeNumber = ({
  title = '',
  alertCount = 0,
  badgeColor = 'primary',
}) => {
  return (
    <Tab
      label={
        alertCount ? (
          <Badge badgeContent={alertCount + ''} color={badgeColor}>
            <Typography padding={'4px'}>{title}</Typography>
          </Badge>
        ) : (
          <Typography padding={'4px'}>{title}</Typography>
        )
      }
    />
  );
};

export default HealthTableComponent;
