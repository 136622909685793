import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectorToUserReducer } from '../../reducers/UserReducer';
import {
  paramsToAtleastOneParamIsTrue,
  reactRouterHistoryToCurrentPath,
} from '../../transformations/utils';
import {
  OPERATIONS_PAGE_SLUG,
  EXPERT_ATTR_PAGE_SLUG,
  WORKFORCE_PAGE_SLUG,
  UBIF_FORM_PAGE,
  GLOBAL_STATS_PAGE_SLUG,
  ADMIN_PAGE_SLUG,
  LOCATION_ROUTING_PAGE_SLUG,
  CANCEL_TASK_PAGE_SLUG,
  ROLLBACK_CHANGES_PAGE_SLUG,
  UPSELL_QSP_AUTOMATION_SLUG,
  // CREATE_TASKQUEUE_PAGE_SLUG,
} from '../../data/constants';

export const currentRouteToHasEditPermissions = (
  currentRoute,
  {
    workforceAdmin = false,
    ubifAdmin = false,
    orbitEditor = false,
    orbitServiceDesk = false,
    orbitUpload = false,
    orbitTA = false,
  },
) => {
  switch (currentRoute) {
    case UBIF_FORM_PAGE: {
      return paramsToAtleastOneParamIsTrue(ubifAdmin, workforceAdmin);
    }
    case EXPERT_ATTR_PAGE_SLUG: {
      return paramsToAtleastOneParamIsTrue(workforceAdmin, orbitEditor);
    }
    case OPERATIONS_PAGE_SLUG:
    case GLOBAL_STATS_PAGE_SLUG:
    case WORKFORCE_PAGE_SLUG: {
      return true;
    }
    case `${ADMIN_PAGE_SLUG}${ROLLBACK_CHANGES_PAGE_SLUG}`: {
      return workforceAdmin;
    }
    case `${ADMIN_PAGE_SLUG}${UPSELL_QSP_AUTOMATION_SLUG}`: {
      return paramsToAtleastOneParamIsTrue(
        workforceAdmin,
        orbitUpload,
        orbitTA,
      );
    }
    case `${ADMIN_PAGE_SLUG}${CANCEL_TASK_PAGE_SLUG}`: {
      return paramsToAtleastOneParamIsTrue(
        orbitEditor,
        workforceAdmin,
        orbitServiceDesk,
      );
    }
    case `${ADMIN_PAGE_SLUG}${LOCATION_ROUTING_PAGE_SLUG}`: {
      return paramsToAtleastOneParamIsTrue(orbitEditor, workforceAdmin);
    }
    // uncomment in the case that this tab implements Read Only / Edit permissions based on the ad group...
    // case `${ADMIN_PAGE_SLUG}${CREATE_TASKQUEUE_PAGE_SLUG}`: {
    //   return paramsToAtleastOneParamIsTrue(orbitTA, workforceAdmin);
    // }
    default:
      return false;
  }
};
export const ReadOnlyWrap = ({ RenderProp = () => <></>, ...props }) => {
  const user = useSelector(selectorToUserReducer);
  const history = useHistory();
  const currentPath = reactRouterHistoryToCurrentPath(history);
  const hasEditPermissions = currentRouteToHasEditPermissions(
    currentPath,
    user,
  );
  return <RenderProp hasEditPermissions={hasEditPermissions} {...props} />;
};
