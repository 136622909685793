import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  CircularProgress,
  Tooltip,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { DialogHeaderWithClose } from '../AddNewExpertButton';
import { DEFAULT_FUNCTION } from '../../data/datatypes';
import { valueToHasLengthGreaterThanZero } from '../../transformations/utils';

const AllowContentScrollDialog = styled(Dialog)(() => ({
  overflow: 'hidden',
  '.MuiDialog-container > div:nth-of-type(1)': {
    maxHeight: '100%',
  },
}));

const DialogFooterActions = ({
  disabledSubmit = false,
  cb = DEFAULT_FUNCTION,
  close = DEFAULT_FUNCTION,
  footerActionText = '',
}) => {
  const [formAction, setFormAction] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const closeDialogAfterSubmit = async () => {
    try {
      setLoading(true);
      await cb(formAction);
      close();
    } catch (e) {
      console.log('[ Failed Dialog Submit ] - ', e);
    }
    setLoading(false);
  };

  const hasFooterAction = !!footerActionText?.length;
  return (
    <DialogActions>
      <Box
        display="flex"
        flexDirection={'row'}
        width="100%"
        justifyContent={hasFooterAction ? 'space-between' : 'flex-end'}
      >
        {hasFooterAction ? (
          <FormControlLabel
            label={footerActionText}
            control={
              <Checkbox
                disableRipple={true}
                checked={formAction}
                onChange={() => setFormAction((bool) => !bool)}
                size="medium"
              />
            }
          />
        ) : (
          <></>
        )}
        {loading ? (
          <Box>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Button
            disabled={disabledSubmit}
            onClick={closeDialogAfterSubmit}
            variant="contained"
          >
            Submit
          </Button>
        )}
      </Box>
    </DialogActions>
  );
};

const ButtonOpenDialog = ({
  title = '',
  CtaIcon = CalendarTodayOutlined,
  asyncFetchData = DEFAULT_FUNCTION,
  disabledDialogOpenButton = false,
  toolTipTitle,
  Render = <></>,
  buttonStyle = {},
  ...dialogFooterActionProps
}) => {
  const [open, setOpen] = React.useState(false);
  const handle = useCallback(() => setOpen((e) => !e), [setOpen]);
  const onClose = useCallback(() => {
    setOpen(false);
    return null;
  }, [setOpen]);
  useEffect(() => {
    const asynced = async () => {
      await asyncFetchData?.(open);
    };
    asynced();
  }, [open, asyncFetchData]);
  return (
    <>
      <Tooltip
        title={
          valueToHasLengthGreaterThanZero(toolTipTitle) ? toolTipTitle : ''
        }
      >
        <Button
          disabled={disabledDialogOpenButton}
          variant="outlined"
          color="primary"
          onClick={handle}
          sx={buttonStyle}
        >
          <CtaIcon color="primary" />
        </Button>
      </Tooltip>
      <AllowContentScrollDialog open={open} onClose={onClose}>
        <DialogHeaderWithClose close={onClose} title={title} />
        <DialogContent sx={{ maxHeight: '80vh' }}>{Render}</DialogContent>
        <DialogFooterActions close={onClose} {...dialogFooterActionProps} />
      </AllowContentScrollDialog>
    </>
  );
};

export default ButtonOpenDialog;
