import React from 'react';
import {
  globalCallIntervalStatsResponseToState,
  globalCallStatsUpdatedAtResponseToState,
  globalCallSummaryStatsResponseToState,
} from '../../../reducers/TableDataReducer';
import { directionToIsAscending } from '../../Table/transformations';
import { dateTimesHeader, headerToGlobalStatsDataKey } from '../constants';
import { templateData } from './constants';
import { MyPieChart } from '../../GlobalCharts/Pie/index';
import { MyStackedBarChart } from '../../GlobalCharts/Bar/index';

/**
 * Ensures consistency of string of digits to
 * specific digit count
 * @param {String} digit
 * @param {Number} minDigits
 * @returns Formatted digit string
 */
export const setDigitCountString = (digit, minDigits = 2) => {
  const num = parseInt(digit);

  return num.toLocaleString('en-US', {
    minimumIntegerDigits: minDigits,
    useGrouping: false,
  });
};

/**
 * Formats time to digital
 * @param {Array} [hour, minute]
 * @returns Formatted time into HH:MM format
 */
export const formattedDigitalTime = ([hour = '', minute = '']) =>
  `${setDigitCountString(hour)}:${setDigitCountString(minute)}`;

/**
 * Formats two passed integers to include a hyphen inbetween
 * (Could probably just generalize this and move to utils/transformations)
 * @param {[]} [firstInt, secondInt]
 * @returns formatted string
 */
export const formattedFullInterval = ([firstInt = '', secondInt = '']) =>
  `${formattedDigitalTime(firstInt?.split(':'))} - ${formattedDigitalTime(
    secondInt?.split(':'),
  )}`;

/**
 * Makes global call data correct format for table
 * @param {String} key
 * @param {Object} data
 * @returns Object
 */
export const transformGlobalCallData = (key, data) => {
  return {
    callsAnswered: data?.callsAnswered,
    totalCalls: data?.callsOffered,
    abandonedCalls: data?.callsAbandoned,
    dateTime: data?.timeSlot,
  };
};

/**
 * Sorts global call data for table
 * ! PROBABLY WON'T WORK DUE TO DATA CHANGES
 * UPDATE IF TABLE IS NEEDED FOR GLOBAL CALL STATS !
 * @param {String} headerSort
 * @param {String} sortDirection
 * @param {Array} globalCallData
 * @returns Sorted data
 */
export const globalCallTableSort = (
  headerSort,
  sortDirection,
  globalCallData,
) => {
  const sortKey = headerToGlobalStatsDataKey[headerSort];
  const statNameToSortedValue = (statName) => {
    const data = transformGlobalCallData(globalCallData, statName);
    return data[sortKey];
  };
  const isAsc = directionToIsAscending(sortDirection);
  switch (headerSort) {
    case dateTimesHeader:
      return (a, b) =>
        timeIntervalSort(statNameToSortedValue(isAsc ? a : b))(
          statNameToSortedValue(isAsc ? b : a),
        );
    default:
      return (a, b) =>
        statNameToSortedValue(isAsc ? a : b) -
        statNameToSortedValue(isAsc ? b : a);
  }
};

/**
 * .sort method for sorting time based on hour
 * @param {String} timeOne
 * @param {String} timeTwo
 */
export const timeIntervalSort = (timeOne) => (timeTwo) => {
  const [timeOneHour] = timeOne.split(':');
  const [timeTwoHour] = timeTwo.split(':');

  return timeTwoHour - timeOneHour;
};

/**
 * Check if intervals are the same hour
 * @param {Object} a
 * @param {Object} b
 * @returns bool
 */
export const compareTemplateVSIncomingTimes = (a) => (b) => {
  return b?.timeSlot?.split(' - ')[0] === a?.Time;
};

/**
 * Compares dates for template data
 * This is just a string comparer, should rename and move to utils/transformations
 * @param {String} a
 * @param {String} b
 * @returns bool
 */
export const compareTemplateVSIncomingDates = (a) => (b) => a === b;

/**
 * Adds hourly counts to template
 * @param {String} key
 * @param {String} countKey
 * @param {Array} acc
 * @param {Object} item
 * @returns updated template
 */
export const reduceHourlyCounts =
  (key = '', countKey = '') =>
  (acc, item) => {
    const index = acc?.findIndex(compareTemplateVSIncomingTimes(item));
    acc[index] = Object.assign(
      {
        ...acc[index],
      },
      { [key]: item[countKey] },
    );
    return acc;
  };

/**
 * Edits template data for hourly data
 * Dispatches when done
 * @param {Dispatch} dispatch
 * @param {Array} accepted
 * @param {Array} offered
 * @param {Array} abandoned
 * @param {Array} initialVal
 */
export const updateGlobalCallStatsHourly = (
  dispatch,
  accepted,
  offered,
  abandoned,
  initialVal = [...templateData],
) => {
  accepted?.HourlyCount?.reduce(
    reduceHourlyCounts('callsAnswered', 'HourlyCount'),
    initialVal,
  );
  offered?.HourlyCount?.reduce(
    reduceHourlyCounts('callsOffered', 'HourlyCount'),
    initialVal,
  );
  abandoned?.HourlyCount?.reduce(
    reduceHourlyCounts('callsAbandoned', 'HourlyCount'),
    initialVal,
  );
  dispatch(globalCallIntervalStatsResponseToState(initialVal));
};

/**
 * Updates data based on template object for summary stats
 * Dispatches when done
 * @param {Dispatch} dispatch
 * @param {Object} deflectionsObj
 * @param {Object} SystemDisconnectsObj
 * @param {Object} CustomerDisconnectsObj
 * @param {Object} avayaTransfersObj
 * @param {Object} callsToIVRObj
 * @param {Number} accepted
 * @param {Number} offered
 * @param {Number} abandoned
 * @param {String} updatedAt
 */
export const updateGlobalCallStatsSummary = (
  dispatch,
  deflectionsObj,
  SystemDisconnectsObj,
  CustomerDisconnectsObj,
  avayaTransfersObj,
  callsToIVRObj,
  accepted,
  offered,
  abandoned,
  updatedAt,
) => {
  let callsAnswered = 0,
    callsAbandoned = 0,
    callsOffered = 0,
    deflections = deflectionsObj?.TotalCount ?? 0,
    SystemDisconnects = SystemDisconnectsObj?.TotalCount ?? 0,
    CustomerDisconnects = CustomerDisconnectsObj?.TotalCount ?? 0,
    avayaTransfers = avayaTransfersObj?.TotalCount ?? 0,
    callsToIVR = callsToIVRObj?.TotalCount ?? 0;

  let intervalStats = [...templateData];

  accepted.HourlyCount.reduce(
    reduceHourlyCounts('callsAnswered'),
    intervalStats,
  );
  callsAnswered = accepted.TotalCount;
  offered.HourlyCount.reduce(reduceHourlyCounts('callsOffered'), intervalStats);
  callsOffered = offered.TotalCount;
  abandoned.HourlyCount.reduce(
    reduceHourlyCounts('callsAbandoned'),
    intervalStats,
  );
  callsAbandoned = abandoned.TotalCount;
  dispatch(globalCallStatsUpdatedAtResponseToState(updatedAt));
  dispatch(
    globalCallSummaryStatsResponseToState({
      callsOffered,
      callsAnswered,
      callsAbandoned,
      SystemDisconnects,
      CustomerDisconnects,
      deflections,
      avayaTransfers,
      callsToIVR,
    }),
  );
};

/**
 * Sets what chart to use
 * @param {String} chart
 * @returns A chart component depending on passed type
 */
export const SetChart = (chart) => {
  switch (chart.type) {
    case 'Pie':
      return <MyPieChart data={chart.data} header="Call Stats - Today" />;
    case 'Bar':
      return (
        <MyStackedBarChart data={chart.data} header="Interval Bar Chart" />
      );
  }
};
