import React from 'react';
import { InfoOutlined } from '@mui/icons-material';
import { Divider, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ConditionalDisplay } from '../ConditionalDisplay';
import './index.scss';

export const VerticalBoxedInformation = ({
  title = '',
  value = 0,
  icon = <></>,
}) => (
  <Box
    className="boxed-info"
    sx={{
      backgroundColor: 'neutral.background',
      padding: '10px',
      margin: '5px',
      border: 'solid 1px',
      borderColor: 'neutral.border',
      display: 'flex',
      minWidth: '150px',
    }}
  >
    <Box
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        component={'span'}
        sx={{
          color: 'neutral.main',
          fontSize: 'xx-large',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '90%',
        }}
      >
        <ConditionalDisplay bool={icon}>{icon ?? <></>}</ConditionalDisplay>
        {value ?? ''}
      </Typography>
      <Divider sx={{ width: '100%' }} variant="middle" />
      <Typography component={'span'}>{title ?? ''}</Typography>
    </Box>
  </Box>
);

export const HorizontalBoxedInformation = ({
  title = '',
  value = 0,
  icon = <></>,
  tooltip = '',
  border = { border: 'solid 1px' },
}) => {
  return (
    <Box
      sx={{
        backgroundColor: 'neutral.background',
        paddingTop: '10px',
        paddingBottom: '10px',
        ...border,
        borderColor: 'neutral.main',
        display: 'flex',
        flex: '1 0 100%',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          flex: '1 0',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography
          component={'span'}
          sx={{
            color: 'neutral.main',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: '1 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '5px',
            }}
          >
            <Typography component={'span'} sx={{ fontSize: 'medium' }}>
              {title}
            </Typography>
            <ConditionalDisplay bool={tooltip}>
              <Tooltip title={tooltip}>
                <InfoOutlined fontSize="20px" />
              </Tooltip>
            </ConditionalDisplay>
            <ConditionalDisplay bool={icon}>{icon}</ConditionalDisplay>
          </Box>
          <Box>
            <Divider orientation="vertical" />
          </Box>
          <Typography
            component={'span'}
            sx={{ fontSize: 'x-large', marginRight: '5px' }}
          >
            {value ?? 0}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export const MapVerticalBox = (item = { title: '', value: 0, icon: <></> }) => (
  <VerticalBoxedInformation
    title={item.title}
    value={item.value}
    icon={item.icon}
  />
);

export const MapHorizontalBox = (
  item = {
    title: '',
    value: 0,
    icon: <></>,
    tooltip: '',
    border: { border: 'solid 1px' },
  },
) => (
  <HorizontalBoxedInformation
    title={item.title}
    value={item.value}
    tooltip={item.tooltip}
    border={item.border}
  />
);
