// @flow
const version = () => '[AIV]{version}[/AIV]';
const isProd = (host) => host.includes('app.orbit.asurion53.com');
const isProd2 = (host) => host.includes('app2.orbit.asurion53.com');
const isUat = (host) => host.includes('uat.orbit.asurion53.com');
const isDev = (host) => host.includes('dev.orbit.nonprod-asurion53.com');
const isQA = (host) => host.includes('qa.orbit.nonprod-asurion53.com');
const isDev2 = (host) => host.includes('dev2.orbit.cc-nonprod.asurion53.com');
const isQA2 = (host) => host.includes('qa2.orbit.cc-nonprod.asurion53.com');

const getEnvironment = () => {
  let env = '';
  /*eslint no-undef: "off"*/
  const host = window.location.hostname;
  if (isProd(host)) {
    env = 'PROD';
  } else if (isProd2(host)) {
    env = 'PROD2';
  } else if (isUat(host)) {
    env = 'UAT';
  } else if (isDev(host)) {
    env = 'DEV';
  } else if (isDev2(host)) {
    env = 'DEV2';
  } else if (isQA(host)) {
    env = 'QA';
  } else if (isQA2(host)) {
    env = 'QA2';
  } else {
    env = 'LOCAL';
  }
  return env;
};

export { version, getEnvironment };
