import { API } from 'aws-amplify';
import { abcSort, directionToIsAscending } from '../../Table/transformations';
import { headerToGlobalStatsDataKey, statusTableHeader } from '../constants';

export const createGlobalStatusQuery = (statusName) => `
      query MyQuery{
      searchWorkers(filter: {status: {eq: "${statusName}"}, nle: {ne: true}}, limit: 10) {
        total
      }
    }`;

export const fetchExpertsInStatus = async (statusName) => {
  const query = createGlobalStatusQuery(statusName);
  let value = 0;
  try {
    let response = await API.graphql({ query });
    value = response?.data?.searchWorkers?.total ?? 0;
  } catch (e) {
    console.log('[ Error Fetching Status Expert Count ]', e);
  }
  return { statusName, numInStatus: value };
};
export const expertStatusItemsToOfflineExpertCount = (statusItems = []) =>
  [...statusItems].find((i) => i.statusName === 'Offline')?.numInStatus ?? 0;
export const expertStatusItemsToTotalExpertCount = (statusItems = []) =>
  [...statusItems].reduce(
    (partialSum, { numInStatus = 0 } = {}) => partialSum + numInStatus,
    0,
  );

export const transformGlobalData = (key, data) => {
  return {
    statusName: data.statusName,
    numberOfExperts: data.numInStatus,
  };
};
export const globalTableSort = (headerSort, sortDirection, globalStatsData) => {
  const sortKey = headerToGlobalStatsDataKey[headerSort];
  const statNameToSortedValue = (statName) => {
    const data = transformGlobalData(globalStatsData, statName);
    return data[sortKey];
  };
  const isAsc = directionToIsAscending(sortDirection);
  switch (headerSort) {
    case statusTableHeader:
      return (b, a) =>
        abcSort(statNameToSortedValue(isAsc ? a : b))(
          statNameToSortedValue(isAsc ? b : a),
        );
    default:
      return (a, b) =>
        statNameToSortedValue(isAsc ? a : b) -
        statNameToSortedValue(isAsc ? b : a);
  }
};
