import * as React from 'react';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { popUpWarningMessage } from '../../services/sweetAlerts';
import { withLoggingAnalytics } from '../../services/KibanaEvents';
import { useSelector } from 'react-redux';
import { deleteExpertStuckTask } from '../../services/Experts';
import { selectorToHelixUser } from '../../reducers/UserReducer';
import { DEFAULT_FUNCTION } from '../../data/datatypes';

const EnhancedTableHead = (props) => {
  const { columns } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {columns.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell === 'Task Age (s)' ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, workerName, worker_Id, currentUser, setTaskDeleted } =
    props;

  const apiSubmitCancelTask = () => {
    popUpWarningMessage(
      'Are you sure?',
      `You are about to cancel these tasks for <br></br>${workerName} (${worker_Id})`,
      withLoggingAnalytics(
        'cancel-expert-stuck-task',
        deleteExpertStuckTask(numSelected, setTaskDeleted),
        { currentUser, updatedExpert: worker_Id },
      ),
    );
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      {numSelected.length > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected.length} selected
        </Typography>
      ) : (
        <Typography>Cancel Task</Typography>
      )}

      {numSelected.length > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={apiSubmitCancelTask}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </Toolbar>
  );
};

export const CancelTaskTable = ({
  data = [],
  columns = [],
  workerName = '',
  worker_Id = '',
  taskDeleted = false,
  setTaskDeleted = DEFAULT_FUNCTION,
}) => {
  const [selected, setSelected] = React.useState([]);
  const [tableData, setTableData] = React.useState(data);

  const currentUser = useSelector(selectorToHelixUser);

  const handleClick = (_, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <Box sx={{ width: '60vw', margin: 'auto' }}>
      <Paper sx={{ width: '60vw', mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected}
          workerName={workerName}
          worker_Id={worker_Id}
          currentUser={currentUser}
          tableData={tableData}
          setTableData={setTableData}
          setTaskDeleted={setTaskDeleted}
        />
        <TableContainer>
          <Table sx={{ minWidth: '60vw' }} size={'medium'}>
            <EnhancedTableHead columns={columns} />
            <TableBody>
              {tableData.map((row, index) => {
                const isItemSelected = isSelected(row.taskSid);
                const labelId = `enhanced-table-checkbox-${index}`;
                let day = moment(row.dateCreated);
                let now = moment();
                let taskAge = now.diff(day, 'seconds');

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.taskSid)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.taskSid}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.taskSid}
                    </TableCell>
                    <TableCell align="right">{taskAge}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
export default CancelTaskTable;
