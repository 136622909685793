export const templateData = [
  {
    timeSlot: '00:00 - 01:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '01:00 - 02:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '02:00 - 03:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '03:00 - 04:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '04:00 - 05:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '05:00 - 06:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '06:00 - 07:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '07:00 - 08:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '08:00 - 09:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '09:00 - 10:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '10:00 - 11:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '11:00 - 12:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '12:00 - 13:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '13:00 - 14:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '14:00 - 15:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '15:00 - 16:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '16:00 - 17:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '17:00 - 18:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '18:00 - 19:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '19:00 - 20:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '20:00 - 21:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '21:00 - 22:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '22:00 - 23:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
  {
    timeSlot: '23:00 - 00:00',
    callsAnswered: 0,
    callsAbandoned: 0,
    callsOffered: 0,
  },
];

export const SUCCESS_PALETTE = 'success',
  FAIULER_PALETTE = 'failure';
