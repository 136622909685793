import React from 'react';
import { GenericLabelText } from '../GenericLabelText';
import CircleMetricComponent from '../CircleMetricComponent';
import './index.css';

export const CIRCLE_METRIC_CONTAINER_CLASS = 'circle-metric-container',
  SERVICE_LEVEL_TEXT = 'Service Level',
  MONTHLY_SERVICE_LEVEL_TEXT = 'Monthly Service Level',
  STAT_CLASS = 'statClass';

export const serviceLevelText = (serviceType = '') =>
  serviceType === 'monthly' ? MONTHLY_SERVICE_LEVEL_TEXT : SERVICE_LEVEL_TEXT;

export const CircleMetricWithTextComponent = ({ serviceLevel, type }) => (
  <div className={CIRCLE_METRIC_CONTAINER_CLASS}>
    <CircleMetricComponent serviceLevel={serviceLevel} />
    <GenericLabelText text={serviceLevelText(type)} className={STAT_CLASS} />
  </div>
);

export default CircleMetricWithTextComponent;
