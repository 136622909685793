import React, { useState, useEffect } from 'react';
import {
  withLoggingAnalytics,
  EXPERT_OPS_TABLE_EXPANDED,
} from '../../services/KibanaEvents';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  LinearProgress,
  Select,
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Checkbox,
  Stack,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CallEndIcon from '@mui/icons-material/CallEnd';
import PauseIcon from '@mui/icons-material/Pause';
import { ConditionalDisplay } from '../ConditionalDisplay';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  LastPage,
  FirstPage,
  NavigateNext,
  NavigateBefore,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import Wand from './wand';
import { HEADER_BOLD } from '../../data/constants';
import {
  inTimeToLapsedTimeSeconds,
  returnFormattedTime,
} from '../../reducers/ExpertReducer/transformations';
import {
  getFormattedTime,
  eventTargetHandler,
  valueToHasLengthGreaterThanZero,
  valueToBoolean,
} from '../../transformations/utils';
import StatusBubble from '../StatusBubbleComponent';
import xCircle from '../../images/x-circle.svg';
import checkCirlce from '../../images/check-circle.svg';
import DropviewSelection from '../DropviewSelection';
import { useGraphQLExpertTablePagination } from '../../reducers/TableDataReducer';
import {
  expertNameGraphQlHeader,
  expertIdGraphQlHeader,
  expertStatusGraphQlHeader,
  expertEvlGraphQlHeader,
  expertCallsGraphQlHeader,
  expertCrtGraphQlHeader,
  expertHoldGraphQlHeader,
  expertTalkGraphQlHeader,
  expertObGraphQlHeader,
  expertAuxGraphQlHeader,
  expertWrapGraphQlHeader,
  expertActiveHoldGraphQLHeader,
  expertActiveOnCallEventsGraphQLHeader,
} from '../../reducers/TableDataReducer/datatypes';
import { ciValueToValueToIntervalToDisplay } from '../../reducers/TableDataReducer/transformations';
import './index.css';
import AuthConditionalRender from '../AuthConditionalRender';
import { DEFAULT_FUNCTION } from '../../data/datatypes';

const EXPERT_STATUS_DETAILS = 'expert-status-details',
  SUBHEADER_LIGHT = 'light-subheader',
  EXPERT_CONTENT_WRAP = 'expert-content-wrap',
  WAND_CONTAINER_CLASS = 'wand-container';

const expertTitle = 'Expert',
  coachTitle = 'Coach',
  statusTitle = 'Status',
  statusTimeTitle = 'Status Time';

export const TitleSubtitlePair = ({ title, subtitle }) => (
  <div className="title-subtitle">
    <span className={HEADER_BOLD}>{title}</span>
    <span className={SUBHEADER_LIGHT}>{subtitle}</span>
  </div>
);

export const TableRowStyledBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '10px',
  letterSpacing: '.5px',
}));
export const KeyMapPairDesignedComponent = ({ item }) => {
  return <TableRowStyledBox>{item}</TableRowStyledBox>;
};
export const TitleKeyMapPair = ({ loop, title, Row }) => {
  const mapPair = !loop?.length ? (
    <span>--</span>
  ) : (
    loop.map((g, i) =>
      Row ? (
        <Row key={g + i} item={g} />
      ) : (
        <span key={g + i} className={SUBHEADER_LIGHT}>
          {g}
        </span>
      ),
    )
  );
  return (
    <div>
      <span className={HEADER_BOLD}>{title}</span>
      <Stack maxHeight="400px" overflow="auto" paddingLeft="8px">
        {mapPair}
      </Stack>
    </div>
  );
};

export const ExpertDescription = ({
  supervisorName = '',
  supervisorId = '',
  name = '',
  status = '',
  market,
  extension,
  statusTimeLapse,
}) => {
  const coachDisplay = `${supervisorName} (${supervisorId})`;
  return (
    <Stack gap="10px" flexDirection="column">
      <TitleSubtitlePair title={expertTitle} subtitle={name} />
      <TitleSubtitlePair title={coachTitle} subtitle={coachDisplay} />
      <TitleSubtitlePair title={statusTitle} subtitle={status} />
      <TitleSubtitlePair title={statusTimeTitle} subtitle={statusTimeLapse} />
      <ConditionalDisplay bool={valueToHasLengthGreaterThanZero(market)}>
        <TitleKeyMapPair loop={market} title="Market" />
      </ConditionalDisplay>
      <ConditionalDisplay bool={valueToHasLengthGreaterThanZero(extension)}>
        <TitleSubtitlePair title={'Extension'} subtitle={extension} />
      </ConditionalDisplay>
    </Stack>
  );
};
export const ExpertOverview = ({
  routing: { taskQueues = [] } = {},
  group = [],
  lob = [],
  adGroups = [],
  ...otherProps
}) => {
  return (
    <>
      <ExpertDescription {...otherProps} />
      <TitleKeyMapPair loop={lob} title="Line of business" />
      <TitleKeyMapPair loop={group} title="Group" />
      <TitleKeyMapPair loop={taskQueues} title="Queue" />
      <TitleKeyMapPair loop={adGroups} title="AD Group" />
    </>
  );
};
export const BooleanStatusDisplay = ({ condition = false }) => (
  <div>
    {condition === null ? (
      '--'
    ) : condition ? (
      <img src={checkCirlce} />
    ) : (
      <img src={xCircle} />
    )}
  </div>
);

export const eventsToString = (events) =>
  events?.map(
    ({ date, EventDescription }) =>
      `${getFormattedTime(date)()} - ${EventDescription}`,
  ) ?? [];

export const ExpertActivitiesDisplay = ({ activities = [] }) => {
  const activityDisplay = eventsToString(activities);
  return (
    <div className={EXPERT_STATUS_DETAILS}>
      <TitleKeyMapPair loop={activityDisplay} title="Activity History" />
    </div>
  );
};
export const ExpertOperationExpanded = (props) => (
  <div className={EXPERT_CONTENT_WRAP}>
    <div>
      <div className={EXPERT_STATUS_DETAILS}>
        <ExpertOverview {...props} />
      </div>
      <ExpertActivitiesDisplay {...props} />
    </div>
    <div className={WAND_CONTAINER_CLASS}>
      <span className={HEADER_BOLD}>WAND Stats</span>
      <Wand {...props} />
    </div>
  </div>
);

export const StyledAccordion = styled((props = {}) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: theme.palette.background.dark,
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  margin: 0,
}));

export const floorTime = (passedTime) =>
  Math.floor(inTimeToLapsedTimeSeconds(passedTime));

/**
 *
 * @param {Number} acw
 * @returns MUI CallEndIcon with elapsed seconds for wrap up time
 */
export const AcwActiveComponent = ({ acw }) => (
  <>
    <CallEndIcon
      className="call-event-icons"
      sx={{ backgroundColor: 'primary.main' }}
    />
    {floorTime(acw)}
  </>
);

/**
 *
 * @param {Number} holdCall
 * @returns MUI Pause Icon next to elapsed seconds passed during hold
 */
export const HoldTimeActiveComponent = ({ holdCall }) => (
  <>
    <PauseIcon
      className="call-event-icons"
      sx={{ backgroundColor: 'primary.main' }}
    />
    {floorTime(holdCall)}
  </>
);
/**
 * What ultimately prints on the page for how long an active activity
 * has lasted.
 * @param {Number} acw
 * @param {Number} holdTime
 * @returns Component and string if call event is active, otherwise empty string
 */
export const ActiveCallEventTransformer = ({ acw = 0, holdCall = 0 }) => {
  if (acw) {
    return <AcwActiveComponent acw={acw} />;
  } else if (holdCall) return <HoldTimeActiveComponent holdCall={holdCall} />;
  else return <></>;
};

export const ExpertDisplay = (props) => {
  const interval = useSelector((state) => state?.expert?.selectedInterval);
  const {
    worker_Id = 0,
    name = '',
    evlConnection = 0,
    calls = 0,
    ciCalls = 0,
    crt = 0,
    ciCrt = 0,
    holdTime = 0,
    ciHoldTime = 0,
    holdCall = 0,
    talkTime = 0,
    ciTalkTime = 0,
    ciOBTime = 0,
    oBTime = 0,
    ciAuxTime = 0,
    auxTime = 0,
    status = 0,
    ciAutoWrap,
    autoWrap = 0,
    talkTimeTimeStamp = 0,
    statusTimeLapse,
  } = props;

  const callDisplay =
    ciValueToValueToIntervalToDisplay(ciCalls)(calls)(interval);
  const crtDisplay = ciValueToValueToIntervalToDisplay(ciCrt)(crt)(interval);
  const holdTimeDisplay =
    ciValueToValueToIntervalToDisplay(ciHoldTime)(holdTime)(interval);
  const talkTimeDisplay =
    ciValueToValueToIntervalToDisplay(ciTalkTime)(talkTime)(interval);
  const obTimeDisplay =
    ciValueToValueToIntervalToDisplay(ciOBTime)(oBTime)(interval);
  const auxDisplay =
    ciValueToValueToIntervalToDisplay(ciAuxTime)(auxTime)(interval);
  const wrapDisplay =
    ciValueToValueToIntervalToDisplay(ciAutoWrap)(autoWrap)(interval);
  return (
    <>
      <StatusBubble status={status} name={name} />
      <Typography>{worker_Id}</Typography>
      <Typography>
        {status} - {statusTimeLapse}
      </Typography>
      <Typography>
        <ActiveCallEventTransformer
          holdCall={holdCall}
          acw={talkTimeTimeStamp}
        />
      </Typography>
      <BooleanStatusDisplay condition={evlConnection} />
      <Typography>{callDisplay}</Typography>
      <Typography>{crtDisplay}</Typography>
      <Typography>{holdTimeDisplay}</Typography>
      <Typography>{talkTimeDisplay}</Typography>
      <Typography>{obTimeDisplay}</Typography>
      <Typography>{auxDisplay}</Typography>
      <Typography>{wrapDisplay}</Typography>
    </>
  );
};

const baseRowWrapComponentStyle = {
  width: '100%',
  display: 'grid',
  boxSizing: 'border-box',
  userSelect: 'text',
  alignItems: 'center',
  '> :not(:first-child)': {
    textAlign: 'center',
    justifyContent: 'center',
  },
};
export const StyledRowWrapper = styled(Box)(baseRowWrapComponentStyle);
export const StyledHeaderRowWrapper = styled(Box)(({ theme }) => ({
  ...baseRowWrapComponentStyle,
  backgroundColor: theme?.palette?.mode === 'dark' ? '#111' : '#FFFFFF',
  // borderBottom: `1px solid ${
  //   theme?.palette?.mode === 'dark' ? 'rgb(128, 128, 128)' : 'rgb(51, 51, 51)'
  // }`,
}));
export const StyledExpandedRowHeaderRowWrapper = styled(Box)(({ theme }) => ({
  ...baseRowWrapComponentStyle,
  backgroundColor: theme?.palette?.mode === 'dark' ? '#111' : '#FFFFFF',
}));

export const GraphqlRowHelperWrap = ({
  demensions,
  children,
  style = {},
  Component,
} = {}) => {
  const columnWidthSizing = demensionToSizeComponent(demensions);
  return valueToBoolean(Component) ? (
    <Component style={{ ...style, ...columnWidthSizing }}>{children}</Component>
  ) : (
    <StyledRowWrapper style={{ ...style, ...columnWidthSizing }}>
      {children}
    </StyledRowWrapper>
  );
};
const ascending = 'asc';
const descending = 'desc';
export const sortToKeyHeaderToArrowDisplay = (direction) => (isActive) => {
  if (isActive) {
    if (direction === ascending) return <KeyboardArrowUp color="inherit" />;
    else return <KeyboardArrowDown color="inherit" />;
  } else return <></>;
};
export const tableSortHeader = (sortKey, sort, setSort) => {
  if (!sortKey) return;
  const isActiveSort = sortKey === sort?.field;
  if (isActiveSort) {
    if (sort?.direction === null || sort?.direction === descending) {
      setSort?.(sortKey, ascending);
    } else {
      setSort?.(sortKey, descending);
    }
  } else {
    setSort?.(sortKey, ascending);
  }
};
/**
 *
 * @param {Array} icon
 * @param {Callback} handleClick
 * @param {String} color
 * @returns icon passed in GraphQLHeaderModel as a button
 */
export const IconAsButton = ({ icon, handleClick, color }) => (
  <IconButton onClick={handleClick} color={color}>
    {icon}
  </IconButton>
);
/**
 *
 * @param {Callback} setSort
 * @param {Object} sort
 * @param {Array} icons
 * @returns mapped array of all of individual icons as buttons
 */
export const IndividualHeaderIcons = ({ setSort, sort, icons }) =>
  icons?.map((e) => {
    const color = headerPropsToIsActive(e?.sortKey, sort) ? 'primary' : 'info';
    const handleIconClick = () => {
      tableSortHeader(e?.sortKey, sort, setSort);
    };
    return (
      <IconAsButton
        icon={e?.icon}
        color={color}
        handleClick={handleIconClick}
      />
    );
  }) ?? <></>;

/**
 *
 * @param {CallBack} setSort
 * @param {Object} sort
 * @param {Array} icons
 * @returns Component that encapsulates the set of icons
 */
export const GraphQLTableHeadericons = ({ setSort, sort, icons }) => (
  <Typography component={'span'} className="graphql-table-header-icons">
    <IndividualHeaderIcons setSort={setSort} sort={sort} icons={icons} />
  </Typography>
);

/**
 *
 * @param {String} headerSortKey
 * @param {Object} sort
 * @param {Array} icons
 * @returns bool based on whether current sort field is
 * being used to sort
 */
export const headerPropsToIsActive = (headerSortKey, sort, icons) => {
  if (icons?.length) {
    return icons.some(({ sortKey }) => sortKey === sort?.field);
  } else {
    return headerSortKey === sort?.field;
  }
};
export const GraphQlTableSortHeader = ({
  header,
  sortKey,
  setSort,
  sort,
  icons,
}) => {
  const handleClick = () => tableSortHeader(sortKey, sort, setSort);
  const isActive = headerPropsToIsActive(sortKey, sort, icons);
  const arrowDisplay = sortToKeyHeaderToArrowDisplay(sort?.direction)(isActive);
  return (
    <Typography
      className="graphql-table-header"
      onClick={!icons ? handleClick : DEFAULT_FUNCTION}
      sx={{
        textAlign: 'center',
        fontSize: '14px',
        letterSpacing: isActive ? '.5px' : null,
        fontWeight: isActive ? 600 : 300,
      }}
      color={'primary.main'}
    >
      <Typography component={'span'} className="graphql-table-header-text">
        {header}
        {arrowDisplay}
      </Typography>
      <GraphQLTableHeadericons sort={sort} setSort={setSort} icons={icons} />
    </Typography>
  );
};
export const GraphQlFooter = ({
  nextPage,
  previousPage,
  nextPageAvailable,
  prevPageAvailable,
  goToLastPage,
  goToFirstPage,
  currentTablePageString,
  setTableLimit,
  limit,
  loading,
}) => {
  return (
    <div className="graphql-table-footer">
      <Link
        href="/help"
        target="_blank"
        variant="subtitle1"
        onClick={withLoggingAnalytics('help-in-footer', () => {})}
        sx={{ marginRight: 5 }}
      >
        Help
      </Link>
      <Typography color="primary">{currentTablePageString}</Typography>
      <IconButton
        onClick={withLoggingAnalytics('go-to-first-page-button', goToFirstPage)}
      >
        <FirstPage />
      </IconButton>
      <Button
        disabled={!prevPageAvailable || loading}
        onClick={withLoggingAnalytics(
          'go-to-previous-page-button',
          previousPage,
        )}
      >
        <NavigateBefore />
      </Button>
      <Button
        disabled={!nextPageAvailable || loading}
        onClick={withLoggingAnalytics('go-to-next-page-button', nextPage)}
      >
        <NavigateNext />
      </Button>
      <IconButton
        onClick={withLoggingAnalytics('go-to-last-page-button', goToLastPage)}
      >
        <LastPage />
      </IconButton>
      <FormControl>
        <InputLabel>Limit</InputLabel>
        <Select
          value={limit}
          label="Limit"
          onChange={withLoggingAnalytics(
            'set-table-limit-drop-down',
            eventTargetHandler(setTableLimit),
          )}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export const StyledBoxWithBackgroundColor = styled(Box)(({ theme }) => ({
  backgroundColor: theme?.palette?.mode === 'dark' ? '#111' : '#FFFFFF',
}));
export const LoadingBar = ({ loading = false, sx = {} } = {}) => (
  <StyledBoxWithBackgroundColor sx={{ ...sx }}>
    <LinearProgress sx={{ visibility: !loading ? 'hidden' : 'visible' }} />
  </StyledBoxWithBackgroundColor>
);
const demensionToSizeComponent = (demensions = '') => ({
  gridTemplateColumns: demensions,
});
export const GraphqlTableEditAllOpts = ({
  editAll = false,
  editAllClick,
  editAllSelected,
}) =>
  editAll ? (
    <Checkbox
      checked={editAllSelected}
      onChange={withLoggingAnalytics('edit-all-checkbox', editAllClick)}
    />
  ) : (
    <></>
  );

export const HiddenChildren = ({ children } = {}) => {
  return <div style={{ visibility: 'hidden' }}>{children}</div>;
};

const HeaderStyle = {
  padding: '0px',
  position: 'sticky',
  top: '67px',
  zIndex: 45,
};

export const GraphqlHeader = ({ headers, setSort, sort, ...otherProps }) => {
  const headerDisplay =
    headers?.map((props, i) => (
      <GraphQlTableSortHeader
        key={props?.header ?? i}
        setSort={setSort}
        sort={sort}
        {...props}
      />
    )) ?? [];
  if (otherProps?.ExpandedRow)
    return (
      <StyledAccordion style={HeaderStyle}>
        <AccordionSummary
          expandIcon={
            <HiddenChildren>
              <ExpandMoreIcon />
            </HiddenChildren>
          }
        >
          <GraphqlRowHelperWrap
            {...otherProps}
            Component={StyledExpandedRowHeaderRowWrapper}
          >
            <AuthConditionalRender authBool={otherProps?.hasEditPermissions}>
              <GraphqlTableEditAllOpts {...otherProps} />
            </AuthConditionalRender>
            {headerDisplay}
          </GraphqlRowHelperWrap>
        </AccordionSummary>
      </StyledAccordion>
    );
  else
    return (
      <StyledAccordion>
        <AccordionSummary>
          <GraphqlRowHelperWrap
            {...otherProps}
            Component={StyledHeaderRowWrapper}
            style={HeaderStyle}
          >
            <AuthConditionalRender authBool={otherProps?.hasEditPermissions}>
              <GraphqlTableEditAllOpts {...otherProps} />
            </AuthConditionalRender>
            {headerDisplay}
          </GraphqlRowHelperWrap>
        </AccordionSummary>
      </StyledAccordion>
    );
};
export const HandleExpandedRow = ({
  Row = () => <></>,
  ExpandedRow,
  analyticsTag = null,
  demensions,
  ...mainProps
}) => {
  const [open, setOpen] = useState(false);
  if (!ExpandedRow) {
    return (
      <StyledAccordion>
        <AccordionSummary>
          <GraphqlRowHelperWrap demensions={demensions}>
            <Row {...mainProps} />
          </GraphqlRowHelperWrap>
        </AccordionSummary>
      </StyledAccordion>
    );
  } else
    return (
      <StyledAccordion
        expanded={open}
        onChange={withLoggingAnalytics(analyticsTag, () => setOpen((b) => !b))}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <GraphqlRowHelperWrap demensions={demensions}>
            <Row {...mainProps} />
          </GraphqlRowHelperWrap>
        </AccordionSummary>
        <AccordionDetails>
          {open ? <ExpandedRow {...mainProps} open={open} /> : <></>}
        </AccordionDetails>
      </StyledAccordion>
    );
};
export const GraphqlTableBody = ({ items, ...other }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 500);
    return () => clearTimeout(timeout);
  }, []);
  const display = items?.map((props, i) => (
    <HandleExpandedRow key={props?.key || i} {...other} {...props} />
  ));
  return loading ? (
    <>
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
      <Skeleton height={80} />
    </>
  ) : (
    <>{display}</>
  );
};

export const GraphQlTable = ({ tableHandler, ...otherProps }) => {
  const tableProps = tableHandler?.() ?? {};
  return (
    <Box>
      <LoadingBar
        {...tableProps}
        sx={{ position: 'sticky', top: '65px', zIndex: 45 }}
      />
      <GraphqlHeader {...otherProps} {...tableProps} />
      <GraphqlTableBody {...otherProps} {...tableProps} />
      <ConditionalDisplay bool={otherProps?.includeFooter ?? true}>
        <GraphQlFooter {...tableProps} />
      </ConditionalDisplay>
    </Box>
  );
};
const Status = () => {
  return (
    <>
      <DropviewSelection />
      <GraphQlTable
        analyticsTag={EXPERT_OPS_TABLE_EXPANDED}
        tableHandler={useGraphQLExpertTablePagination}
        Row={ExpertDisplay}
        ExpandedRow={ExpertOperationExpanded}
        demensions="20% 100px 10% 7% repeat(8, minmax(60px, 8%))"
        headers={[
          expertNameGraphQlHeader,
          expertIdGraphQlHeader,
          expertStatusGraphQlHeader,
          expertActiveOnCallEventsGraphQLHeader,
          expertEvlGraphQlHeader,
          expertCallsGraphQlHeader,
          expertCrtGraphQlHeader,
          expertHoldGraphQlHeader,
          expertTalkGraphQlHeader,
          expertObGraphQlHeader,
          expertAuxGraphQlHeader,
          expertWrapGraphQlHeader,
        ]}
      />
    </>
  );
};
export default Status;
