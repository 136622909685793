import { API } from 'aws-amplify';
import { popHideSuccessMsg, popHideFailMessage } from './sweetAlerts';

const taskqueueFilterResponseTransformation = ({
  obQueue,
  ...taskQueueData
}) => ({
  obQueue: typeof obQueue === 'string' ? JSON.parse(obQueue) : obQueue,
  ...taskQueueData,
});

const fetchTaskqueueWithFilter = async (filter) => {
  try {
    const query = `
        query MyQuery($filter: SearchableTaskQueueFilterInput) {
          searchTaskQueues(filter: $filter){
              items {
                  taskqueueName
                  obQueue
                  serviceLevel
                  SLKey
                  active
                  taskqueueSid
                  lob
                  group
                  lastReservation
                  language
                }
          }
        }`;

    const { data: { searchTaskQueues } = {} } = await API.graphql({
      query,
      variables: {
        filter,
      },
    });
    console.log(searchTaskQueues);
    return (
      searchTaskQueues?.items?.map(taskqueueFilterResponseTransformation) ?? []
    );
  } catch (err) {
    console.log('[ ERROR fetching task queue details ] - ', err);
    return [];
  }
};

export const fetchTaskqueueWithSid = async (taskqueueSid) => {
  const filter = { taskqueueSid: { eq: taskqueueSid } };
  const results = await fetchTaskqueueWithFilter(filter);
  return results;
};

export const createNewTaskqueue = async (input, logAnalytics) => {
  try {
    const mutation = `
    mutation MyMutation($input: CreateTaskQueueInput!) {
        createTaskQueue(input: $input){
                  taskqueueName
                  updatedAt
                  obQueue
                  SLKey
                  active
                  createdAt
                  taskqueueSid
                  lob
                  group
                  lastReservation
                  language
      }
    }`;
    const response = await API.graphql({
      query: mutation,
      variables: {
        input,
      },
    });
    await logAnalytics({
      createdValues: handleObqueueParse(input),
      status: 'SUCCESS',
    });
    console.log(response);
    popHideSuccessMsg('Taskqueue created successfully');
  } catch (err) {
    await logAnalytics({
      createdValues: handleObqueueParse(input),
      status: 'FAILED',
    });
    console.log(err);
    popHideFailMessage('Failed to create Taskqueue');
  }
};

export const updateTaskqueue = async (input, logAnalytics, previousValues) => {
  try {
    const mutation = `
    mutation MyMutation($input: UpdateTaskQueueInput!) {
        updateTaskQueue(input: $input){
                  taskqueueName
                  updatedAt
                  obQueue
                  SLKey
                  active
                  createdAt
                  taskqueueSid
                  lob
                  group
                  lastReservation
                  language
      }
    }`;
    const response = await API.graphql({
      query: mutation,
      variables: {
        input,
      },
    });
    await logAnalytics({
      updatedValues: handleObqueueParse(input),
      previousValues,
      status: 'SUCCESS',
    });
    console.log(response);
    popHideSuccessMsg('Taskqueue updated successfully');
  } catch (err) {
    await logAnalytics({
      updateValues: handleObqueueParse(input),
      previousValues,
      status: 'FAILED',
    });
    console.log(err);
    popHideFailMessage('Failed to update Taskqueue');
  }
};
const handleObqueueParse = ({ obQueue, ...other }) => ({
  ...other,
  obQueue: JSON.parse(obQueue),
});
