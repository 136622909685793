export const statusTableHeader = 'Status',
  numberOfExpertsHeader = 'Number of Experts',
  dateTimesHeader = 'Time Interval',
  callsAnsweredHeader = 'Calls Answered',
  callsAbandonedHeader = 'Calls Abandoned',
  totalCallsHeader = 'Total Calls';
export const headerToGlobalStatsDataKey = {
  [statusTableHeader]: 'statusName',
  [numberOfExpertsHeader]: 'numberOfExperts',
  [callsAnsweredHeader]: 'callsAnswered',
  [totalCallsHeader]: 'totalCalls',
  [callsAbandonedHeader]: 'abandonedCalls',
  [dateTimesHeader]: 'dateTime',
};
