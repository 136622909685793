import moment from 'moment';
import { ReduxAction } from '../../data/datatypes';

export const ARROW_DOWN_CODE = 'keyboard_arrow_down',
  ARROW_UP_CODE = 'keyboard_arrow_up',
  TABLE_ROW_CLASS = 'tableRow',
  TABLE_ROW_SELECTED_CLASS = 'tableRow tableRowSelected',
  DEFAULT_LENGTH = 0;

export const rowSelected = (selected = false, id = '') => selected === id;
export const valueToArrowDirection = (isAsc = false) =>
  isAsc ? ARROW_UP_CODE : ARROW_DOWN_CODE;

export const valueToRowClass = (rowIsSelected = false) =>
  rowIsSelected ? TABLE_ROW_SELECTED_CLASS : TABLE_ROW_CLASS;

// stringAndStringToBoolean :: String, String -> Boolean
export const stringAndStringToBoolean =
  (string = '') =>
  (string2 = '') =>
    string === string2;

export const isEqual = (value, value2) => value === value2;

export const valueToIsEqual = (value1) => (value2) => isEqual(value1, value2);

export const valueToHasLengthGreaterThanZero = (value = '') =>
  valueToLength(value) > DEFAULT_LENGTH;

export const valueToLength = (text = '') => text?.length ?? DEFAULT_LENGTH;

export const getMinutesElapsed = (currentTime, statusChangeTime) => {
  const totalMilliseconds = currentTime - statusChangeTime;
  const totalSeconds = totalMilliseconds / 1000;
  const minutesAgo = Math.floor(totalSeconds / 60);
  return minutesAgo;
};

/**
 * Calcuales elapsed time in seconds from two ints
 * of seconds
 * @param {Int} currentTime
 * @param {Int} statusChangeTime
 * @returns total seconds elapsed as int
 */
export const getSecondsElapsedFromSeconds = (currentTime, statusChangeTime) =>
  currentTime - statusChangeTime;

/**
 * @returns Time at the moment of calling as seconds
 */
export const getCurrentTimeInSeconds = () => Math.floor(Date.now() / 1000);

export const transformFullName = (fullName) => {
  const modifiedName = fullName.split(/,|\s/).reverse().join(' ');
  return modifiedName;
};

export const addLessThanSymbol = (value) => (value > 0 ? value : '< 1');

export const removeDotFormatFullNameWithSpace = (name = '') =>
  name?.split('.')?.join(' ') ?? '';

export const getSkillsFromRoutingObj = ({ taskQueues = [] } = {}) => taskQueues;

export const areObjectsEqual = (object1 = {}, object2 = {}) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1?.length !== keys2?.length) {
    return false;
  }
  for (const key of keys1) {
    if (object1?.[key] !== object2?.[key]) {
      return false;
    }
  }
  return true;
};

/**
 * based on mainObjKey we find the matching item for each index in unchangedArray from changedArr
 * and only return the objects that have changed
 * @param {String} mainObjKey
 * @param {Array} unchangedArr
 * @param {Array} changedArr
 * @returns Array of the updated objects from changedArr
 */
export const compareArrayDifferencesWithObjKeyFind = (
  mainObjKey = '',
  unchangedArr = [],
  changedArr = [],
) =>
  unchangedArr?.reduce((acc, unchangedItem) => {
    const matchedItem = changedArr?.find(
      ({ [mainObjKey]: compareKey = '' }) =>
        compareKey === unchangedItem?.[mainObjKey],
    );
    const objectKeysAreNotEqual = !areObjectsEqual(unchangedItem, matchedItem);
    if (objectKeysAreNotEqual && matchedItem !== undefined) {
      acc.push(matchedItem);
    }
    return acc;
  }, []) ?? [];

export const valueToBoolean = (int) => !!int;

export const sortObjectByKeys = (sorter) => (o) =>
  Object.keys(o)
    .sort(sorter)
    .reduce((r, k) => ((r[k] = o[k]), r), {});

export const numberToString = (num) => (num += '');

/**
 * turns string into number
 * @param {String} str
 * @returns {Number} Either the number or 0 if NaN
 */
export const stringToNumber = (str) => +str || 0;

export const selectedMetric = (selectedInterval, metric1 = 0, metric2 = 0) =>
  selectedInterval === 'Today' ? metric1 : metric2;

const partnerStatsData = (
  {
    callsInQueue,
    cicallsInQueue,
    longestWait,
    cilongestWait,
    abandonRate,
    ciabandonRate,
    ASA,
    ciASA,
    CRT,
    ciCRT,
    SLA,
    ciSLA,
    monthlySLA,
    ExpertsAvailSp,
    ciExpertsAvailSp,
    ciExpertsAvailEn,
    ExpertsAvailEn,
    totalCalls,
    ciTotalCalls,
    ExpertsAvailFr,
    ciExpertsAvailFr,
  },
  selectedInterval,
) => {
  const crtSelected = selectedMetric(selectedInterval, CRT, ciCRT);
  const asaSelected = selectedMetric(selectedInterval, ASA, ciASA);
  const abandonRateSelected = selectedMetric(
    selectedInterval,
    abandonRate,
    ciabandonRate,
  );
  const longestWaitSelected = selectedMetric(
    selectedInterval,
    longestWait,
    cilongestWait,
  );
  const callsInQueueSelected = selectedMetric(
    selectedInterval,
    callsInQueue,
    cicallsInQueue,
  );
  const serviceLevel = selectedMetric(selectedInterval, SLA, ciSLA);

  const spanishExpertsAvailable = selectedMetric(
    selectedInterval,
    ExpertsAvailSp,
    ciExpertsAvailSp,
  );
  const englishExpertsAvailable = selectedMetric(
    selectedInterval,
    ExpertsAvailEn,
    ciExpertsAvailEn,
  );
  const metricTotalCalls = selectedMetric(
    selectedInterval,
    totalCalls,
    ciTotalCalls,
  );
  const frenchExpertsAvailable = selectedMetric(
    selectedInterval,
    ExpertsAvailFr,
    ciExpertsAvailFr,
  );
  return {
    queueCalls: callsInQueueSelected,
    longestWait: longestWaitSelected,
    abandonedRate: abandonRateSelected,
    asa: asaSelected,
    crt: crtSelected,
    sla: serviceLevel,
    monthlySLA,
    enAvail: englishExpertsAvailable,
    spAvail: spanishExpertsAvailable,
    frenchAvail: frenchExpertsAvailable,
    totalCalls: metricTotalCalls,
  };
};

export const removeUnderscores = (string) => string.replace(/_/g, ' ');

export const modifiedPartnerMapping =
  (interval) =>
  (partner = {}) => {
    const { globalStats = {} } = partner;
    const partnerStats = partnerStatsData(globalStats, interval);
    const partnerDataObj = {
      partnerStats,
      ...partner,
    };
    return partnerDataObj;
  };
const groupToWorkforceGlobalStats = ({ summary_stats }) => summary_stats;
export const dataFormatToPartnerData = (wfData) => (lob) => {
  const [workforceGroupTitle] = Object.keys(wfData);
  const [, groupSelectorName] = workforceGroupTitle.split('_');
  const formattedTitle = removeUnderscores(workforceGroupTitle);
  const globalStats = groupToWorkforceGlobalStats(wfData[workforceGroupTitle]);
  const { summary_routing = {} } = wfData[workforceGroupTitle];
  return {
    displayName: formattedTitle,
    lob,
    syncName: workforceGroupTitle,
    id: workforceGroupTitle,
    groupSelectorName,
    globalStats,
    routing: summary_routing,
  };
};

export const nestedObjectsArrayToNestedObject = (data) =>
  data.reduce((acc, val) => ({ ...acc, ...val }), {});
export const arrayToConditionallyRemoveOrAddElement = (arr) => (element) => {
  const copyArr = [...arr];
  if (copyArr.includes(element)) {
    const remove = copyArr.indexOf(element);
    copyArr.splice(remove, 1);
  } else {
    copyArr.push(element);
  }
  return copyArr;
};
export const typeToActionCreator = (type) => (payload) => ({
  ...new ReduxAction(type, payload),
});

export const expertNameMapping = (name) =>
  name.charAt(0).toUpperCase() + name.substring(1);
export const formatContractorName = (name) => name.slice(4) + ' (CWR)';
export const nameToIsContractor = (name) => name.includes('Cwr');
export const combineExpertNames = (name) => name.join(' ');
export const nameToSplitLowerCase = (name) => name.toLowerCase().split(' ');
export const nameToFormattedName = (name) => {
  const nameWithDotRemoved = removeDotFormatFullNameWithSpace(name);
  const lowerCaseSplitName = nameToSplitLowerCase(nameWithDotRemoved);
  const splitFormattedNames = lowerCaseSplitName.map(expertNameMapping);
  const combinedName = combineExpertNames(splitFormattedNames);
  const isContractor = nameToIsContractor(combinedName);
  const formattedContractorName = formatContractorName(combinedName);
  return isContractor ? formattedContractorName : combinedName;
};
export const arrayToRemoveDups = (arr = []) => [...new Set([...arr])];
export const arrayToArrayToCombineWithNoDups = (arr1) => (arr2) => {
  return [...new Set([...arr1, ...arr2])];
};

/**
 * Given two arrays of objects, remove any with duplicate key value pairs
 * based on passed key
 * @param {Array} arr1
 * @param {Array} arr2
 * @param {String} key
 * @returns {Array}
 */
export const arrayOfObjectsNoDupes =
  (arr1) =>
  (arr2) =>
  (key = 'name') => {
    const combinedArr = arr1.concat(arr2);
    const noDupesArr = combinedArr?.reduce(reduceArrOfObjToNoDupes(key), []);
    return noDupesArr;
  };

/**
 * Checks if item exists already in array based on key
 * @param {Array} acc
 * @param {Object} item
 */
export const reduceArrOfObjToNoDupes = (key) => (acc, item) => {
  if (!acc?.some((element) => item?.[key] === element?.[key])) {
    acc.push(item);
  }
  return acc;
};

const function3xRecallOnErr = (count) => async (cb) => {
  count = count + 1;
  try {
    const res = await cb();
    return res;
  } catch (err) {
    if (count <= 2) {
      console.log(' EXPERT SYNC SYNC FAIL - RETRY ', err);
      return function3xRecallOnErr(count)(cb);
    } else {
      console.error('MAX RETRY 3x ERROR CATCH => ', err);
    }
  }
};

export const sleepWellCb3x = async (cb) => {
  let count = 0;
  const response = await function3xRecallOnErr(count)(cb);
  return response;
};

export const numberToPercentValueOfNumber = (n1) => (n2) => {
  return ((n2 / n1) * 100).toFixed(2);
};

/**
 *
 * @param fx Function
 * returns function to handle incoming Input Event Object
 */
export const eventTargetHandler = (fx) => (e) => fx?.(e?.target?.value);

/**
 *  @param key String, the name of the key to be added
 *  @param value the value that will be mapped to the key
 * @returns Function for the object that will be modified
 */
export const keyValuePairToMappedWithObject =
  (key, value) =>
  (e = {}) => ({
    ...e,
    [key]: value,
  });

/**
 * @param {*} useHistoryObj useHistory() from react-router-dom
 * @returns {String} the path URL
 */
export const reactRouterHistoryToCurrentPath = (useHistoryObj) =>
  useHistoryObj?.location?.pathname ?? '/';

/**
 * Give an Array, and we return total sum all together
 * @param  {...any} params
 * @returns {Number} The Total Su
 */
export const addAllNumberParamsTotal = (...params) =>
  params.reduce((acc, num) => acc + stringToNumber(num), 0);

/**
 * Give an array of values, return TRUE if all of them are TRUTHYY
 * @param  {...any} params
 * @returns {Boolean}
 */
export const paramsToAllParamsAreTrue = (...params) =>
  params.reduce((acc, param) => acc && !!param, true);

/**
 * Give an array of values, return TRUE if ATLEAST ONE of them are TRUTHYY
 * @param  {...any} params
 * @returns {Boolean}
 */
export const paramsToAtleastOneParamIsTrue = (...params) =>
  params.reduce((acc, param) => acc || !!param, false);

/**
 * Used to loop over values, and have the values get assigned to the key at the same index in the loop
 * @param {Object} objToUpdate
 * @param {Array} valueIndexedArray
 * @returns {(value: string, index: number, array: string[]) => void}
 */
export const updateObjectKeysBasedOnLoopIndex =
  (objToUpdate, valueIndexedArray) => (key, nestedLoopIndex) =>
    (objToUpdate[key] = valueIndexedArray[nestedLoopIndex]);

/**
 * used for loop, to check for matching values within the objects of each iteration
 * @param {String} keyString
 * @param {any} value
 * @returns {(value: any, index: number, obj: any[]) => Boolean} for the loop to execute
 */
export const keyStringValueForLoopCheck =
  (keyString, value) => (objectForCheck) =>
    isEqual(objectForCheck[keyString], value);

/**
 * Used to break a text string into an array of individual new lines
 * @param {String} text
 * @returns {Array}
 */
export const textToArrayOfLineBreaks = (text) =>
  text?.split('\n').filter(valueToHasLengthGreaterThanZero) ?? [];

/**
 * Used to break a text string into an array of individual table cells
 * @param {String} text
 * @returns {Array}
 */
export const textToArrayOfTableCells = (text) =>
  text?.split('\t').filter(valueToHasLengthGreaterThanZero) ?? [];

/**
 * Given an array of anything, return array of numbers
 * @param {Array} arr
 * @returns {Array}
 */
export const stringArrayToNumberArray = (arr) => arr?.map(stringToNumber) ?? [];

//
//
//***********TIME TRANSFORMATIONS ***********
//
//
export const TIMESTAMP_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
export const HOUR_MINUTE_FORMAT = 'HH:mm';

export const getFormattedTime =
  (time) =>
  (FORMAT = TIMESTAMP_FORMAT) =>
    moment(new Date(time)).format(FORMAT);

export const dateTimeTo24HourMinutes = (time) =>
  moment(time, HOUR_MINUTE_FORMAT);
/**
 * Comparing SAME DAY Timestamps
 * @param {String} hourMinute1 "00:00" -> HH:mm
 * @param {*} hourMinute2 "00:00" -> HH:mm
 * @returns Boolean (true) if the first parameter time is greater than the 2nd parameter time
 */
export const isHourMinuteGreaterThanHourMinute = (hourMinute1, hourMinute2) =>
  dateTimeTo24HourMinutes(hourMinute1)?.isAfter(
    dateTimeTo24HourMinutes(hourMinute2),
  ) ?? false;

export const dateToFormattedAwsDate = (date = new Date()) =>
  moment(date).format('YYYY-MM-DD');

export const firstDateToIsSecondDateBefore = (
  firstDateString,
  secondDateString,
) => moment(firstDateString).isBefore(secondDateString, 'day');

export const firstDateToIsSecondDateSameDay = (
  firstDateString,
  secondDateString,
) => moment(firstDateString).isSame(secondDateString, 'day');

export const ternaryCompare = (value, returnParam = '') =>
  valueToBoolean(value) ? value : returnParam;

export const sortDatesInAscendingOrder = (a, b) => new Date(a) - new Date(b);
