import React from 'react';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const CheckboxComponent = ({ labelText = '', ...other }) => (
  <FormGroup>
    <FormControlLabel control={<Checkbox {...other} />} label={labelText} />
  </FormGroup>
);

export default CheckboxComponent;
