import { v4 as uuidv4 } from 'uuid';
import { API } from 'aws-amplify';
import moment from 'moment';
import config from '../../config.json';
import {
  fetchApiData,
  postApiData,
  apiKeyToFormattedHeader,
  singleGqlQueryGetAllItemsUntilNullToken,
} from '../../services/shared/index';
import {
  popHideSuccessMsg,
  popHideFailMessage,
  noAutoClosePopSuccessMessage,
} from '../../services/sweetAlerts';
import { getEnvironment } from '../../utils/applicationEnvironment';
import { valueToHasLengthGreaterThanZero } from '../../transformations/utils';
import { removeMissingAllocations } from './transformations';

const env = getEnvironment();
const ivrNextGenRouterApi = config[env].IVR_NEXT_GEN_ROUTER_API;
const locationRoutingSchedulerSlotApi =
  config[env].LOCATION_ROUTING_SLOT_SCHEDULER;
const locationRoutingSchedulerSlotApiKey =
  config[env].API_AUTH_KEYS.DELETE_EXPERT_TASK_KEY;
const rollBackUpdatesApiUrl = config[env].ROLLBACK_UPDATES;

const locationTransferTargetType = 'LOCATION_TRANSFER';
const ASURION_CORRELATION_HEADER = 'Asurion-correlationid';

const getUuidWithHeader = () => ({ [ASURION_CORRELATION_HEADER]: uuidv4() });

export const getSubTargetAllocation = async (
  TargetType = locationTransferTargetType,
) => {
  try {
    const apiUrl = `${ivrNextGenRouterApi}/getsubtargetallocation`;
    const { data: { SubTargetAllocationResults = [] } = {} } =
      await fetchApiData(apiUrl, getUuidWithHeader(), { TargetType });
    console.log(
      '[ Sub Target Allocation Fetch Results ] - ',
      SubTargetAllocationResults,
    );
    return SubTargetAllocationResults;
  } catch (err) {
    console.log('[ Sub Target Allocation FAILED ] - ', err);
  }
};

export const saveSubTargetAllocations = async (bodyParams = {}) => {
  const apiUrl = `${ivrNextGenRouterApi}/SaveSubTargetAllocation`;
  const saveAllocations = await postApiData(
    apiUrl,
    {
      SubTargetAllocationResults: [bodyParams],
    },
    {
      'content-type': 'application/json',
      accept: 'application/json',
      ...getUuidWithHeader(),
    },
  );
  return saveAllocations;
};
export const getSubTargetTypes = async () => {
  try {
    const apiUrl = `${ivrNextGenRouterApi}/getsubtargettypes`;
    const { data: { SubTargetTypeResults = [] } = {} } = await fetchApiData(
      apiUrl,
      getUuidWithHeader(),
    );
    return SubTargetTypeResults;
  } catch (err) {
    console.log('[ Error at fetching sub target types ] - ', err);
    return [];
  }
};

export const fetchLocationRoutingSchedulerWithDate = async (date, client) => {
  try {
    const query = `
      query MyQuery($client: String, $date: String, $nextToken: String) {
        listClientBasedLocationAllocations(client: $client, nextToken: $nextToken, filter: {date: {eq: $date}}, limit: 1000, sortDirection: ASC) {
          items {
            offshore
            onshoreNonPPX
            onshorePPX
            outsourcedOffshore
            outsourcedOnshore
            timeSlot
            client
            date
          }
          nextToken
        }
      }`;
    const variables = {
      date,
      client,
    };
    const results = await singleGqlQueryGetAllItemsUntilNullToken(
      query,
      variables,
    );
    return results;
  } catch (err) {
    console.log('ERROR Fetching Routing Scheduler', err);
  }
};

export const updateLocationRoutingScheduledTimeslot = async (
  allocations,
  logger,
) => {
  try {
    const saveAllocations = await postApiData(
      locationRoutingSchedulerSlotApi,
      {
        allocations,
      },
      {
        ...apiKeyToFormattedHeader(locationRoutingSchedulerSlotApiKey),
        'content-type': 'application/json',
        accept: 'application/json',
      },
    );
    console.log('Successful Location Routing Timeslot Update', saveAllocations);
    popHideSuccessMsg('Successfully updated location routing timeslots!');
    await logger('success');
  } catch (err) {
    console.log('ERROR Updating Routing Scheduler', err);
    popHideFailMessage('Failed to update location routing timeslots!');
    await logger('failed');
  }
};
export const createLocationBasedRoutingActivity = async (message, user) => {
  try {
    const query = `
      mutation MyMutation($input: CreateActivitiesInput!) {
        createActivities(input: $input) {
          message
        }
      }`;
    const variables = {
      input: {
        message,
        type: locationTransferTargetType,
        user,
      },
    };
    const res = await API.graphql({
      query,
      variables,
    });
    console.log('Created Location Based Routing Activity Log ', res);
    return res;
  } catch (err) {
    console.log('[ ERROR failed to create activity ] -', err);
  }
};

export const getAllRecentLocationRoutingActivities = async () => {
  try {
    const query = `
    query MyQuery {
      listActivities(type: "LOCATION_TRANSFER", sortDirection: DESC, limit: 25) {
        items {
          type
          message
          createdAt
        }
      }
    }`;
    const res = await API.graphql({
      query,
    });
    const mappedActivities =
      res?.data?.listActivities?.items?.map(
        (activity) => activity?.message ?? '',
      ) ?? [];
    return mappedActivities;
  } catch (err) {
    console.log('[ ERROR failed to fetch for activities ] -', err);
    return [];
  }
};

const getClientTimeSlot = async (client, date, timeSlot) => {
  try {
    const query = `
      query MyQuery($client: String, $nextToken: String, $dateTimeSlot: ModelClientBasedLocationAllocationsPrimaryCompositeKeyConditionInput) {
        listClientBasedLocationAllocations(client: $client, nextToken: $nextToken, limit: 1000, dateTimeSlot: $dateTimeSlot) {
          items {
            timeSlot
            client
            date
          }
          nextToken
        }
      }`;
    const variables = {
      client,
      dateTimeSlot: { eq: { date, timeSlot } },
    };
    const results = await singleGqlQueryGetAllItemsUntilNullToken(
      query,
      variables,
    );
    console.log('[ TimeSlot Fetch Response ] - ', results);
    return results;
  } catch (err) {
    console.log('[ ERROR Fetching Date Timeslot ] -', err);
  }
};

const createTimeSlot = async (
  client,
  date,
  timeSlot,
  offshore,
  onshorePPX,
  onshoreNonPPX,
  outsourcedOnshore,
  outsourcedOffshore,
  SubTargetAllocations,
) => {
  try {
    const query = `
  mutation MyMutation($input: CreateClientBasedLocationAllocationsInput!) {
    createClientBasedLocationAllocations(input: $input) {
      date
      timeSlot
      client
    }
  }`;
    let variables = {
      input: {
        client,
        date,
        offshore,
        onshorePPX,
        onshoreNonPPX,
        outsourcedOffshore,
        outsourcedOnshore,
        timeSlot,
      },
    };
    variables.input = removeMissingAllocations(SubTargetAllocations)(
      variables.input,
    );
    const res = await API.graphql({
      query,
      variables,
    });
    console.log('[ Successfully Created Timeslot ] - ', res);
  } catch (err) {
    console.log('[ ERROR Creating Timeslot ] - ', err);
  }
};
const updateTimeSlot = async (
  client,
  date,
  timeSlot,
  onshorePPX,
  onshoreNonPPX,
  outsourcedOnshore,
  offshore,
  outsourcedOffshore,
  SubTargetAllocations,
) => {
  try {
    const query = `
    mutation MyMutation($input: UpdateClientBasedLocationAllocationsInput!) {
      updateClientBasedLocationAllocations(input: $input) {
        client
        date
        timeSlot
      }
    }`;
    const variables = {
      input: {
        client,
        date,
        offshore,
        onshoreNonPPX,
        onshorePPX,
        outsourcedOffshore,
        outsourcedOnshore,
        timeSlot,
      },
    };
    variables.input = removeMissingAllocations(SubTargetAllocations)(
      variables.input,
    );
    const res = await API.graphql({
      query,
      variables,
    });
    console.log('[ Successfully UPDATED Timeslot ] - ', res);
  } catch (err) {
    console.log('[ ERROR UPDATING Timeslot ] - ', err);
  }
};
export const updateTimeslotScheduler = async (
  client,
  onshorePPX,
  onshoreNonPPX,
  outsourcedOnshore,
  offshore,
  outsourcedOffshore,
  SubTargetAllocations,
) => {
  const now = moment();
  const dateNow = now.format('YYYY-MM-DD');
  const hourMinuteNow = now.format('HH:mm');
  const timeSlots = await getClientTimeSlot(client, dateNow, hourMinuteNow);
  const timeSlotAvailable = valueToHasLengthGreaterThanZero(timeSlots);
  if (timeSlotAvailable) {
    await updateTimeSlot(
      client,
      dateNow,
      hourMinuteNow,
      onshorePPX,
      onshoreNonPPX,
      outsourcedOnshore,
      offshore,
      outsourcedOffshore,
    );
  } else {
    await createTimeSlot(
      client,
      dateNow,
      hourMinuteNow,
      offshore,
      onshorePPX,
      onshoreNonPPX,
      outsourcedOnshore,
      outsourcedOffshore,
      SubTargetAllocations,
    );
  }
};

/**
 * This Api will roll back the expert updates
 * to the values that they experts had for the previous Day
 * @param {Function} logAnalytics pass useLoggingAnalytics returned function in the parent component
 */
export const rollBackUpdatesApi = async (logAnalytics) => {
  try {
    const response = await postApiData(rollBackUpdatesApiUrl);
    logAnalytics({ status: 'success' });
    noAutoClosePopSuccessMessage(
      'Restoration In Progress',
      'Can take up to 15min, please verify expert settings in Operations section shortly.',
    );
    console.log('[ RollBack Changes Succuess ] - ', response);
  } catch (err) {
    logAnalytics({ status: 'failed' });
    popHideFailMessage('Failed to Roll Back Changes');
    console.log('[ ERROR RollBack Changes ] ', err);
  }
};
