import { fetchApiData } from './shared';
import { getEnvironment } from '../utils/applicationEnvironment';
import config from '../config.json';

const env = getEnvironment();

const healthUtilityAPI = config[env].HEALTH_UTILITY_URL,
  healthUtilityAPIKey = config[env].HEALTH_UTILITY_APIKEY;

async function getHealthAlarmsData() {
  try {
    const { data = [] } = await fetchApiData(healthUtilityAPI, {
      'x-api-key': healthUtilityAPIKey,
    });
    const sortedData = [
      ...sortAlarms(filterByState(data, 'ALARM')),
      ...sortAlarms(filterByState(data, 'INSUFFICIENT')),
      ...sortAlarms(filterByState(data, 'OK')),
    ];
    return sortedData;
  } catch (err) {
    /*eslint no-console: "off"*/
    console.error('[ API FAILURE ] - HEALTH CHECK', err);
    return false;
  }
}

const filterByState = (data, state) =>
  data.filter((item) => item.StateValue.toUpperCase() === state);

const sortAlarms = (alarms) =>
  alarms.sort((a, b) =>
    a.AlarmName < b.AlarmName ? -1 : a.AlarmName > b.AlarmName ? 1 : 0,
  );

export default getHealthAlarmsData;
