import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import IconComponent from '../IconComponent';
import NewExpertDetails from '../../services/NewExpertDetails';

import {
  Select,
  IconButton,
  FormControl,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DebounceApiInput } from '../Dialog';
import { addExpert } from '../../services/Experts';
import CtaText from '../CtaTextComponent';
import GenericLabelText from '../GenericLabelText';
import {
  EnabledTaskQTable,
  DisabledTaskQTable,
} from '../UserListContainerComponent';
import {
  removeDotFormatFullNameWithSpace,
  arrayToConditionallyRemoveOrAddElement,
} from '../../transformations/utils';
import UserUpload from '../UserUpload';

import './index.css';

export const HEADING_TEXT_CLASS = 'headingText',
  NEW_EXPERT_BUTTON_CLASS = 'newExpertBtn',
  FLEX_CONTAINER_CLASS = 'flexContainer',
  MATERIAL_ADD_CIRCLE_ICON = 'add_circle_outline',
  CIRCLE_ICON_TITLE = 'New Expert',
  ADD_ICON_STYLE_CLASS = 'addIcon';
export const DisabledFieldViews = ({ clone = {} }) => {
  const { EMP_Login_ID = '', Supervisor_Name = '' } = clone;
  if (EMP_Login_ID || Supervisor_Name) {
    const displayName = removeDotFormatFullNameWithSpace(EMP_Login_ID);
    return (
      <div className="header-group-actions">
        <section>
          <label>Employee </label>
          <GenericLabelText label="Name" disabled={true} text={displayName} />
        </section>
        <section>
          <label>Supervisor Name</label>
          <GenericLabelText
            label="Coach"
            disabled={true}
            text={Supervisor_Name}
          />
        </section>
      </div>
    );
  } else return null;
};
export const SelectMenu = ({ label, selections, value, onChange }) => {
  return (
    <FormControl sx={{ m: 1, width: 300, margin: 0 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={(e) => onChange(e?.target?.value ?? [])}
        input={<OutlinedInput label={label} />}
      >
        {selections?.map((select) => (
          <MenuItem key={select} value={select}>
            {select}
          </MenuItem>
        )) ?? []}
      </Select>
    </FormControl>
  );
};
export const DialogHeaderWithClose = ({ title, close }) => {
  return (
    <DialogTitle>
      <Typography>{title}</Typography>
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
export const AddExpertDialog = (props) => {
  const lobSelection = useSelector((s) => s?.selection?.lobSelection ?? []);
  const [selectedLob, setSelectedLob] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);

  const { groupSelection, taskQueueSelection } = useSelector((s) =>
    s?.selection?.available.reduce(
      (acc, { lob, group, taskqueues = [] }) => {
        if (
          selectedLob?.includes(lob) &&
          !acc?.groupSelection?.includes(group)
        ) {
          acc?.groupSelection?.push(group);
          if (selectedGroup?.includes(group)) {
            acc.taskQueueSelection.push(...taskqueues);
          }
        }
        return acc;
      },
      {
        groupSelection: [],
        taskQueueSelection: [],
      },
    ),
  );

  const [clone, setClone] = useState({});
  const [empId, setEmpId] = useState('');
  const [files, setFiles] = useState([]);
  const [searchByEmpId, setSearchByEmpId] = useState(true);
  const [enabledQ, setEnableQ] = useState({});
  const [disabledQ, setDisableQ] = useState([]);
  const helixUser = useSelector((state) => state?.user?.helixUser);

  useEffect(() => {
    if (!props.open) {
      setEmpId('');
      setClone({});
      setSelectedLob([]);
      setEnableQ({});
      setDisableQ([]);
      setSelectedGroup([]);
      setFiles([]);
    }
  }, [props.open]);
  useEffect(() => {
    setSelectedGroup((groups) =>
      groups.filter((g) => groupSelection?.includes(g) ?? false),
    );
  }, [selectedLob]);
  useEffect(() => {
    const qKeys = Object.keys(enabledQ);
    setDisableQ(() => taskQueueSelection?.filter((q) => !qKeys?.includes(q)));
  }, [selectedGroup]);

  const apiSearchId = useCallback(
    (emp_id) => {
      return new Promise(async (resolve) => {
        if (emp_id.length === 6) {
          const empIdToQuery = {
            emp_id: Number(emp_id),
          };
          NewExpertDetails(empIdToQuery)(helixUser)
            .then((data) => {
              setEmpId(emp_id);
              setClone(data);
              return false;
            })
            .catch(() => setEmpId(''), true)
            .then(resolve);
        } else {
          setEmpId('');
          resolve(true);
        }
      });
    },
    [helixUser, setEmpId, setClone],
  );
  const addExpertToDB = useCallback(() => {
    /*eslint no-undef: "off"*/
    const formData = new FormData();
    formData.append('files', files[0]);
    const req = searchByEmpId
      ? {
          emp_id: Number(empId),
          group: selectedGroup,
          lob: selectedLob,
          taskqueues: enabledQ,
        }
      : formData;
    req['routingCreatedBy'] = {
      routingCreatedByUserName: helixUser.userId,
      routingCreatedByUserID: helixUser.employeeId,
    };
    addExpert(!searchByEmpId)(req)(helixUser);
    props?.onClose?.();
  }, [helixUser, selectedGroup, selectedLob, empId, files, enabledQ]);
  const toggleAddMode = useCallback(
    () => setSearchByEmpId((status) => !status),
    [setSearchByEmpId],
  );
  const disabledSubmit = searchByEmpId
    ? empId?.length !== 6 || !selectedGroup?.length || !selectedLob?.length
    : !files?.length;
  return (
    <Dialog {...props}>
      <DialogHeaderWithClose
        close={() => props?.onClose?.()}
        title={'Add Expert'}
      />
      <DialogContent>
        {searchByEmpId ? (
          <Stack sx={{ paddingTop: '10px' }} gap={1}>
            <DebounceApiInput
              onChange={apiSearchId}
              value={empId}
              label="Employee ID"
              disabled={false}
            />
            <DisabledFieldViews clone={clone} />
            <div className="select-side-by-side">
              <SelectMenu
                label={'Line of business'}
                selections={lobSelection}
                value={selectedLob}
                onChange={setSelectedLob}
              />
              <SelectMenu
                label={'Group'}
                selections={groupSelection}
                value={selectedGroup}
                onChange={setSelectedGroup}
              />
            </div>
            <Stack flexDirection={'row'} height="300px" gap={2} width={'100%'}>
              <EnabledTaskQTable
                skillsObj={enabledQ}
                onClick={(d) => {
                  setEnableQ((q) => {
                    const copy = { ...q };
                    delete copy[d];
                    return copy;
                  });
                  setDisableQ((q) => [...q, d]);
                }}
                onChange={(d) => (e) => {
                  e.persist();
                  setEnableQ((q) => {
                    const copy = { ...q };
                    copy[d] = e?.target?.value ?? 2;
                    return copy;
                  });
                }}
              />
              <DisabledTaskQTable
                disabledSkills={disabledQ}
                onClick={(d) => {
                  setEnableQ((q) => ({ ...q, [d]: 2 }));
                  setDisableQ((qs) =>
                    arrayToConditionallyRemoveOrAddElement(qs)(d),
                  );
                }}
              />
            </Stack>
          </Stack>
        ) : (
          <UserUpload files={files} setFiles={setFiles} />
        )}
        <CtaText
          text={searchByEmpId ? 'Upload CSV' : 'Search Employee Id'}
          callToAction={toggleAddMode}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disabledSubmit}
          onClick={addExpertToDB}
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const AddNewExpertButton = () => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);
  return (
    <>
      <div className={FLEX_CONTAINER_CLASS}>
        <div className={NEW_EXPERT_BUTTON_CLASS}>
          <div className={FLEX_CONTAINER_CLASS} onClick={onOpen}>
            New Expert
            <IconComponent
              icon={MATERIAL_ADD_CIRCLE_ICON}
              iconTitle={CIRCLE_ICON_TITLE}
              styleClass={ADD_ICON_STYLE_CLASS}
            />
          </div>
        </div>
      </div>
      <AddExpertDialog open={open} onClose={onClose} />
    </>
  );
};

export default AddNewExpertButton;
