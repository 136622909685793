import {
  arrayToConditionallyRemoveOrAddElement,
  arrayToArrayToCombineWithNoDups,
  arrayOfObjectsNoDupes,
} from '../../transformations/utils';
import {
  ElasticSearchTerm,
  ElasticMustySearch,
} from '../../reducers/TableDataReducer/datatypes';
import {
  expertsInAuxHeader,
  expertsAailableHeader,
} from '../../components/WorkForceExpandedComponent/transformations';
import { ALL_CAPITAL } from '../../data/constants';
import { CoachDataModel } from '../../data/datatypes';

export const selectionToClickedToFilter =
  (selection) =>
  (clicked, returnAll = []) => {
    if (clicked === ALL_CAPITAL) {
      if (returnAll?.length === selection?.length) {
        return [];
      }
      return returnAll;
    } else return arrayToConditionallyRemoveOrAddElement(selection)(clicked);
  };

export const graphQlResToAvailableGroups = (res) =>
  (res?.data?.listLOBGroupMaps?.items ?? []).map((data) => ({
    ...data,
    taskqueues: data?.taskqueues?.items?.map(
      ({ taskqueueName }) => taskqueueName,
    ),
  }));
const LOB_KEY_MAP = 'LOB';
const STATUS_KEY_MAP = 'STATUS';
const WORKER_EXTENSION_KEY_MAP = 'WORKER_EXTENSION';
const graphqlMapsWithKeyToGraphqlMap = (res, key) =>
  res?.data?.listOrbitMaps?.items?.find(({ Key }) => Key === key)?.Map ?? [];
export const graphQlResToSelectionLobs = (res) =>
  graphqlMapsWithKeyToGraphqlMap(res, LOB_KEY_MAP);
export const graphQlResToSelectionStatus = (res) =>
  graphqlMapsWithKeyToGraphqlMap(res, STATUS_KEY_MAP);
export const graphQlResToSelectionExtension = (res) =>
  graphqlMapsWithKeyToGraphqlMap(res, WORKER_EXTENSION_KEY_MAP);

const sortBeforeReturn = (obj) => {
  const keys = Object.keys(obj);
  return keys.reduce((acc, key) => {
    if (key === 'coachSelection') {
      acc[key] = arrayToABCSortWithOddCasingCoach(obj[key]);
    } else acc[key] = arrayToAbcSordWithOddCasing(obj[key]);
    return acc;
  }, {});
};
export const lobFilterToGroupFilterToAvailableToSelection =
  (lobFilters = []) =>
  (groupFilters = []) =>
  (available) => {
    const groupSelection = available?.reduce(
      (acc, { lob = '', group = '' }) => {
        const hasLobSelected = !lobFilters?.length || lobFilters.includes(lob);
        if (hasLobSelected) acc.push(group);
        return acc;
      },
      [],
    );
    const updatedGroupFilter =
      groupFilters?.filter((g) => groupSelection?.includes(g)) ?? [];

    const groupCoachSelection = {
      groupSelection,
      coachSelection: [],
      siteSelection: [],
      taskQueueSelection: [],
    };
    const selections =
      available?.reduce(
        (
          acc,
          { lob = '', group = '', supervisorList, siteList, taskqueues } = {},
        ) => {
          const hasLobSelected =
            !lobFilters?.length || lobFilters.includes(lob);
          const hasGroupSelected =
            !updatedGroupFilter?.length || updatedGroupFilter.includes(group);
          if (hasLobSelected) {
            if (hasGroupSelected) {
              const newCoaches =
                supervisorList?.reduce((acc, list) => {
                  let newList = list;
                  if (typeof list === 'string') {
                    newList = JSON.parse(list);
                  }
                  const { supervisorName, supervisorId } = newList;
                  if (typeof supervisorName === 'string') {
                    acc.push(new CoachDataModel(supervisorName, supervisorId));
                  }
                  return acc;
                }, []) ?? [];
              acc.taskQueueSelection = arrayToArrayToCombineWithNoDups(
                acc.taskQueueSelection,
              )(taskqueues);
              acc.coachSelection = arrayOfObjectsNoDupes(acc.coachSelection)(
                newCoaches,
              )('name');
              acc.siteSelection =
                siteList == null
                  ? acc.siteSelection
                  : arrayToArrayToCombineWithNoDups(acc.siteSelection)(
                      siteList,
                    );
            }
          }

          return acc;
        },
        groupCoachSelection,
      ) ?? groupCoachSelection;
    return { ...sortBeforeReturn(selections), groupFilter: updatedGroupFilter };
  };

export const lobFilterToGroupFilterToAvailableToAvailableQueues =
  (lobFilter) => (groupFilter) => (available) =>
    available?.reduce((acc, { taskqueues = [], group, lob }) => {
      const lobSelected = !lobFilter?.length || lobFilter?.includes(lob);
      const groupSelected =
        !groupFilter?.length || groupFilter?.includes(group);
      if (lobSelected && groupSelected && taskqueues?.length)
        acc = arrayToArrayToCombineWithNoDups(acc)(taskqueues);
      return acc;
    }, []) ?? [];
export const stringArrayToAbcSort = (arr) => arr?.sort() ?? [];
const arrayToAbcSordWithOddCasing = (arr) =>
  arr.sort((a, b) => a?.localeCompare(b));

const arrayToABCSortWithOddCasingCoach = (arr) => {
  return arr.sort((a, b) => a?.name?.localeCompare(b?.name));
};

export const buildAppSyncExpertsInQueueQueryFilter = (
  lobFilter = '',
  groupFilter = '',
  taskQueueFilter = '',
  header = '',
) => {
  const mustHaveValues = [
    new ElasticSearchTerm('lob', lobFilter),
    new ElasticSearchTerm('group', groupFilter),
    new ElasticSearchTerm('routing.taskQueues', taskQueueFilter),
  ];
  const mustNotHaveValues = [new ElasticSearchTerm('nle', true)];
  if (expertsAailableHeader === header) {
    mustHaveValues.push(new ElasticSearchTerm('status', ['Available']));
  } else if (expertsInAuxHeader === header) {
    mustNotHaveValues.push(
      new ElasticSearchTerm('status', ['Available', 'Busy', 'Offline']),
    );
  }
  return new ElasticMustySearch(mustHaveValues, mustNotHaveValues);
};
