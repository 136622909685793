import {
  nameToFormattedName,
  reactRouterHistoryToCurrentPath,
} from '../../transformations/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectorToArrayOfTableDataChanges } from '../../reducers/TableDataReducer';
import { useHistory } from 'react-router-dom';
export const defaultSummaryStats = {
  ciCRT: 0,
  ExpertsAvailEn: 0,
  callsAcceptedBelow30: 0,
  callsInQueue: 0,
  callsAcceptedBelowAbove30: 0,
  CRT: 0,
  cicallsInQueue: 0,
  monthlySLA: 0,
  ASA: 0,
  ciExpertsAvailSp: 0,
  ciTotalCalls: 0,
  longestWait: 0,
  ExpertsAvailSp: 0,
  cilongestWait: 0,
  totalCalls: 0,
  ciabandonRate: 0,
  ciASA: 0,
  ciSLA: 0,
  SLA: 0,
  abandonRate: 0,
  ciExpertsAvailEn: 0,
};
export const syncWorkerTransform = ({
  workerName = '',
  WANDStats: wand = [],
  ...otherWorkerData
}) => {
  const WANDStats =
    wand && wand.sort
      ? wand.sort(
          ({ Timestamp: a }, { Timestamp: b }) => new Date(b) - new Date(a),
        )
      : wand;
  const name = nameToFormattedName(workerName);
  return { ...otherWorkerData, name, WANDStats };
};

export const objectToAddNumberKeys = (obj = {}) =>
  Object.keys(obj).reduce((acc, key) => acc + obj[key], 0) ?? 0;

export const setTimerWithUpdates = (handleTime = 200) => {
  let timer = null;
  let preVal = [];
  return (batchUpdateFunc) => (singleUpdatedVal) => {
    if (timer === null) {
      timer = setTimeout(() => {
        batchUpdateFunc(preVal);
      }, handleTime);
      preVal = [singleUpdatedVal];
    } else {
      clearTimeout(timer);
      preVal.push(singleUpdatedVal);
      timer = setTimeout(() => {
        batchUpdateFunc(preVal);
        preVal = [];
      }, handleTime);
    }
  };
};
export const useScrollToTopOnTableDataChange = () => {
  const history = useHistory();
  const url = reactRouterHistoryToCurrentPath(history);
  const stateChangeArray = useSelector(selectorToArrayOfTableDataChanges);
  // fire scroll when selection changes for item fetch and when tabs change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [...stateChangeArray, url]);
};
