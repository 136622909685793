import React from 'react';

const colorToSvgFill = (color) => {
  switch (color) {
    case 'Yellow':
      return '#F9D51C';
    case 'Green':
      return '#00BF63';
    case 'Red':
      return '#DB1B1B';
    default:
      return '#f7f7f7';
  }
};

export const colorToWifiIcon = (color) => {
  const fill = colorToSvgFill(color);
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3375 5.20494C15.2436 3.11098 12.4601 1.95911 9.50004 1.95911C6.5392 1.95911 3.7557 3.11098 1.66254 5.20494L0.543121 4.08552C2.93475 1.6939 6.11566 0.375773 9.50004 0.375773C12.8828 0.375773 16.0637 1.69311 18.457 4.08552L17.3375 5.20494Z"
        fill={fill}
      />
      <path
        d="M9.5 8.29244C10.769 8.29244 11.9613 8.78565 12.8583 9.68419L13.9777 8.56477C12.7823 7.36777 11.191 6.70911 9.5 6.70911C7.80901 6.70911 6.21776 7.36777 5.02234 8.56477L6.14176 9.68419C7.03871 8.78565 8.23096 8.29244 9.5 8.29244Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12501 13.0424C7.12501 14.3519 8.19059 15.4174 9.50001 15.4174C10.8094 15.4174 11.875 14.3519 11.875 13.0424C11.875 11.733 10.8094 10.6674 9.50001 10.6674C8.19059 10.6674 7.12501 11.733 7.12501 13.0424ZM8.70834 13.0424C8.70834 12.6062 9.0638 12.2508 9.50001 12.2508C9.93622 12.2508 10.2917 12.6062 10.2917 13.0424C10.2917 13.4786 9.93622 13.8341 9.50001 13.8341C9.0638 13.8341 8.70834 13.4786 8.70834 13.0424Z"
        fill={fill}
      />
      <path
        d="M3.90292 7.44377C6.99042 4.35785 12.0112 4.35865 15.0971 7.44456L16.2173 6.32594C12.5139 2.62094 6.4885 2.62015 2.7835 6.32435L3.90292 7.44377Z"
        fill={fill}
      />
    </svg>
  );
};
export const colorToBoltIcon = (color) => {
  const fill = colorToSvgFill(color);
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.59781 6.92307L8.11606 0.99999L4.87237 6.65374L8.40054 8.12297L3.49424 14.3075L7.01977 8.41474L3.59781 6.92307Z"
        stroke={fill}
        strokeWidth="1.45464"
        strokeMiterlimit="10"
        strokedinecap="round"
        strokedinejoin="round"
      />
    </svg>
  );
};
export const colorToArrowStackIcon = (color) => {
  const fill = colorToSvgFill(color);
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.707 9.70795L13.293 8.29395L15.586 6.00095H4V4.00095H15.586L13.293 1.70795L14.707 0.293945L19.414 5.00095L14.707 9.70795Z"
        fill={fill}
      />
      <path
        d="M5.29306 8.29395L6.70706 9.70795L4.41406 12.0009H16.0001V14.0009H4.41406L6.70706 16.2939L5.29306 17.7079L0.58606 13.0009L5.29306 8.29395Z"
        fill={fill}
      />
    </svg>
  );
};
