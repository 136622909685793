import React from 'react';
import { Stack, Typography } from '@mui/material';
import './index.css';

const UBIF_SUBSECTION = 'ubif-subsection';

export default function UbifSubSection({ title, children }) {
  return (
    <Stack id={UBIF_SUBSECTION} gap={2}>
      <Typography color="primary">
        <span>{title}</span>
      </Typography>
      {children}
    </Stack>
  );
}
