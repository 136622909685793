import {
  allDataRowViews,
  callsInQueueHeader,
} from '../../components/WorkForceExpandedComponent/transformations';
export const handleIncomingUserSettings = ({
  taskQueueViews: taskQueueSetting = [],
  taskQueueHeaderSort: taskQSortKey = '',
  ...otherSettings
} = {}) => {
  return {
    taskQueueViews: taskQueueSetting?.length
      ? taskQueueSetting
      : allDataRowViews,
    taskQueueHeaderSort: taskQSortKey?.length
      ? taskQSortKey
      : callsInQueueHeader,
    ...otherSettings,
  };
};
