import React from 'react';
import { Typography } from '@mui/material';

import './index.scss';

const FullscreenImageWithText = ({ image = '', text = '' }) => {
  return (
    <div className="center-screen">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img src={image} />
        <Typography
          style={{ marginTop: '20px', textAlign: 'center', fontSize: '20px' }}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
};

export default FullscreenImageWithText;
