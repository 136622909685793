import { HelixUser } from '../../data/datatypes';
import { LOB_QUEUE_USER_CHANGE } from '../ExpertReducer';
import { handleIncomingUserSettings } from './transformations';

export const orbitEditorCheckVal = 'ORBIT-EDITOR';
export const ubifAdminCheckVal = 'ORBIT-UBIFSTOREDB-ADMIN';
export const workforceAdminAdGroup = 'ORBIT-ADMIN';
export const orbitRestrictedCheckVal = 'ORBIT-ACCESS-RESTRICTED';
export const orbitServiceDeskCheckVal = 'ORBIT-SERVICEDESK';
// groups that include read-only routes
export const orbitRestoreReadOnlyCheckVal = 'ORBIT-RESTORE-READ-ONLY';
export const orbitEditorReadOnlyCheckVal = 'ORBIT-EDITOR-READ-ONLY';
export const orbitUploadCheckVal = 'ORBIT-UPLOAD';
export const orbitTACheckVal = 'ORBIT-TA';

const initialState = {
  authenticated: false,
  helixUser: new HelixUser(),
  userSettings: {},
  orbitEditor: false,
  ubifAdmin: false,
  workforceAdmin: false,
  orbitRestricted: false,
  orbitRestoreReadOnly: false,
  orbitEditorReadOnly: false,
  orbitUpload: false,
  orbitServiceDesk: false,
  updateUserSettingsReady: false,
};

// state selectors
export const selectorToHelixUser = (state) => state.user.helixUser,
  selectorToSaveUserSettings = (state) => state.user.userSettings,
  selectorToUserSavedLobs = (state) => state.user?.userSettings?.selectedLob,
  selectorToUserSavedQueues = (state) =>
    state.user?.userSettings?.selectedQueue,
  selectorToOrbitEditor = (state) => state.user.orbitEditor,
  selectorToUserAuthenticated = (state) => state.user.authenticated,
  selectorToUserReducer = ({ user = {} } = {}) => user,
  selectorToUserDarkMode = ({
    user: {
      userSettings: { darkMode = false },
    },
  }) => darkMode,
  selectorToAppViewAuths = ({
    user: { orbitEditor, ubifAdmin, orbitRestricted },
  }) => ({
    orbitEditor,
    ubifAdmin,
    orbitRestricted,
  });

// action types
export const SIGN_IN = 'SIGN_IN',
  SET_ADGROUP_PERMS = 'SET_ADGROUP_PERMS',
  SAVE_USER_SETTINGS = 'SAVE_USER_SETTINGS',
  SAVE_DARK_MODE = 'SAVE_DARK_MODE',
  SAVE_USER_APP_SELECTION = 'SAVE_USER_APP_SELECTION',
  SAVE_TASK_Q_HEADERS = 'SAVE_TASK_Q_HEADERS',
  SAVE_TASK_Q_SORT_KEY = 'SAVE_TASK_Q_SORT_KEY';

// action creators
export const userAuthToUserSignIn = (payload) => ({ type: SIGN_IN, payload }),
  tokenToAdGroupPerms = (payload) => ({
    type: SET_ADGROUP_PERMS,
    payload,
  }),
  valueToSaveUserSettingsAction = (payload) => ({
    type: SAVE_USER_SETTINGS,
    payload,
  }),
  valueToSaveDarkMode = (payload) => ({
    type: SAVE_DARK_MODE,
    payload,
  }),
  lobToGroupToUpdateAppSelect = (lob) => (group) => ({
    type: SAVE_USER_APP_SELECTION,
    payload: { lob, group },
  }),
  taskQHeaderArrayToUpdateAppSelect = (payload) => ({
    type: SAVE_TASK_Q_HEADERS,
    payload,
  }),
  setTaskQueueSortKey = (payload) => ({
    type: SAVE_TASK_Q_SORT_KEY,
    payload,
  });

// reducer
function userReducer(state = initialState, { type = '', payload }) {
  switch (type) {
    case SIGN_IN:
      return { ...state, ...payload };
    case SET_ADGROUP_PERMS:
      const orbitEditor = payload.includes(orbitEditorCheckVal);
      const ubifAdmin = payload.includes(ubifAdminCheckVal);
      const workforceAdmin = payload.includes(workforceAdminAdGroup);
      const orbitUpload = payload.includes(orbitUploadCheckVal);
      const orbitServiceDesk = payload.includes(orbitServiceDeskCheckVal);
      const orbitRestoreReadOnly = payload.includes(
        orbitRestoreReadOnlyCheckVal,
      );
      const orbitTA = payload.includes(orbitTACheckVal);
      const orbitEditorReadOnly = payload.includes(orbitEditorReadOnlyCheckVal);
      // EXPERT RESTRICTION CHECK For restricting and experts access
      const orbitRestricted = payload.includes(orbitRestrictedCheckVal);

      return {
        ...state,
        orbitEditor,
        ubifAdmin,
        orbitRestricted,
        workforceAdmin,
        orbitUpload,
        orbitRestoreReadOnly,
        orbitEditorReadOnly,
        orbitTA,
        orbitServiceDesk,
      };
    case SAVE_USER_SETTINGS:
      return {
        ...state,
        userSettings: handleIncomingUserSettings(payload),
        updateUserSettingsReady: true,
      };
    case SAVE_USER_APP_SELECTION: {
      const { group, lob } = payload;
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          selectedQueue: group,
          selectedLob: lob,
        },
      };
    }
    case SAVE_TASK_Q_HEADERS: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          taskQueueViews: payload,
        },
      };
    }
    case SAVE_TASK_Q_SORT_KEY: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          taskQueueHeaderSort: payload,
        },
      };
    }
    case SAVE_DARK_MODE:
      return {
        ...state,
        userSettings: { ...state.userSettings, darkMode: payload },
      };
    case LOB_QUEUE_USER_CHANGE:
      const { lob: selectedLob, client: selectedQueue } = payload;
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          selectedLob,
          selectedQueue,
        },
      };
    default:
      return state;
  }
}
export default userReducer;
