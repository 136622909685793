// activeTaskQueueString to be added to query here (if this is what the end up calling it in the backend)
export const FetchExpertsWithFilter = `
    query MyQuery($limit: Int, $from: Int, $filter:AWSJSON, $sort: SearchableWorkerSortInput) {
      searchWorkersWithESFilter(limit: $limit, filter: $filter, sort: $sort, from: $from) {
      nextToken
      total
      items {
        nle
        activities
        adGroups
        autoWrap
        auxTime
        calls
        ciAutoWrap
        ciAuxTime
        ciCalls
        ciCrt
        ciHoldTime
        ciOBTime
        ciTalkTime
        crt
        evlConnection
        group
        holdTime
        holdCall
        talkTimeTimeStamp
        lob
        oBTime
        routing
        site
        status
        statusIntime
        supervisorId
        supervisorName
        talkTime
        upsell
        wandstats
        worker_Id
        workerName
        market
        extension
        QSP100Rank
        activeTaskqueue
        }
      }
    }
  `;

export const expertNameAndStatusStatusQuery = `
query MyQuery($limit: Int, $from: Int, $filter:AWSJSON, $sort: SearchableWorkerSortInput, $nextToken: String) {
  searchWorkersWithESFilter(limit: $limit, filter: $filter, from: $from, sort: $sort, nextToken: $nextToken){
      nextToken
      items {
        workerName
        statusIntime
        status
        }
      }
    }
  `;
export const workforceSummaryStatsFetch = `
query MyQuery($limit: Int, $filter:AWSJSON, $from: Int, $sort: SearchableSummaryStatsSortInput) {
  searchSummaryStatsWithESFilter(limit: $limit, filter:$filter, from: $from, sort:$sort){
    total
    items{
      group
      lob
      SLA
      ciSLA
      callsInQueue
      cicallsInQueue
      longestWait
      cilongestWait
      ciabandonRate
      abandonRate
      totalCalls
      ciTotalCalls
      ciExpertsAvailEn
      ciExpertsAvailFr
      ciExpertsAvailSp
      ciExpertsAvailBil
      ExpertsAvailBil
      ExpertsAvailEn
      ExpertsAvailFr
      ExpertsAvailSp
      ciASA
      ciCRT
      ASA
      CRT
      monthlySLA
      expertsAvailablePercent
    }
  }
}
`;
export const workforceNotificationFetchQuery = `
query MyQuery($limit: Int, $filter:AWSJSON, $from: Int, $sort: SearchableLOBGroupSortInput) {
  searchLOBGroupMapsWithESFilter(limit: $limit, filter:$filter, from: $from, sort:$sort) {
      items {
        taskqueues {
          items {
            notifications {
              calls_in_queue
            }
            taskqueueName
            lob
            group
          }
        }
    }
  }
    }
  `;
export const workforceTaskQueues = `
query MyQuery($lob: String = "", $group: String = "") {
  listLOBGroupMaps(lob: $lob, group: {eq: $group}) {
    items {
      taskqueues {
        items {
          taskqueueName
          taskqueueSid
          eventStats {
            SLCallCounts {
              items {
                isOutbound
                isCI
                T150
              }
            }
          }
          queueStats {
            expertDetails {
              ciExpertsAvailable
              expertsAvailable
              ciExpertsInAux
              expertsInAux
              ciExpertsWorking
              ciExpertsStaffed
              expertsStaffed
              expertsWorking
            }
            SLLessShortAbandoned
            ciSLLessShortAbandoned
            ciSLOffered
            SLOffered
            ciATA
            ATA
            callsAbandoned
            ciCallsAbandoned
            CRT
            ciCRT
            AHT
            ciAHT
            ciTransferredCalls
            transferredCalls
            ASA
            SLAnswered
            callsAnswered
            callsAnsweredInSL
            callsInQueue
            callsOffered
            ciASA
            ciCallsAnswered
            ciCallsAnsweredInSL
            ciCallsInQueue
            ciCallsOffered
            ciLongestwait
            ciMaxwait
            ciSLAnswered
            longestWait
            maxWait
          }
        }
      }
    }
  }
}
  `;

export const fetchUpsellQueryString = `query MyQuery ($direction: SearchableSortDirection, $field: SearchableUpsellUploadNotificationsSortableFields, $limit: Int = 30){
  searchUpsellUploadNotifications(sort: {direction: $direction, field: $field}, limit: $limit) {
      items {
        uploadDate
        lob
        client
        url
        uploadCount
        status
      }
    }
  }`;

export const listSummaryStatsForGlobal = `query MyQuery {
    listSummaryStats (limit: 1000) {
      items {
        callsAnswered
        totalCalls
        abandonedCalls
      }
      nextToken
    }
  }`;

export const onUpdateGlobalCallStats = `subscription MySubscription($Date: String) {
  onUpdateContactCenterStats(Date: $Date) {
    Stats
    Date
    updatedAt
  }
}`;

export const globalCallStatsInterval = ` query MyQuery($date: AWSDate) {
  listContactCenterHourlyReports(date: $date) {
    items {
      callsAbandoned
      callsAnswered
      callsOffered
      date
      timeSlot
    }
  }
}`;

export const listContactCenterStats = `query MyQuery($between: [String] = "") {
  listContactCenterStats(filter: {Date: {between: $between}}) {
    items {
      Stats
      Date
      updatedAt
      createdAt
    }
  }
}`;
