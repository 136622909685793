import React from 'react';
import { Button } from '@mui/material';
import { rollBackUpdatesApi } from '../services';
import { useLoggingAnalytics } from '../../../services/KibanaEvents';
import { popUpWarningMessage } from '../../../services/sweetAlerts';
import { DisplayBoxWith3Texts1Button } from '../../UploadCSVButton';
import { useTheme } from '@mui/material/styles';

const RollBackUpdateButton = (props) => {
  const logAnalytics = useLoggingAnalytics('Roll Back Expert Updates');
  const handleRollBackApi = async () => {
    popUpWarningMessage(
      'Sure you want to continue?',
      '',
      async () => await rollBackUpdatesApi(logAnalytics),
    );
  };
  const theme = useTheme();

  return (
    <Button
      disabled={!props?.hasEditPermissions ?? true}
      variant="outlined"
      sx={{
        color: 'black',
        backgroundColor: 'white',
        ':hover': {
          backgroundColor: theme?.palette?.button?.main,
          color: theme?.palette?.neutral?.contrastText,
        },
      }}
      onClick={handleRollBackApi}
    >
      Restore
    </Button>
  );
};

const RollBackUpdates = (props) => {
  const header = "Restore ALL the expert profiles from yesterday's settings";
  const subtitle =
    'By clicking the button, you will revert all expert changes for the day and roll back to the previous day.';
  const footer = 'Routing data backup is taken every night at 12am.';
  return (
    <DisplayBoxWith3Texts1Button
      header={header}
      subtitle={subtitle}
      footer={footer}
      Button={RollBackUpdateButton}
      {...props}
    />
  );
};

export default RollBackUpdates;
