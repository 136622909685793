import { directionToIsAscending, abcSort } from '../Table/transformations';

export const queueNameTableHeader = 'Task Queue Name',
  callsInQueueHeader = 'Calls in Queue',
  expertsAailableHeader = 'Experts Available',
  expertsInAuxHeader = 'Experts in Aux',
  slAnsweredHeader = 'SL - Answered',
  slLessShortAbandonedHeader = 'SL - Less Short Abandoned',
  slOfferedHeader = 'SL - Offered',
  asaHeader = 'ASA',
  ataHeader = 'ATA',
  callsAbandonedHeader = 'Calls Abandoned',
  callsAnsweredHeader = 'Calls Answered',
  callsAnsweredInSlHeader = 'Calls Answered In SL',
  callsOfferedHeader = 'Calls Offered',
  longestWaitHeader = 'Longest Wait',
  maxWaitHeader = 'Max Wait',
  crtHeader = 'CRT',
  outboundCallsHeader = 'Outbound Calls',
  callsTransferredHeader = 'Calls Transferred',
  expertsStaffedHeader = 'Experts Staffed',
  expertsWorkingHeader = 'Experts Working',
  ahtHeader = 'AHT';
export const allDataRowViews = [
  callsInQueueHeader,
  expertsAailableHeader,
  expertsInAuxHeader,
  slAnsweredHeader,
  slLessShortAbandonedHeader,
  slOfferedHeader,
  asaHeader,
  ataHeader,
  callsAbandonedHeader,
  callsAnsweredHeader,
  callsAnsweredInSlHeader,
  callsOfferedHeader,
  longestWaitHeader,
  maxWaitHeader,
  crtHeader,
  outboundCallsHeader,
  callsTransferredHeader,
  expertsStaffedHeader,
  expertsWorkingHeader,
  ahtHeader,
];
export const headerToTaskQDataKey = {
  [queueNameTableHeader]: 'queueName',
  [asaHeader]: 'asa',
  [ataHeader]: 'ata',
  [callsAbandonedHeader]: 'callsAbandoned',
  [callsAnsweredHeader]: 'callsAnswered',
  [callsAnsweredInSlHeader]: 'callsAnsweredInSl',
  [callsInQueueHeader]: 'callsInQueue',
  [callsOfferedHeader]: 'callsOffered',
  [callsTransferredHeader]: 'callsTransferred',
  [crtHeader]: 'crt',
  [expertsAailableHeader]: 'expertsAvailable',
  [expertsInAuxHeader]: 'expertsInAux',
  [expertsStaffedHeader]: 'expertsStaffed',
  [expertsWorkingHeader]: 'expertsWorking',
  [longestWaitHeader]: 'longestWait',
  [maxWaitHeader]: 'maxWait',
  [outboundCallsHeader]: 'outboundCalls',
  [slAnsweredHeader]: 'slAnswered',
  [slLessShortAbandonedHeader]: 'slLessShortAbandoned',
  [slOfferedHeader]: 'slOffered',
  [ahtHeader]: 'aht',
};

export const headerSortKeyToResolveData =
  (headerSort) =>
  ({ [headerToTaskQDataKey[headerSort]]: val } = {}) =>
    val;
export const taskQueueTableSort = (headerSort, sortDirection) => {
  const isAsc = directionToIsAscending(sortDirection);
  const resolveData = headerSortKeyToResolveData(headerSort);
  switch (headerSort) {
    case queueNameTableHeader:
      return (a, b) =>
        abcSort(resolveData(isAsc ? a : b))(resolveData(isAsc ? b : a));
    default:
      return (a, b) => resolveData(isAsc ? a : b) - resolveData(isAsc ? b : a);
  }
};

export const addSecondAnnotation = (text) => `${text} (s)`;
export const addPercentAnnotation = (text) => `${text} %`;
export const addPercentAnnotationForHeader = (text) => `${text} (%)`;
export const addExpertsAnnotation = (text) => `${text} experts`;
export const percentAnnotatedKeys = [
  slAnsweredHeader,
  slLessShortAbandonedHeader,
  slOfferedHeader,
];
export const secondAnnotatedKeys = [
  asaHeader,
  ataHeader,
  longestWaitHeader,
  maxWaitHeader,
  crtHeader,
  ahtHeader,
];
/**
 *
 * @param {String} header
 * @param {String} value
 * @returns {String} with after text to describe the data value
 */

export const taskQDataHeaderAnnotation = (header) => {
  if (percentAnnotatedKeys?.includes(header))
    return addPercentAnnotationForHeader(header);
  else if (secondAnnotatedKeys?.includes(header))
    return addSecondAnnotation(header);
  else return header;
};

const toolTipDataHoverHeaders = [expertsAailableHeader, expertsInAuxHeader];
export const headerToShouldHoverTooltip = (header) =>
  toolTipDataHoverHeaders?.includes(header);
