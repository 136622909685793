import React from 'react';
import {
  CancelTask,
  LocationBasedRoutingTab,
  CreateTaskqueForm,
} from '../components/AdminComponent';
import RollBackUpdates from '../components/AdminComponent/RollBackUpdates';
import ExpertStatus from '../components/ExpertStatus';
import GlobalStatsComponent from '../components/GlobalStats';
import PartnerListContainerComponent from '../components/PartnerListContainerComponent';
import { ProtectedRoutes } from '../components/RoutingComponent';
import UbifComponent from '../components/UbifComponent';
import UserListContainerComponent from '../components/UserListContainerComponent';
import UploadCSVButton from '../components/UploadCSVButton';

export const ALL_CAPITAL = 'ALL';

// TAB URLS
export const OPERATIONS_PAGE_SLUG = '/ops-view',
  EXPERT_ATTR_PAGE_SLUG = '/expert-attributes',
  WORKFORCE_PAGE_SLUG = '/work-force',
  UBIF_FORM_PAGE = '/ubif-view',
  GLOBAL_STATS_PAGE_SLUG = '/global-stats',
  EXPERT_CHAT_PAGE_SLUG = '/chat',
  ADMIN_PAGE_SLUG = '/admin',
  HEALTH_CHECK_PAGE_SLUG = '/health-check',
  LOCATION_ROUTING_PAGE_SLUG = '/location-based-routing',
  CANCEL_TASK_PAGE_SLUG = '/cancel-stuck-task',
  ROLLBACK_CHANGES_PAGE_SLUG = '/restore',
  UPSELL_QSP_AUTOMATION_SLUG = '/upsell-qsp-automation',
  CREATE_TASKQUEUE_PAGE_SLUG = '/create-taskqueue';

export const MAIN_SUCCESS_COLOR_LIGHT = '#4CAF50',
  MAIN_SUCCESS_COLOR_DARK = '#6efac3;',
  MAIN_WARNING_COLOR = '#FFEB3B',
  MAIN_ERROR_COLOR_LIGHT = '#E53935',
  MAIN_ERROR_COLOR_DARK = '#ff6a6a';
// Statuses
export const status = [
  'Available',
  'Academy Support',
  'Alt Claims',
  'Break',
  'Busy',
  'Callback Pending',
  'Coaching',
  'Default',
  'Lunch',
  'Messaging',
  'Meeting',
  'Not Available',
  'Offline',
  'Special Project',
  'Technical Issues',
  'Training',
  'Wrap Up',
];

// AD GROUPS
// MGMT-US-N-ORBIT-ADMIN
const workforceAdminAdGroupKey = 'workforceAdmin',
  // MGMT-US-N-ORBIT-UBIFSTOREDB-ADMIN
  ubifAdminAdGroupKey = 'ubifAdmin',
  //MGMT-US-N-ORBIT-EDITOR
  orbitEditorAdGroupKey = 'orbitEditor',
  // MGMT-US-N-ORBIT-RESTORE-READ-ONLY
  orbitRestoreAdGroupKeyReadOnly = 'orbitRestoreReadOnly',
  // MGMT-US-N-ORBIT-EDITOR-READ-ONLY
  orbitEditorAdGroupKeyReadOnly = 'orbitEditorReadOnly',
  // MGMT-US-N-ORBIT-UPLOAD
  orbitUploadAdGroupKey = 'orbitUpload',
  // MGMT-US-N-ORBIT-TA
  orbitTAAdGroupKey = 'orbitTA',
  // MGMT-US-N-ORBIT-SERVICEDESK
  orbitServiceDeskAdGroupKey = 'orbitServiceDesk';

// TAB READ NAMES
const locationBasedRoutingTab = 'Location Based Routing',
  cancelTaskTab = 'Cancel Task',
  rollBackUpdatesTitle = 'Restore',
  upsellQSPAutomationTitle = 'Upsell/QSP Automation',
  createTaskqueueTab = 'Create/Update Taskqueues';
/**
 *
 * @param {String} routeName
 * @param {String} friendlyName
 * @param {String} kibanaEventName
 * @param {String} adGroup
 */
class NavigationRouteProps {
  constructor(routeName, friendlyName, Component, adGroup = []) {
    this.routeName = routeName;
    this.friendlyName = friendlyName;
    this.Component = Component;
    this.adGroup = adGroup;
  }
}

export const adminPageSubRouteList = [
  new NavigationRouteProps(CANCEL_TASK_PAGE_SLUG, cancelTaskTab, CancelTask, [
    workforceAdminAdGroupKey,
    orbitEditorAdGroupKey,
    orbitServiceDeskAdGroupKey,
  ]),
  new NavigationRouteProps(
    LOCATION_ROUTING_PAGE_SLUG,
    locationBasedRoutingTab,
    LocationBasedRoutingTab,
    [
      orbitRestoreAdGroupKeyReadOnly,
      orbitEditorAdGroupKeyReadOnly,
      workforceAdminAdGroupKey,
      orbitEditorAdGroupKey,
    ],
  ),
  new NavigationRouteProps(
    ROLLBACK_CHANGES_PAGE_SLUG,
    rollBackUpdatesTitle,
    RollBackUpdates,
    [workforceAdminAdGroupKey, orbitRestoreAdGroupKeyReadOnly],
  ),
  new NavigationRouteProps(
    UPSELL_QSP_AUTOMATION_SLUG,
    upsellQSPAutomationTitle,
    UploadCSVButton,
    [
      workforceAdminAdGroupKey,
      orbitUploadAdGroupKey,
      orbitRestoreAdGroupKeyReadOnly,
      orbitTAAdGroupKey,
    ],
  ),
  new NavigationRouteProps(
    CREATE_TASKQUEUE_PAGE_SLUG,
    createTaskqueueTab,
    CreateTaskqueForm,
    [workforceAdminAdGroupKey, orbitTAAdGroupKey],
  ),
];
export const mainNavRouteList = [
  new NavigationRouteProps(
    WORKFORCE_PAGE_SLUG,
    'Workforce',
    PartnerListContainerComponent,
  ),
  new NavigationRouteProps(OPERATIONS_PAGE_SLUG, 'Operations', ExpertStatus),
  new NavigationRouteProps(
    GLOBAL_STATS_PAGE_SLUG,
    'Global',
    GlobalStatsComponent,
  ),
  new NavigationRouteProps(UBIF_FORM_PAGE, 'UBIF', UbifComponent, [
    workforceAdminAdGroupKey,
    ubifAdminAdGroupKey,
    orbitRestoreAdGroupKeyReadOnly,
  ]),
  new NavigationRouteProps(
    EXPERT_ATTR_PAGE_SLUG,
    'Attributes',
    UserListContainerComponent,
    [
      orbitEditorAdGroupKey,
      orbitRestoreAdGroupKeyReadOnly,
      orbitEditorAdGroupKeyReadOnly,
      workforceAdminAdGroupKey,
      orbitUploadAdGroupKey,
    ],
  ),
  new NavigationRouteProps(
    ADMIN_PAGE_SLUG,
    'Admin',
    () => (
      <ProtectedRoutes
        mainPath={ADMIN_PAGE_SLUG}
        links={adminPageSubRouteList}
      />
    ),
    [
      workforceAdminAdGroupKey,
      orbitEditorAdGroupKey,
      orbitRestoreAdGroupKeyReadOnly,
      orbitEditorAdGroupKeyReadOnly,
      orbitUploadAdGroupKey,
      orbitTAAdGroupKey,
      orbitServiceDeskAdGroupKey,
    ],
  ),
];
export const healthCheckRouteList = [
  new NavigationRouteProps(HEALTH_CHECK_PAGE_SLUG, 'Health Check'),
];

export const SECTION_CLASS = 'section',
  HEADER_ACTION_CLASS = 'header-group-actions',
  CONTAINER_CLASS = 'container',
  HEADER_BOLD = 'bold-header';

// GQL key constants
export const gqlOnShoreKey = 'onshorePPX',
  gqlOnshoreNonPPXKey = 'onshoreNonPPX',
  gqlOutsourcedOnshoreKey = 'outsourcedOnshore',
  gqlOffshoreKey = 'offshore',
  gqlOutsourcedOffshoreKey = 'outsourcedOffshore';
