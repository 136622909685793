import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { Box, Divider, Paper, Typography } from '@mui/material';
import {
  StickyTaskDataTableCell,
  StickyTaskQueueNameTableCell,
} from '../../WorkForceExpandedComponent';
import {
  formattedFullInterval,
  SetChart,
  updateGlobalCallStatsHourly,
  updateGlobalCallStatsSummary,
} from './transformations';
import { ChartDataModel, DEFAULT_FUNCTION } from '../../../data/datatypes';
import {
  dateToFormattedAwsDate,
  getFormattedTime,
  sortDatesInAscendingOrder,
} from '../../../transformations/utils';
import {
  listContactCenterStats,
  onUpdateGlobalCallStats,
} from '../../../data/queries';
import { API } from 'aws-amplify';
import { globalCallIntervalStatsResponseToState } from '../../../reducers/TableDataReducer';
import { FAIULER_PALETTE, SUCCESS_PALETTE } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './dateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { ChartHeader } from '../../GlobalCharts';
import { ConditionalDisplay } from '../../ConditionalDisplay';

/**
 * Subscribes client to updates to today's summary stats
 * @param {Dispatch} dispatch
 * @returns Subscription object
 */
export const subscribeToSummary = (dispatch) => {
  return API.graphql({
    query: onUpdateGlobalCallStats,
    variables: { Date: dateToFormattedAwsDate() },
  }).subscribe({
    next: ({ value }) => {
      const stats = JSON.parse(value?.data?.onUpdateContactCenterStats?.Stats);
      console.log('[ Global Stats Summary Subscription Update ] - ', stats);
      const updatedAt = getFormattedTime(
        value?.data?.onUpdateContactCenterStats?.updatedAt,
      )();
      const [
        deflectionsObj,
        SystemDisconnectsObj,
        CustomerDisconnectsObj,
        avayaTransfersObj,
        callsToIVRObj,
        offered,
        accepted,
        abandoned,
      ] = stats;

      updateGlobalCallStatsSummary(
        dispatch,
        deflectionsObj,
        SystemDisconnectsObj,
        CustomerDisconnectsObj,
        avayaTransfersObj,
        callsToIVRObj,
        accepted,
        offered,
        abandoned,
        updatedAt,
      );
    },
    error: (error) => {
      console.warn(`Subscription To Summary Stats Failed! - `, error);
    },
  });
};

/**
 * Subscribes client to today's hourly stats
 * @param {Dispatch} dispatch
 * @returns Subscription object
 */
export const subscribeToHourly = (dispatch) =>
  API.graphql({
    query: onUpdateGlobalCallStats,
    variables: { Date: dateToFormattedAwsDate() },
  }).subscribe({
    next: ({ value }) => {
      const stats = JSON.parse(value?.data?.onUpdateContactCenterStats?.Stats);
      console.log('[ Global Stats Hourly Subscription Update ] - ', stats);
      const [abandoned, offered, accepted] = stats.reverse();

      updateGlobalCallStatsHourly(dispatch, accepted, offered, abandoned);
    },
    error: (error) => {
      console.warn(`Subscription To Hourly Stats Failed! - `, error);
    },
  });

/**
 * Called on page load and any time the user selects a different date
 * Sets data global call stats and subscribes to relevant queries
 * @param {Array} callIntervalSearchDate
 * @param {Dispatch} dispatch
 */
export const globalCallStatsAPIFetch = async (
  callIntervalSearchDate = [dateToFormattedAwsDate(), dateToFormattedAwsDate()],
  dispatch = DEFAULT_FUNCTION,
) => {
  // "EventType": "Accepted"
  // "EventType": "Offered"
  // "EventType": "Canceled"
  // "EventType": "System Disconnects"
  // "EventType": "Customer Disconnects"
  // "EventType": "Deflections"
  // "EventType": "Avaya Transfers"
  // "EventType": "Calls to IVR"

  // Get initial data
  await API.graphql({
    query: listContactCenterStats,
    variables: {
      between: [
        callIntervalSearchDate[0] ?? dateToFormattedAwsDate(),
        callIntervalSearchDate[1] ?? dateToFormattedAwsDate(),
      ],
    },
  })
    .then(async (response) => {
      if (callIntervalSearchDate[0] !== callIntervalSearchDate[1]) {
        const allStats = response?.data?.listContactCenterStats?.items?.reduce(
          reduceGlobalCallStatsToDays,
          [],
        );
        const dayObjects = allStats
          ?.map(mapGlobalCallsToDays)
          ?.sort((a, b) => sortDatesInAscendingOrder(a.timeSlot, b.timeSlot));
        console.log(`[ Global Call Multi-Day Stats ] - `, dayObjects);
        dispatch(globalCallIntervalStatsResponseToState(dayObjects));
      }

      if (callIntervalSearchDate[0] === callIntervalSearchDate[1]) {
        const stats =
          JSON.parse(response?.data?.listContactCenterStats?.items[0]?.Stats) ??
          [];
        const [
          deflectionsObj,
          SystemDisconnectsObj,
          CustomerDisconnectsObj,
          avayaTransfersObj,
          callsToIVRObj,
          offered,
          accepted,
          abandoned,
        ] = stats;
        console.log('[ Global Call Summary Stats ] - ', stats);
        updateGlobalCallStatsHourly(dispatch, accepted, offered, abandoned);
        if (callIntervalSearchDate[0] === dateToFormattedAwsDate()) {
          const updatedAt = getFormattedTime(
            response?.data?.listContactCenterStats?.items[0]?.updatedAt,
          )();
          updateGlobalCallStatsSummary(
            dispatch,
            deflectionsObj,
            SystemDisconnectsObj,
            CustomerDisconnectsObj,
            avayaTransfersObj,
            callsToIVRObj,
            accepted,
            offered,
            abandoned,
            updatedAt,
          );
        }
      }
    })
    .catch((error) => console.error(`Failed To Get Initial Data! - `, error));
};

/**
 * Reduces global stats when user choose multiple days
 * @param {Array} acc
 * @param {Object} curr
 * @returns Array of objects in format needed for ReCharts
 */
export const reduceGlobalCallStatsToDays = (acc = [], curr) => {
  const dataObj = {
    stats: JSON.parse(curr?.Stats) ?? [],
    Date: curr?.Date,
  };
  acc.push(dataObj);
  return acc;
};

/**
 * Maps total counts for calls to object structure that can
 * be used in ReCharts
 * @param {Object} item
 * @returns Array of data objects
 */
export const mapGlobalCallsToDays = (item = {}) => {
  const [abandoned = {}, accepted = {}, offered = {}] = item?.stats.reverse();

  const dateObj = {
    callsOffered: offered?.TotalCount,
    callsAnswered: accepted?.TotalCount,
    callsAbandoned: abandoned?.TotalCount,
    timeSlot: item?.Date,
  };

  return dateObj;
};

/**
 * @param {String} time
 * @param {String} totalCalls
 * @param {String} callsAnswered
 * @param {String} callsAbandoned
 * @returns Full row for call stats table
 */
export const GlobalCallstatsTableData = ({
  time = '',
  totalCalls = '',
  callsAnswered = '',
  callsAbandoned = '',
} = {}) => {
  const theme = useTheme();
  const formattedTime = formattedFullInterval(time.split(' - '));
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'neutral.background',
          display: 'flex',
          flexDirection: 'row',
          width: 'inherit',
          flex: 1,
        }}
      >
        <StickyTaskQueueNameTableCell
          sx={{
            paddingLeft: '7px',
            backgroundImage: 'none',
            backgroundColor: 'neutral.background',
            flex: 1,
            textAlign: 'center',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        >
          {formattedTime}
        </StickyTaskQueueNameTableCell>
        <StickyTaskDataTableCell
          sx={{
            flex: 1,
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        >
          {totalCalls}
        </StickyTaskDataTableCell>
        <StickyTaskDataTableCell
          sx={{
            flex: 1,
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        >
          {callsAnswered}
        </StickyTaskDataTableCell>
        <StickyTaskDataTableCell
          sx={{
            flex: 1,
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        >
          {callsAbandoned}
        </StickyTaskDataTableCell>
      </Box>
    </>
  );
};

/**
 * Container for global call stats chart
 * @param {Array} Boxes
 * @returns Container for global call stats hourly chart and summary chart
 */
export const GlobalCallStatsChartsComponent = ({ Boxes }) => {
  const dispatch = useDispatch();
  const { summaryStats, intervalStats, updatedAt } = useSelector(
    (state) => state?.tableData?.globalCallStats,
  );
  const theme = useTheme();
  const [searchDate, setSearchDate] = useState([new Date(), new Date()]);
  const [formattedSearchDate, setFormattedSearchDate] = useState([
    dateToFormattedAwsDate(),
    dateToFormattedAwsDate(),
  ]);
  const [interval, setInterval] = useState(dateToFormattedAwsDate());

  const getData = useCallback(async () => {
    await globalCallStatsAPIFetch(formattedSearchDate, dispatch);
  }, [globalCallStatsAPIFetch, formattedSearchDate, dispatch]);

  const onDateChange = (value) => {
    if (!value) {
      value = [new Date(), new Date()];
    }
    setSearchDate(value);
    const formattedDate = [
      dateToFormattedAwsDate(value[0]),
      dateToFormattedAwsDate(value[1]),
    ];
    if (formattedDate[0] === formattedDate[1]) {
      setInterval(`${formattedDate[0].replaceAll('-', '/')}`);
    } else {
      setInterval(
        `${formattedDate[0].replaceAll(
          '-',
          '/',
        )} to ${formattedDate[1].replaceAll('-', '/')}`,
      );
    }

    setFormattedSearchDate(formattedDate);
  };

  useEffect(() => {
    const summarySub = subscribeToSummary(dispatch);
    let hourlySub;

    getData();
    if (formattedSearchDate[0] === dateToFormattedAwsDate()) {
      hourlySub = subscribeToHourly(dispatch);
    }

    return () => {
      if (summarySub) summarySub.unsubscribe();
      if (hourlySub) hourlySub.unsubscribe();
    };
  }, [formattedSearchDate]);
  const dailyData = [
    new ChartDataModel('Pie Chart', 'Pie', [
      {
        name: 'Answered',
        value: summaryStats?.callsAnswered ?? 0,
        color: SUCCESS_PALETTE,
      },
      {
        name: 'Abandoned',
        value: summaryStats?.callsAbandoned ?? 0,
        color: FAIULER_PALETTE,
      },
    ]),
  ];

  const dataForCharts = intervalStats;

  const hourlyData = [new ChartDataModel('Bar Chart', 'Bar', dataForCharts)];
  const dailyCharts = dailyData?.map(SetChart);
  const hourlyCharts = hourlyData?.map(SetChart);

  return (
    <Paper
      sx={{
        flex: '1 1',
        ...theme.palette.chartsContainer,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: '1 1',
          border: 1,
          flexDirection: 'column',
          ...theme.palette.charts,
        }}
      >
        <ChartHeader header={`Call Stats - ${interval}`} />
        {hourlyCharts}
        <ConditionalDisplay bool={true}>
          <Box sx={{ marginTop: '7px' }}>
            <DateRangePicker
              onChange={onDateChange}
              value={searchDate}
              maxDetail="month"
              minDate={new Date('March 23, 2023')}
              maxDate={new Date()}
              showLeadingZeros={true}
            />
          </Box>
        </ConditionalDisplay>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: '.3 1',
          alignItems: 'center',
          border: 1,
          justifyContent: 'space-between',
          ...theme.palette.charts,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <ChartHeader header={'Inbound call stats - Today'} size="h6" />
              {dailyCharts}
            </Box>
            {Boxes}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', marginTop: 1 }}>
          <Typography whiteSpace="nowrap">
            Updated:{' '}
            <Typography color="primary" variant="span">
              {updatedAt}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
