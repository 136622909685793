import React, { useCallback, useEffect } from 'react';
import {
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Input,
  Stack,
  Box,
  styled,
} from '@mui/material';
import ButtonOpenDialog from '../../ButtonOpenDialog';
import {
  eventTargetHandler,
  compareArrayDifferencesWithObjKeyFind,
  isEqual,
  addAllNumberParamsTotal,
  keyValuePairToMappedWithObject,
  paramsToAllParamsAreTrue,
  paramsToAtleastOneParamIsTrue,
  keyStringValueForLoopCheck,
  stringArrayToNumberArray,
  textToArrayOfLineBreaks,
  textToArrayOfTableCells,
  updateObjectKeysBasedOnLoopIndex,
  stringToNumber,
  isHourMinuteGreaterThanHourMinute,
  dateToFormattedAwsDate,
  firstDateToIsSecondDateBefore,
  firstDateToIsSecondDateSameDay,
} from '../../../transformations/utils';
import {
  fetchLocationRoutingSchedulerWithDate,
  updateLocationRoutingScheduledTimeslot,
} from '../services';
import {
  checkIfSubtypeIncluded,
  removeKeysFromArrOfObj,
  removeMissingAllocations,
} from '../transformations';
import { ErrorWithTooltip, allParamsEqual100 } from '../index';
import { useLoggingAnalytics } from '../../../services/KibanaEvents';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { DEFAULT_FUNCTION } from '../../../data/datatypes';
import AuthConditionalRender from '../../AuthConditionalRender';
import {
  offshoreSortKey,
  onShoreBamSortKey,
  onShoreWahSortKey,
  outsourcedOffshoreSortKey,
  outSourceOnShoreSortKey,
} from '../../../reducers/TableDataReducer/constants';
import {
  gqlOnShoreKey,
  gqlOnshoreNonPPXKey,
  gqlOutsourcedOnshoreKey,
  gqlOffshoreKey,
  gqlOutsourcedOffshoreKey,
} from '../../../data/constants';

const ColoredStickyTableHeader = styled(TableHead)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 9999,
  background: theme.palette.background.paper,
}));
const LocationRoutingAttributeMUITableHeaders = () => (
  <ColoredStickyTableHeader>
    <TableRow>
      <TableCell>Time Slot</TableCell>
      <TableCell align="center">Onshore BAM</TableCell>
      <TableCell align="center">Onshore WAH</TableCell>
      <TableCell align="center">Outsourced Onshore</TableCell>
      <TableCell align="center">Offshore</TableCell>
      <TableCell align="center">Outsourced Offshore</TableCell>
    </TableRow>
  </ColoredStickyTableHeader>
);
const LocationRoutingTableCell = ({
  error,
  value,
  fx = DEFAULT_FUNCTION,
  pasteFx = DEFAULT_FUNCTION,
  disabled = false,
}) => {
  return (
    <TableCell align="center" sx={{ padding: '0px 8px' }}>
      <Input
        disabled={disabled}
        error={error}
        inputProps={{ min: 0, max: 100 }}
        type="number"
        className="hide-number-changer"
        value={value}
        onChange={eventTargetHandler(fx)}
        onPaste={disabled ? DEFAULT_FUNCTION : pasteFx}
      />
    </TableCell>
  );
};

/**
 * pass on paste event to get back the clip board of rows copied
 * @param {Object} onPasteEvent
 * @returns {any[]} An array of nested Arrays where each nested array is an individual row
 */
export const clipBoardPasteToArrayOfColumnArrayNumbers = (
  onPasteEvent = {},
) => {
  const copiedCsv = onPasteEvent?.clipboardData?.getData('text/plain');
  const individualRows = textToArrayOfLineBreaks(copiedCsv);
  return individualRows.map((e) =>
    stringArrayToNumberArray(textToArrayOfTableCells(e)),
  );
};

/**
 * Use forEach to update the timeslots
 * @param {Number} pastedRowIndex
 * @param {Array} timeSlots
 * @param {String} column
 * @returns nothing. This only updates the timeSlots
 */
export const multiColRowTimeSlotUpdates =
  (pastedRowIndex, timeSlots, column) => (numberArray, index) => {
    const numberArrayLength = numberArray?.length;
    const updateCopyArrayIndex = addAllNumberParamsTotal(pastedRowIndex, index);
    let updatedLocationRouting = {
      ...(timeSlots?.[updateCopyArrayIndex] ?? {}),
    };
    const orderedLocationRoutingTableColumns = [
      gqlOnShoreKey,
      gqlOnshoreNonPPXKey,
      gqlOutsourcedOnshoreKey,
      gqlOffshoreKey,
      gqlOutsourcedOffshoreKey,
    ];
    let keyedStringColumns = isEqual(index, 0)
      ? orderedLocationRoutingTableColumns.slice(
          orderedLocationRoutingTableColumns.indexOf(column),
        )
      : orderedLocationRoutingTableColumns;
    keyedStringColumns =
      keyedStringColumns?.length > numberArrayLength
        ? keyedStringColumns.slice(0, numberArrayLength)
        : keyedStringColumns;
    // Modifying the timeSlot here...
    keyedStringColumns.forEach(
      updateObjectKeysBasedOnLoopIndex(updatedLocationRouting, numberArray),
    );
    // do not update, if the user copied more rows than we have left
    if (timeSlots.length >= addAllNumberParamsTotal(updateCopyArrayIndex, 1)) {
      timeSlots[updateCopyArrayIndex] = updatedLocationRouting;
    }
  };

/**
 * Update Timeslots with Users clip board
 * @param {String} timeSlot
 * @param {String} column
 * @param {Object} event
 * @returns
 */
export const updateTimeSlotsWithClipBoard =
  (timeSlot, column, event) => (originalTimeSlots) => {
    const sequentialRowChangesFromPaste =
      clipBoardPasteToArrayOfColumnArrayNumbers(event);
    const copy = [...originalTimeSlots];
    const pastedRowIndex = copy.findIndex(
      keyStringValueForLoopCheck('timeSlot', timeSlot),
    );
    sequentialRowChangesFromPaste.forEach(
      multiColRowTimeSlotUpdates(pastedRowIndex, copy, column),
    );
    return copy;
  };

/**
 * Update individual columns for individual timeslots
 * @param {String} timeSlot
 * @param {String} key
 * @param {Number|String} value
 * @returns
 */
export const singleTimeSlotKeyPairToTimeslotsUpdate =
  (timeSlot, key, value) => (allTimeSlots) => {
    const index = allTimeSlots.findIndex(
      keyStringValueForLoopCheck('timeSlot', timeSlot),
    );
    const updatedStateValue = keyValuePairToMappedWithObject(
      key,
      stringToNumber(value),
    )(allTimeSlots?.[index] ?? {});
    const copy = [...allTimeSlots];
    copy.splice(index, 1, updatedStateValue);
    return copy;
  };
const LocationRoutingSingleTimeslot = ({
  setTimeslots,
  selectedDateIsHistoric,
  selectedDateIsToday,
  timeSlotNow,
  hasEditPermissions,
  SubTargetAllocations,
  ...props
}) => {
  const { timeSlot } = props;
  const timeSlotHasPassedToday = paramsToAllParamsAreTrue(
    selectedDateIsToday,
    isHourMinuteGreaterThanHourMinute(timeSlotNow, timeSlot),
  );
  const disabledInputs = paramsToAtleastOneParamIsTrue(
    selectedDateIsHistoric,
    timeSlotHasPassedToday,
    !hasEditPermissions,
  );

  const handleValueLocationChange = (key) => (value) => {
    setTimeslots(singleTimeSlotKeyPairToTimeslotsUpdate(timeSlot, key, value));
  };

  const handlePasteFromCSVFile = (column) => (event) => {
    event.preventDefault();
    setTimeslots(updateTimeSlotsWithClipBoard(timeSlot, column, event));
  };

  const onshoreValue = props[gqlOnShoreKey] ?? 0;
  const onshoreNonPPXValue = props[gqlOnshoreNonPPXKey] ?? 0;
  const outsourcedOnshoreValue = props[gqlOutsourcedOnshoreKey] ?? 0;
  const offshoreValue = props[gqlOffshoreKey] ?? 0;
  const outsourcedOffshoreValue = props[gqlOutsourcedOffshoreKey] ?? 0;
  const showError =
    !allParamsEqual100(
      onshoreValue,
      onshoreNonPPXValue,
      outsourcedOnshoreValue,
      offshoreValue,
      outsourcedOffshoreValue,
    ) && !timeSlotHasPassedToday;
  return (
    <TableRow>
      <TableCell sx={{ padding: '0px 8px' }}>
        <Stack
          justifyContent={'center'}
          alignItems="center"
          flexDirection="row"
        >
          {showError ? <ErrorWithTooltip /> : <></>}
          {timeSlot}
        </Stack>
      </TableCell>
      <LocationRoutingTableCell
        disabled={
          disabledInputs ||
          !checkIfSubtypeIncluded(SubTargetAllocations, onShoreBamSortKey)
        }
        error={showError}
        value={onshoreValue}
        fx={handleValueLocationChange(gqlOnShoreKey)}
        pasteFx={handlePasteFromCSVFile(gqlOnShoreKey)}
      />
      <LocationRoutingTableCell
        disabled={
          disabledInputs ||
          !checkIfSubtypeIncluded(SubTargetAllocations, onShoreWahSortKey)
        }
        error={showError}
        value={onshoreNonPPXValue}
        fx={handleValueLocationChange(gqlOnshoreNonPPXKey)}
        pasteFx={handlePasteFromCSVFile(gqlOnshoreNonPPXKey)}
      />
      <LocationRoutingTableCell
        disabled={
          disabledInputs ||
          !checkIfSubtypeIncluded(SubTargetAllocations, outSourceOnShoreSortKey)
        }
        error={showError}
        value={outsourcedOnshoreValue}
        fx={handleValueLocationChange(gqlOutsourcedOnshoreKey)}
        pasteFx={handlePasteFromCSVFile(gqlOutsourcedOnshoreKey)}
      />
      <LocationRoutingTableCell
        disabled={
          disabledInputs ||
          !checkIfSubtypeIncluded(SubTargetAllocations, offshoreSortKey)
        }
        error={showError}
        value={offshoreValue}
        fx={handleValueLocationChange(gqlOffshoreKey)}
        pasteFx={handlePasteFromCSVFile(gqlOffshoreKey)}
      />
      <LocationRoutingTableCell
        disabled={
          disabledInputs ||
          !checkIfSubtypeIncluded(
            SubTargetAllocations,
            outsourcedOffshoreSortKey,
          )
        }
        error={showError}
        value={outsourcedOffshoreValue}
        fx={handleValueLocationChange(gqlOutsourcedOffshoreKey)}
        pasteFx={handlePasteFromCSVFile(gqlOutsourcedOffshoreKey)}
      />
    </TableRow>
  );
};
const LocationRoutingTimeslots = ({
  timeSlots,
  SubTargetAllocations,
  ...parentProps
}) => (
  <TableBody>
    {timeSlots?.map((props) => (
      <LocationRoutingSingleTimeslot
        key={props?.timeSlot}
        SubTargetAllocations={SubTargetAllocations}
        {...parentProps}
        {...props}
      />
    ))}
  </TableBody>
);

export const ChooseADate = ({ searchDate, setSearchDate }) => {
  return (
    <Box height="10%" minHeight="70px">
      <TextField
        label="Choose A Date"
        type="date"
        defaultValue={searchDate}
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => {
          setSearchDate(event?.target?.value);
        }}
      />
    </Box>
  );
};

export const DisplayRoutingSchedule = ({
  searchDate,
  setSearchDate,
  SubTargetAllocations,
  ...timeslotDisplayProps
}) => {
  return (
    <Stack paddingTop="10px" gap={2} height="100%">
      <ChooseADate searchDate={searchDate} setSearchDate={setSearchDate} />
      <TableContainer component={Paper} sx={{ maxHeight: '90%' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <LocationRoutingAttributeMUITableHeaders />
          <LocationRoutingTimeslots
            {...timeslotDisplayProps}
            SubTargetAllocations={SubTargetAllocations}
          />
        </Table>
      </TableContainer>
    </Stack>
  );
};
export const addUpAllNumberValuedKeys = (obj) =>
  Object.keys(obj)?.reduce((acc, key) => {
    const value = obj[key];
    if (typeof value !== 'number') return acc;
    acc += value;
    return acc;
  }, 0);
export const numToObjectKeysNotEqToNum = (num) => (obj) =>
  num !== addUpAllNumberValuedKeys(obj);

export const filterPassedDays = (selectedDateIsToday, timeSlotNow) => (e) =>
  paramsToAllParamsAreTrue(
    selectedDateIsToday,
    !isHourMinuteGreaterThanHourMinute(timeSlotNow, e?.timeSlot),
  );

const LocationBasedRoutingScheduler = ({
  client,
  hasEditPermissions,
  SubTargetAllocations,
}) => {
  const logAnalytics = useLoggingAnalytics('Scheduled Location Routing Change');

  const [today] = React.useState(dateToFormattedAwsDate());
  const [selectedDateIsHistoric, setSelectedDateIsHistoric] =
    React.useState(true);
  const [selectedDateIsToday, setSelectedDateIsToday] = React.useState(true);
  const [searchDate, setSearchDate] = React.useState(dateToFormattedAwsDate());

  const timeSlotNow = momentTz().tz('America/Chicago').format('HH:mm');

  const [unChangedTimeSlots, setUnChangedTimeSlots] = React.useState([]);
  const [timeSlots, setTimeslots] = React.useState([]);
  const asyncFetchData = useCallback(
    async (open) => {
      if (open) {
        const res = await fetchLocationRoutingSchedulerWithDate(
          searchDate,
          client,
        ).then(removeMissingAllocations(SubTargetAllocations));

        console.log(`Location Routing Scheduler (${searchDate})`, res);
        setTimeslots(res);
        setUnChangedTimeSlots(res);
        const selectedDateIsHistoric = firstDateToIsSecondDateBefore(
          searchDate,
          today,
        );
        const selectedDateIsToday = firstDateToIsSecondDateSameDay(
          searchDate,
          today,
        );
        setSelectedDateIsHistoric(selectedDateIsHistoric);
        setSelectedDateIsToday(selectedDateIsToday);
      } else {
        setSearchDate(dateToFormattedAwsDate());
        setSelectedDateIsHistoric(true);
        setSelectedDateIsToday(true);
      }
    },
    [searchDate, setSearchDate],
  );

  const submitAllTimeSlotChanges = useCallback(async () => {
    const updatedTimeslots = compareArrayDifferencesWithObjKeyFind(
      'timeSlot',
      unChangedTimeSlots,
      timeSlots,
    );
    const logger = async (status) => {
      await logAnalytics({
        scheduledDate: searchDate,
        timeSlots: updatedTimeslots,
        status,
      });
    };
    await updateLocationRoutingScheduledTimeslot(updatedTimeslots, logger);
  }, [timeSlots, searchDate]);
  const disabledSubmit = paramsToAtleastOneParamIsTrue(
    !timeSlots?.length,
    !hasEditPermissions,
    timeSlots
      ?.filter(filterPassedDays(selectedDateIsToday, timeSlotNow))
      ?.some(numToObjectKeysNotEqToNum(100)),
  );

  return (
    <ButtonOpenDialog
      cb={submitAllTimeSlotChanges}
      asyncFetchData={asyncFetchData}
      title={`${client} - Location Routing Schedule`}
      disabledSubmit={disabledSubmit}
      toolTipTitle="Scheduler"
      Render={
        <DisplayRoutingSchedule
          timeSlots={timeSlots}
          setTimeslots={setTimeslots}
          searchDate={searchDate}
          setSearchDate={setSearchDate}
          selectedDateIsHistoric={selectedDateIsHistoric}
          selectedDateIsToday={selectedDateIsToday}
          timeSlotNow={timeSlotNow}
          hasEditPermissions={hasEditPermissions}
          SubTargetAllocations={SubTargetAllocations}
        />
      }
    />
  );
};
export default LocationBasedRoutingScheduler;
