import {
  ElasticSimpleQuery,
  ElasticMustySearch,
  GraphqlTableSort,
  ElasticSearchTerm,
} from './datatypes';
import {
  expertIntSet,
  expertArraySet,
  expertBooleanSet,
  expertStringSet,
  todayKeyMapToCi,
  ciKeys,
  expertNameSortKey,
  employeeIdSortKey,
} from './constants';
import { stringToNumber } from '../../transformations/utils';
import {
  inTimeToLapsedTime,
  stringToAppendedMinuteAnnotation,
} from '../ExpertReducer/transformations';
export const transformJsonString = (json) => {
  try {
    if (typeof json === 'string') {
      return JSON.parse(json);
    } else return json;
  } catch (err) {
    console.log('ERR ', err);
    return {};
  }
};

export const ciValueToValueToIntervalToDisplay =
  (ciValue) => (value) => (interval) =>
    interval === 'Today' ? value ?? 0 : ciValue ?? 0;
export const workernameToFirstLastNameRead = (name) =>
  name?.replace(/\./g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()) ?? 'Null';
/**
* Checks each top level expert field for nulls and overwrites with a default value to prevent errors
 * @param {Object} element  Object Value to be null checked
 * @param {Set} intSet
 * @param {Set} arraySet
 * @param {Set} booleanSet
 * @param {Set} stringSet
 @returns An object without nulls at the top level
 */
export const removeNullValues = (
  element,
  intSet = new Set([]),
  arraySet = new Set([]),
  booleanSet = new Set([]),
  stringSet = new Set([]),
) => {
  //TODO This should log to an console or an event to kibana to indicate which items were changed for each worker
  return Object.keys(element).reduce((acc, key) => {
    if (element[key] === null) {
      if (intSet?.has(key)) {
        acc[key] = 0;
      } else if (arraySet?.has(key)) {
        acc[key] = [];
      } else if (booleanSet?.has(key)) {
        acc[key] = false;
      } else if (stringSet?.has(key)) {
        acc[key] = '';
      } else {
        acc[key] = '';
      }
    } else {
      // @ts-ignore
      acc[key] = element[key];
    }

    return acc;
  }, {});
};
const HANDLE_BAD_DATA_NESTED_ROUTING_IN_ROUTING_TO_BE_REMOVED_ONCE_DATA_FIXED =
  ({ routing, ...other }) => (routing !== undefined ? routing : other);

//
//
// USED FOR CLONE EXPERT TRANSFORMATION
export const HANDLE_BAD_TASKQUEUE_DATA_TO_BE_REMOVED_ONCE_DATA_FIXED = (
  all,
) => {
  try {
    const {
      taskqueues: taskQueues = [],
      taskQueueLevel: taskQueueLevels = {},
      ...other
    } = HANDLE_BAD_DATA_NESTED_ROUTING_IN_ROUTING_TO_BE_REMOVED_ONCE_DATA_FIXED(
      all || {},
    );
    let modBadData = { ...other };

    if (taskQueues && taskQueues?.length) {
      modBadData = { ...modBadData, taskQueues };
    }
    if (taskQueueLevels && Object.keys(taskQueueLevels).length) {
      modBadData = { ...modBadData, taskQueueLevels };
    }
    return modBadData;
  } catch (e) {
    return all;
  }
};
const moveActivity = (activity) => {
  if (activity.length) {
    const actCopy = [...activity];
    actCopy.unshift(actCopy.pop());
    return actCopy;
  }
  return activity;
};
const graphQlExpertToTransformation = ({
  routing,
  workerName,
  activities = [],
  wandstats = [],
  statusIntime,
  ...other
} = {}) => {
  const name = workernameToFirstLastNameRead(workerName);
  const transformRouting =
    HANDLE_BAD_TASKQUEUE_DATA_TO_BE_REMOVED_ONCE_DATA_FIXED(
      transformJsonString(routing),
    );
  const transformActivities = moveActivity(
    activities?.map(transformJsonString) ?? [],
  );
  const wandTransform = wandstats?.map(transformJsonString) ?? [];
  const reverseWandStats = wandTransform.reverse();
  const taskQueueString = transformRouting?.taskQueues?.join(', ') ?? '';
  const statusTimeLapse = inTimeToLapsedTime(statusIntime);
  const readStatusTimeLapse = stringToAppendedMinuteAnnotation(statusTimeLapse);
  return {
    ...removeNullValues(
      other,
      expertIntSet,
      expertArraySet,
      expertBooleanSet,
      expertStringSet,
    ),
    statusTimeLapse,
    readStatusTimeLapse,
    name,
    key: name + (other?.worker_Id ?? ''),
    routing: transformRouting,
    activities: transformActivities,
    wand: reverseWandStats,
    taskQueueString,
  };
};
export const expertsToTransformExpertData = (graphQlExperts = []) =>
  graphQlExperts?.map(graphQlExpertToTransformation);

export const fieldSearchToSearchQueryFilter = (fieldSearch) => {
  // build appSync query object for search string
  if (!fieldSearch?.length) return {};
  const searchFields = [expertNameSortKey, employeeIdSortKey];
  const mustHave = [new ElasticSimpleQuery(fieldSearch, searchFields)];
  const mustNotHave = [new ElasticSearchTerm('nle', true)];
  return new ElasticMustySearch(mustHave, mustNotHave);
};

export const ifArrayReturnArg2ElseArg3 = (arg1, arg2, arg3) =>
  Array.isArray(arg1) ? arg2 : arg3;

/**
 * format filter to allow multi expert search w/ space delimiter
 * @param {*} fieldSearch
 */
export const fieldSearchToFilterString = (fieldSearch) =>
  fieldSearch
    .replace(/[,;]/g, '')
    .split(' ')
    .filter((n) => n)
    .join('* | ') + '*';

const stringArrayToOnlyCapitalizeFirstLetter = (array = []) =>
  array?.map((string) => string.charAt(0).toUpperCase() + string?.slice?.(1)) ??
  [];
export const baseStringToArrayField = (fieldSearch) =>
  stringArrayToOnlyCapitalizeFirstLetter(
    fieldSearch
      .replace(/[,;]/g, '')
      .split(' ')
      .filter((n) => n),
  );

/**
 *
 * @param {Number} firstIndexOfView
 * @param {Number} lastIndexOfView
 * @param {Number} sumOfAll
 * @returns {String} a view string of what sequence data you are currently viewing
 */
export const generateTablePageString = (
  firstIndexOfView,
  lastIndexOfView,
  sumOfAll,
) =>
  `${firstIndexOfView} - ${
    lastIndexOfView > sumOfAll ? sumOfAll : lastIndexOfView
  } of ${sumOfAll}`;

export const zeroPageIndexAndLimitToFirstIndexInView = (zeroIndexPage, limit) =>
  !zeroIndexPage ? 1 : zeroIndexPage * limit;
export const firstIndexInViewAndLimitToLastIndexInView = (
  firstIndexInView,
  limit,
) => (firstIndexInView === 1 ? 0 : firstIndexInView) + limit;

export const functionToHandleIncomingSort =
  (fx) =>
  (field = '', direction = 'asc') => {
    const payload = new GraphqlTableSort(direction, field);
    fx(payload);
  };
export const numberMinusOne = (number = 0) => number - 1;

const removeWhiteSpaceWhenChecking = (curr, key) => (item) =>
  item?.[key]?.replaceAll(' ', '') === curr?.replaceAll(' ', '');

export const buildExpertAppSyncQueryFilter = ({
  lobFilter,
  groupFilter,
  statusFilter,
  coachFilter,
  coachSelection,
  siteFilter,
  taskQueueFilter,
  fieldSearch,
}) => {
  if (fieldSearch?.length) {
    return fieldSearchToSearchQueryFilter(fieldSearch);
  }
  const mustHave = [];
  const mustNotHave = [new ElasticSearchTerm('nle', true)];
  if (lobFilter?.length) {
    mustHave.push(new ElasticSearchTerm('lob', lobFilter));
  }
  if (groupFilter?.length) {
    mustHave.push(new ElasticSearchTerm('group', groupFilter));
  }
  if (statusFilter?.length) {
    mustHave.push(new ElasticSearchTerm('status', statusFilter));
  }
  if (coachFilter?.length) {
    const ids = coachFilter?.reduce((acc, curr) => {
      if (coachSelection?.some(removeWhiteSpaceWhenChecking(curr, 'name'))) {
        acc.push(
          coachSelection.find(removeWhiteSpaceWhenChecking(curr, 'name'))?.id,
        );
      }
      return acc;
    }, []);
    mustHave.push(new ElasticSearchTerm('supervisorId', ids));
  }
  if (siteFilter?.length) {
    mustHave.push(new ElasticSearchTerm('site', siteFilter));
  }
  if (taskQueueFilter?.length) {
    mustHave.push(new ElasticSearchTerm('routing.taskQueues', taskQueueFilter));
  }
  return new ElasticMustySearch(mustHave, mustNotHave);
};

export const sortToIntervalToHandleCi =
  (sort = {}) =>
  (interval = 'Today') => {
    const { field } = sort;
    if (ciKeys.includes(field)) {
      const intervalField =
        interval === 'Today' ? field : todayKeyMapToCi[field];
      return { ...sort, field: intervalField };
    } else {
      return sort;
    }
  };
export const expertTableResponseToThrowError = (response = {}) => {
  const {
    data: { searchWorkersWithESFilter: { nextToken, items, total } = {} } = {},
  } = response;
  if (nextToken === null && !items?.length && total === null) {
    throw 'no data';
  }
  return response;
};

export const updateDisplayNameWording = (lob, group) => {
  const lowercaseLob = lob?.toString()?.toLowerCase();
  const updatingCondition = ['salesforce']; // to remove future LOBs, insert LOB here in all lowercase
  if (updatingCondition.includes(lowercaseLob)) return group;
  else return `${lob} ${group}`;
};

const intervalToAppsyncWfToOrbitViewWf =
  (interval) =>
  ({
    group,
    lob,
    SLA,
    ciSLA,
    callsInQueue,
    cicallsInQueue,
    longestWait,
    cilongestWait,
    ciabandonRate,
    abandonRate,
    totalCalls,
    ciTotalCalls,
    ciExpertsAvailEn,
    ExpertsAvailEn,
    ciExpertsAvailBil,
    ExpertsAvailBil,
    ciASA,
    ciCRT,
    ASA,
    CRT,
    ...other
  } = {}) => {
    const displayName = updateDisplayNameWording(lob, group) ?? '';
    return {
      ...other,
      displayName,
      key: displayName,
      lob,
      group,
      sla: ciValueToValueToIntervalToDisplay(ciSLA)(SLA)(interval),
      callsInQ:
        ciValueToValueToIntervalToDisplay(cicallsInQueue)(callsInQueue)(
          interval,
        ),
      longestWait:
        ciValueToValueToIntervalToDisplay(cilongestWait)(longestWait)(interval),
      abandonRate:
        ciValueToValueToIntervalToDisplay(ciabandonRate)(abandonRate)(interval),
      totalCalls:
        ciValueToValueToIntervalToDisplay(ciTotalCalls)(totalCalls)(interval),
      expertsAvailEn:
        ciValueToValueToIntervalToDisplay(ciExpertsAvailEn)(ExpertsAvailEn)(
          interval,
        ),
      expertsAvailBil: ciValueToValueToIntervalToDisplay(
        stringToNumber(ciExpertsAvailBil),
      )(stringToNumber(ExpertsAvailBil))(interval),
      asa: ciValueToValueToIntervalToDisplay(ciASA)(ASA)(interval),
      crt: ciValueToValueToIntervalToDisplay(ciCRT)(CRT)(interval),
    };
  };
export const graphQlWorkforceToViewData = (
  selectedInterval = 'Today',
  { items = [], ...other } = {},
) => {
  const refactorForWorkforceView = items.map(
    intervalToAppsyncWfToOrbitViewWf(selectedInterval),
  );
  return { items: refactorForWorkforceView, ...other };
};

const transformGraphQlTaskQ =
  (interval) =>
  (taskQData = {}) => {
    const {
      taskqueueName = '',
      taskqueueSid = '',
      eventStats,
      queueStats,
    } = taskQData ?? {};
    const {
      SLLessShortAbandoned,
      ciSLLessShortAbandoned,
      ciSLOffered,
      SLOffered,
      ciATA,
      ATA,
      callsAbandoned,
      ciCallsAbandoned,
      CRT,
      ciCRT,
      AHT,
      ciAHT,
      ciTransferredCalls,
      transferredCalls,
      ASA,
      SLAnswered,
      callsAnswered,
      callsAnsweredInSL,
      callsInQueue,
      callsOffered,
      ciASA,
      ciCallsAnswered,
      ciCallsAnsweredInSL,
      ciCallsInQueue,
      ciCallsOffered,
      ciLongestwait,
      ciMaxwait,
      ciSLAnswered,
      longestWait,
      maxWait,
      expertDetails = {},
    } = queueStats ?? {};
    const outboundCalls =
      eventStats?.SLCallCounts?.items?.find(
        ({ isCI, isOutbound }) => isOutbound === 'T' && isCI === 'F',
      )?.T150 ?? 0;
    const ciOutboundCalls =
      eventStats?.SLCallCounts?.items?.find(
        ({ isCI, isOutbound }) => isOutbound === 'T' && isCI === 'T',
      )?.T150 ?? 0;
    const {
      ciExpertsAvailable,
      expertsAvailable,
      ciExpertsInAux,
      expertsInAux,
      ciExpertsWorking,
      ciExpertsStaffed,
      expertsStaffed,
      expertsWorking,
    } = expertDetails ?? {};
    return {
      queueName: taskqueueName,
      taskqueueSid,
      callsInQueue:
        ciValueToValueToIntervalToDisplay(ciCallsInQueue)(callsInQueue)(
          interval,
        ),
      callsAbandoned:
        ciValueToValueToIntervalToDisplay(ciCallsAbandoned)(callsAbandoned)(
          interval,
        ),
      callsAnswered:
        ciValueToValueToIntervalToDisplay(ciCallsAnswered)(callsAnswered)(
          interval,
        ),
      callsAnsweredInSl:
        ciValueToValueToIntervalToDisplay(ciCallsAnsweredInSL)(
          callsAnsweredInSL,
        )(interval),
      callsOffered:
        ciValueToValueToIntervalToDisplay(ciCallsOffered)(callsOffered)(
          interval,
        ),
      callsTransferred:
        ciValueToValueToIntervalToDisplay(ciTransferredCalls)(transferredCalls)(
          interval,
        ),
      outboundCalls:
        ciValueToValueToIntervalToDisplay(ciOutboundCalls)(outboundCalls)(
          interval,
        ),
      asa: ciValueToValueToIntervalToDisplay(ciASA)(ASA)(interval),
      ata: ciValueToValueToIntervalToDisplay(ciATA)(ATA)(interval),
      longestWait:
        ciValueToValueToIntervalToDisplay(ciLongestwait)(longestWait)(interval),
      maxWait: ciValueToValueToIntervalToDisplay(ciMaxwait)(maxWait)(interval),
      crt: ciValueToValueToIntervalToDisplay(ciCRT)(CRT)(interval),
      aht: ciValueToValueToIntervalToDisplay(ciAHT)(AHT)(interval),
      expertsAvailable:
        ciValueToValueToIntervalToDisplay(ciExpertsAvailable)(expertsAvailable)(
          interval,
        ),
      expertsInAux:
        ciValueToValueToIntervalToDisplay(ciExpertsInAux)(expertsInAux)(
          interval,
        ),
      expertsStaffed:
        ciValueToValueToIntervalToDisplay(ciExpertsStaffed)(expertsStaffed)(
          interval,
        ),
      expertsWorking:
        ciValueToValueToIntervalToDisplay(ciExpertsWorking)(expertsWorking)(
          interval,
        ),
      slAnswered:
        ciValueToValueToIntervalToDisplay(ciSLAnswered)(SLAnswered)(interval),
      slLessShortAbandoned: ciValueToValueToIntervalToDisplay(
        ciSLLessShortAbandoned,
      )(SLLessShortAbandoned)(interval),
      slOffered:
        ciValueToValueToIntervalToDisplay(ciSLOffered)(SLOffered)(interval),
    };
  };
export const graphQlWorkforceTaskQueuesToViewData = (
  selectedInterval = 'Today',
  { data: { listLOBGroupMaps: { items = [] } = {} } = {} } = {},
) => {
  const firstIndexedItems = items?.[0]?.taskqueues?.items ?? [];
  return firstIndexedItems?.map(transformGraphQlTaskQ(selectedInterval));
};
