//
//
/////////////////////////// expert constants ////////////////////////////
//
//
export const expertNameTitle = 'Name',
  expertNameSortKey = 'workerName',
  employeeIdHeader = 'Emp. Id',
  employeeIdSortKey = 'worker_Id',
  statusHeader = 'Status (m)',
  statusSortKey = 'statusIntime',
  evlHeader = 'EVL',
  evlSortKey = 'evlConnection',
  callsHeader = 'Calls',
  callsSortKey = 'calls',
  ciCallsSortKey = 'ciCalls',
  crtHeader = 'CRT (s)',
  crtSortKey = 'crt',
  ciCrtSortKey = 'ciCrt',
  holdTimeHeader = 'Hold (s)',
  holdTimeSortKey = 'holdTime',
  ciHoldTimeSortKey = 'ciHoldTime',
  talkTimeHeader = 'Talk (s)',
  talkTimeSortKey = 'talkTime',
  ciTalkTimeSortKey = 'ciTalkTime',
  obTimeHeader = 'OB (s)',
  obTimeSortKey = 'oBTime',
  ciObTimeSortKey = 'ciOBTime',
  auxTimeHeader = 'Aux (s)',
  auxTimeSortKey = 'auxTime',
  ciAuxTimeSortKey = 'ciAuxTime',
  upsellHeader = 'Upsell',
  upsellSortKey = 'upsell',
  activeAttributesHeader = 'Active Attributes',
  autoWrapHeader = 'Wrap Avg (s)',
  autoWrapSortKey = 'autoWrap',
  ciAutoWrapSortKey = 'ciAutoWrap',
  qsp100Header = 'QSP100',
  qsp100SortKey = 'QSP100Rank',
  expertAdGroupKey = 'adGroups',
  expertActivitiesKey = 'activities',
  expertGroupKey = 'group',
  expertLobKey = 'lob',
  expertWandStatsKey = 'wandstats',
  expertRoutingKey = 'routing',
  expertStatusKey = 'status',
  expertSupervisorIdKey = 'supervisorId',
  expertSupervisorNameKey = 'supervisorName',
  expertEvlConnectionKey = 'evlConnection',
  activeTaskQueueKey = 'activeTaskqueue',
  activeTaskQueueHeader = 'Task Queue',
  activeHoldHeader = 'Active Hold (s)',
  activeHoldSortKey = 'holdCall',
  activeOnCallEventsHeader = 'On Call Events',
  activeACWSortKey = 'talkTimeTimeStamp';
export const expertIntSet = new Set([
  autoWrapSortKey,
  auxTimeSortKey,
  callsSortKey,
  crtSortKey,
  holdTimeSortKey,
  obTimeSortKey,
  talkTimeSortKey,
  upsellSortKey,
  statusSortKey,
  ciAutoWrapSortKey,
  ciAuxTimeSortKey,
  ciCallsSortKey,
  ciCrtSortKey,
  ciHoldTimeSortKey,
  ciObTimeSortKey,
  ciTalkTimeSortKey,
  activeHoldSortKey,
  activeACWSortKey,
]);
export const expertArraySet = new Set([
  expertAdGroupKey,
  expertActivitiesKey,
  expertGroupKey,
  expertLobKey,
  expertWandStatsKey,
]);
export const expertBooleanSet = new Set([expertEvlConnectionKey]);
export const expertStringSet = new Set([
  expertRoutingKey,
  expertStatusKey,
  expertSupervisorIdKey,
  expertSupervisorNameKey,
  employeeIdSortKey,
  expertNameSortKey,
  activeTaskQueueKey,
]);

//
//
/////////////////////////// workforce constants ////////////////////////////
//
//
export const wfQueueTitle = 'Client',
  wfQueueSortKey = 'lob',
  wfSlTitle = 'Service Level',
  wfSlSortKey = 'SLA',
  ciWfSlSortKey = 'ciSLA',
  wfCallsInQueueTitle = 'Calls in Queue',
  wfCallsInQueueSortKey = 'callsInQueue',
  ciWfCallsInQueueSortKey = 'cicallsInQueue',
  wfLongestWaitTitle = 'Longest Wait',
  wfLongestWaitSortKey = 'longestWait',
  ciWfLongestWaitSortKey = 'cilongestWait',
  wfAbandonedRateTitle = 'Abandon Rate',
  wfAbandonedRateSortKey = 'abandonRate',
  ciWfAbandonedRateSortKey = 'ciabandonRate',
  wfTotalCallsTitle = 'Total Calls',
  wfTotalCallsSortKey = 'totalCalls',
  ciWfTotalCallsSortKey = 'ciTotalCalls',
  wfEnglishAvailableTitle = 'Avail (EN)',
  wfEnglishAvailableSortKey = 'ExpertsAvailEn',
  ciWfEnglishAvailableSortKey = 'ciExpertsAvailEn',
  wfBilingualAvailableTitle = 'Avail (BIL)',
  wfBilingualSortKey = 'ExpertsAvailBil',
  ciWfBilingualSortKey = 'ciExpertsAvailBil',
  wfASATitle = 'ASA',
  wfASASortKey = 'ASA',
  ciWfASASortKey = 'ciASA',
  wfPercentExpertAvailTitle = '% Avail',
  wfPercentExpertAvailSortKey = 'expertsAvailablePercent';
// const wfIntSet = new Set([
//   wfTotalCallsSortKey,
//   wfCallsInQueueSortKey,
//   wfLongestWaitSortKey,
//   wfAbandonedRateSortKey,
//   wfEnglishAvailableSortKey,
//   wfASASortKey,
//   wfSlSortKey,
//   wfBilingualSortKey,
//   wfPercentExpertAvailSortKey,
// ]);
// const wfArraySet = new Set([]);
// const wfBooleanSet = new Set([]);
// const wfStringSet = new Set([wfQueueSortKey]);

// BOTH Workforce and Expert mapping
export const todayKeyMapToCi = {
  [callsSortKey]: ciCallsSortKey,
  [crtSortKey]: ciCrtSortKey,
  [holdTimeSortKey]: ciHoldTimeSortKey,
  [talkTimeSortKey]: ciTalkTimeSortKey,
  [obTimeSortKey]: ciObTimeSortKey,
  [auxTimeSortKey]: ciAuxTimeSortKey,
  [autoWrapSortKey]: ciAutoWrapSortKey,
  [wfTotalCallsSortKey]: ciWfTotalCallsSortKey,
  [wfCallsInQueueSortKey]: ciWfCallsInQueueSortKey,
  [wfLongestWaitSortKey]: ciWfLongestWaitSortKey,
  [wfAbandonedRateSortKey]: ciWfAbandonedRateSortKey,
  [wfEnglishAvailableSortKey]: ciWfEnglishAvailableSortKey,
  [wfBilingualSortKey]: ciWfBilingualSortKey,
  [wfASASortKey]: ciWfASASortKey,
  [wfSlSortKey]: ciWfSlSortKey,
};
export const ciKeys = [
  callsSortKey,
  crtSortKey,
  holdTimeSortKey,
  talkTimeSortKey,
  obTimeSortKey,
  auxTimeSortKey,
  autoWrapSortKey,
  wfTotalCallsSortKey,
  wfCallsInQueueSortKey,
  wfLongestWaitSortKey,
  wfAbandonedRateSortKey,
  wfEnglishAvailableSortKey,
  wfASASortKey,
  wfSlSortKey,
];

//
//
/////////////////////////// Location Based Routing constants ////////////////////////////
//
//
export const clientTitle = 'Client',
  clientSortKey = 'client',
  onShoreBamTitle = 'Onshore BAM',
  onShoreBamSortKey = 'Onshore_BAM',
  onShoreWahTitle = 'Onshore WAH',
  onShoreWahSortKey = 'Onshore_WAH',
  outSourceOnShoreTitle = 'Outsourced – Onshore',
  outSourceOnShoreSortKey = 'Outsourced_Onshore',
  offshoreTitle = 'Offshore',
  offshoreSortKey = 'Offshore',
  outsourcedOffshoreTitle = 'Outsourced - Offshore',
  outsourcedOffshoreSortKey = 'Outsourced_Offshore',
  activeTitle = 'Active',
  activeSortKey = 'active';
export const tableDataKeyToTitlePair = {
  [onShoreBamSortKey]: onShoreBamTitle,
  [onShoreWahSortKey]: onShoreWahTitle,
  [outSourceOnShoreSortKey]: outSourceOnShoreTitle,
  [offshoreSortKey]: offshoreTitle,
  [outsourcedOffshoreSortKey]: outsourcedOffshoreTitle,
  [activeSortKey]: activeTitle,
};

//
//
/////////////////////////// Upsell/Upload Constants ////////////////////////////
//
//
export const dateOfUploadHeader = 'Date Of Upload',
  dateOfUploadSortKey = 'uploadDate',
  lobHeader = 'LOB',
  groupHeader = 'Group',
  csvDownloadLinkHeader = 'Link To .CSV',
  numberOfProfilesUpdatedHeader = '# Profiles Updated',
  successStatsusHeader = 'Success/Error';
