import React from 'react';

export const MATERIAL_ICON_CLASS = 'material-icons';

const styleDataToClass = (styleClass) => {
  return `${MATERIAL_ICON_CLASS} ${styleClass}`;
};

const getStyleClass = (styleClass) =>
  styleClass !== '' ? styleDataToClass(styleClass) : MATERIAL_ICON_CLASS;

export const IconComponent = ({ icon = '', styleClass = '', iconTitle }) => {
  const iconClassName = getStyleClass(styleClass);
  return (
    <i className={iconClassName} title={iconTitle} fontSize={10}>
      {icon}
    </i>
  );
};

export default IconComponent;
