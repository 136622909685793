import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CloseButton from '../XCloseButton';
import CtaText from '../CtaTextComponent';
import { inTimeToLapsedTime } from '../../reducers/ExpertReducer/transformations';
import AddNewExpertButton, {
  DialogHeaderWithClose,
} from '../AddNewExpertButton';

import ButtonWithEditExpertDialog from './EditExpertsDialog';
import {
  selectorToExpertTableSortedCount,
  useGraphQLExpertTablePagination,
} from '../../reducers/TableDataReducer';
import {
  expertIdGraphQlHeader,
  expertNameGraphQlHeader,
  expertStatusGraphQlHeader,
  expertUpsellGraphQlHeader,
  expertAttributeGraphQlHeader,
  expertQSP100GraphQlHeader,
  expertActiveAttributeGraphQlHeader,
} from '../../reducers/TableDataReducer/datatypes';
import updateExpertAttributes, {
  getExpertByExtension,
  getMarketDetailsFetch,
  updateExpert,
} from '../../services/Experts';
import {
  valueToIsCheckedAction,
  valueToToggleEditAllExperts,
  resetBulkEditExpertAttributes,
  selectorToCheckedIds,
} from '../../reducers/ExpertReducer';
import {
  arrayToConditionallyRemoveOrAddElement,
  arrayToRemoveDups,
  ternaryCompare,
} from '../../transformations/utils';
import DropviewSelection from '../DropviewSelection';
import { GraphQlTable, ExpertDescription } from '../ExpertStatus';
import AttributeControlsComponent from '../AttributeControlsComponent';
import './index.css';
import {
  Checkbox,
  Typography,
  Tooltip,
  Input,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { EXPERT_ATTRIBUTES_TABLE_EXPANDED } from '../../services/KibanaEvents';
import { DebounceApiInput } from '../Dialog';
import { ConditionalDisplay } from '../ConditionalDisplay';
import ButtonOpenDialog from '../ButtonOpenDialog';
import { DEFAULT_FUNCTION } from '../../data/datatypes';
import AuthConditionalRender from '../AuthConditionalRender';
import { activeTaskQueueHeader } from '../../reducers/TableDataReducer/constants';

export const BANNER_ALERT = 'banner-alert',
  STATIC_TABLE_ROW_CLASS = 'staticTableRow',
  DATA_GRID_PARENT_CLASS = 'dataGridParent',
  STATIC_DATA_GRID_TEMPLATE_CLASS = 'staticDataGridTemplate',
  TABLE_LABEL_CLASS = 'tableLabelBold';
const EXPERT_SKILL_TABLE_CLASS = 'expert-skill-table handle-custom-scroll';

export const EnabledTaskQTable = ({
  title = 'Active Skills',
  skillsObj,
  onClick,
  onChange,
  hasEditPermissions,
}) => {
  return (
    <TableStringsWithConditionalIntegerValue
      title={title}
      click={onClick}
      strings={Object.keys(skillsObj)}
      change={onChange}
      keyStringValues={skillsObj}
      hasEditPermissions={hasEditPermissions}
    />
  );
};
export const DisabledTaskQTable = ({
  disabledSkills,
  onClick,
  hasEditPermissions,
}) => {
  return (
    <TableStringsWithConditionalIntegerValue
      title={'Skills'}
      click={onClick}
      strings={disabledSkills}
      hasEditPermissions={hasEditPermissions}
    />
  );
};

const TableStringsWithConditionalIntegerValue = ({
  title,
  strings,
  click,
  change,
  keyStringValues = {},
  hasEditPermissions = true, // if not passed, they need to ability to make these edit
}) => {
  const displayIntegerValue = !!(Object?.keys?.(keyStringValues) ?? [])?.length;
  const tableRows = arrayToRemoveDups(strings)?.map((skill) => (
    <TableRow key={skill}>
      <TableCell
        onClick={() => hasEditPermissions && click(skill)}
        component="th"
        scope="row"
      >
        {skill}
      </TableCell>
      <ConditionalDisplay bool={displayIntegerValue}>
        <TableCell component="th" scope="row" sx={{ width: '30px' }}>
          <Input
            disabled={!hasEditPermissions}
            className="attribute-input-edit"
            value={keyStringValues?.[skill] ?? 0}
            onChange={change?.(skill)}
            type="number"
          />
        </TableCell>
      </ConditionalDisplay>
    </TableRow>
  )) ?? <></>;
  return (
    <TableContainer
      component={Paper}
      className="handle-custom-scroll"
      sx={{
        overflowY: 'auto',
        flexGrow: 1,
        width: 'auto',
        height: '100%',
      }}
    >
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="overline" color="primary">
                {title}
              </Typography>
            </TableCell>
            <ConditionalDisplay bool={displayIntegerValue}>
              <TableCell align="right">
                <Typography color="primary" variant="overline">
                  Level
                </Typography>
              </TableCell>
            </ConditionalDisplay>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
};
export const checkedIdsToIsAllSelected = (checkedIds) =>
  checkedIds?.some?.(({ name = '' } = {}) => name === 'all') ?? false;
export const selectedExpertsToBannerDisplay = (checkedIds, totalCount) => {
  const idLength = checkedIds?.length;
  const allSelected = checkedIdsToIsAllSelected(checkedIds);
  if (idLength) {
    const startString = allSelected
      ? `All ${totalCount} Experts`
      : `${idLength} ${idLength > 1 ? 'Experts' : 'Expert'}`;
    return `${startString} Selected`;
  } else {
    return '';
  }
};
const BannerAlert = () => {
  const dispatch = useDispatch();
  const checkedIds = useSelector(selectorToCheckedIds);
  const totalViewCount = useSelector(selectorToExpertTableSortedCount);

  const resetCta = () => {
    dispatch(valueToToggleEditAllExperts([]));
  };
  const checkedIdStatus = selectedExpertsToBannerDisplay(
    checkedIds,
    totalViewCount,
  );

  return (
    <ConditionalDisplay bool={!!checkedIdStatus?.length}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
          boxSizing: 'border-box',
          padding: '15px 25px',
          backgroundColor: 'primary.dark',
        }}
      >
        <Typography color={'secondary.contrastText'}>
          {checkedIdStatus}
        </Typography>
        <Stack flexDirection="row" justifyContent="center" gap={3}>
          <ButtonWithEditExpertDialog />
          <CloseButton
            close={resetCta}
            sx={{ color: 'secondary.contrastText' }}
          />
        </Stack>
      </Stack>
    </ConditionalDisplay>
  );
};
const TextHoverEllipse = ({ string }) => {
  return (
    <Tooltip title={string}>
      <Typography className="orbit-tool-tip-text">{string}</Typography>
    </Tooltip>
  );
};
const AssociatedAreasWithZipcode = ({ marketsFromSearch, market }) => {
  return (
    <Stack sx={{ width: '50%' }}>
      {!market?.length ? (
        <></>
      ) : marketsFromSearch?.length ? (
        <>
          <Typography color="primary">
            <strong>Associated Markets</strong>
          </Typography>
          {marketsFromSearch?.map((marketString) => {
            return (
              <Stack flexDirection="row" key={marketString}>
                <Typography>{marketString} </Typography>
              </Stack>
            );
          })}
        </>
      ) : (
        <Typography>No Associated Markets</Typography>
      )}
    </Stack>
  );
};
const BaseStackWrapWithHalfWidth = ({ children }) => {
  return <Stack sx={{ width: '50%' }}>{children}</Stack>;
};
const ExtensionInputResultsDisplay = ({ extension, invalidExtension }) => {
  return (
    <BaseStackWrapWithHalfWidth>
      {!extension?.length ? (
        <></>
      ) : invalidExtension ? (
        <Typography>Extension Not Available</Typography>
      ) : (
        <Typography>Extension Available</Typography>
      )}
    </BaseStackWrapWithHalfWidth>
  );
};
const MAAdvocateMarketExtensionEdit = (props) => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  const [extension, setExtension] = useState('');
  const [market, setMarket] = useState('');

  const [marketsFromSearch, setMarketsFromSearch] = useState([]);
  const [invalidExtension, setInvalidExtension] = useState(true);

  const resetData = () => {
    setOpen(false);
    setExtension('');
    setMarket('');
    setMarketsFromSearch([]);
    setInvalidExtension(true);
  };
  useEffect(() => {
    if (!open) {
      resetData();
    }
  }, [open]);
  const zipCodeSelection = useSelector(
    (state) => state?.selection?.zipCodeSelection ?? [],
  );

  const extensionOnChange = (val) => {
    return new Promise(async (res) => {
      setExtension(val);
      if (!zipCodeSelection?.includes(val)) {
        setInvalidExtension(true);
        res(true);
        return;
      }
      if (val?.length) {
        const expert = await getExpertByExtension(val);
        const currentExpertHasExtension =
          props?.worker_Id === expert?.worker_Id;
        const extensionOccupied = Object.keys(expert)?.length ?? 0;
        setInvalidExtension(extensionOccupied && !currentExpertHasExtension);
      } else {
        setInvalidExtension(true);
      }
      res(false);
    });
  };
  const marketOnChange = (val) => {
    return new Promise(async (res) => {
      setMarket(val);
      if (val?.length) {
        const markets = await getMarketDetailsFetch(val);
        const nonDuplicatedMarkets = arrayToRemoveDups(
          markets?.map(({ market }) => market),
        );
        setMarketsFromSearch(nonDuplicatedMarkets);
        if (!markets?.length) {
          res(true);
        }
      }
      res(false);
    });
  };

  const handleSubmit = async () => {
    const success = await updateExpert(props, marketsFromSearch, extension);
    if (success) resetData();
  };
  const disableSubmit = !marketsFromSearch?.length && invalidExtension;
  return (
    <>
      <AuthConditionalRender authBool={props?.hasEditPermissions}>
        <CtaText text="Edit Market / Extension" callToAction={onOpen} />
      </AuthConditionalRender>
      <Dialog open={open} onClose={onClose}>
        <DialogHeaderWithClose close={onClose} title={'Edit Ext / Market'} />
        <DialogContent>
          <Typography>
            {props?.name} ( {props?.worker_Id} )
          </Typography>
          <Stack
            sx={{ paddingTop: '20px' }}
            gap={4}
            justifyContent="center"
            flexDirection="row"
          >
            <DebounceApiInput
              value={extension}
              onChange={extensionOnChange}
              label="Extension"
              required
            />
            <DebounceApiInput
              value={market}
              onChange={marketOnChange}
              label="Market"
              required
            />
          </Stack>
          <Stack
            sx={{ paddingTop: '20px' }}
            gap={4}
            justifyContent="center"
            flexDirection="row"
          >
            <ExtensionInputResultsDisplay
              extension={extension}
              invalidExtension={invalidExtension}
            />
            <AssociatedAreasWithZipcode
              market={market}
              marketsFromSearch={marketsFromSearch}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disableSubmit}
            onClick={handleSubmit}
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const selectedQsToExpertActiveQsToDisabledQs = (selected) => (active) =>
  selected.filter((q) => !active?.includes(q)).sort();
const ExpertAttributeExpandedView = (props) => {
  const taskQueueSelection = useSelector(
    (state) => state?.selection?.taskQueueSelection ?? [],
  );
  const { routing: { taskQueues, taskQueueLevels = {} } = {} } = props;

  const [edit, setEdit] = useState(false);
  const [applyPermanent, setApplyPermanent] = useState(false);
  const [activeSkills, setActiveSkills] = useState({ ...taskQueueLevels });
  const [disabledSkills, setDisabledSkills] = useState(
    selectedQsToExpertActiveQsToDisabledQs(taskQueueSelection)(taskQueues),
  );
  const helixUser = useSelector((state) => state?.user?.helixUser);

  useEffect(() => {
    if (!edit) {
      const moddedTaskQueues =
        JSON.stringify(taskQueueLevels) != JSON.stringify(activeSkills);
      if (moddedTaskQueues) {
        const disabledSkills =
          selectedQsToExpertActiveQsToDisabledQs(taskQueueSelection)(
            taskQueues,
          );
        setActiveSkills({ ...taskQueueLevels });
        setDisabledSkills(disabledSkills);
      }
    }
  }, [taskQueues, taskQueueLevels]);
  useEffect(() => {
    setDisabledSkills(
      selectedQsToExpertActiveQsToDisabledQs(taskQueueSelection)(taskQueues),
    );
  }, [taskQueueSelection]);
  useEffect(() => {
    if (!edit) {
      setActiveSkills({ ...taskQueueLevels });
      setDisabledSkills(
        selectedQsToExpertActiveQsToDisabledQs(taskQueueSelection)(taskQueues),
      );
      setApplyPermanent(false);
    }
  }, [edit]);
  const onChangeSkillValue = (skill) => (e) => {
    setActiveSkills((skills) => ({ ...skills, [skill]: e?.target?.value }));
    setEdit(true);
    e.persist();
  };
  const onActiveSkillClick = (skill) => {
    setActiveSkills((skills) => {
      const copy = { ...skills };
      delete copy[skill];
      return copy;
    });
    setDisabledSkills((skills) =>
      arrayToConditionallyRemoveOrAddElement(skills)(skill),
    );
    setEdit(true);
  };
  const onDisabledSkillClick = (skill) => {
    setActiveSkills((skills) => {
      skills[skill] = taskQueueLevels?.[skill] ?? 2;
      return skills;
    });
    setDisabledSkills((skills) =>
      arrayToConditionallyRemoveOrAddElement(skills)(skill),
    );
    setEdit(true);
  };

  const submitChanges = () => {
    const activeSkillKeys = Object.keys(activeSkills);
    const { taskQueueLevels, taskQueues } = activeSkillKeys.reduce(
      (acc, skill) => {
        const value = activeSkills[skill];
        acc.taskQueueLevels[skill] = Number(value);
        acc.taskQueues.push(skill);
        return acc;
      },
      { taskQueueLevels: {}, taskQueues: [] },
    );
    const { worker_Id, lob, group } = props;
    const reqObject = {
      emp_ids: [worker_Id],
      lob,
      group,
      updateTemplate: applyPermanent,
      routing: { taskQueues, taskQueueLevels },
      routingUpdatedBy: {
        routingUpdatedByUserName: helixUser.userId,
        routingUpdatedByUserID: helixUser.employeeId,
      },
    };
    updateExpertAttributes(reqObject);
    setEdit(false);
  };

  const attributeControls = {
    onChange: () => setApplyPermanent((state) => !state),
    checked: applyPermanent,
  };
  return (
    <>
      <div className="expert-attribute-expanded">
        <Stack flexDirection={'column'} width="30%">
          <ExpertDescription {...props} />
          <MAAdvocateMarketExtensionEdit {...props} helixUser={helixUser} />
        </Stack>
        <Stack flexDirection={'row'} height="500px" gap={2} width={'50%'}>
          <EnabledTaskQTable
            {...props}
            skillsObj={activeSkills}
            onClick={onActiveSkillClick}
            onChange={onChangeSkillValue}
          />
          <DisabledTaskQTable
            {...props}
            disabledSkills={disabledSkills}
            onClick={onDisabledSkillClick}
          />
        </Stack>
      </div>
      <AttributeControlsComponent
        editMode={edit}
        setEditMode={setEdit}
        apply={submitChanges}
        {...attributeControls}
      />
    </>
  );
};

const ExpertAttributeDisplay = (props) => {
  const dispatch = useDispatch();
  const {
    worker_Id = 0,
    name = '',
    status = 0,
    upsell,
    QSP100Rank,
    taskQueueString,
    activeTaskqueue = '',
    statusTimeLapse,
  } = props;
  const isChecked = useSelector((state) =>
    state?.expert?.checkedIds.some(
      ({ worker_Id: checkedId, name = '' }) =>
        name === 'all' || checkedId === worker_Id,
    ),
  );
  const onCheckExpert = (e) => {
    e.stopPropagation();
    dispatch(valueToIsCheckedAction({ worker_Id, name }));
  };
  const qsp100Value = ternaryCompare(QSP100Rank, '-');
  return (
    <>
      <AuthConditionalRender authBool={props?.hasEditPermissions}>
        <Checkbox
          checked={isChecked}
          onClick={onCheckExpert}
          className="no-padding"
        />
      </AuthConditionalRender>
      <Typography>{worker_Id}</Typography>
      <Typography>{name}</Typography>
      <TextHoverEllipse string={activeTaskqueue} />
      <Typography>
        {status} - {statusTimeLapse}
      </Typography>
      <Typography>{upsell ?? 0}</Typography>
      <Typography>{qsp100Value}</Typography>
      <TextHoverEllipse string={taskQueueString} />
    </>
  );
};

const UserListContainerComponent = ({ hasEditPermissions }) => {
  return (
    <>
      <AuthConditionalRender authBool={hasEditPermissions}>
        <AddNewExpertButton />
      </AuthConditionalRender>
      <DropviewSelection />
      <AuthConditionalRender authBool={hasEditPermissions}>
        <BannerAlert />
      </AuthConditionalRender>
      <GraphQlTable
        hasEditPermissions={hasEditPermissions}
        analyticsTag={EXPERT_ATTRIBUTES_TABLE_EXPANDED}
        tableHandler={() => useGraphQLExpertTablePagination(true)}
        Row={ExpertAttributeDisplay}
        ExpandedRow={ExpertAttributeExpandedView}
        demensions={
          hasEditPermissions
            ? '5% 10% 20% 10% 15% 5% 10% 25%'
            : '10% 20% 20% 10% 5% 10% 25%'
        }
        headers={[
          expertIdGraphQlHeader,
          expertNameGraphQlHeader,
          expertActiveAttributeGraphQlHeader,
          expertStatusGraphQlHeader,
          expertUpsellGraphQlHeader,
          expertQSP100GraphQlHeader,
          expertAttributeGraphQlHeader,
        ]}
      />
    </>
  );
};

export default UserListContainerComponent;
