import React from 'react';
import ImgDisplay from '../SvgDisplay';
import toggleSwitch from './toggleSwitch.png';
import attributeMGM from './attribute-mgm.png';
import expandedAttributeMgm from './expanded-attribute-mgm.png';
import expandedEditAttributeMgm from './expanded-attribute-edit.png';
import savedFromEdit from './save-changes-attribute.png';
import './index.css';

const HELP_PAGE_MAIN = 'help-page',
  HEADING_TEXT = 'help-page-heading-text',
  SUBHEADING_TEXT = 'help-page-SUBheading-text',
  SECTION_OVERVIEW_HEADER = 'help-page-section-header',
  TEXT_HIGHLIGHT = 'help-page-text-highlight',
  TABLE_HEADER = 'help-table-header',
  SECTION_SECONDARY_OVERVIEW_HEADER = 'help-page-section-subheader',
  SECTION_DETAILS = 'help-page-section-details',
  OPENING_STATEMENT = `Hi there and welcome to Orbit, a new dashboard designed to give you visibility into our Twilio Contact Center!  If you came here looking for a reference, to get some help deciphering the new real-time dashboard, or have questions about performing workforce administration, you've come to the right place.  Here's a quick index to get you started:`;
const Help = () => (
  <div id={HELP_PAGE_MAIN}>
    <header>
      <span id={HEADING_TEXT}>Orbit Overview / Helpful Hints</span>
      <span id={SUBHEADING_TEXT}>{OPENING_STATEMENT}</span>
    </header>
    <div>
      <ul>
        <li>
          <a href="#OrbitOverview">Orbit Overview / Terminology</a>
          <ul>
            <li>
              <a href="#OrbitOverview/Terminology">Terminology</a>
            </li>
            <li>
              <a href="#OrbitOverview/Timeframes">Timeframes</a>
            </li>
          </ul>
        </li>
        <li>
          <span>
            <a href="#MetricsReference">Metrics Reference</a>
          </span>
          <ul>
            <li>
              <a href="#MetricsReference/OpsView">Operations View</a>
            </li>
            <li>
              <a href="#MetricsReference/WFView">Workforce View</a>
              <ul>
                <li>
                  <a href="#MetricsReference/WFView/SummaryQueue">
                    Summary Queue
                  </a>
                </li>
                <li>
                  <a href="#MetricsReference/WFView/TaskQueue">
                    Task Queue Table
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <a href="#OrbitOverview/WFAdmin">Workforce Administration</a>
          <ul>
            <li>
              <a href="#OrbitOverview/WFAdmin/mgm">
                Worker Attribute Management
              </a>
              <ul>
                <li>
                  <a href="#OrbitOverview/WFAdmin/workerUpdate">
                    Single Worker Update Flow
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <span id="OrbitOverview" className={SECTION_OVERVIEW_HEADER}>
      Orbit Overview / Terminology
    </span>
    <span className={SECTION_DETAILS}>
      Orbit is a real-time dashboard custom-built by Asurion to provide you with
      a quick glance into what's happening in our new Twilio environment.
    </span>
    <span className={SECTION_DETAILS}>
      Quick Note: Orbit will only work in Google Chrome, so ensure to launch the
      dashboard in Chrome.
    </span>
    <span
      id="OrbitOverview/Terminology"
      className={SECTION_SECONDARY_OVERVIEW_HEADER}>
      Terminology
    </span>
    <ul>
      <li>
        Worker - This is the Twilio term for a user. It can be equated to an
        Expert.
      </li>
      <li>
        Task - This is the Twilio term for a unit of work. It can be equated to
        a Call.
      </li>
      <li>
        Task Queue - This is where calls will land to ultimately be answered by
        a Worker. Can be generally equated to a Skill within Avaya.
      </li>
      <li>
        Summary Queue - This is an Orbit term to refer to a collection of Task
        Queues. Used for grouping similar queues for quick reporting.
      </li>
      <li>
        Activity State - The status of a Worker within Twilio. Examples include:
        "Offline", "Busy", "Available", "Lunch", "Wrap Up", etc.
      </li>
    </ul>
    <span
      id="OrbitOverview/Timeframes"
      className={SECTION_SECONDARY_OVERVIEW_HEADER}>
      Timeframes
    </span>
    <ImgDisplay svg={toggleSwitch} height={'75px'} />
    <span className={SECTION_DETAILS}>
      At launch, users will be able to use the buttons shown above to select a
      timeframe in which to view the Orbit dashboard. While not all metrics are
      dependent upon the timeframe selected, most will change to reflect what is
      happening in the system for that period of time. If selecting Today, for
      example, you will see the number of calls answered since Midnight Central
      time.
    </span>
    <ul>
      <li>
        Current Interval - An interval is defined as 30 minutes. All data will
        reflect what has transpired since the beginning of the current interval.
        At 3:29 PM, for example, The dashboard will display metrics from 3:00 PM
        - 3:29 PM. At 3:30 PM the Current Interval filter will be zeroed out and
        the counters will start over. At 3:32 PM, it will display data from 3:30
        PM - 3:32 PM.
      </li>
      <li>
        Today - Data since Midnight CST. At Midnight, the today filter will be
        zeroed out and the counters will start over.
      </li>
    </ul>
    <span id="MetricsReference" className={SECTION_OVERVIEW_HEADER}>
      Metrics Reference
    </span>
    <span
      id="MetricsReference/OpsView"
      className={SECTION_SECONDARY_OVERVIEW_HEADER}>
      Operations View
    </span>
    <span className={SECTION_DETAILS}>
      This view provides real-time metrics aggregated at the Worker level. Each
      row in this view represents a worker within Twliio. The drop-downs at the
      top-left allow you to select a group and then a coach (or "All") to view a
      set of users. The hierarchy for the coaches is a daily feed from Workday.
    </span>
    <table>
      <thead>
        <tr>
          <th>Metric</th>
          <th>Description</th>
          <th>Units / Display</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Status</th>
          <th>Current Activity State for the Worker</th>
          <th>Busy / Offline / etc.</th>
          <th></th>
        </tr>
        <tr>
          <th>Time in Status</th>
          <th>Minutes since last Activity State Change</th>
          <th>15 Minutes</th>
          <th></th>
        </tr>
        <tr>
          <th>Calls</th>
          <th>
            Number of Calls answered by that Worker (Reservation Accepted)
          </th>
          <th>4 Calls</th>
          <th></th>
        </tr>
        <tr>
          <th>Aux Time </th>
          <th>Minutes spent in an AUX Activity State since timeframe start</th>
          <th>10 Minutes</th>
          <th>
            This only updates once a worker leaves their current activity state,
            meaning it only reflects completed Aux time. If the worker is
            currently in Aux, the duration from Time in Status should also be
            considered as Aux time.
          </th>
        </tr>

        <tr>
          <th>Average ACW</th>
          <th>Average Seconds Spent in ACW / Wrap Up</th>
          <th>18 Seconds</th>
          <th></th>
        </tr>
        <tr>
          <th>CRT</th>
          <th>
            ( Inbound Time + Outbound Time + Hold Time + Total Wrap Up ) / Calls
          </th>
          <th>800 Seconds</th>
          <th></th>
        </tr>

        <tr>
          <th>Inbound Time</th>
          <th>Total Time Spent on Inbound Calls</th>
          <th>90 Minutes</th>
          <th></th>
        </tr>
        <tr>
          <th>Hold Time</th>
          <th>Total Time Spent while customers are on hold</th>
          <th>7 Minutes</th>
          <th></th>
        </tr>
        <tr>
          <th>Outbound Time</th>
          <th>Total Time Spent on Outbound Calls</th>
          <th>12 Minutes</th>
          <th></th>
        </tr>
      </tbody>
    </table>
    <span
      id="MetricsReference/WFView"
      className={SECTION_SECONDARY_OVERVIEW_HEADER}>
      Workforce View
    </span>
    <span className={SECTION_DETAILS}>
      This view provides real-time metrics aggregated at the Task Queue level.
      At first only the Summary Queue will be visible, but upon clicking inside
      the Summary Queue, it will expand to list each Task Queue as a column in
      the resulting table. While on initial launch there will only be one
      Summary Queue listed ("Home Plus Service") more will be added to this view
      as additional clients are on-boarded to the Twilio platform.
    </span>
    <span id="MetricsReference/WFView/SummaryQueue" className={TABLE_HEADER}>
      Summary Queue
    </span>
    <span className={SECTION_DETAILS}>
      Note: Metrics listed in the Summary Queue are weighted averages of values
      int he expanded Task Queue table.
    </span>
    <table>
      <thead>
        <tr>
          <th>Metric</th>
          <th>Description</th>
          <th>Units / Display</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Service Level </th>
          <th>
            Percent of Calls Answered within Service Level Threshold (30s for
            MVP Client)
          </th>
          <th>80 %</th>
          <th></th>
        </tr>
        <tr>
          <th>Calls in Queue</th>
          <th>
            Sum of number of Calls currently in Queue in any of the underlying
            Task Queues
          </th>
          <th>10 Calls</th>
          <th></th>
        </tr>
        <tr>
          <th>Max Wait</th>
          <th>
            Greatest duration a caller in queue has waited to speak to a Worker
          </th>
          <th>15 Seconds</th>
          <th>
            This is the maximum value from underlying Task Queues. If a Summary
            Queue is made up of TQ1 and TQ2, a Caller in TQ1 has been waiting 5
            seconds, and a Caller in TQ2 has been waiting 15 seconds, this
            metric will reflect 15 seconds.
          </th>
        </tr>
        <tr>
          <th>ASA</th>
          <th>
            Average Seconds to Answer (Time from Entering Task Queue to Speaking
            with a Worker)
          </th>
          <th>10 Seconds</th>
          <th></th>
        </tr>

        <tr>
          <th>CRT</th>
          <th>
            ( Inbound Time + Outbound Time + Hold Time + Total Wrap Up ) / Calls
            Answered
          </th>
          <th>800 Seconds</th>
          <th></th>
        </tr>
        <tr>
          <th>Monthly Service Level </th>
          <th>
            Percent of Calls Answered within Service Level Threshold (30s for
            MVP Client)
          </th>
          <th>80 %</th>
          <th>
            This will be pulled from Historical Reporting and not reflected
            Real-Time
          </th>
        </tr>
      </tbody>
    </table>
    <span id="MetricsReference/WFView/TaskQueue" className={TABLE_HEADER}>
      Task Queue Table
    </span>
    <table>
      <thead>
        <tr>
          <th>Metric</th>
          <th>Description</th>
          <th>Units / Display</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Service Level Offered</th>
          <th>
            Percent of Calls Answered within Service Level Threshold (30s for
            MVP Client)
          </th>
          <th>85 %</th>
          <th></th>
        </tr>
        <tr>
          <th>Service Level Answered</th>
          <th>
            Percent of Calls Answered within Service Level Threshold (30s for
            MVP Client) not including Abandons
          </th>
          <th>80 %</th>
          <th></th>
        </tr>
        <tr>
          <th>Service Level Less Short Abandons</th>
          <th>{`Percent of Calls Answered within Service Level Threshold (30s for MVP Client) not including Abandons < 20 Seconds`}</th>
          <th>75 %</th>
          <th></th>
        </tr>
        <tr>
          <th>Calls in Queue</th>
          <th>
            Number of Calls currently waiting to be answered by the next
            Available Worker
          </th>
          <th>20 Calls</th>
          <th></th>
        </tr>

        <tr>
          <th>Calls Offered</th>
          <th>
            Number of Calls that have entered the Task Queue (includes in queue,
            answered and abandoned)
          </th>
          <th>100 Calls</th>
          <th></th>
        </tr>
        <tr>
          <th>Longest Waiting</th>
          <th>
            Duration that the longest caller currently in queue has been waiting
          </th>
          <th>10 Seconds</th>
          <th></th>
        </tr>
        <tr>
          <th>Max Wait</th>
          <th>
            Longest amount of time that a caller waited before being answered or
            abandoned
          </th>
          <th>100 Seconds</th>
          <th></th>
        </tr>
        <tr>
          <th>ASA</th>
          <th>
            Average Seconds to Answer (Time from Entering Task Queue to Speaking
            with a Worker)
          </th>
          <th>12 Seconds</th>
          <th></th>
        </tr>
        <tr>
          <th>Calls Answered</th>
          <th>
            Number of Calls Answered by Workers assigned to this Task Queue
          </th>
          <th>100 Calls</th>
          <th></th>
        </tr>
        <tr>
          <th>Calls Abandoned</th>
          <th>
            Number of Calls assigned to this Task Queue that ended before being
            answered by a Worker
          </th>
          <th>4 Calls</th>
          <th></th>
        </tr>
        <tr>
          <th>ATA</th>
          <th>Average Time to Abandon</th>
          <th>90 Seconds</th>
          <th></th>
        </tr>
        <tr>
          <th>CRT</th>
          <th>
            ( Inbound Time + Outbound Time + Hold Time + Total Wrap Up ) / Calls
            Answered
          </th>
          <th>800 Seconds</th>
          <th></th>
        </tr>
        <tr>
          <th>Experts Staffed</th>
          <th>
            Number of Workers assigned to this Task Queue in an Activity State
            other than "Offline"
          </th>
          <th>10 Workers</th>
          <th></th>
        </tr>
        <tr>
          <th>Experts Available</th>
          <th>
            Number of Workers assigned to this Task Queue in "Available"
            Activity State
          </th>
          <th>2 Workers</th>
          <th></th>
        </tr>
        <tr>
          <th>Experts in AUX</th>
          <th>
            Number of Workers assigned to this Task Queue in an AUX Activity
            State (i.e. "Lunch", "Coaching", "Break")
          </th>
          <th>3 Workers</th>
          <th></th>
        </tr>
        <tr>
          <th>Experts in Wrap Up</th>
          <th>
            Number of Workers assigned to this Task Queue in "Wrap Up" Activity
            State
          </th>
          <th>5 Workers</th>
          <th></th>
        </tr>
        <tr>
          <th>Experts Working</th>
          <th>Number of Workers currently in a "Busy" Activity State</th>
          <th>10 Workers</th>
          <th></th>
        </tr>
      </tbody>
    </table>
    <span id="OrbitOverview/WFAdmin" className={SECTION_OVERVIEW_HEADER}>
      Workforce Administration
    </span>
    <span
      id="OrbitOverview/WFAdmin/mgm"
      className={SECTION_SECONDARY_OVERVIEW_HEADER}>
      Worker Attribute Management
    </span>
    <span className={SECTION_DETAILS}>
      In order to support our Workforce Management partners more efficiently, we
      have implemented the ability to update worker attributes. This feature can
      be accessed by clicking on the Expert Attributes button in the top right
      corner of the Orbit Dashboard. To see this button you will need to be a
      member of the AD group
      <span className={TEXT_HIGHLIGHT}>MGMT-US-N-ORBIT-EDITOR</span>. Once
      clicked, you will be brought to a screen similar to the following:
    </span>
    <ImgDisplay svg={attributeMGM} />
    <span className={SECTION_DETAILS}>
      From here, use the first drop-down to select the appropriate group (these
      are the same group selection from the Operations Page and Summary Queue
      selections) and Coach (or "All). This will display a list of experts and
      their current attribute set.
    </span>
    <span id="OrbitOverview/WFAdmin/workerUpdate" className={TABLE_HEADER}>
      Single Worker Update Flow
    </span>
    <span className={SECTION_DETAILS}>
      Clicking on the row for any individual expert will cause the panel to
      expand, revealing the full list of active and eligible attributes for a
      worker.
    </span>
    <ImgDisplay svg={expandedAttributeMgm} />
    <span className={SECTION_DETAILS}>
      From here, simply add eligible attributes to a worker or remove existing
      attributes by clicking on the + or - icons accordingly.
    </span>
    <ImgDisplay svg={expandedEditAttributeMgm} />
    <span className={SECTION_DETAILS}>
      Once all changes are reviewed and ready to be applied for this worker,
      click Apply Changes. A confirmation message will appear once the changes
      have been processed successfully.
    </span>
    <ImgDisplay svg={savedFromEdit} />
    <span className={SECTION_DETAILS}>
      By default, all workers will be reset to their default attribute set
      nightly, so changes made using this interface will be overwritten by the
      Worker Template job that processes each night to update all workers. If
      the change being made should not be overwritten with the nightly reset to
      default process, make sure to check the "Apply Permanently" checkbox
      before clicking Apply Changes. This will update both Twilio and the
      template for that worker.
    </span>
  </div>
);

export default Help;
