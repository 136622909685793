import React, { useCallback } from 'react';
import CheckboxComponent from '../CheckboxComponent';
import ButtonComponent from '../ButtonComponent';

import './index.scss';

const PANEL_CONTROLS_CLASS = 'panelControlsContainer';
export const ApplyCancelPairBtnComponent = ({
  cancel = () => {},
  apply = () => {},
}) => (
  <section>
    <button
      id="order-btn-cancel"
      className="btn ripple-effect"
      onClick={cancel}>
      Cancel
    </button>
    <ButtonComponent
      text="Apply Changes"
      id="order-btn-apply"
      onClick={apply}
    />
  </section>
);

const AttributeControlsComponent = ({
  editMode = false,
  setEditMode = {},
  apply = {},
  ...otherCheckBoxProps
}) => {
  const cancel = useCallback(() => setEditMode(false), [setEditMode]);

  if (!editMode) {
    return null;
  }
  return (
    <div className={PANEL_CONTROLS_CLASS}>
      <section>
        <CheckboxComponent
          labelText="Apply permanently"
          {...otherCheckBoxProps}
        />
      </section>
      <ApplyCancelPairBtnComponent cancel={cancel} apply={apply} />
    </div>
  );
};

export default AttributeControlsComponent;
