import { postApiData } from './shared';
import { getEnvironment } from '../utils/applicationEnvironment';
import config from '../config.json';
import Amplify from 'aws-amplify';
import { selectorToHelixUser } from '../reducers/UserReducer';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { reactRouterHistoryToCurrentPath } from '../transformations/utils';
import { useHistory } from 'react-router-dom';

export const KIBANA_WORKFLOW_PRIORITY_EVENT = 'workflow-priority';
export const KIBANA_UPDATE_EXPERT_ATTRIBUTE = 'update-expert-attribute';
export const KIBANA_EDIT_EXPERT = 'edit-expert';
export const KIBANA_SEARCH_NEW_EXPERT = 'add-new-expert-search-by-id';
export const KIBANA_NEW_EXPERT_ADDED = 'new-expert-added';
export const KIBANA_NEW_BULK_EXPERT_ADDED = 'new-bulk-expert-added';
export const KIBANA_USER_LOG_IN_EVENT = 'user-login';
export const KIBANA_CREATE_UPDATE_UBIF = 'create-update-ubif-form';
export const KIBANA_SUPPORT_CLICK_EVENT = 'support-click';
export const KIBANA_UPDATE_EXPERT_EXTENSION_MARKET =
  'expert-market-extension-update';
// Dropdown selections
export const LOB_FILTER_EVENT = 'lob-drop-down';
export const GROUP_FILTER_EVENT = 'group-drop-down';
export const STATUS_FILTER_EVENT = 'status-drop-down';
export const COACH_FILTER_EVENT = 'coach-drop-down';
export const SITE_FILTER_EVENT = 'site-drop-down';
export const TASKQ_FILTER_EVENT = 'task-queue-drop-down';
//
export const SAVE_LOCATION_BASED_ROUTING = 'save-locating-based-routing';
export const KIBANA_GET_USER_SETTINGS = 'get-user-settings';
export const KIBANA_SAVE_USER_SETTINGS = 'save-user-settings';
export const KIBANA_FAILED_STATE = 'fail';
export const KIBANA_SUCCESS_STATE = 'success';

//Table Click
export const WORKFORCE_TABLE_EXPANDED = 'workforce-expanded-row';
export const EXPERT_OPS_TABLE_EXPANDED = 'expert-operations-expanded-row';
export const EXPERT_ATTRIBUTES_TABLE_EXPANDED =
  'expert-attributes-expanded-row';

const env = getEnvironment();
Amplify.configure(config[env].KIBANA_EVENT_URL);

export const kibanaUpdateURL = config[env].KIBANA_EVENT_URL;
/**
 * Wraps a function and logs to elastic search after executing the passesd in function. Serves as a
 * consistent way to handle usage metrics logs.
 * @param {String} id Name to be used in long for elementName field
 * @param {*} fx Function to run prior to logging, typically an event handler
 * @param {Object} alt other data to log
 */
export const withLoggingAnalytics =
  (id, fx, alt = {}) =>
  (e) => {
    /*eslint no-undef: "off"*/
    const formatBody = {
      event: 'usage-metrics',
      elementName: id,
      tab: window.location.href.split('/').at(-1),
      ...alt,
    };
    fx?.(e);
    kibanaEventTrigger(formatBody);
  };

export const useLoggingAnalytics = (event) => {
  const currentUser = useSelector(selectorToHelixUser);
  const logAnalytics = useCallback(
    (values) => kibanaEventTrigger({ ...values, currentUser, event }),
    [currentUser, event],
  );
  return logAnalytics;
};

export default async function kibanaEventTrigger(body) {
  const timeStamp = new Date().toISOString();
  postApiData(kibanaUpdateURL, {
    ...body,
    timeStamp,
  })
    /*eslint no-console: "off"*/
    .then((success) => console.log('[ Kibana event: Success ]', success))
    /*eslint no-console: "off"*/
    .catch((fail) => console.log('[ Kibana event: Fail ]', fail));
}

export const filterSelectionChange = (change) => (value) => (user) =>
  kibanaEventTrigger({
    change,
    value,
    ...user,
    state: KIBANA_SUCCESS_STATE,
  });

export const useKibanaMamaLama = () => {
  const logAnalytics = useLoggingAnalytics('Page Route Change');
  const [previousPath, setPreviousPath] = useState('/');
  const [timeStamp, setTimestamp] = useState();

  const history = useHistory();
  const path = reactRouterHistoryToCurrentPath(history);
  useEffect(() => {
    const timeNow = Date.now();
    const isRedirect = timeStamp ? timeNow - timeStamp < 100 : true;
    setTimestamp(timeNow);
    setPreviousPath(path);
    if (!isRedirect) {
      logAnalytics({
        previousValue: previousPath,
        value: path,
      });
    }
  }, [path]);
};
