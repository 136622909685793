import React from 'react';

import './index.css';

export const GENERIC_LABEL_TEXT_CLASS = 'genericLabelText';

export const GenericLabelText = ({
  text,
  className = '',
  id = '',
  ...other
}) => {
  const appendedClassName = `${className} ${GENERIC_LABEL_TEXT_CLASS}`;

  return (
    <div className={appendedClassName} {...other} data-id={id}>
      {text}
    </div>
  );
};

export default GenericLabelText;
