import React from 'react';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/system';
import {
  StickyTaskDataTableCell,
  StickyTaskQueueNameTableCell,
} from '../../WorkForceExpandedComponent';
import { Divider } from '@mui/material';
import { DEFAULT_FUNCTION } from '../../../data/datatypes';
import {
  expertStatusItemsToOfflineExpertCount,
  expertStatusItemsToTotalExpertCount,
  fetchExpertsInStatus,
} from './transformations';
import { status } from '../../../data/constants';
import {
  globalExpertOfflineCountResponseToState,
  globalExpertStatsResponseToState,
  globalExpertTotalsResponseToState,
} from '../../../reducers/TableDataReducer';

/**
 *Gets data for contact center
 * @param {Dispatch} dispatch
 */
export const globalContactCenterStatsAPIFetch = async (
  dispatch = DEFAULT_FUNCTION,
) => {
  await Promise.all(status?.map(fetchExpertsInStatus)).then((allStatus) => {
    console.log('[ Global Status Responses ] ', allStatus);
    const totalCount = expertStatusItemsToTotalExpertCount(allStatus);
    const offlineCount = expertStatusItemsToOfflineExpertCount(allStatus);
    dispatch(globalExpertTotalsResponseToState(totalCount));
    dispatch(globalExpertOfflineCountResponseToState(offlineCount));
    dispatch(globalExpertStatsResponseToState(allStatus));
  });
};

/**
 * Maps array to collection of rows made for contact center
 * table
 * @param {Object} item
 * @param {String} key
 * @returns Array
 */
export const globalDataRowMap = (item, key) => {
  return (
    <Box>
      <GlobalContactRowsTableData
        key={key}
        status={item.statusName}
        numOfExperts={item.numInStatus}
      />
      <Divider sx={{ color: 'primary.main' }} />
    </Box>
  );
};

/**
 * Row for global contact center table
 * @param {String} status
 * @param {Number} numOfExperts
 * @returns Fragment row
 */
export const GlobalContactRowsTableData = ({
  status,
  numOfExperts,
  ...props
} = {}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'neutral.background',
          display: 'flex',
          flexDirection: 'row',
          width: 'inherit',
          flex: 1,
        }}
      >
        <StickyTaskQueueNameTableCell
          sx={{
            backgroundColor: 'neutral.background',
            backgroundImage: 'none',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '5px',
            flex: '1 1 150px',
          }}
        >
          {status}
        </StickyTaskQueueNameTableCell>
        <StickyTaskDataTableCell
          sx={{
            backgroundColor: 'neutral.background',
            paddingLeft: '7px',
            paddingTop: '5px',
            paddingBottom: '5px',
            backgroundImage: 'none',
            flex: '1 1 150px',
          }}
        >
          {numOfExperts}
        </StickyTaskDataTableCell>
      </Box>
    </>
  );
};
