import React from 'react';
export const ConditionalDisplay = ({
  bool,
  children,
  RenderProp,
  ...props
} = {}) => {
  if (bool) {
    return children;
  } else if (RenderProp) {
    return <RenderProp {...props} />;
  } else {
    return <></>;
  }
};
