import { GraphqlHeader } from '../../components/ExpertStatus';
import {
  clientTitle,
  clientSortKey,
  activeTitle,
  activeSortKey,
  onShoreBamTitle,
  onShoreBamSortKey,
  onShoreWahTitle,
  onShoreWahSortKey,
  outSourceOnShoreTitle,
  outSourceOnShoreSortKey,
  offshoreTitle,
  offshoreSortKey,
  outsourcedOffshoreTitle,
  outsourcedOffshoreSortKey,
  expertNameTitle,
  expertNameSortKey,
  employeeIdHeader,
  employeeIdSortKey,
  statusHeader,
  statusSortKey,
  evlHeader,
  evlSortKey,
  callsHeader,
  callsSortKey,
  crtHeader,
  crtSortKey,
  holdTimeHeader,
  holdTimeSortKey,
  talkTimeHeader,
  talkTimeSortKey,
  obTimeHeader,
  obTimeSortKey,
  auxTimeHeader,
  auxTimeSortKey,
  qsp100Header,
  qsp100SortKey,
  upsellHeader,
  upsellSortKey,
  activeAttributesHeader,
  autoWrapHeader,
  autoWrapSortKey,
  wfQueueTitle,
  wfQueueSortKey,
  wfSlTitle,
  wfSlSortKey,
  wfCallsInQueueTitle,
  wfCallsInQueueSortKey,
  wfLongestWaitTitle,
  wfLongestWaitSortKey,
  wfAbandonedRateTitle,
  wfAbandonedRateSortKey,
  wfTotalCallsTitle,
  wfTotalCallsSortKey,
  wfEnglishAvailableTitle,
  wfEnglishAvailableSortKey,
  wfBilingualAvailableTitle,
  wfBilingualSortKey,
  wfASATitle,
  wfASASortKey,
  wfPercentExpertAvailTitle,
  wfPercentExpertAvailSortKey,
  activeTaskQueueHeader,
  activeTaskQueueKey,
  activeHoldHeader,
  activeOnCallEventsHeader,
  activeHoldSortKey,
  activeACWSortKey,
  dateOfUploadHeader,
  lobHeader,
  groupHeader,
  csvDownloadLinkHeader,
  numberOfProfilesUpdatedHeader,
  successStatsusHeader,
  dateOfUploadSortKey,
} from './constants';
import {
  ifArrayReturnArg2ElseArg3,
  fieldSearchToFilterString,
} from './transformations';

import CallEndIcon from '@mui/icons-material/CallEnd';
import PauseIcon from '@mui/icons-material/Pause';

import React from 'react';

export class GraphqlTableSort {
  constructor(direction = 'asc', field = '') {
    this.direction = direction;
    this.field = field;
  }
}
export class GraphqlHeaderModel {
  constructor(header = '', sortKey, icons) {
    this.header = header;
    this.sortKey = sortKey;
    this.icons = icons;
  }
}
class ElasticSearchTermKeyWord {
  constructor(key = '', value = '') {
    const keyWord = ifArrayReturnArg2ElseArg3(value, `${key}.keyword`, key);
    this[keyWord] = value;
  }
}
export class ElasticSearchTerm {
  constructor(key = '', value = '') {
    const searchTerm = ifArrayReturnArg2ElseArg3(value, 'terms', 'term');
    this[searchTerm] = new ElasticSearchTermKeyWord(key, value);
  }
}
class ElasticMustAndMustNotHave {
  constructor(mustHaveFilter = [], mustNotHaveFilter = []) {
    if (mustHaveFilter?.length) {
      this.must = mustHaveFilter;
    }
    if (mustNotHaveFilter?.length) {
      this.must_not = mustNotHaveFilter;
    }
  }
}
export class ElasticMustySearch {
  constructor(mustHaveFilter = [], mustNotHaveFilter = []) {
    this.bool = new ElasticMustAndMustNotHave(
      mustHaveFilter,
      mustNotHaveFilter,
    );
  }
}

class ElasticSimpleQueryFields {
  constructor(fieldSearch = '', fields = []) {
    this.query = fieldSearchToFilterString(fieldSearch);
    this.fields = fields;
    this.lenient = 'true';
    this.default_operator = 'AND';
  }
}
export class ElasticSimpleQuery {
  constructor(fieldSearch = '', fields = []) {
    this.simple_query_string = new ElasticSimpleQueryFields(
      fieldSearch,
      fields,
    );
  }
}

//
//
/////////////////////////// Admin View Headers ////////////////////////////
//
//
export const clientTitleGraphQlHeader = new GraphqlHeaderModel(
    clientTitle,
    clientSortKey,
  ),
  activeToggleGraphQlHeader = new GraphqlHeaderModel(
    activeTitle,
    activeSortKey,
  ),
  onshoreBamHeader = new GraphqlHeaderModel(onShoreBamTitle, onShoreBamSortKey),
  onshoreWahHeader = new GraphqlHeaderModel(onShoreWahTitle, onShoreWahSortKey),
  outsourcedOnshoreGraphQlHeader = new GraphqlHeaderModel(
    outSourceOnShoreTitle,
    outSourceOnShoreSortKey,
  ),
  offshoreGraphQlHeader = new GraphqlHeaderModel(
    offshoreTitle,
    offshoreSortKey,
  ),
  outsourcedOffshoreGraphQlHeader = new GraphqlHeaderModel(
    outsourcedOffshoreTitle,
    outsourcedOffshoreSortKey,
  );

//
//
/////////////////////////// Expert Graphql Headers ////////////////////////////
//
//
export const expertNameGraphQlHeader = new GraphqlHeaderModel(
    expertNameTitle,
    expertNameSortKey,
  ),
  expertIdGraphQlHeader = new GraphqlHeaderModel(
    employeeIdHeader,
    employeeIdSortKey,
  ),
  expertStatusGraphQlHeader = new GraphqlHeaderModel(
    statusHeader,
    statusSortKey,
  ),
  expertEvlGraphQlHeader = new GraphqlHeaderModel(evlHeader, evlSortKey),
  expertCallsGraphQlHeader = new GraphqlHeaderModel(callsHeader, callsSortKey),
  expertCrtGraphQlHeader = new GraphqlHeaderModel(crtHeader, crtSortKey),
  expertHoldGraphQlHeader = new GraphqlHeaderModel(
    holdTimeHeader,
    holdTimeSortKey,
  ),
  expertTalkGraphQlHeader = new GraphqlHeaderModel(
    talkTimeHeader,
    talkTimeSortKey,
  ),
  expertObGraphQlHeader = new GraphqlHeaderModel(obTimeHeader, obTimeSortKey),
  expertAuxGraphQlHeader = new GraphqlHeaderModel(
    auxTimeHeader,
    auxTimeSortKey,
  ),
  expertQSP100GraphQlHeader = new GraphqlHeaderModel(
    qsp100Header,
    qsp100SortKey,
  ),
  expertUpsellGraphQlHeader = new GraphqlHeaderModel(
    upsellHeader,
    upsellSortKey,
  ),
  expertAttributeGraphQlHeader = new GraphqlHeaderModel(activeAttributesHeader),
  expertWrapGraphQlHeader = new GraphqlHeaderModel(
    autoWrapHeader,
    autoWrapSortKey,
  ),
  expertActiveAttributeGraphQlHeader = new GraphqlHeaderModel(
    activeTaskQueueHeader,
    activeTaskQueueKey,
  ),
  expertActiveHoldGraphQLHeader = new GraphqlHeaderModel(
    activeHoldHeader,
    activeHoldSortKey,
  ),
  expertActiveOnCallEventsGraphQLHeader = new GraphqlHeaderModel(
    activeOnCallEventsHeader,
    '',
    [
      { sortKey: activeACWSortKey, icon: <CallEndIcon /> },
      { sortKey: activeHoldSortKey, icon: <PauseIcon /> },
    ],
  );

//
//
/////////////////////////// WORKFORCE TABLE HEADERS ////////////////////////////
//
//
export const wfQueueGraphQlHeader = new GraphqlHeaderModel(
    wfQueueTitle,
    wfQueueSortKey,
  ),
  wfSlGraphQlHeader = new GraphqlHeaderModel(wfSlTitle, wfSlSortKey),
  wfCallsInQueueGraphQlHeader = new GraphqlHeaderModel(
    wfCallsInQueueTitle,
    wfCallsInQueueSortKey,
  ),
  wfLongestWaitGraphQlHeader = new GraphqlHeaderModel(
    wfLongestWaitTitle,
    wfLongestWaitSortKey,
  ),
  wfAbandonedRateGraphQlHeader = new GraphqlHeaderModel(
    wfAbandonedRateTitle,
    wfAbandonedRateSortKey,
  ),
  wfTotalCallsGraphQlHeader = new GraphqlHeaderModel(
    wfTotalCallsTitle,
    wfTotalCallsSortKey,
  ),
  wfEnglishAvailableGraphQlHeader = new GraphqlHeaderModel(
    wfEnglishAvailableTitle,
    wfEnglishAvailableSortKey,
  ),
  wfBilingualAvailableGraphQlHeader = new GraphqlHeaderModel(
    wfBilingualAvailableTitle,
    wfBilingualSortKey,
  ),
  wfASAGraphQlHeader = new GraphqlHeaderModel(wfASATitle, wfASASortKey),
  wfPercentExpertAvailGraphQlHeader = new GraphqlHeaderModel(
    wfPercentExpertAvailTitle,
    wfPercentExpertAvailSortKey,
  );

//
//
/////////////////////////// Upsell/Upload HEADERS ////////////////////////////
//
//
export const dateOfUploadGraphQLHeader = new GraphqlHeaderModel(
    dateOfUploadHeader,
    dateOfUploadSortKey,
  ),
  lobGraphQLHeader = new GraphqlHeaderModel(lobHeader),
  groupGraphQLHeader = new GraphqlHeaderModel(groupHeader),
  csvDownloadLinkGraphQLHeader = new GraphqlHeaderModel(csvDownloadLinkHeader),
  numberOfProfilesUpdatedGraphQLHeader = new GraphqlHeaderModel(
    numberOfProfilesUpdatedHeader,
  ),
  successStatusGraphQLHeader = new GraphqlHeaderModel(successStatsusHeader);
