import {
  graphQlResToSelectionLobs,
  graphQlResToAvailableGroups,
  lobFilterToGroupFilterToAvailableToSelection,
  graphQlResToSelectionStatus,
  selectionToClickedToFilter,
  lobFilterToGroupFilterToAvailableToAvailableQueues,
  stringArrayToAbcSort,
  graphQlResToSelectionExtension,
} from './transformations';

import { SAVE_USER_SETTINGS, SAVE_USER_APP_SELECTION } from '../UserReducer';
import { fieldSearchToSearchQueryFilter } from '../TableDataReducer/transformations';

const initialState = {
  fieldSearch: '',
  searchSelections: {},

  lobFilter: [],
  lobSelection: [],

  groupFilter: [],
  groupSelection: [],

  statusFilter: [],
  statusSelection: [],

  coachFilter: [],
  coachSelection: [],

  siteFilter: [],
  siteSelection: [],

  available: [],

  taskQueueFilter: [],
  taskQueueSelection: [],
};
export const selectorToSelectionFilters = ({
    selection: {
      lobFilter = [],
      groupFilter = [],
      statusFilter = [],
      coachFilter = [],
      siteFilter = [],
      taskQueueFilter = [],
    } = {},
  } = {}) => ({
    lobFilter,
    groupFilter,
    statusFilter,
    coachFilter,
    siteFilter,
    taskQueueFilter,
  }),
  selectorToSearchSelection = (state) =>
    state?.selection?.searchSelections ?? {};

const SET_INITIAL_SELECTION = 'SET_INITIAL_SELECTION';
export const UPDATE_LOB_FILTER = 'UPDATE_LOB_FILTER';
export const UPDATE_GROUP_FILTER = 'UPDATE_GROUP_FILTER';
export const UPDATE_STATUS_FILTER = 'UPDATE_STATUS_FILTER';
export const UPDATE_COACH_FILTER = 'UPDATE_COACH_FILTER';
export const UPDATE_SITE_FILTER = 'UPDATE_SITE_FILTER';
export const UPDATE_TASK_QUEUE_FILTER = 'UPDATE_TASK_QUEUE_FILTER';
export const UPDATE_USER_INPUT_FILTER = 'UPDATE_USER_INPUT_FILTER';

export const graphqlSelectionQueryToState = (payload) => ({
  type: SET_INITIAL_SELECTION,
  payload,
});
export const lobToUpateLobFilterState = (payload) => ({
  payload,
  type: UPDATE_LOB_FILTER,
});
export const groupToUpateGroupFilterState = (payload) => ({
  payload,
  type: UPDATE_GROUP_FILTER,
});
export const statusToUpateStatusFilterState = (payload) => ({
  payload,
  type: UPDATE_STATUS_FILTER,
});
export const statusToUpateCoachFilterState = (payload) => ({
  payload,
  type: UPDATE_COACH_FILTER,
});
export const statusToUpateSiteFilterState = (payload) => ({
  payload,
  type: UPDATE_SITE_FILTER,
});
export const taskQueueToUpdateTaskQueueFilterState = (payload) => ({
  payload,
  type: UPDATE_TASK_QUEUE_FILTER,
});
export const userInputSearch = (payload) => ({
  payload,
  type: UPDATE_USER_INPUT_FILTER,
});
// reducer
function selectionReducer(state = initialState, { type = '', payload }) {
  switch (type) {
    case SET_INITIAL_SELECTION: {
      const lobSelection = stringArrayToAbcSort(
        graphQlResToSelectionLobs(payload),
      );
      const available = graphQlResToAvailableGroups(payload);
      const { lobFilter = [], groupFilter = [] } = state;
      const { coachSelection, groupSelection, siteSelection } =
        lobFilterToGroupFilterToAvailableToSelection(lobFilter)(groupFilter)(
          available,
        );
      const statusSelection = stringArrayToAbcSort(
        graphQlResToSelectionStatus(payload),
      );
      const zipCodeSelection = graphQlResToSelectionExtension(payload);
      const taskQueueSelection =
        lobFilterToGroupFilterToAvailableToAvailableQueues(lobFilter)(
          groupFilter,
        )(available);
      return {
        ...state,
        lobSelection,
        statusSelection,
        groupSelection,
        coachSelection,
        siteSelection,
        zipCodeSelection,
        available,
        taskQueueSelection,
      };
    }
    case UPDATE_LOB_FILTER: {
      const lobFilter = selectionToClickedToFilter(state?.lobFilter)(payload);
      const {
        coachSelection,
        siteSelection,
        groupSelection,
        groupFilter,
        taskQueueSelection,
      } = lobFilterToGroupFilterToAvailableToSelection(lobFilter)(
        state?.groupFilter,
      )(state?.available);
      const coachFilter = state?.coachFilter?.filter((g) =>
        coachSelection.includes(g),
      );
      const siteFilter = state?.siteFilter?.filter((g) =>
        siteSelection.includes(g),
      );
      const taskQueueFilter = state?.taskQueueFilter?.filter((g) =>
        taskQueueSelection.includes(g),
      );

      return {
        ...state,
        lobFilter,
        groupSelection,
        coachSelection,
        siteSelection,
        taskQueueSelection,
        groupFilter,
        coachFilter,
        siteFilter,
        taskQueueFilter,
      };
    }
    case UPDATE_GROUP_FILTER: {
      const { lobFilter, available } = state;
      const groupFilter = selectionToClickedToFilter(state?.groupFilter)(
        payload,
      );
      const { coachSelection, siteSelection, taskQueueSelection } =
        lobFilterToGroupFilterToAvailableToSelection(lobFilter)(groupFilter)(
          available,
        );
      return {
        ...state,
        groupFilter,
        coachSelection,
        siteSelection,
        taskQueueSelection,
      };
    }
    case UPDATE_COACH_FILTER: {
      const coachFilter = selectionToClickedToFilter(state?.coachFilter)(
        payload,
      );
      return { ...state, coachFilter };
    }
    case UPDATE_TASK_QUEUE_FILTER: {
      const taskQueueFilter = selectionToClickedToFilter(
        state?.taskQueueFilter,
      )(payload);
      return { ...state, taskQueueFilter };
    }
    case UPDATE_SITE_FILTER: {
      const siteFilter = selectionToClickedToFilter(state?.siteFilter)(payload);
      return { ...state, siteFilter };
    }
    case UPDATE_STATUS_FILTER: {
      const statusFilter = selectionToClickedToFilter(state?.statusFilter)(
        payload,
        state?.statusSelection,
      );
      return {
        ...state,
        statusFilter,
      };
    }
    case UPDATE_USER_INPUT_FILTER: {
      return {
        ...state,
        fieldSearch: payload,
        searchSelections: fieldSearchToSearchQueryFilter(payload),
      };
    }
    case SAVE_USER_SETTINGS: {
      const {
        selectedLob: lobFilter = [],
        selectedCoach: coachFilter = [],
        selectedQueue: groupFilter = [],
        statusFilter = [],
      } = payload;
      const selections = lobFilterToGroupFilterToAvailableToSelection(
        lobFilter,
      )(groupFilter)(state?.available);
      return {
        ...state,
        lobFilter,
        coachFilter,
        groupFilter,
        statusFilter,
        // ...selections,
      };
    }
    case SAVE_USER_APP_SELECTION: {
      const { lob, group } = payload;
      const selections = lobFilterToGroupFilterToAvailableToSelection(lob)(
        group,
      )(state?.available);
      return { ...state, lobFilter: lob, groupFilter: group, ...selections };
    }
    default:
      return state;
  }
}
export default selectionReducer;
