import React from 'react';
import { MenuItem } from '@mui/material';
import { DEFAULT_FUNCTION } from '../../data/datatypes';

/**
 * Maps selections to MenuItems to be used in dropdown
 * @param {Function} onClick
 * @param {String} key
 * @returns
 */
export const mapSelections =
  (onClick = DEFAULT_FUNCTION) =>
  (text, i) => {
    return (
      <MenuItem key={text + i} value={text} onClick={() => onClick(text)}>
        {text}
      </MenuItem>
    );
  };
