import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import {
  TableHeaderCell,
  StickHeaderToTableTopLeft,
} from '../../WorkForceExpandedComponent/index';
import {
  directionToOpposingDirection,
  ASCENDING,
} from '../../Table/transformations';
import { useTheme } from '@emotion/react';

import { ConditionalDisplay } from '../../ConditionalDisplay';
import { GLOBAL_STATS_METRIC_CONTAINER_CLASS } from './constants';
import { DEFAULT_FUNCTION } from '../../../data/datatypes';
import { useSelector } from 'react-redux';

/**
 * @param {Array} headers
 * @returns Headers for table
 */
export const GlobalStatTableHeaders = ({ headers, ...props }) => {
  const theme = useTheme();
  const headerMap = headers?.map((e) => {
    return (
      <StickHeaderToTableTopLeft
        sx={{
          backgroundColor: 'neutral.background',
          borderBottom: `1px solid`,
          borderBottomColor: 'neutral.border',
          backgroundImage: 'none',
        }}
      >
        <TableHeaderCell text={e} {...props} />
      </StickHeaderToTableTopLeft>
    );
  });
  return <>{headerMap}</>;
};

/**
 * @param {String} sortKey
 * @param {String} sortDirection
 * @param {Function} sortMethod
 * @param {Function} rowCreation
 * @returns Collection of rows for global stats table
 */
export const GlobalDataRowPreparer = ({
  sortKey = '',
  sortDirection = ASCENDING,
  sortMethod = DEFAULT_FUNCTION,
  rowCreation = DEFAULT_FUNCTION,
} = {}) => {
  const { items } = useSelector((state) => state?.tableData?.globalExpertStats);
  return (
    <ConditionalDisplay bool={items?.length}>
      {items
        ?.sort(sortMethod(sortKey, sortDirection, items))
        ?.map((item, key) => rowCreation(item, key))}
    </ConditionalDisplay>
  );
};

/**
 * Creates table based on passed props
 * @param {Array} headers
 * @param {String} dimensions
 * @param {String} width
 * @param {Function} sortMethod
 * @param {Function} rowMapMethod
 * @param {Bool} useScrollBar
 * @param {Bool} includeTable
 * @param {Component} Boxes
 * @returns Table for global stats
 */
export const GlobalStatsTableComponent = ({
  headers = [''],
  dimensions = '100px',
  width = '100px',
  sortMethod = DEFAULT_FUNCTION,
  rowMapMethod = DEFAULT_FUNCTION,
  useScrollBar = false,
  includeTable = true,
  Boxes = <></>,
  props,
}) => {
  const theme = useTheme();

  const [headerSort, setHeaderSort] = useState(headers[0]);
  const [sortDirection, setSortDirection] = useState(
    directionToOpposingDirection(ASCENDING),
  );
  const handleHeaderSortChange = (incomingHeader) => {
    setSortDirection(
      directionToOpposingDirection(
        incomingHeader === headerSort ? sortDirection : '',
      ),
    );
    setHeaderSort(incomingHeader);
  };

  return (
    <ConditionalDisplay bool={includeTable}>
      <ConditionalDisplay bool={Boxes}>
        <div className={GLOBAL_STATS_METRIC_CONTAINER_CLASS}>{Boxes}</div>
      </ConditionalDisplay>
      <div
        style={{
          border: 'solid 1px',
          borderColor: 'neutral.border',
          width: 'fit-content',
          marginBottom: '10px',
        }}
      >
        <Grid container display="inline-grid" gridTemplateColumns={dimensions}>
          <GlobalStatTableHeaders
            setSort={handleHeaderSortChange}
            activeSort={headerSort}
            sortDirection={sortDirection}
            headers={headers}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              width: { width },
            }}
            height={'auto'}
            style={useScrollBar ? { overflowY: 'scroll' } : {}}
          >
            <GlobalDataRowPreparer
              sortKey={headerSort}
              sortDirection={sortDirection}
              sortMethod={sortMethod}
              rowCreation={rowMapMethod}
              {...props}
            />
          </Box>
        </Grid>
      </div>
    </ConditionalDisplay>
  );
};
