import React, { useEffect } from 'react';
import Twilio from 'twilio-sync';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Paper } from '@mui/material';
import Navbar from '../Navbar/';
import { mainNavRouteList } from '../../data/constants';
import {
  graphqlUpdateTableRefresh,
  selectorToArrayOfTableDataChanges,
} from '../../reducers/TableDataReducer';
import {
  setTimerWithUpdates,
  useScrollToTopOnTableDataChange,
} from './transformations';
import useUpdateUserSettings from '../../services/UpdateUserSettings';
import { graphqlSelectionQueryToState } from '../../reducers/SelectionReducer';

import { getEnvironment } from '../../utils/applicationEnvironment';
import config from '../../config.json';
import Amplify, { API } from 'aws-amplify';
import { ProtectedRoutes } from '../RoutingComponent';
import { useKibanaMamaLama } from '../../services/KibanaEvents';

const env = getEnvironment();
const {
  GRAPHQL_ENDPOINT,
  API_AUTH_KEYS: { GRAPHQL_ENDPOINT_KEY },
} = config[env];
const appConfig = {
  aws_appsync_graphqlEndpoint: GRAPHQL_ENDPOINT,
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: GRAPHQL_ENDPOINT_KEY,
};
Amplify.configure(appConfig);
const StaticSelectionsQl = `
  query MyQuery{
    listOrbitMaps {
      items {
        Key
        Map
      }
    }
    listLOBGroupMaps(limit: 1000) {
      items {
        group
        lob
        supervisorList
        taskqueues {
          items {
            taskqueueName
          }
        }
        siteList
      }
    }
  }
  `;
const useGraphQl = (dispatch) => {
  const graphqlUpdateTableInterval = setTimerWithUpdates(1000)(() => {
    dispatch(graphqlUpdateTableRefresh);
  });
  // graphql intervals should be in the unmount array
  useEffect(() => {
    let clearArrOnUnmount = [
      setInterval(() => graphqlUpdateTableInterval(), 7500),
    ];
    return () => clearArrOnUnmount.forEach((e) => clearInterval(e));
  }, []);
  useEffect(() => {
    // MAIN SELECTIONS FOR APP
    const asyncFetch = async () => {
      const selections = await API.graphql({
        query: StaticSelectionsQl,
      });
      dispatch(graphqlSelectionQueryToState(selections));
    };
    asyncFetch();
  }, []);
};

export const HandleSoftUpdates = () => {
  useUpdateUserSettings();
  useScrollToTopOnTableDataChange();
  return <></>;
};

const MainRoutes = () => {
  const dispatch = useDispatch();
  useGraphQl(dispatch);
  useKibanaMamaLama();

  return (
    <Paper square sx={{ minHeight: '100vh' }} elevation={0}>
      <HandleSoftUpdates />
      <Navbar links={mainNavRouteList} dispatch={dispatch}>
        <ProtectedRoutes links={mainNavRouteList} />
        {/* remove this redirect, to remove workforce as the main page.
         This would allow refresh, and stay on the current page */}
        <Redirect from="/" to="/work-force" />
      </Navbar>
    </Paper>
  );
};

export default MainRoutes;
