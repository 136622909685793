import React, { useState } from 'react';
import { MenuOpen, Menu } from '@mui/icons-material';
import {
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Drawer,
  List,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userAdgroupsToHasAccess } from '..';
import { selectorToUserReducer } from '../../../reducers/UserReducer';

const MobileNavListItem = (props) => {
  const { history, closeDrawer, routeName, friendlyName } = props;
  const { location: { pathname = '' } = {} } = history;
  return (
    <ListItem
      sx={{
        '&:hover': {
          bgcolor: 'primary.dark',
          cursor: 'pointer',
          color: 'primary.light',
        },
      }}
      onClick={() => {
        history?.push(routeName);
        closeDrawer();
      }}
    >
      <ListItemText>
        <Typography color={routeName === pathname ? 'primary.light' : null}>
          {friendlyName}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};
const navigationItems = ({ links, user, ...otherProps }) => {
  return links?.reduce((acc, { adGroup, ...linkProps }) => {
    if (userAdgroupsToHasAccess(user, adGroup)) {
      const { routeName } = linkProps;
      acc.push(
        <MobileNavListItem key={routeName} {...otherProps} {...linkProps} />,
      );
    }
    return acc;
  }, []);
};
const mobileNavIcon = {
  sx: { position: 'absolute', right: '15px', zIndex: 99999 },
};
const MobileNavBurger = ({ open, click }) => (
  <IconButton {...mobileNavIcon} onClick={click}>
    {open ? <MenuOpen /> : <Menu />}
  </IconButton>
);

const MobileNavListRoutes = (props) => {
  const { closeDrawer } = props;
  const history = useHistory();
  const user = useSelector(selectorToUserReducer);
  const navItems = navigationItems({
    ...props,
    history,
    user,
    closeDrawer,
  });
  return <List sx={{ width: '300px', marginTop: '5vh' }}>{navItems}</List>;
};
const MobileNavBar = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const closeDrawer = () => setOpenDrawer(false);
  return (
    <>
      <Drawer open={openDrawer} onClose={closeDrawer} anchor="right">
        <MobileNavListRoutes {...props} closeDrawer={closeDrawer} />
      </Drawer>
      <MobileNavBurger
        open={openDrawer}
        click={() => setOpenDrawer((bool) => !bool)}
      />
    </>
  );
};
export default MobileNavBar;
