import React from 'react';
import { GenericLabelText } from '../GenericLabelText';

import './index.css';

export const METRIC_CLASS = 'metricComponent',
  STAT_CLASS = 'statClass';

export const MetricComponent = ({ value, label }) => {
  return (
    <div>
      <div className={METRIC_CLASS}>{label}</div>
      <GenericLabelText text={value} className={STAT_CLASS} />
    </div>
  );
};

export default MetricComponent;
