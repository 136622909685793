import React from 'react';
import { useSelector } from 'react-redux';
import './index.css';
import { IconComponent } from '../IconComponent';

const MATERIAL_CIRCLE_ICON = 'circle',
  MATERIAL_OFFLINE_ICON = 'cancel',
  ONLINE_STYLE_CLASS = 'online-stat status-bubble',
  OFFLINE_STYLE_CLASS = 'offline-stat status-bubble',
  BUSY_STYLE_CLASS = 'busy-stat status-bubble',
  AUX_STYLE_CLASS = 'aux-stat status-bubble';

const statusToClass = (status) => {
  switch (status) {
    case 'available':
      return ONLINE_STYLE_CLASS;
    case 'busy':
      return BUSY_STYLE_CLASS;
    case 'offline':
    case 'unavailable':
      return OFFLINE_STYLE_CLASS;
    default:
      return AUX_STYLE_CLASS;
  }
};

const statusToIconType = (status) => {
  switch (status) {
    case 'offline':
    case 'unavailable':
      return MATERIAL_OFFLINE_ICON;
    default:
      return MATERIAL_CIRCLE_ICON;
  }
};

const StatusBubble = ({ status = '', name = '' } = {}) => {
  const modifiedStatus = status?.toLowerCase?.() ?? '';
  const statusForStyle = statusToClass(modifiedStatus);
  const statusForIcon = statusToIconType(modifiedStatus);
  return (
    <span>
      <IconComponent
        icon={statusForIcon}
        styleClass={statusForStyle}
        iconTitle={'status bubble'}
      />
      {name}
    </span>
  );
};
export default StatusBubble;
