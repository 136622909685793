import React, { useState, useCallback } from 'react';
import UbifSubSection from './UbifSubSection';
import { DebounceApiInput } from '../Dialog';
import { fetchUbifFormWithDN, postUbifStore } from '../../services/Ubif';
import { withLoggingAnalytics } from '../../services/KibanaEvents';
import {
  selectorToHelixUser,
  selectorToUserDarkMode,
} from '../../reducers/UserReducer';
import CtaText from '../CtaTextComponent';
import {
  FormHelperText,
  TextField,
  Container,
  Button,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  NativeSelect,
  Stack,
} from '@mui/material';
import './index.scss';
import moment from 'moment';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
const hardCodedDates = ['01/01', '07/04', '12/25'];
const readDate = {
  '01/01': 'New Years Eve',
  '07/04': 'Independence Day',
  '12/25': 'Christmas',
};

const initialValue = {
  DN: '',
  Store_Number: '',
  Actual_Phone_Number: '',
  Store_Name: '',
  Store_Email: [],
  Store_Holidays: {
    Static: hardCodedDates,
    Dynamic: [],
  },
  Store_Address: {
    Zip: '',
    Street: '',
    City: '',
    State: '',
  },
  Store_District_Market: '',
  Store_Location: '',
  Map_Location: '',
  Directions: '',
  East_West_Canada: '',
  Tax_Rate: '',
  Transfer_Endpoint: '',
  Legacy_Skill: '',
  Legacy_VDN: '',
  Lead_Gen_Pilot: false,
  Remote_Tech: false,
  Call_Center_Transfer: false,
  Voice_Bot: false,
  RoutingToStore: false,
  Enqueue_To_Store: false,
  Asurion_Rebrand: false,
  LOB: '',
  Client: '',
  Language: '',
  RoutingQueue: '',
  Whisper_Tone: '',
  WhisperTTS: '',
  Store_HOO: '',
  Lang_Menu_Selection: '',
  S3_Media_Environment: '',
  Store_Hours: {
    Monday: 'CLOSED',
    Tuesday: 'CLOSED',
    Wednesday: 'CLOSED',
    Thursday: 'CLOSED',
    Friday: 'CLOSED',
    Saturday: 'CLOSED',
    Sunday: 'CLOSED',
  },
  Store_Timezone: '',
  Important_Info: '',
};
const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

/* Custom Components */
function TextEntries(props) {
  const theme = useTheme();
  return (
    <div className="text-entries">
      <TextField
        {...props}
        autoComplete="off"
        variant="outlined"
        fullWidth
        style={{ background: theme?.palette?.background?.dark }}
      />
    </div>
  );
}

// datePickerToString :: String -> String
// ei "2021-06-29" -> "06/29/2021"
export const datePickerToString = (date) => {
  const month = date.slice(date.indexOf('-') + 1, date.lastIndexOf('-'));
  const day = date.slice(date.lastIndexOf('-') + 1);
  const year = date.slice(0, date.indexOf('-'));
  return month + '/' + day + '/' + year;
};
// ei  "06/29/2021 -> "2021-06-29""
export const dateStringToDatePicker = (date = '') => {
  if (date === '') {
    return '';
  }
  const year = date.slice(date.lastIndexOf('/') + 1);
  const month = date.slice(0, date.indexOf('/'));
  const day = date.slice(date.indexOf('/') + 1, date.lastIndexOf('/'));
  return year + '-' + month + '-' + day;
};
export const readTodaysDate = (format = 'MM/DD/YYYY') =>
  moment().format(format);

function StoreHolidays({
  data: { Store_Holidays: { Static = [], Dynamic = [] } = {} },
  change,
  hasEditPermissions,
} = {}) {
  const handleChangeInput = (date, event) => {
    change((state) => {
      const { Dynamic } = state.Store_Holidays;
      const index = Dynamic.indexOf(date);
      if (index !== -1) {
        Dynamic.splice(index, 1, datePickerToString(event.target.value));
      }
      return {
        ...state,
        Store_Holidays: {
          ...state.Store_Holidays,
          Dynamic,
        },
      };
    });
  };

  const handleAddFields = () => {
    const date = readTodaysDate();
    change((state) => ({
      ...state,
      Store_Holidays: {
        ...state.Store_Holidays,
        Dynamic: state.Store_Holidays.Dynamic.includes(date)
          ? state.Store_Holidays.Dynamic
          : [...state.Store_Holidays.Dynamic, date],
      },
    }));
  };

  const handleRemoveFields = (date) => {
    change((state) => {
      const { Dynamic } = state.Store_Holidays;
      const index = Dynamic.indexOf(date);
      if (index !== -1) {
        Dynamic.splice(index, 1);
      }
      return {
        ...state,
        Store_Holidays: {
          ...state.Store_Holidays,
          Dynamic,
        },
      };
    });
  };

  const dateToReadDate = (date) => readDate[date];
  return (
    <Container>
      <FormControl>
        <Stack gap={2}>
          {Static.map((staticDate) => (
            <div key={staticDate}>
              <span>{staticDate} - </span>
              <span>{dateToReadDate(staticDate)}</span>
            </div>
          ))}
          <Button
            disabled={!hasEditPermissions}
            variant="contained"
            onClick={withLoggingAnalytics(
              'ubif-add-date-button',
              handleAddFields,
            )}
          >
            Add Date
          </Button>
          {Dynamic.map((dateString) => (
            <div key={dateString}>
              <TextField
                label="Choose A Date"
                type="date"
                defaultValue={dateStringToDatePicker(dateString)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  // console.log(typeof event.target.value, event.target.value);
                  return handleChangeInput(dateString, event);
                }}
              />
              <IconButton onClick={() => handleRemoveFields(dateString)}>
                <RemoveIcon />
              </IconButton>
            </div>
          ))}
        </Stack>
      </FormControl>
    </Container>
  );
}
/* function takes in string, returns hour
  '01:44-2:33' => '01' */
const stringTimeStampToFormatTime = (time) => {
  if (time === 'CLOSED') {
    return {
      startTime: '00:00',
      endTime: '00:00',
    };
  }
  const [startTime, endTime] = time.split('-');
  return {
    startTime,
    endTime,
  };
};
const stringTransformation = (s1) => (s2) => `${s1}-${s2}`;
function StoreHours({ day = '', startTime, endTime, dispatch }) {
  const formatHours = (day, e) => {
    let formattedString = 'CLOSED';
    switch (e.target.name) {
      case 'start-time': {
        const format = stringTransformation(e.target.value)(endTime);
        formattedString = format === '00:00-00:00' ? 'CLOSED' : format;
        break;
      }
      case 'end-time': {
        const format = stringTransformation(startTime)(e.target.value);
        formattedString = format === '00:00-00:00' ? 'CLOSED' : format;
        break;
      }
    }
    dispatch(day, formattedString);
  };
  const theme = useTheme();
  return (
    <div className="store-hours-style">
      <div className="day-name">{day}</div>
      <div>
        <TextField
          label="Start Time"
          variant="outlined"
          name="start-time"
          value={startTime}
          type="time"
          onChange={(event) => formatHours(day, event)}
          style={{ background: theme?.palette?.background?.dark }}
        />
        <span className="col">-</span>
        <TextField
          label="End Time"
          variant="outlined"
          name="end-time"
          value={endTime}
          type="time"
          onChange={(event) => formatHours(day, event)}
          style={{ background: theme?.palette?.background?.dark }}
        />
      </div>
    </div>
  );
}

const UbifComponent = (props) => {
  const [ubifData, setUbifData] = useState(initialValue);
  const user = useSelector(selectorToHelixUser);

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (value === 'true' || value === 'false') {
      value = value === 'true' ? true : false;
    }
    setUbifData({ ...ubifData, [name]: value });
  };

  const handleChangeEmail = (event) => {
    const { name, value } = event.target;
    setUbifData({ ...ubifData, [name]: [value] });
  };

  const handleStoreAddressChange = (event) => {
    const { name, value } = event.target;
    setUbifData({
      ...ubifData,
      Store_Address: {
        ...ubifData.Store_Address,
        [name]: value,
      },
    });
  };

  const handleDayHoursChange = (day, value) => {
    setUbifData({
      ...ubifData,
      Store_Hours: {
        ...ubifData.Store_Hours,
        [day]: value,
      },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents page refresh
    postUbifStore(ubifData)(user);
    clearForm();
  };

  const handleDnApiSearch = useCallback(
    (dnId) => {
      setUbifData((pVal) => ({ ...pVal, DN: dnId }));
      return new Promise(async (resolve) => {
        if (dnId.length === 11) {
          const empIdToQuery = {
            DN: Number(dnId),
          };
          fetchUbifFormWithDN(empIdToQuery)
            .then((data) => {
              setUbifData((pVal) => ({ ...pVal, ...data }));
              return false;
            })
            .catch(() => true)
            .then(resolve);
        } else {
          resolve(true);
        }
      });
    },
    [setUbifData],
  );

  const clearForm = () => {
    setUbifData({ ...initialValue });
  };
  return (
    <div>
      <form className="form-class-container">
        <div className="store-information">
          <h2>Store Information</h2>
          <CtaText text="Reset Form" callToAction={clearForm} />
          <div className="store-information-top">
            <UbifSubSection title="Contact Information">
              <DebounceApiInput
                value={ubifData.DN}
                onChange={handleDnApiSearch}
                id="dn"
                label="DN"
                required
                name="DN"
              />
              <TextEntries
                id="store-number"
                label="Store Number"
                required
                name="Store_Number"
                value={ubifData.Store_Number}
                onChange={handleChange}
              />
              <TextEntries
                id="Actual_Phone_Number"
                label="Actual Phone Number"
                name="Actual_Phone_Number"
                value={ubifData.Actual_Phone_Number}
                onChange={handleChange}
              />
              <TextEntries
                id="store-name"
                label="Store Name"
                name="Store_Name"
                value={ubifData.Store_Name}
                onChange={handleChange}
              />
              <TextEntries
                id="store-email"
                label="Store Email"
                name="Store_Email"
                value={ubifData.Store_Email}
                onChange={handleChangeEmail}
              />
            </UbifSubSection>
            <UbifSubSection title="Store Location/Region">
              <FormControl id="store-location-region-top" autoComplete="off">
                <Stack gap={2}>
                  <div>
                    <TextEntries
                      id="street-address"
                      label="Street Address"
                      name="Street"
                      value={ubifData.Store_Address['Street']}
                      onChange={handleStoreAddressChange}
                    />
                  </div>

                  <div className="store-location-address-bottom">
                    <TextEntries
                      id="street-address-city"
                      label="City"
                      name="City"
                      value={ubifData.Store_Address['City']}
                      onChange={handleStoreAddressChange}
                    />

                    <TextEntries
                      id="street-address-state"
                      label="State"
                      name="State"
                      value={ubifData.Store_Address['State']}
                      onChange={handleStoreAddressChange}
                    />

                    <TextEntries
                      id="street-address-zip"
                      label="Zip"
                      name="Zip"
                      value={ubifData.Store_Address['Zip']}
                      onChange={handleStoreAddressChange}
                    />
                  </div>
                </Stack>
              </FormControl>

              <div>
                <TextEntries
                  id="directions"
                  label="Directions"
                  rows={3}
                  multiline
                  name="Directions"
                  value={ubifData.Directions}
                  onChange={handleChange}
                />
              </div>

              <div className="store-location-region-middle">
                <TextEntries
                  id="store-district-market"
                  label="Store District Market"
                  name="Store_District_Market"
                  value={ubifData.Store_District_Market}
                  onChange={handleChange}
                />

                <TextEntries
                  id="store-location"
                  label="Store Location"
                  name="Store_Location"
                  value={ubifData.Store_Location}
                  onChange={handleChange}
                />

                <TextEntries
                  id="map-location"
                  label="Map Location"
                  name="Map_Location"
                  value={ubifData.Map_Location}
                  onChange={handleChange}
                />
              </div>

              <div className="store-location-region-dropdowns">
                <div>
                  <FormControl margin="dense">
                    <FormHelperText>STORE TIME ZONE</FormHelperText>
                    <NativeSelect
                      inputProps={{
                        id: 'Store_Timezone',
                      }}
                      name="Store_Timezone"
                      value={ubifData.Store_Timezone}
                      onChange={handleChange}
                    >
                      <option value="" />
                      <option value="America/Chicago">America/Chicago</option>
                      <option value="America/Denver">America/Denver</option>
                      <option value="America/Indiana/Indianapolis">
                        America/Indiana, Indianapolis
                      </option>
                      <option value="America/Kentucky/Louisville">
                        America/Kentucky, Louisville
                      </option>
                      <option value="America/Los_Angeles">
                        America/Los Angeles
                      </option>
                      <option value="America/New_York">America/New York</option>
                      <option value="America/Phoenix">America/Phoenix</option>
                    </NativeSelect>
                  </FormControl>
                </div>

                <div>
                  <FormControl margin="dense">
                    <FormHelperText>EAST/WEST CANADA</FormHelperText>
                    <NativeSelect
                      inputProps={{
                        id: 'East_West_Canada',
                      }}
                      name="East_West_Canada"
                      value={ubifData.East_West_Canada}
                      onChange={handleChange}
                    >
                      <option value="" />
                      <option value="East">East</option>
                      <option value="West">West</option>
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
            </UbifSubSection>
            <UbifSubSection title="Store Metadata">
              <div className="store-metadata-text-entries">
                <div className="store-metadata-text-entries-left">
                  <TextEntries
                    id="tax-rate"
                    label="Tax Rate"
                    name="Tax_Rate"
                    value={ubifData.Tax_Rate}
                    onChange={handleChange}
                  />
                  <TextEntries
                    id="transfer-endpoint"
                    label="Transfer Endpoint"
                    name="Transfer_Endpoint"
                    value={ubifData.Transfer_Endpoint}
                    onChange={handleChange}
                  />
                </div>
                <div className="store-metadata-text-entries-right">
                  <TextEntries
                    id="legacy-skill"
                    label="Legacy Skill"
                    name="Legacy_Skill"
                    value={ubifData.Legacy_Skill}
                    onChange={handleChange}
                  />
                  <TextEntries
                    id="legacy-vdn"
                    label="Legacy VDN"
                    name="Legacy_VDN"
                    value={ubifData.Legacy_VDN}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="store-metadata-radio-buttons">
                <div className="store-metadata-radio-buttons-left">
                  <div>
                    <FormControl id="Lead_Gen_Pilot" margin="dense">
                      <FormHelperText>LEAD GEN PILOT</FormHelperText>

                      <RadioGroup
                        name="Lead_Gen_Pilot"
                        row
                        color="primary"
                        value={ubifData.Lead_Gen_Pilot}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div>
                    <FormControl margin="dense">
                      <FormHelperText>REMOTE TECH</FormHelperText>
                      <RadioGroup
                        name="Remote_Tech"
                        value={ubifData.Remote_Tech}
                        row
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div>
                    <FormControl margin="dense">
                      <FormHelperText>ENQUEUE TO STORE</FormHelperText>
                      <RadioGroup
                        name="Enqueue_To_Store"
                        value={ubifData.Enqueue_To_Store}
                        row
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl margin="dense">
                      <FormHelperText>ASURION REBRAND</FormHelperText>
                      <RadioGroup
                        name="Asurion_Rebrand"
                        value={ubifData.Asurion_Rebrand}
                        row
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>

                <div className="store-metadata-radio-buttons-right">
                  <div>
                    <FormControl margin="dense">
                      <FormHelperText>CALL CENTER TRANSFER</FormHelperText>
                      <RadioGroup
                        name="Call_Center_Transfer"
                        value={ubifData.Call_Center_Transfer}
                        row
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl margin="dense">
                      <FormHelperText>VOICE BOT</FormHelperText>
                      <RadioGroup
                        name="Voice_Bot"
                        value={ubifData.Voice_Bot}
                        row
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl margin="dense">
                      <FormHelperText>ROUTE TO STORE</FormHelperText>
                      <RadioGroup
                        name="RoutingToStore"
                        value={ubifData.RoutingToStore}
                        row
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>

              <div className="store-metadata-dropdowns">
                <div>
                  <FormControl margin="dense">
                    <FormHelperText>LOB</FormHelperText>
                    <NativeSelect
                      inputProps={{
                        id: 'LOB',
                      }}
                      name="LOB"
                      value={ubifData.LOB}
                      onChange={handleChange}
                    >
                      <option value="" />
                      <option value="UBIF">UBIF</option>
                    </NativeSelect>
                  </FormControl>
                </div>

                <div>
                  <FormControl margin="dense">
                    <FormHelperText>CLIENT</FormHelperText>
                    <NativeSelect
                      inputProps={{
                        id: 'Client',
                      }}
                      name="Client"
                      value={ubifData.Client}
                      onChange={handleChange}
                    >
                      <option value="" />
                      <option value="UBIF">UBIF</option>
                    </NativeSelect>
                  </FormControl>
                </div>

                <div>
                  <FormControl margin="dense">
                    <FormHelperText>LANGUAGE</FormHelperText>
                    <NativeSelect
                      inputProps={{
                        id: 'Language',
                      }}
                      name="Language"
                      value={ubifData.Language}
                      onChange={handleChange}
                    >
                      <option value="" />
                      <option value="en-us">en-us</option>
                    </NativeSelect>
                  </FormControl>
                </div>

                <div>
                  <FormControl margin="dense">
                    <FormHelperText>ROUTING QUEUE</FormHelperText>
                    <NativeSelect
                      inputProps={{
                        id: 'RoutingQueue',
                      }}
                      name="RoutingQueue"
                      value={ubifData.RoutingQueue}
                      onChange={handleChange}
                    >
                      <option value="" />
                      <option value="UBIF_Stores_2960">UBIF_Stores_2960</option>
                      <option value="ATRS_Stores_5006">ATRS_Stores_5006</option>
                      <option value="UBIF_Stores_Project_Mercury_5004">
                        UBIF_Stores_Project_Mercury_5004
                      </option>
                      <option value="UBIF_MSRPTest_5017">
                        UBIF_MSRPTest_5017
                      </option>
                      <option value="UBIF_ModelGenerationTest_5018">
                        UBIF_ModelGenerationTest_5018
                      </option>
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
              <div>
                <TextEntries
                  id="Important_Info"
                  label="Important Information"
                  rows={3}
                  multiline
                  name="Important_Info"
                  value={ubifData.Important_Info}
                  onChange={handleChange}
                />
              </div>
            </UbifSubSection>
            <UbifSubSection title="Media">
              <TextEntries
                id="whisper_tone"
                label="Whisper Tone"
                fullWidth
                name="Whisper_Tone"
                value={ubifData.Whisper_Tone}
                onChange={handleChange}
              />

              <TextEntries
                id="whisper_tts"
                label="Whisper TTS"
                fullWidth
                name="WhisperTTS"
                value={ubifData.WhisperTTS}
                onChange={handleChange}
              />

              <TextEntries
                id="store-hoo"
                label="Store HOO Media"
                fullWidth
                name="Store_HOO"
                value={ubifData.Store_HOO}
                onChange={handleChange}
              />

              <TextEntries
                id="lang_menu_selection"
                label="Language Menu Selection"
                fullWidth
                name="Lang_Menu_Selection"
                value={ubifData.Lang_Menu_Selection}
                onChange={handleChange}
              />

              <div>
                <FormControl margin="dense">
                  <FormHelperText>S3 MEDIA ENVIRONMENT</FormHelperText>
                  <NativeSelect
                    inputProps={{
                      id: 'S3_Media_Environment',
                    }}
                    name="S3_Media_Environment"
                    value={ubifData.S3_Media_Environment}
                    onChange={handleChange}
                  >
                    <option value="" />
                    <option value="PROD-B">PROD-B</option>
                    <option value="QA">QA</option>
                  </NativeSelect>
                </FormControl>
              </div>
            </UbifSubSection>
            <UbifSubSection title="Store Hours">
              <FormControl id="store-hours">
                {daysOfWeek.map((dayName) => {
                  const { startTime, endTime } = stringTimeStampToFormatTime(
                    ubifData.Store_Hours[dayName],
                  );
                  return (
                    <StoreHours
                      key={dayName}
                      day={dayName}
                      startTime={startTime}
                      endTime={endTime}
                      dispatch={handleDayHoursChange}
                    />
                  );
                })}
              </FormControl>
            </UbifSubSection>
            <UbifSubSection title="Store Holidays">
              <StoreHolidays
                hasEditPermissions={props.hasEditPermissions}
                data={ubifData}
                change={setUbifData}
              />
            </UbifSubSection>
          </div>
        </div>

        <Button
          disabled={!props?.hasEditPermissions ?? true}
          variant="contained"
          color="primary"
          type="submit"
          onClick={withLoggingAnalytics('ubif-submit-button', (event) =>
            handleSubmit(event),
          )}
        >
          SUBMIT
        </Button>
      </form>
    </div>
  );
};

export default UbifComponent;
