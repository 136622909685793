import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CtaText from '../../CtaTextComponent';
import { DebounceApiInput } from '../../Dialog';
import updateExpertAttributes, {
  fetchExpertSkills,
} from '../../../services/Experts';
import { SelectMenu } from '../../AddNewExpertButton';

import {
  EnabledTaskQTable,
  DisabledTaskQTable,
  checkedIdsToIsAllSelected,
} from '../index';
import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import './index.css';
import MultiOptionToggle from '../../MultiOptionToggle';
import { ConditionalDisplay } from '../../ConditionalDisplay';
import { StyledTooltip } from '../../ExpertStatus/wand';
import { selectorToCheckedIds } from '../../../reducers/ExpertReducer';
import { selectorToExpertTableSortedCount } from '../../../reducers/TableDataReducer';
import { Group as GroupIcon } from '@mui/icons-material';
import { arrayToRemoveDups } from '../../../transformations/utils';
import { abcSort } from '../../Table/transformations';
import {
  selectorToSearchSelection,
  selectorToSelectionFilters,
} from '../../../reducers/SelectionReducer';
import { selectorToHelixUser } from '../../../reducers/UserReducer';
import { DEFAULT_FUNCTION } from '../../../data/datatypes';
import ButtonOpenDialog from '../../ButtonOpenDialog';

const FULL_EDIT_OPTION = 'Full Edit',
  ADD_QUEUE_OPTION = 'Add Queue',
  REMOVE_QUEUE_OPTION = 'Remove Queue';
const containerAlignPadding = '0px 8px';
const lobsToGroupAvailableSelections = (lobSelection, available) => {
  return available
    ?.reduce((acc, { lob, group }) => {
      if (lobSelection?.includes(lob) && !acc?.includes(group)) {
        acc.push(group);
      }
      return acc;
    }, [])
    .sort(abcSort);
};
const LobGroupDropDownDisplay = ({
  setLob,
  setGroup,
  selectedLob,
  selectedGroup,
}) => {
  const { lobSelection, available } = useSelector((s) => s?.selection ?? {});
  const groupSelections = lobsToGroupAvailableSelections(
    selectedLob,
    available,
  );

  const addStringToExisting = (fx) => (e) => {
    fx(e);
  };
  return (
    <Stack
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="center"
      gap={2}
    >
      <SelectMenu
        label={'Line of business'}
        selections={lobSelection}
        value={selectedLob}
        onChange={addStringToExisting(setLob)}
      />
      <SelectMenu
        label={'Group'}
        selections={groupSelections}
        value={selectedGroup}
        onChange={addStringToExisting(setGroup)}
      />
    </Stack>
  );
};

const SelectedExpertMoreDetailsToolTip = (props) => {
  const { checkedIds } = props;
  const display =
    checkedIds?.map?.(({ worker_Id, name }) => (
      <Typography key={name + worker_Id}>
        {name} {worker_Id}
      </Typography>
    )) ?? [];
  return (
    <Stack spacing={2} className="handle-custom-scroll" maxHeight="95vh">
      {display}
    </Stack>
  );
};
const SelectedExpertDisplay = (props) => {
  const { checkedIds, totalViewCount, allSelected } = props;
  const baseCountDisplay = `${
    allSelected ? totalViewCount : checkedIds?.length
  } experts selected to be updated`;

  if (allSelected) {
    return (
      <Typography sx={{ padding: containerAlignPadding }}>
        {baseCountDisplay}
      </Typography>
    );
  }

  return (
    <StyledTooltip
      sx={{ padding: containerAlignPadding }}
      arrow={true}
      placement="left"
      title={<SelectedExpertMoreDetailsToolTip checkedIds={checkedIds} />}
    >
      <Stack flexDirection="row" gap={1} alignItems="center">
        <GroupIcon color="primary" />
        <Typography>{baseCountDisplay}</Typography>
      </Stack>
    </StyledTooltip>
  );
};
const SearchExpertClone = ({ setQueueLevels, setLob, setGroup }) => {
  const apiSearchId = (emp_id) => {
    return new Promise(async (resolve) => {
      if (emp_id.length > 5) {
        try {
          const { routing, lob, group } = await fetchExpertSkills(emp_id);
          setQueueLevels?.(routing?.taskQueueLevels ?? {});
          setLob?.(lob);
          setGroup?.(group);
          resolve(false);
        } catch (err) {
          return resolve(true);
        }
      }
    });
  };
  return (
    <DebounceApiInput
      onChange={apiSearchId}
      value={''}
      label="Clone Existing Expert"
      disabled={false}
    />
  );
};

const typeOfEditToTitle = (typeOfEdit) => {
  switch (typeOfEdit) {
    case FULL_EDIT_OPTION:
      return 'Active Skills';
    case ADD_QUEUE_OPTION:
      return 'Add Skills';
    case REMOVE_QUEUE_OPTION:
      return 'Remove Skills';
    default:
      return 'Skills';
  }
};
const DisplayExpertQueueEdit = ({
  typeOfEdit,
  queueLevels,
  setQueueLevels,
  selectedLob,
  selectedGroup,
}) => {
  const enabledQueueKeys = Object?.keys(queueLevels) ?? [];
  const available = useSelector((s) => s?.selection?.available ?? []);
  const disabledQueues = arrayToRemoveDups(
    available.reduce((acc, { lob, group, taskqueues = [] }) => {
      if (selectedLob?.includes(lob) && selectedGroup?.includes(group)) {
        acc.push(...taskqueues.filter((q) => !enabledQueueKeys.includes(q)));
      }
      return acc;
    }, []),
  );
  const selectedQueueTableTitle = typeOfEditToTitle(typeOfEdit);

  return (
    <Stack
      justifyContent="center"
      alignItems="flex-start"
      display="inline-flex"
      flexDirection="row"
      width="max-content"
      minWidth="100%"
      height={'45%'}
      minHeight="150px"
      gap={2}
    >
      <EnabledTaskQTable
        title={selectedQueueTableTitle}
        skillsObj={queueLevels}
        onClick={(d) => {
          setQueueLevels((q) => {
            const copy = { ...q };
            delete copy[d];
            return copy;
          });
        }}
        onChange={(d) => (e) => {
          e.persist();
          setQueueLevels((q) => {
            const copy = { ...q };
            copy[d] = Number(e?.target?.value) ?? 2;
            return copy;
          });
        }}
      />
      <DisabledTaskQTable
        disabledSkills={disabledQueues}
        onClick={(d) => {
          setQueueLevels((q) => ({ ...q, [d]: 2 }));
        }}
      />
    </Stack>
  );
};

const submitExpertUpdates = async (
  lobModalSelection,
  checkedIds,
  groupModalSelection,
  orbitSearchSelection,
  helixUser,
  typeOfEdit,
  allSelected,
  userSelections,
  queueLevels,
  applyPermanent,
) => {
  const appendQueue = typeOfEdit === ADD_QUEUE_OPTION,
    removeQueue = typeOfEdit === REMOVE_QUEUE_OPTION;
  const taskQueues = Object.keys(queueLevels);
  let reqObject = {
    lob: lobModalSelection,
    group: groupModalSelection,
    updateTemplate: applyPermanent,
    routing: { taskQueues, taskQueueLevels: queueLevels },
    routingUpdatedBy: {
      routingUpdatedByUserName: helixUser.userId,
      routingUpdatedByUserID: helixUser.employeeId,
    },
    appendQueue,
    removeQueue,
  };
  if (allSelected) {
    reqObject = {
      ...reqObject,
      allSelected,
      userSelections,
      searchSelections: orbitSearchSelection,
    };
  } else {
    reqObject = {
      ...reqObject,
      emp_ids: checkedIds.map(({ worker_Id }) => worker_Id),
    };
  }
  await updateExpertAttributes(reqObject);
};
const EditExperts = ({ disableSubmit, setCallBack }) => {
  const checkedIds = useSelector(selectorToCheckedIds);
  const totalViewCount = useSelector(selectorToExpertTableSortedCount);
  const orbitSelectionFilters = useSelector(selectorToSelectionFilters);
  const orbitSearchSelection = useSelector(selectorToSearchSelection);
  const helixUser = useSelector(selectorToHelixUser);

  const allSelected = checkedIdsToIsAllSelected(checkedIds);

  const [typeOfEdit, setTypeOfEdit] = useState(FULL_EDIT_OPTION);
  const [selectedLob, setSelectedLob] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [queueLevels, setQueueLevels] = useState({});
  const [confirmAll, setConfirmAll] = useState(false);

  const typeEditIsAppendQueue = typeOfEdit === ADD_QUEUE_OPTION;

  const noTaskQueuesSelected = !(Object.keys(queueLevels) ?? [])?.length ?? 0;
  useEffect(() => {
    disableSubmit?.(
      !selectedLob?.length ||
        !selectedGroup?.length ||
        noTaskQueuesSelected ||
        (allSelected && confirmAll === false),
    );
  }, [
    disableSubmit,
    selectedLob,
    selectedGroup,
    allSelected,
    confirmAll,
    noTaskQueuesSelected,
  ]);

  useEffect(() => {
    //applyPermanently is passed in from ButtonOpenDialog upon submitting
    const modCallBack = () => async (applyPermanently) => {
      await submitExpertUpdates(
        selectedLob,
        checkedIds,
        selectedGroup,
        orbitSearchSelection,
        helixUser,
        typeOfEdit,
        allSelected,
        orbitSelectionFilters,
        queueLevels,
        applyPermanently,
      );
    };
    setCallBack(modCallBack);
  }, [
    setCallBack,
    selectedLob,
    checkedIds,
    selectedGroup,
    orbitSearchSelection,
    helixUser,
    typeOfEdit,
    allSelected,
    orbitSelectionFilters,
    queueLevels,
  ]);

  return (
    <Stack
      width={'100%'}
      height={'100%'}
      display="flex"
      justifyContent={'flex-start'}
      gap={1}
    >
      <Stack width={'100%'} display="flex" flexDirection="column" gap={1}>
        <MultiOptionToggle
          options={[FULL_EDIT_OPTION, ADD_QUEUE_OPTION, REMOVE_QUEUE_OPTION]}
          selected={typeOfEdit}
          setSelected={setTypeOfEdit}
        />
        <ConditionalDisplay bool={!typeEditIsAppendQueue}>
          <SearchExpertClone
            setQueueLevels={setQueueLevels}
            setLob={setSelectedLob}
            setGroup={setSelectedGroup}
          />
        </ConditionalDisplay>
        <Stack>
          <ConditionalDisplay bool={allSelected}>
            <FormControlLabel
              label={`Are you sure you want to update all ${totalViewCount} selected experts?*`}
              control={
                <Checkbox
                  checked={confirmAll}
                  onChange={() => setConfirmAll((bool) => !bool)}
                  size="medium"
                  disableRipple={true}
                />
              }
            />
          </ConditionalDisplay>
        </Stack>
        <LobGroupDropDownDisplay
          selectedLob={selectedLob}
          setLob={setSelectedLob}
          setGroup={setSelectedGroup}
          selectedGroup={selectedGroup}
        />
      </Stack>
      <DisplayExpertQueueEdit
        typeOfEdit={typeOfEdit}
        queueLevels={queueLevels}
        setQueueLevels={setQueueLevels}
        selectedLob={selectedLob}
        selectedGroup={selectedGroup}
      />
    </Stack>
  );
};
const EditCtaText = () => (
  <CtaText text="Edit" color="secondary.contrastText" />
);
export const ButtonWithEditExpertDialog = () => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [cb, setCallBack] = useState(DEFAULT_FUNCTION);

  return (
    <ButtonOpenDialog
      title="Edit Experts"
      CtaIcon={EditCtaText}
      cb={cb}
      disabledSubmit={disableSubmit}
      toolTipTitle="Edit Selected Experts"
      footerActionText="Apply Permanently"
      Render={
        <EditExperts
          disableSubmit={setDisableSubmit}
          setCallBack={setCallBack}
        />
      }
    />
  );
};

export default ButtonWithEditExpertDialog;
