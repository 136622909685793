import axios from 'axios';
import { API } from 'aws-amplify';

export const apiKeyToFormattedHeader = (key) => ({
  'x-api-key': key,
});

export const fetchApiData = (apiUrl, config, params = null) =>
  axios.get(apiUrl, { headers: config, params });

export const postApiData = (apiUrl, body, config) =>
  axios.post(apiUrl, body, { headers: config });

export const deleteApiData = (apiUrl, params, headers) =>
  axios.delete(apiUrl, { headers, params });

const singleGqlResToDataSet = (res) =>
  res?.data[Object.keys(res?.data)[0]] ?? {};

/**
 * Recursive function for handling single query bulk fetch until null nextToken
 * @param {String} query
 * @param {Object} variables
 * THIS IS NOT TO BE PASSED IN BY YOU @param {*} items from previous query results
 * @returns Array of items
 */
export const singleGqlQueryGetAllItemsUntilNullToken = async (
  query,
  variables,
  items = [], // do not call func with this parameter
) => {
  try {
    const res = await API.graphql({
      query,
      variables,
    });
    const dataSet = singleGqlResToDataSet(res);
    const nextToken = dataSet?.nextToken;
    const gqpItemResponse = dataSet?.items ?? [];
    items = gqpItemResponse?.length ? [...items, ...gqpItemResponse] : items;
    if (nextToken === null) return items;
    else
      return singleGqlQueryGetAllItemsUntilNullToken(
        query,
        { ...variables, nextToken },
        items,
      );
  } catch (err) {
    console.log('[ Graphql Get All Items With Next Token Failed ] - ', err);
  }
  return items;
};
