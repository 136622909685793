import React from 'react';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const CloseButton = ({ close, ...other }) => {
  return (
    <IconButton onClick={close}>
      <Close {...other} />
    </IconButton>
  );
};
export default CloseButton;
