import { syncToWorkForceGlobalState } from './transformations';
import { SAVE_USER_SETTINGS } from '../UserReducer';
import { selectionToClickedToFilter } from '../SelectionReducer/transformations';
import {
  modifiedPartnerMapping,
  typeToActionCreator,
} from '../../transformations/utils/';

export const selectionAll = 'All',
  TODAY_INTERVAL = 'Today',
  CURRENT_INTERVAL = 'Current Interval',
  initialState = {
    checkedIds: [],
    isMenuOpen: false,
    chatExpertSelection: {
      coachSelection: [],
      siteSelection: [],
      statusSelection: [],
      activeCoach: [],
      activeSite: [],
      activeStatus: [],
    },
    chatExperts: [],
    expertNameFilter: '',
    group: {
      available: {},
      selection: [],
      active: [],
    },
    lob: {
      selection: [],
      active: [],
    },
    notifications: {},
    twilioSyncObject: {},
    isButtonSelected: false,
    selectedInterval: CURRENT_INTERVAL,
    workForceGlobalStats: {},
  };

// state selectors
export const selectorToCheckedIds = (state) => state?.expert?.checkedIds ?? [],
  selectorToSyncObject = (state) => state.expert.twilioSyncObject,
  selectorToWorkForceActiveGroups = (state) => {
    const {
      userSettings: { selectedLob = [], selectedQueue = [] } = {},
      lobSelection,
      groupFilter,
      workForceGlobalStats,
      selectedInterval,
      notifications,
    } = state;
    const noSelection = !selectedLob?.length;

    return (noSelection ? lobSelection : selectedLob).reduce((acc, lob) => {
      const lobDetails = workForceGlobalStats[lob];
      const lobNotifications = notifications?.[lob] ?? [];
      if (lobDetails) {
        acc.push(
          ...lobDetails.reduce((acc, group) => {
            const { groupSelectorName = '', routing } = group;
            const hasGroup = selectedQueue?.length
              ? selectedQueue?.includes(groupSelectorName)
              : !groupFilter?.length || groupFilter.includes(groupSelectorName);
            if (hasGroup) {
              const notifications =
                lobNotifications?.filter(({ name }) =>
                  routing?.taskQueues?.includes(name),
                ) ?? [];
              acc.push({
                ...modifiedPartnerMapping(selectedInterval)(group),
                notifications,
              });
            }
            return acc;
          }, []),
        );
      }
      return acc;
    }, []);
  };

//Action types
export const IS_CHECKED = 'IS_CHECKED',
  IS_ADD_EXPERT_OPEN = 'IS_ADD_EXPERT_OPEN',
  IS_MENU_OPEN = 'IS_MENU_OPEN',
  BLUR_MENU = 'BLUR_MENU',
  IS_STAT_EXPANDED = 'IS_STAT_EXPANDED',
  BULK_SET_EXPERTS = 'BULK_SET_EXPERTS',
  SINGLE_EXPERT_UPDATE = 'SINGLE_EXPERT_UPDATE',
  GET_ALL_SUPERVISORS = 'GET_ALL_SUPERVISORS',
  GET_SYNC_OBJECT = 'GET_SYNC_OBJECT',
  IS_BUTTON_SELECTED = 'IS_BUTTON_SELECTED',
  BULK_STATUS_SELECTION_CHANGE = 'BULK_STATUS_SELECTION_CHANGE',
  SINGLE_STATUS_SELECTION_CHANGE = 'SINGLE_STATUS_SELECTION_CHANGE',
  EDIT_ALL_EXPERT_SKILLS = 'EDIT_ALL_EXPERT_SKILLS',
  RESET_EDIT_EXPERT_ATTRIBUTES = 'RESET_EDIT_EXPERT_ATTRIBUTES',
  UPDATE_WORKFORCE_GLOBAL = 'UPDATE_WORKFORCE_GLOBAL',
  EXPERT_FILTER_NAME = 'EXPERT_FILTER_NAME',
  SINGLE_COACH_SELECTION_CHANGE = 'SINGLE_COACH_SELECTION_CHANGE',
  BULK_COACH_SELECTION_CHANGE = 'BULK_COACH_SELECTION_CHANGE',
  SINGLE_SITE_SELECTION_CHANGE = 'SINGLE_SITE_SELECTION_CHANGE',
  BULK_SITE_SELECTION_CHANGE = 'BULK_SITE_SELECTION_CHANGE',
  LOB_QUEUE_USER_CHANGE = 'LOB_QUEUE_USER_CHANGE',
  SINGLE_LOB_SELECTION_CHANGE = 'SINGLE_LOB_SELECTION_CHANGE',
  SINGLE_GROUP_SELECTION_CHANGE = 'SINGLE_GROUP_SELECTION_CHANGE',
  SINGLE_QUEUE_SELECTION_CHANGE = 'SINGLE_QUEUE_SELECTION_CHANGE',
  BULK_LOB_SELECTION_CHANGE = 'BULK_LOB_SELECTION_CHANGE',
  BULK_GROUP_SELECTION_CHANGE = 'BULK_GROUP_SELECTION_CHANGE',
  BULK_QUEUE_SELECTION_CHANGE = 'BULK_QUEUE_SELECTION_CHANGE',
  OPS_PAGE_SINGLE_COACH_SELECTION_CHANGE =
    'OPS_PAGE_SINGLE_COACH_SELECTION_CHANGE',
  OPS_PAGE_BULK_COACH_SELECTION_CHANGE = 'OPS_PAGE_BULK_COACH_SELECTION_CHANGE',
  BULK_SET_CHAT_EXPERTS = 'BULK_SET_CHAT_EXPERTS',
  BULK_UPDATE_CHAT_EXPERTS = 'BULK_UPDATE_CHAT_EXPERTS',
  CHAT_PAGE_COACH_SELECT = 'CHAT_PAGE_COACH_SELECT',
  CHAT_PAGE_STATUS_SELECT = 'CHAT_PAGE_STATUS_SELECT',
  CHAT_PAGE_SITE_SELECT = 'CHAT_PAGE_SITE_SELECT',
  LOB_NOTIFICATIONS = 'LOB_NOTIFICATIONS';
//Action Creators
export const valueToIsCheckedAction = typeToActionCreator(IS_CHECKED),
  valueToIsButtonSelectedAction = typeToActionCreator(IS_BUTTON_SELECTED),
  valueToAddExpertAction = typeToActionCreator(IS_ADD_EXPERT_OPEN),
  valueToIsMenuOpenAction = typeToActionCreator(IS_MENU_OPEN),
  valueToIsMenuBlurAction = typeToActionCreator(BLUR_MENU),
  valueToBulkSetExpertsAction = typeToActionCreator(BULK_SET_EXPERTS),
  valueToSingleExpertUpdateAction = typeToActionCreator(SINGLE_EXPERT_UPDATE),
  valueToGetSupervisorsAction = typeToActionCreator(GET_ALL_SUPERVISORS),
  valueToSetChatExperts = typeToActionCreator(BULK_SET_CHAT_EXPERTS),
  valueToUpdateChatExperts = typeToActionCreator(BULK_UPDATE_CHAT_EXPERTS),
  valueToTwilioSyncAction = typeToActionCreator(GET_SYNC_OBJECT),
  valueToToggleEditAllExperts = typeToActionCreator(EDIT_ALL_EXPERT_SKILLS),
  resetBulkEditExpertAttributes = typeToActionCreator(
    RESET_EDIT_EXPERT_ATTRIBUTES,
  ),
  valueToFilterByExpertName = typeToActionCreator(EXPERT_FILTER_NAME),
  // LOB / Group setting modal change
  valueToLobAndQueueChange = typeToActionCreator(LOB_QUEUE_USER_CHANGE),
  valueToUpdateExpertChatCoachFilter = typeToActionCreator(
    CHAT_PAGE_COACH_SELECT,
  ),
  valueToUpdateExpertChatStatusFilter = typeToActionCreator(
    CHAT_PAGE_STATUS_SELECT,
  ),
  valueToUpdateExpertChatSiteFilter = typeToActionCreator(
    CHAT_PAGE_SITE_SELECT,
  ),
  valueToSetLobNotifications = typeToActionCreator(LOB_NOTIFICATIONS);

//Reducer
export const ExpertReducer = (state = initialState, { type = '', payload }) => {
  switch (type) {
    case IS_ADD_EXPERT_OPEN:
      return { ...state, isAddExpertOpen: !state.isAddExpertOpen };
    case IS_MENU_OPEN:
      return { ...state, isMenuOpen: !payload };
    case BLUR_MENU:
      return { ...state, isMenuOpen: false };
    case EXPERT_FILTER_NAME:
      return { ...state, expertNameFilter: payload };
    case GET_SYNC_OBJECT:
      return { ...state, twilioSyncObject: payload };
    case IS_CHECKED: {
      const { worker_Id: id } = payload;
      const copyArr = [...state.checkedIds];
      const remove = copyArr.findIndex(({ worker_Id }) => worker_Id === id);
      if (remove != -1) {
        copyArr.splice(remove, 1);
      } else if (!copyArr?.some(({ name }) => name === 'all')) {
        copyArr.push(payload);
      }
      return {
        ...state,
        checkedIds: copyArr,
      };
    }
    case IS_BUTTON_SELECTED:
      return state.selectedInterval === TODAY_INTERVAL
        ? { ...state, selectedInterval: CURRENT_INTERVAL }
        : { ...state, selectedInterval: TODAY_INTERVAL };
    case EDIT_ALL_EXPERT_SKILLS:
      return {
        ...state,
        checkedIds: payload,
      };
    case RESET_EDIT_EXPERT_ATTRIBUTES:
      return {
        ...state,
        checkedIds: [],
      };
    case SAVE_USER_SETTINGS: {
      const { selectedInterval = TODAY_INTERVAL } = payload;
      return {
        ...state,
        selectedInterval,
      };
    }
    case BULK_UPDATE_CHAT_EXPERTS: {
      const {
        experts: queuedChatExperts,
        supervisorSelection,
        siteSelection,
        statusSelection,
      } = payload;
      const { chatExperts: oldChatExperts } = state;
      const chatExperts = [...oldChatExperts];
      queuedChatExperts.forEach((expert) => {
        const { emp_id } = expert;
        const replace = chatExperts.findIndex(
          ({ emp_id: compareId }) => compareId === emp_id,
        );
        if (replace !== -1) {
          chatExperts.splice(replace, 1, expert);
        }
      });
      return {
        ...state,
        chatExperts,
        chatExpertSelection: {
          ...state.chatExpertSelection,
          coachSelection: supervisorSelection,
          siteSelection,
          statusSelection,
        },
      };
    }
    case BULK_SET_CHAT_EXPERTS: {
      const { experts, supervisorSelection, siteSelection, statusSelection } =
        payload;
      return {
        ...state,
        chatExperts: experts,
        chatExpertSelection: {
          ...state.chatExpertSelection,
          coachSelection: supervisorSelection,
          siteSelection,
          statusSelection,
        },
      };
    }
    case LOB_NOTIFICATIONS: {
      const { lob, notifications } = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [lob]: notifications,
        },
      };
    }
    default:
      return state;
  }
};

export default ExpertReducer;
