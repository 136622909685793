import React, { useEffect, useState } from 'react';
import { DEFAULT_FUNCTION } from '../../data/datatypes';
import { valueToArrowDirection } from '../../transformations/utils';
import ExpandCollapseArrowComponent from '../ExpandCollapseArrowComponent';
import TextHoverForMoreTextInfo from '../TextHoverTextDisplay';
import { TableCell, Typography } from '@mui/material';
const ACTIVE_ROW = 'orbit-table-open-row';
const INACTIVE_SORTED_HEADER = 'orbit-toggle-inactive-header';

export const ASCENDING = 'asc',
  DESCENDING = 'desc',
  rowPerPageSelection = [4, 8, 16, 32, 100],
  rowPerPageLabel = 'Show: ',
  jumpPageLabel = 'Jump to page:',
  DEFAULT_NUMBER_OF_ROW_VIEW = 100;
const strung = (a) => JSON.stringify(a);
export const directionToIsAscending = (direction) => direction === ASCENDING;
export const directionToOpposingDirection = (direction) =>
  direction === ASCENDING ? DESCENDING : ASCENDING;
export const strictCompare = (a, b) => strung(a) === strung(b);
const headerToToolTip = (ToolTipComponent) => (header) =>
  ToolTipComponent ? (
    <TextHoverForMoreTextInfo
      id={header}
      text={header}
      Component={ToolTipComponent}
    />
  ) : (
    header
  );

const TableHeaderMap = ({
  header,
  click,
  activeSort,
  sortDirection,
  component: C,
  checked,
  onChange,
  ToolTip,
}) => {
  const isActive = activeSort === header;
  const isNotAsc = sortDirection !== ASCENDING;
  const displayHeader = headerToToolTip(ToolTip)(header);
  return (
    <TableCell onClick={click} sx={{ padding: '2px 5px' }}>
      <Typography
        color={isActive ? 'primary.contrastText' : 'primary.main'}
        fontSize={16}
        fontWeight={700}
      >
        {displayHeader}
        {isActive ? (
          <ExpandCollapseArrowComponent
            direction={valueToArrowDirection(isNotAsc)}
          />
        ) : null}
      </Typography>
    </TableCell>
  );
};
export const stringArrayToTableHeader = ({
  header = [],
  tableSortClick,
  ...otherProps
}) =>
  header.map(({ val, allowSort, ...other }) => (
    <TableHeaderMap
      key={val}
      {...otherProps}
      header={val}
      click={() => allowSort && tableSortClick(val)}
      {...other}
    />
  ));

export const stringArrayToTableHeaderStyle = ({ header = [] }) => ({
  gridTemplateColumns: `repeat(${header.length}, '1fr')`,
});

export const propsToPaginatorExtend = ({ header = [] }) => ({
  colSpan: header.length,
});

export const useTableSort =
  (initialTableData = []) =>
  (valueToSwitchSort = DEFAULT_FUNCTION) => {
    const [activePage, setActivePage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(DEFAULT_NUMBER_OF_ROW_VIEW);
    const [tableData, setData] = useState(
      initialTableData.slice(0, rowPerPage),
    );
    const [activeSort, setActiveSort] = useState(null);
    const [sortDirection, setSortDirection] = useState(ASCENDING);
    const [expandedRow, setExpandedRow] = useState(null);
    const allDataLength = initialTableData.length;
    const availablePages = Math.ceil(allDataLength / rowPerPage);
    const setActivePageToLastPage = () => setActivePage(availablePages);
    const setActivePageToFirstPage = () => setActivePage(1);
    useEffect(() => {
      setActivePage(1);
      const copy = [...initialTableData];
      if (activeSort !== null) {
        const sorter = valueToSwitchSort(activeSort)(sortDirection);
        copy.sort(sorter);
      }
      const data = copy.slice(0, rowPerPage);
      setData(data);
    }, [rowPerPage]);
    useEffect(() => {
      const start =
        activePage === 1 ? activePage - 1 : (activePage - 1) * rowPerPage;
      const end = activePage === 1 ? rowPerPage : rowPerPage * activePage;
      const copy = [...initialTableData];
      if (activeSort !== null) {
        const sorter = valueToSwitchSort(activeSort)(sortDirection);
        copy.sort(sorter);
      }
      const data = copy.slice(start, end);
      setData(data);
    }, [activePage, initialTableData]);
    const pageRowSelectionProps = {
      title: 'Limit',
      selections: rowPerPageSelection,
      selected: rowPerPage,
      onChange: (e) => setRowPerPage(e?.target?.value),
    };

    const tableSortClick = (h) => {
      const updatedSortDirection =
        activeSort === h
          ? sortDirection === ASCENDING
            ? DESCENDING
            : ASCENDING
          : ASCENDING;
      if (activeSort !== h) {
        setActiveSort(h);
      }
      setSortDirection(updatedSortDirection);
      const sorter = valueToSwitchSort(h)(updatedSortDirection);
      const start =
        activePage === 1 ? activePage - 1 : (activePage - 1) * rowPerPage;
      const end = activePage === 1 ? rowPerPage : rowPerPage * activePage;
      const mock = [...initialTableData].sort(sorter);
      const newData = mock.slice(start, end);
      setData(newData);
    };
    const tablePaginatorChange = (pageForward) => {
      const page = pageForward ? activePage + 1 : activePage - 1;
      if (pageForward) {
        const availablePages = Math.ceil(allDataLength / rowPerPage);
        const pageIsAvailable = !(page > availablePages);
        if (pageIsAvailable) {
          setActivePage(page);
        }
      } else {
        if (activePage !== 1) {
          setActivePage(page);
        }
      }
    };
    const resultLengthString = `${
      activePage === 1 ? 1 : (activePage - 1) * rowPerPage
    }-${
      rowPerPage > allDataLength ? allDataLength : rowPerPage
    } of ${allDataLength}`;
    return {
      tableSortClick,
      tableData,
      activePage,
      availablePages,
      setActivePageToLastPage,
      setActivePageToFirstPage,
      tablePaginatorChange,
      pageRowSelectionProps,
      resultLengthString,
      activeSort,
      sortDirection,
      expandedRow,
      setExpandedRow,
    };
  };

export const abcSort = (a) => (b) => a.localeCompare(b);
export const numericalSort = (a) => (b) => a - b;
export const booleanSort = (a) => (b) => a === b ? 0 : a ? -1 : 1;
