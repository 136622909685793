import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { API } from 'aws-amplify';
import {
  Grid,
  Paper,
  Button,
  Tooltip,
  Typography,
  Stack,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  expertNameAndStatusStatusQuery,
  workforceTaskQueues,
} from '../../data/queries';
import {
  queueNameTableHeader,
  taskQueueTableSort,
  headerSortKeyToResolveData,
  headerToShouldHoverTooltip,
  taskQDataHeaderAnnotation,
} from './transformations';
import {
  directionToOpposingDirection,
  ASCENDING,
} from '../Table/transformations';
import {
  sortToIntervalToHandleCi,
  graphQlWorkforceTaskQueuesToViewData,
} from '../../reducers/TableDataReducer/transformations';
import { GraphqlTableSort } from '../../reducers/TableDataReducer/datatypes';
import { statusSortKey } from '../../reducers/TableDataReducer/constants';
import { setTimerWithUpdates } from '../DashboardComponent/transformations';
import {
  inTimeToLapsedTime,
  stringToAppendedMinuteAnnotation,
} from '../../reducers/ExpertReducer/transformations';
import { setTaskQueueSortKey } from '../../reducers/UserReducer';
import { buildAppSyncExpertsInQueueQueryFilter } from '../../reducers/SelectionReducer/transformations';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  removeDotFormatFullNameWithSpace,
  numberToPercentValueOfNumber,
} from '../../transformations/utils';

import './index.scss';

export const SortedDirectionArrow = ({ isActive, sortDirection }) => {
  const isAsc = sortDirection === ASCENDING;
  return isActive ? (
    isAsc ? (
      <ArrowDropDown color="primary" />
    ) : (
      <ArrowDropUp color="primary" />
    )
  ) : (
    <></>
  );
};
const contrastTextColor = 'primary.contrastText';
export const TableHeaderCell = ({
  text = '',
  activeSort,
  sortDirection,
  setSort = () => null,
} = {}) => {
  const isActive = activeSort === text;
  const textColor = 'primary.main';
  const fontWeight = isActive ? 'bold' : 'regular';

  const annotatedHeader = taskQDataHeaderAnnotation(text);

  return (
    <Button fullWidth onClick={() => setSort(text)}>
      <Typography fontSize="14px" color={textColor} sx={{ fontWeight }}>
        {annotatedHeader}
      </Typography>
      <SortedDirectionArrow isActive={isActive} sortDirection={sortDirection} />
    </Button>
  );
};

const baseStyleHeader = {
  position: 'sticky',
  whiteSpace: 'nowrap',
  top: 0,
  border: 'none',
  boxShadow: 'none',
  borderRadius: '0px',
};
export const StickHeaderToTableTop = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  top: 0,
  ...baseStyleHeader,
}));
export const StickHeaderToTableTopLeft = styled(Paper)(({ theme }) => ({
  textAlign: 'left',
  left: 0,
  zIndex: 40,
  ...baseStyleHeader,
}));
export const TaskQueueTableHeaders = ({ showDataHeaders, ...props } = {}) => {
  const dataHeaders = showDataHeaders?.map((header) => (
    <StickHeaderToTableTop key={header}>
      <TableHeaderCell text={header} {...props} />
    </StickHeaderToTableTop>
  ));
  return (
    <>
      <StickHeaderToTableTopLeft>
        <TableHeaderCell text={queueNameTableHeader} {...props} />
      </StickHeaderToTableTopLeft>
      {dataHeaders}
    </>
  );
};

export const StickyTaskQueueNameTableCell = styled(Paper)(({ theme }) => ({
  textAlign: 'left',
  position: 'sticky',
  whiteSpace: 'nowrap',
  left: 0,
  padding: '2px',
  boxShadow: 'none',
  borderRadius: '0px',
  borderRight: `4px solid ${theme.palette.primary.main}`,
}));
export const StickyTaskDataTableCell = styled(Paper)(() => ({
  textAlign: 'center',
  padding: '2px',
  border: 'none',
  background: 'transparent',
  boxShadow: 'none',
  borderRadius: '0px',
}));

export const ExpertHoverDisplay = ({ data = [] } = {}) => {
  return data?.length ? (
    data?.map(({ workerName, statusIntime, status }, i) => {
      const timeChange = stringToAppendedMinuteAnnotation(
        inTimeToLapsedTime(statusIntime),
      );
      return (
        <Stack key={workerName + i}>
          <Typography>
            {removeDotFormatFullNameWithSpace(workerName)} ( {status} )
          </Typography>
          <Typography>{timeChange}</Typography>
        </Stack>
      );
    })
  ) : (
    <Typography>No Data...</Typography>
  );
};

export const TaskQDataToolTipFetchOnHover = ({ fetch }) => {
  const tableEl = React.createRef();
  const [data, setData] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiCall, setApiCall] = useState(null);

  const selectedInterval = useSelector(
    (state) => state?.expert?.selectedInterval,
  );

  useEffect(() => {
    let asyncApiCall;
    const asynced = async () => {
      if (loading) {
        asyncApiCall = fetch(nextToken, selectedInterval);
        setApiCall(asyncApiCall);
        asyncApiCall.then(({ items = [], nextToken: newNextToken }) => {
          console.log('[ ToolTip Hover Response ] - ', items);
          setApiCall(null);
          setNextToken(newNextToken);
          setData((existingData) => [...existingData, ...items]);
          setLoading(false);
        });
      }
    };
    asynced();
    return () => API.cancel(asyncApiCall);
  }, [loading]);
  const scrollListener = (e) => {
    if (nextToken !== null && apiCall === null) {
      const scrollDistance = e.target.clientHeight + e.target.scrollTop;
      const percentDifference = numberToPercentValueOfNumber(
        e.target.scrollHeight,
      )(scrollDistance);
      if (percentDifference > 95) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    }
  };
  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef.addEventListener('scroll', scrollListener);
    return () => tableRef.removeEventListener('scroll', scrollListener);
  }, [scrollListener]);
  return (
    <Stack
      spacing={2}
      className="handle-custom-scroll"
      maxHeight="95vh"
      ref={tableEl}
    >
      {loading && !data?.length ? <></> : <ExpertHoverDisplay data={data} />}
      {loading ? <CircularProgress color="primary" /> : <></>}
    </Stack>
  );
};
const fetchNextExperts =
  ({ lob, group, queue, header } = {}) =>
  async (nextToken, interval) => {
    const filter = buildAppSyncExpertsInQueueQueryFilter(
      [lob],
      [group],
      [queue],
      header,
    );
    const defaultSort = new GraphqlTableSort('asc', statusSortKey);
    const variables = {
      filter: JSON.stringify(filter),
      nextToken,
      limit: 100,
      from: 0,
      sort: sortToIntervalToHandleCi({ ...defaultSort })(interval),
    };
    return API.graphql({
      query: expertNameAndStatusStatusQuery,
      variables,
    }).then(
      ({ data: { searchWorkersWithESFilter = {} } = {} } = {}) =>
        searchWorkersWithESFilter,
    );
  };
const TaskQDataCell = ({ dataValue = 0, header, ...fetchDetails } = {}) => {
  const shouldShowToolTip = headerToShouldHoverTooltip(header) && dataValue > 0;
  return (
    <StickyTaskDataTableCell>
      <Tooltip
        sx={{ padding: 0, margin: 0 }}
        placement="right"
        title={
          shouldShowToolTip ? (
            <TaskQDataToolTipFetchOnHover
              fetch={fetchNextExperts({ ...fetchDetails, header })}
            />
          ) : (
            ''
          )
        }
      >
        <Typography>{dataValue}</Typography>
      </Tooltip>
    </StickyTaskDataTableCell>
  );
};
export const TaskQueueTableData = ({
  showDataHeaders,
  queueName,
  lob,
  group,
  ...singleRowTaskQData
} = {}) => {
  const dataRow = showDataHeaders?.map((header) => (
    <TaskQDataCell
      key={header}
      header={header}
      lob={lob}
      group={group}
      queue={queueName}
      dataValue={headerSortKeyToResolveData(header)(singleRowTaskQData)}
    />
  ));
  return (
    <>
      <StickyTaskQueueNameTableCell>{queueName}</StickyTaskQueueNameTableCell>
      {dataRow}
    </>
  );
};
export const TaskQueueDataRows = ({ data, ...other } = {}) =>
  data?.map((props, i) => (
    <TaskQueueTableData key={props?.taskqueueSid || i} {...other} {...props} />
  )) ?? [];

const WorkForceExpandedComponent = ({ lob, group, loading, ...props } = {}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const selectedInterval = useSelector(
    (state) => state?.expert?.selectedInterval ?? '',
  );
  const showDataHeaders = useSelector(
    (state = {}) => state?.user?.userSettings?.taskQueueViews ?? [],
  );
  const userSettingsHeaderSort = useSelector(
    (state) => state?.user?.userSettings?.taskQueueHeaderSort,
  );
  const [sortDirection, setSortDirection] = useState(
    directionToOpposingDirection(),
  );
  const handleHeaderSortChange = (incomingHeader) => {
    setSortDirection(
      directionToOpposingDirection(
        incomingHeader === userSettingsHeaderSort ? sortDirection : '',
      ),
    );
    dispatch(setTaskQueueSortKey(incomingHeader));
  };
  const asyncedFetch = async () => {
    const variables = {
      lob,
      group,
    };
    const response = await API.graphql({
      query: workforceTaskQueues,
      variables,
    });
    console.log('[ Task Queue Response ] ', lob, group, response);
    setData(response);
  };
  useEffect(() => {
    if (loading) {
      asyncedFetch();
    }
  }, [loading]);
  useEffect(() => {
    asyncedFetch();
  }, [lob, group]);
  const sortedView = useMemo(
    () =>
      graphQlWorkforceTaskQueuesToViewData(selectedInterval, data).sort(
        taskQueueTableSort(userSettingsHeaderSort, sortDirection),
      ),
    [selectedInterval, data, userSettingsHeaderSort, sortDirection],
  );
  return (
    <Grid
      container
      display="inline-grid"
      className="workforce-expanded-panel"
      maxHeight="600px"
      gridTemplateColumns={`350px repeat(${
        showDataHeaders?.length ?? 0
      }, minmax(auto, max-content))`}
    >
      <TaskQueueTableHeaders
        showDataHeaders={showDataHeaders}
        setSort={handleHeaderSortChange}
        activeSort={userSettingsHeaderSort}
        sortDirection={sortDirection}
      />
      <TaskQueueDataRows
        {...props}
        showDataHeaders={showDataHeaders}
        data={sortedView}
        lob={lob}
        group={group}
      />
    </Grid>
  );
};

export default WorkForceExpandedComponent;
