import { useSelector, useDispatch } from 'react-redux';
import { API } from 'aws-amplify';
import { useCallback, useEffect } from 'react';
import {
  UPDATE_LOB_FILTER,
  UPDATE_GROUP_FILTER,
  UPDATE_STATUS_FILTER,
  UPDATE_COACH_FILTER,
  UPDATE_USER_INPUT_FILTER,
  UPDATE_SITE_FILTER,
  UPDATE_TASK_QUEUE_FILTER,
} from '../SelectionReducer';
import { IS_BUTTON_SELECTED } from '../ExpertReducer';
import { SAVE_USER_APP_SELECTION } from '../UserReducer';
import {
  expertsToTransformExpertData,
  generateTablePageString,
  zeroPageIndexAndLimitToFirstIndexInView,
  firstIndexInViewAndLimitToLastIndexInView,
  functionToHandleIncomingSort,
  numberMinusOne,
  buildExpertAppSyncQueryFilter,
  expertTableResponseToThrowError,
  sortToIntervalToHandleCi,
} from './transformations';
import { valueToToggleEditAllExperts } from '../../reducers/ExpertReducer';
import {
  dateToFormattedAwsDate,
  sleepWellCb3x,
} from '../../transformations/utils';
import { FetchExpertsWithFilter } from '../../data/queries';
import { dateOfUploadSortKey, employeeIdSortKey } from './constants';
import { templateData } from '../../components/GlobalStats/GlobalCallStats/constants';

const initialState = {
  experts: {
    totalSortedCount: 0,
    items: null,
    limit: 100,
    page: 1,
    sort: {
      direction: 'asc',
      field: employeeIdSortKey,
    },
    loading: true,
    refetch: 0,
  },
  workforce: {
    items: null,
    limit: 100,
    loading: true,
    refetch: 0,
    page: 1,
    sort: {
      direction: 'asc',
      field: 'lob',
    },
    totalSortedCount: 0,
  },
  upsellUpload: {
    loading: true,
    items: null,
    sort: {
      direction: 'asc',
      field: dateOfUploadSortKey,
    },
    page: 1,
    limit: 30,
    refetch: 0,
  },
  globalCallStats: {
    loading: true,
    updatedAt: dateToFormattedAwsDate(),
    summaryStats: null,
    intervalStats: templateData,
    refetch: 0,
  },
  globalExpertStats: {
    loading: true,
    items: null,
    totalCount: 0,
    offlineCount: 0,
    refetch: 0,
  },
};

export const selectorToExpertTableSortedCount = (state) =>
    state?.tableData?.experts?.totalSortedCount ?? 0,
  selectorToArrayOfTableDataChanges = ({
    user: { userSettings: { selectedLob = [], selectedQueue = [] } = {} } = {},
    expert: { selectedInterval = '' } = {},
    tableData: {
      experts: {
        limit: expertsLimit = 0,
        page: expertsPage = 0,
        sort: {
          direction: expertsSortDirection = '',
          field: expertsSortField = '',
        } = {},
      } = {},
      workforce: {
        limit: wfLimit = 0,
        page: wfPage = 0,
        sort: { direction: wfSortDirection = '', field: wfSortField = '' } = {},
      } = {},
    } = {},
  } = {}) => [
    selectedLob?.length,
    selectedQueue?.length,
    expertsLimit,
    expertsPage,
    expertsSortDirection,
    expertsSortField,
    wfLimit,
    wfPage,
    wfSortDirection,
    wfSortField,
    selectedInterval,
  ];

const SET_EXPERT_TABLE_LIMIT = 'SET_EXPERT_TABLE_LIMIT';
const SET_EXPERT_ACTIVE_SORT_DETAILS = 'SET_EXPERT_ACTIVE_SORT_DETAILS';
const SET_EXPERT_TABLE_DATA = 'SET_EXPERT_TABLE_DATA';
const GRAPHQL_UPDATE_TABLE_REFRESH = 'GRAPHQL_UPDATE_TABLE_REFRESH';
const SET_EXPERT_TABLE_PAGE = 'SET_EXPERT_TABLE_PAGE';

const SET_WORKFORCE_TABLE_DATA = 'SET_WORKFORCE_TABLE_DATA';
const SET_WORKFORCE_ACTIVE_SORT_DETAILS = 'SET_WORKFORCE_ACTIVE_SORT_DETAILS';
const SET_WORKFORCE_TABLE_LIMIT = 'SET_WORKFORCE_TABLE_LIMIT';
const SET_WORKFORCE_TABLE_PAGE = 'SET_WORKFORCE_TABLE_PAGE';

const SET_UPSELL_UPLOAD_DATA = 'SET_UPSELL_UPLOAD_DATA';
const SET_UPSELL_UPLOAD_ACTIVE_SORT_DETAILS =
  'SET_UPSELL_UPLOAD_ACTIVE_SORT_DETAILS';

const SET_GLOBAL_CALL_SUMMARY_STATS_DATA = 'SET_GLOBAL_CALL_SUMMARY_STATS_DATA';
const SET_GLOBAL_CALL_INTERVAL_STATS_DATA =
  'SET_GLOBAL_CALL_INTERVAL_STATS_DATA';
const SET_GLOBAL_SET_UPDATED_AT = 'SET_GLOBAL_SET_UPDATED_AT';

const SET_GLOBAL_EXPERT_DATA = 'SET_GLOBAL_EXPERT_DATA';
const SET_GLOBAL_EXPERT_TOTAL = 'SET_GLOBAL_EXPERT_TOTAL';
const SET_GLOBAL_EXPERT_OFFLINE = 'SET_GLOBAL_EXPERT_OFFLINE';

export const workforceTableResponseToState = (payload) => ({
  payload,
  type: SET_WORKFORCE_TABLE_DATA,
});
const expertTableResponseToState = (payload) => ({
  payload,
  type: SET_EXPERT_TABLE_DATA,
});
export const upsellUploadTableResponseToState = (payload) => ({
  payload,
  type: SET_UPSELL_UPLOAD_DATA,
});
export const globalCallSummaryStatsResponseToState = (payload) => {
  return {
    payload,
    type: SET_GLOBAL_CALL_SUMMARY_STATS_DATA,
  };
};
export const globalCallIntervalStatsResponseToState = (payload) => ({
  payload,
  type: SET_GLOBAL_CALL_INTERVAL_STATS_DATA,
});
export const globalCallStatsUpdatedAtResponseToState = (payload) => ({
  payload,
  type: SET_GLOBAL_SET_UPDATED_AT,
});

export const globalExpertStatsResponseToState = (payload) => ({
  payload,
  type: SET_GLOBAL_EXPERT_DATA,
});
export const globalExpertTotalsResponseToState = (payload) => ({
  payload,
  type: SET_GLOBAL_EXPERT_TOTAL,
});
export const globalExpertOfflineCountResponseToState = (payload) => ({
  payload,
  type: SET_GLOBAL_EXPERT_OFFLINE,
});

export const graphqlUpdateTableRefresh = {
  type: GRAPHQL_UPDATE_TABLE_REFRESH,
};

const resetExpertTableState = (state) => ({
  ...state,
  refetch: 0,
  page: 1,
  loading: true,
});
const resetWfTableState = (state) => ({ ...state, loading: true });
// reducer
function graphQlDataTablePagination(
  state = initialState,
  { type = '', payload },
) {
  switch (type) {
    case SAVE_USER_APP_SELECTION:
    case IS_BUTTON_SELECTED:
      return {
        ...state,
        experts: resetExpertTableState(state?.experts),
        workforce: resetWfTableState(state?.workforce),
      };
    case UPDATE_USER_INPUT_FILTER:
    case UPDATE_LOB_FILTER:
    case UPDATE_GROUP_FILTER:
    case UPDATE_COACH_FILTER:
    case UPDATE_STATUS_FILTER:
    case UPDATE_SITE_FILTER:
    case UPDATE_TASK_QUEUE_FILTER: {
      return {
        ...state,
        experts: resetExpertTableState(state?.experts),
      };
    }
    case GRAPHQL_UPDATE_TABLE_REFRESH: {
      return {
        ...state,
        experts: {
          ...state.experts,
          refetch: state.experts.refetch + 1,
          loading: true,
        },
        workforce: {
          ...state.workforce,
          refetch: state.workforce.refetch + 1,
          loading: true,
        },
        upsellUpload: {
          ...state.upsellUpload,
          refetch: state.upsellUpload.refetch + 1,
          loading: true,
        },
      };
    }
    case SET_WORKFORCE_TABLE_DATA: {
      const { items, total } = payload;
      return {
        ...state,
        workforce: {
          ...state.workforce,
          items,
          loading: false,
          totalSortedCount: total === null ? 0 : total,
        },
      };
    }
    case SET_EXPERT_TABLE_DATA: {
      const {
        data: { searchWorkersWithESFilter: { items, total } = {} } = {},
      } = payload || {};
      console.log('[ NEW EXPERTS GraphQL Fetch ] -> ', items, total);
      return {
        ...state,
        experts: {
          ...state.experts,
          items: expertsToTransformExpertData(items),
          totalSortedCount: total === null ? 0 : total,
          loading: false,
        },
      };
    }
    case SET_EXPERT_ACTIVE_SORT_DETAILS: {
      return {
        ...state,
        experts: { ...state.experts, sort: payload, loading: true, page: 1 },
      };
    }
    case SET_EXPERT_TABLE_LIMIT: {
      return {
        ...state,
        experts: {
          ...state.experts,
          limit: payload,
          page: 1,
          refetch: 0,
          loading: true,
        },
      };
    }
    case SET_EXPERT_TABLE_PAGE: {
      return {
        ...state,
        experts: {
          ...state.experts,
          page: payload,
          loading: !(payload === state?.experts?.page),
        },
      };
    }
    case SET_WORKFORCE_ACTIVE_SORT_DETAILS: {
      return {
        ...state,
        workforce: {
          ...state.workforce,
          sort: payload,
          loading: true,
          page: 1,
        },
      };
    }
    case SET_WORKFORCE_TABLE_LIMIT: {
      return {
        ...state,
        workforce: {
          ...state.workforce,
          limit: payload,
          page: 1,
          refetch: 0,
          loading: true,
        },
      };
    }
    case SET_WORKFORCE_TABLE_PAGE: {
      return {
        ...state,
        workforce: {
          ...state.workforce,
          page: payload,
          loading: !(payload === state?.workforce?.page),
        },
      };
    }
    case SET_UPSELL_UPLOAD_DATA: {
      return {
        ...state,
        upsellUpload: {
          ...state.upsellUpload,
          items: payload,
          loading: false,
        },
      };
    }
    case SET_UPSELL_UPLOAD_ACTIVE_SORT_DETAILS: {
      return {
        ...state,
        upsellUpload: {
          ...state.upsellUpload,
          sort: payload,
          loading: true,
        },
      };
    }
    case SET_GLOBAL_CALL_SUMMARY_STATS_DATA: {
      return {
        ...state,
        globalCallStats: {
          ...state.globalCallStats,
          summaryStats: payload,
          loading: false,
        },
      };
    }
    case SET_GLOBAL_CALL_INTERVAL_STATS_DATA: {
      return {
        ...state,
        globalCallStats: {
          ...state.globalCallStats,
          intervalStats: payload,
          loading: false,
        },
      };
    }
    case SET_GLOBAL_SET_UPDATED_AT: {
      return {
        ...state,
        globalCallStats: {
          ...state.globalCallStats,
          updatedAt: payload,
          loading: true,
        },
      };
    }
    case SET_GLOBAL_EXPERT_DATA: {
      return {
        ...state,
        globalExpertStats: {
          ...state.globalExpertStats,
          items: payload,
          loading: false,
        },
      };
    }
    case SET_GLOBAL_EXPERT_TOTAL: {
      return {
        ...state,
        globalExpertStats: {
          ...state.globalExpertStats,
          totalCount: payload,
          loading: false,
        },
      };
    }
    case SET_GLOBAL_EXPERT_OFFLINE: {
      return {
        ...state,
        globalExpertStats: {
          ...state.globalExpertStats,
          offlineCount: payload,
          loading: false,
        },
      };
    }
    default:
      return state;
  }
}
export default graphQlDataTablePagination;

// workforce table actions
export const setWorkforceTableSort = (payload) => ({
  type: SET_WORKFORCE_ACTIVE_SORT_DETAILS,
  payload,
});
export const setWorkforceTableLimit = (payload) => ({
  type: SET_WORKFORCE_TABLE_LIMIT,
  payload,
});
export const setWorkforceTablePage = (payload) => ({
  type: SET_WORKFORCE_TABLE_PAGE,
  payload,
});

// expert table actions
const setExpertTableSort = (payload) => ({
  type: SET_EXPERT_ACTIVE_SORT_DETAILS,
  payload,
});
const setExpertTableLimit = (payload) => ({
  type: SET_EXPERT_TABLE_LIMIT,
  payload,
});
const setExpertTablePage = (payload) => ({
  type: SET_EXPERT_TABLE_PAGE,
  payload,
});

// upsell table actions
export const setUpsellUploadTableSort = (payload) => {
  return {
    type: SET_UPSELL_UPLOAD_ACTIVE_SORT_DETAILS,
    payload,
  };
};

export const useGraphQLExpertTablePagination = (editAll = false) => {
  const dispatch = useDispatch();
  const { page, items, limit, loading, refetch, sort, totalSortedCount } =
    useSelector((state) => state?.tableData?.experts);
  const editAllSelected = useSelector(({ expert: { checkedIds = [] } = {} }) =>
    editAll
      ? checkedIds?.some?.(({ name = '' }) => name === 'all') ?? false
      : false,
  );
  const selectedInterval = useSelector(
    (state) => state?.expert?.selectedInterval,
  );
  const selectionState = useSelector((state) => state?.selection ?? {});
  const zeroIndexPage = numberMinusOne(page);
  useEffect(() => {
    if (!loading && (items?.length || !totalSortedCount)) {
      ///occurs on page nav change, & we already have the fetched experts
      return;
    }
    //Filter is using Query DSL documented here: https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl.html
    const asyncFetch = async () => {
      const filter = buildExpertAppSyncQueryFilter(selectionState);
      const from = !zeroIndexPage ? 0 : zeroIndexPage * limit;
      const variables = {
        sort: sortToIntervalToHandleCi(sort)(selectedInterval),
        filter: JSON.stringify(filter),
        from,
        limit,
      };
      console.log('[ GraphQL Query Fetching... ] ', { ...variables, filter });
      const selections = await sleepWellCb3x(async () => {
        const response = await API.graphql({
          query: FetchExpertsWithFilter,
          variables,
        });
        return expertTableResponseToThrowError(response);
      });
      dispatch(expertTableResponseToState(selections));
    };
    asyncFetch();
  }, [selectionState, page, limit, sort, refetch, selectedInterval]);
  const nextPage = useCallback(() => {
    dispatch(setExpertTablePage(page + 1));
  }, [page]);
  const previousPage = useCallback(() => {
    dispatch(setExpertTablePage(page - 1));
  }, [page]);
  const goToFirstPage = useCallback(() => {
    dispatch(setExpertTablePage(1));
  }, [page]);
  const goToLastPage = useCallback(() => {
    const lastPage = Math.ceil(totalSortedCount / limit);
    dispatch(setExpertTablePage(lastPage));
  }, [page, totalSortedCount, limit]);
  const sortKeyToSortDirection = functionToHandleIncomingSort((payload) =>
    dispatch(setExpertTableSort(payload)),
  );
  const setTableLimit = (value) => dispatch(setExpertTableLimit(value ?? 50));
  const editAllClick = () => {
    dispatch(
      valueToToggleEditAllExperts(editAllSelected ? [] : [{ name: 'all' }]),
    );
  };
  const firstIndexOfCurrentView = zeroPageIndexAndLimitToFirstIndexInView(
    zeroIndexPage,
    limit,
  );
  const lastIndexOfCurrentVew = firstIndexInViewAndLimitToLastIndexInView(
    firstIndexOfCurrentView,
    limit,
  );
  const currentTablePageString = generateTablePageString(
    firstIndexOfCurrentView,
    lastIndexOfCurrentVew,
    totalSortedCount,
  );
  const prevPageAvailable = !!zeroIndexPage;
  const nextPageAvailable = lastIndexOfCurrentVew < totalSortedCount;
  return {
    nextPage,
    previousPage,
    setTableLimit,
    goToLastPage,
    goToFirstPage,
    limit,
    currentTablePageString,
    nextPageAvailable,
    prevPageAvailable,
    setSort: sortKeyToSortDirection,
    sort,
    loading,
    items,
    editAll,
    editAllSelected,
    editAllClick,
  };
};
