import React from 'react';
import {
  Typography,
  TableRow,
  Tooltip,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import IconComponent from '../../IconComponent';
import {
  colorToWifiIcon,
  colorToBoltIcon,
  colorToArrowStackIcon,
} from '../icon';
import { getFormattedTime } from '../../../transformations/utils';
import './index.css';
const WAND_TOOL_TIP = 'wand-tool-tip',
  MATERIAL_INFO_ICON = 'info',
  INFO_ICON_CLASS = 'info-icon';
const WandToolTipTemplate = ({ title = '', term = '', mapColorCode = [] }) => {
  const colorCodes = mapColorCode.map(({ logo, text }) => (
    <div key={text} className="flex-parent-class">
      {logo}
      <Typography sx={{ whiteSpace: 'nowrap' }}>{text}</Typography>
    </div>
  ));
  return (
    <div className={WAND_TOOL_TIP}>
      <Typography variant="h6" color="primary.contrastText">
        {title}
      </Typography>
      <Typography>{term}</Typography>
      {mapColorCode.length ? (
        <section>
          <Typography variant="overline" color="primary.contrastText">
            Color codes
          </Typography>
          <div className="flex-parent-class">{colorCodes}</div>
        </section>
      ) : null}
    </div>
  );
};

export const StyledTooltip = styled(Tooltip)``;
const InfoIcon = () => {
  return (
    <IconComponent
      icon={MATERIAL_INFO_ICON}
      styleClass={INFO_ICON_CLASS}
      iconTitle={MATERIAL_INFO_ICON}
    />
  );
};
const timestamp = 'TimeStamp',
  bitrate = 'Bitrate',
  bandwidth = 'Bandwidth',
  latency = 'Latency',
  bandwidthDefine = 'Maximum rate of data transfer across a given path',
  bitrateDefine =
    'The depth of information that can be transmitted along a digital network, whether in video, audio or text',
  latencyDefine =
    'The time delay of the cause and effect for some physical change in the system being observed';

const greenBitrate = colorToArrowStackIcon('Green'),
  redBitrate = colorToArrowStackIcon('Red'),
  bitRateTermDefine = [
    {
      logo: greenBitrate,
      text: '>35mbps',
    },
    {
      logo: redBitrate,
      text: '<35mbps',
    },
  ],
  greenWifi = colorToWifiIcon('Green'),
  yellowWifi = colorToWifiIcon('Yellow'),
  redWifi = colorToWifiIcon('Red'),
  bandwidthTermDefine = [
    {
      logo: greenWifi,
      text: '15meg ',
    },
    {
      logo: yellowWifi,
      text: '10meg ',
    },
    {
      logo: redWifi,
      text: '5meg ',
    },
  ],
  greenBolt = colorToBoltIcon('Green'),
  yellowBolt = colorToBoltIcon('Yellow'),
  redBolt = colorToBoltIcon('Red'),
  latencyTermDefine = [
    {
      logo: greenBolt,
      text: '<150ms',
    },
    {
      logo: yellowBolt,
      text: '150-250ms',
    },
    {
      logo: redBolt,
      text: '>250ms',
    },
  ];
const WandRow = ({
  LatencyStatus = '',
  BitrateStatus = '',
  BandwidthStatus = '',
  Timestamp = '',
}) => {
  const dateString = getFormattedTime(Timestamp)();
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {dateString}
      </TableCell>
      <TableCell align="center">
        {colorToArrowStackIcon(BitrateStatus)}
      </TableCell>
      <TableCell align="center">{colorToWifiIcon(BandwidthStatus)}</TableCell>
      <TableCell align="center">{colorToBoltIcon(LatencyStatus)}</TableCell>
    </TableRow>
  );
};
const Wand = ({ wand = [] }) => (
  <TableContainer>
    <Table sx={{ minWidth: 350 }} size="small">
      <TableHead>
        <TableRow>
          <TableCell>{timestamp}</TableCell>
          <TableCell align="center">
            <StyledTooltip
              title={
                <WandToolTipTemplate
                  title={bitrate}
                  term={bitrateDefine}
                  mapColorCode={bitRateTermDefine}
                ></WandToolTipTemplate>
              }
            >
              <Typography>
                {bitrate}
                <InfoIcon />
              </Typography>
            </StyledTooltip>
          </TableCell>
          <TableCell align="center">
            <StyledTooltip
              title={
                <WandToolTipTemplate
                  title={bandwidth}
                  term={bandwidthDefine}
                  mapColorCode={bandwidthTermDefine}
                ></WandToolTipTemplate>
              }
            >
              <Typography>
                {bandwidth}
                <InfoIcon />
              </Typography>
            </StyledTooltip>
          </TableCell>
          <TableCell align="center">
            <StyledTooltip
              title={
                <WandToolTipTemplate
                  title={latency}
                  term={latencyDefine}
                  mapColorCode={latencyTermDefine}
                ></WandToolTipTemplate>
              }
            >
              <Typography>
                {latency}
                <InfoIcon />
              </Typography>
            </StyledTooltip>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {wand.map((props, i) => (
          <WandRow
            key={props?.Timestamp + i ?? JSON.stringify(props)}
            {...props}
          />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default Wand;
